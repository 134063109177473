<template>
  <v-card>
    <v-container>
      <v-btn
        class="absolute top right"
        icon
        color="blue darken-1"
        text
        @click="cerrar()"
      >
        <v-icon dark> mdi-close </v-icon>
      </v-btn>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="justify-center">
              <v-card-title>
                {{ item.razon_social }}
              </v-card-title>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="estado"
                  label="Activo en saux"
                  :items="items"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-btn @click="modificarEstado" color="teal lighten-2" text
              >Modificar</v-btn
            >
          </v-form>
        </v-container>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "AgregarLatitudLongitud",
  props: ["item"],
  data() {
    return {
      estado: this.item.activo_saux,
      items: [
        {
          text: "Sí",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],

      valid: true,
    };
  },
  methods: {
    async modificarEstado() {
      try {
        await axios.put(
          `/clientes/modificar_activo_saux?id_cliente=${this.item.id}&nuevo_estado_saux=${this.estado}`
        );

        alert("Estado en saux actualizado");

        this.$emit("clienteModificado");
        this.cerrar();
        this.$refs.form.reset();
        // location.reload();
      } catch (error) {
        alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        console.log(error.response.data);
      }
    },
    cerrar() {
      this.$refs.form.resetValidation();
      this.$emit("cerrarDialog", (this.dialog = false));
    },
  },
  computed: {},
  async created() {},
};
</script>

<style>
.close-btn {
  float: right;
}

.absolute {
  position: absolute;
}

.top {
  top: 10;
}

.right {
  right: 10;
}
</style>
