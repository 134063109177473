<template>
  <v-card>
    <v-container>
      <v-btn
        class="absolute top right"
        icon
        color="blue darken-1"
        text
        @click="cerrar()"
      >
        <v-icon dark> mdi-close </v-icon>
      </v-btn>
      <v-card-text>
        <v-container>
          <form ref="form" @submit.prevent="restablecerContraseña()">
            <v-row>
              <v-col>
                <v-card-title class="justify-center">
                  Restablecer contraseña de: {{ item.nombre }}
                </v-card-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Id de usuario"
                  append-icon="mdi-text-box-multiple-outline"
                  v-model="usuario_id"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Contraseña"
                  append-icon="mdi-lock"
                  v-model="password"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="Confirmar contraseña"
                  append-icon="mdi-lock"
                  v-model="passwordConfirm"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn type="submit" color="teal lighten-2" text>Aceptar</v-btn>
          </form>
        </v-container>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "RestablecerContraseña",
  props: ["item"],
  data() {
    return {
      password: "",
      passwordConfirm: "",
      usuario_id: this.item.id,
      items: [],
      errores: [],
    };
  },
  methods: {
    async restablecerContraseña() {
      if (this.password != this.passwordConfirm) {
        alert("Las contraseñas deben ser iguales");
        this.password = "";
        this.passwordConfirm = "";
      } else {
        try {
          const response = await axios.put(
            `/usuariosrestablecer_password?usuario_id=${this.usuario_id}&nuevo_password=${this.password}`,
            {},
            {
              headers: {
                accept: "application/json",
              },
            }
          );
          console.log(JSON.stringify(response.data));
          alert("Contraseña modificada");
          location.reload();
        } catch (error) {
          if (error) {
            this.errores.push(error);
            alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
          }
          this.serverError = true;
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error.config);
        }
      }
    },
    cerrar() {
      this.$emit("cerrarDialog", (this.dialog = false));
    },
  },
};
</script>

<style>
.close-btn {
  float: right;
}

.absolute {
  position: absolute;
}

.top {
  top: 10;
}

.right {
  right: 10;
}
</style>
