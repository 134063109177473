var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Historial")]),_c('v-divider',{staticClass:"mt-2"}),(_vm.items.length === 0)?_c('div',[_vm._v(" Este "+_vm._s(_vm.objeto)+" no cuenta con historial ")]):(
          _vm.objeto === 'faltante' || _vm.objeto === 'envio' || _vm.objeto === 'pedido'
        )?_c('div',[_c('v-list',_vm._l((_vm.itemsInverso),function(data,index){return _c('v-list-item',{key:data.id},[_c('v-card',{staticClass:"styled-card mt-3"},[_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v("Tipo:")]),_c('span',{staticStyle:{"white-space":"pre-line"},domProps:{"innerHTML":_vm._s(data.funcion)}})]),_c('v-list-item-title',[_c('strong',[_vm._v("Realizado por:")]),_c('span',{staticStyle:{"white-space":"pre-line"},domProps:{"innerHTML":_vm._s(_vm.getVendedor(data.usuario))}})]),_c('v-list-item-title',[_c('strong',[_vm._v("Fecha:")]),_c('span',{staticStyle:{"white-space":"pre-line"},domProps:{"innerHTML":_vm._s(_vm.getFechaLegible(data.fecha_creacion))}})]),(index < _vm.itemsInverso.length - 1)?_c('div',[_c('v-divider',{staticClass:"my-2"}),_c('strong',[_vm._v("Cambios")]),_c('span',{staticClass:"formatted-text",staticStyle:{"white-space":"pre-line"},domProps:{"innerHTML":_vm._s(
                      _vm.getFormattedCambios(
                        _vm.itemsInverso[index + 1].objeto,
                        data.objeto
                      )
                    )}})],1):_vm._e()],1)],1)],1)}),1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }