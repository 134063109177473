import { render, staticRenderFns } from "./ComprasView.vue?vue&type=template&id=3621ed95"
import script from "./ComprasView.vue?vue&type=script&lang=js"
export * from "./ComprasView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports