<template>
  <v-dialog max-width="70%" v-model="dialog">
    <v-card>
      <v-container>
        <v-card-title>
          Agregar laboratorio
          <v-spacer />
          <v-btn
            class="close-btn"
            icon
            value="log in"
            color="blue darken-1"
            text
            @click="cerrar()"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-text-field
                  v-model="nombre"
                  outlined
                  label="Nombre"
                  required
                  :rules="nameRules"
                ></v-text-field>
              </v-row>

              <v-row>
                <v-text-field
                  v-model="nombre_adminpaq"
                  outlined
                  label="Nombre Adminpaq"
                ></v-text-field>
              </v-row>
              <!-- <v-row>
                <v-file-input
                  v-model="logo"
                  counter
                  outlined
                  label="Logo"
                  :rules="nameRules"
                  required
                  show-size
                  small-chips
                  accept="image/png, image/jpeg, image/bmp"
                  truncate-length="13"
                  append-icon="mdi-camera"
                  prepend-icon=""
                ></v-file-input>
              </v-row>
              <v-row>
                <div v-if="previewUrl">
                  <v-row>
                    <h3 class="ml-4">Vista previa de logo:</h3>
                  </v-row>

                  <v-row class="mb-3">
                    <v-col>
                      <img
                        :src="previewUrl"
                        alt="Vista previa de la imagen"
                        style="max-width: 200px"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-row>
              <v-row>
                <v-file-input
                  v-model="imagenes"
                  counter
                  outlined
                  label="Imágenes"
                  multiple
                  show-size
                  small-chips
                  accept="image/png, image/jpeg, image/bmp"
                  truncate-length="13"
                  append-icon="mdi-camera"
                  prepend-icon=""
                  :rules="nameRules"
                  required
                ></v-file-input>
              </v-row>
              <v-row>
                <div v-if="previewUrls.length > 0">
                  <v-row>
                    <h3 class="ml-4">Vista previa de imágenes:</h3>
                  </v-row>
                  <v-row class="mb-3">
                    <div v-for="(url, index) in previewUrls" :key="index">
                      <v-col>
                        <img
                          :src="url"
                          alt="Vista previa de la imágen"
                          style="max-width: 150px"
                        />
                      </v-col>
                    </div>
                  </v-row>
                </div>
              </v-row> -->
              <v-row>
                <v-autocomplete
                  outlined
                  v-model="logo"
                  :items="items"
                  label="Logo"
                  small-chips
                  deletable-chips
                ></v-autocomplete>
              </v-row>
              <v-row>
                <div v-if="logoSrc">
                  <v-row>
                    <h3 class="ml-4">Vista previa de logo:</h3>
                  </v-row>

                  <v-row class="mb-3">
                    <v-col>
                      <img
                        :src="logoSrc"
                        alt="Vista previa de la imagen"
                        style="max-width: 200px"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-row>
              <v-row>
                <v-autocomplete
                  outlined
                  v-model="imagenes"
                  :items="items"
                  label="Imágenes"
                  small-chips
                  deletable-chips
                  multiple
                  @change="cambioImagenes"
                ></v-autocomplete>
              </v-row>

              <v-row>
                <div v-if="imagenes.length > 0">
                  <v-row>
                    <h3 class="ml-4">Vista previa de imágenes:</h3>
                  </v-row>
                  <v-row class="mb-3">
                    <div v-for="nombre in imagenes" :key="nombre">
                      <v-col>
                        <img
                          :src="imagenesSolicitadas[nombre]"
                          alt="Vista previa de la imágen"
                          style="max-width: 150px"
                        />
                      </v-col>
                    </div>
                  </v-row>
                </div>
              </v-row>

              <v-row>
                <v-col>
                  <v-autocomplete
                    outlined
                    v-model="clasificacion"
                    :items="clasificaciones"
                    label="Clasificación"
                    small-chips
                    deletable-chips
                    multiple
                  ></v-autocomplete>
                  <v-select
                    outlined
                    v-model="activo"
                    :items="activoItems"
                    label="Activo"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-textarea
                    v-model="descripcion"
                    outlined
                    label="Descripción"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-btn
                :disabled="!valid"
                @click="crearLaboratorio"
                color="teal lighten-2"
                text
                >Crear laboratorio</v-btn
              >
            </v-form>
          </v-container>
        </v-card-text>
      </v-container>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-2" v-on="on" v-bind="attrs">Agregar laboratorio</v-btn>
    </template>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["items"],
  data() {
    return {
      // items: [],
      valid: true,
      dialog: false,
      nombre: "",
      nombre_adminpaq: "",
      clasificaciones: ["Veterinaria", "Plagas"],
      clasificacion: [],
      logo: "",
      logoSrc: null,
      imagenes: [],
      imagenesSeleccionadas: {},
      imagenesSolicitadas: {},
      previewUrl: null,
      previewUrls: [],
      descripcion: "",
      activoItems: [
        {
          text: "Sí",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      activo: true,
      // rules: {
      //   required: (value) => !!value || "No puede estar vacio",
      // },
      nameRules: [(v) => !!v || "No puede estar vacio"],
    };
  },
  watch: {
    logo(nuevoLogo) {
      // Aquí realizas la llamada a tu API para obtener la imagen según el nombre seleccionado
      // console.log(nuevoLogo);
      if (nuevoLogo) {
        this.getImagen(nuevoLogo);
      } else {
        this.logoSrc = null;
      }
    },

    // imagenes(nombreImagen) {
    //   // Realiza la llamada a tu API para obtener las imágenes correspondientes a los nombres seleccionados
    //   // Comprobar aqui se se ha solicitado la imagen antes
    //   if (!this.imagenesSolicitadas[nombreImagen]) {
    //     //Si no se encuentra la imagen, se debe hacer una peticion al API
    //     this.getImagenesDesdeAPI(nombreImagen).then((imagenes) => {
    //       this.imagenesSeleccionadas = imagenes;
    //     });
    //   }
    // },
  },
  methods: {
    cambioImagenes() {
      // Realiza la llamada a tu API para obtener las imágenes correspondientes a los nombres seleccionados
      // Comprobar aqui se se ha solicitado la imagen antes
      this.imagenes.forEach((element) => {
        if (!this.imagenesSolicitadas[element]) {
          //Si no se encuentra la imagen, se debe hacer una peticion al API
          this.getImagenesDesdeAPI(element).then((imagen) => {
            this.imagenesSeleccionadas = imagen;
          });
        }
      });
    },
    async getImagenesDesdeAPI(nombreImagen) {
      try {
        // Realiza la llamada a tu API para obtener las imágenes correspondientes a los nombres seleccionados
        // const respuestas = await Promise.all(
        //   nombreImagen.map(async (nombreImagen) => {
        //     const response = await axios.get(
        //       `/imagenes/get/${nombreImagen}`,
        //       {
        //         responseType: "arraybuffer",
        //       }
        //     );
        //     return { nombre: nombreImagen, data: response.data };
        //   })
        // );

        const response = await axios.get(`/imagenes/get/${nombreImagen}`, {
          responseType: "arraybuffer", // Importante: Configura el tipo de respuesta como 'arraybuffer'
        });
        const respuestaImagen = { nombre: nombreImagen, data: response.data };

        const blob = new Blob([response.data], { type: "image/jpg" }); // Ajusta el tipo de imagen según corresponda
        const imageUrl = URL.createObjectURL(blob);
        this.$set(this.imagenesSolicitadas, respuestaImagen.nombre, imageUrl);

        // console.log(respuestas);
        // Crea un objeto para almacenar las imágenes con los nombres como clave
        // const imagenes = {};

        // Convierte las respuestas en URLs y almacénalas en el objeto 'imagenes'
        // respuestas.forEach((respuesta) => {
        //   const blob = new Blob([respuesta.data], { type: "image/jpg" }); // Ajusta el tipo de imagen según corresponda
        //   const imageUrl = URL.createObjectURL(blob);
        //   imagenes[respuesta.nombre] = imageUrl;
        //   this.$set(this.imagenesSolicitadas, respuesta.nombre, imageUrl);
        //   console.log(this.imagenesSolicitadas);
        // });

        // 'imagenes' ahora contiene las URLs de las imágenes correspondientes a los nombres seleccionados
        // console.log(imagenes);
        return respuestaImagen;
      } catch (error) {
        console.error("Error al obtener la imágen:", error);
      }
    },
    async crearLaboratorio() {
      if (this.$refs.form.validate()) {
        const datosLaboratorio = {
          nombre: this.nombre,
          nombre_adminpaq: this.nombre_adminpaq,
          logo: this.logo,
          imagenes: this.imagenes,
          clasificaciones: this.clasificacion,
          descripcion: this.descripcion,
          activo: this.activo,
        };
        console.log(datosLaboratorio);
        try {
          const response = await axios.post(
            `/laboratorios/crear_laboratorio`,
            datosLaboratorio
          );
          alert("Laboratorio creado");
          this.dialog = !this.dialog;
          this.$refs.form.reset();
          this.$emit("cambioLaboratorios");
          console.log(response.data);
        } catch (error) {
          console.error("Error al agregar laboratorio:", error);
          alert("Error al agregar laboratorio:", error);
        }
      }
    },
    cerrar() {
      this.$refs.form.resetValidation();
      this.dialog = !this.dialog;
    },
    // async getNombreImagenes() {
    //   const response = await axios.get("/imagenes/get");
    //   this.items = response.data.imagenes;
    // },
    async getImagen(nombreImagen) {
      try {
        const response = await axios.get(`/imagenes/get/${nombreImagen}`, {
          responseType: "arraybuffer", // Importante: Configura el tipo de respuesta como 'arraybuffer'
        });

        // Convierte la respuesta en un Blob y crea una URL para mostrar la imagen
        const blob = new Blob([response.data], { type: "image/jpg" }); // Ajusta el tipo de imagen según corresponda
        const imageUrl = URL.createObjectURL(blob);

        // Ahora puedes mostrar la imagen en tu componente
        this.logoSrc = imageUrl;
      } catch (error) {
        console.error("Error al obtener la imagen:", error);
      }
    },
    // async getImagenesDesdeAPI(nombresImagenes) {
    //   try {
    //     // console.log(nombresImagenes);
    //     // Realiza la llamada a tu API para obtener las imágenes correspondientes a los nombres seleccionados
    //     const respuestas = await Promise.all(
    //       nombresImagenes.map(async (nombresImagenes) => {
    //         const response = await axios.get(
    //           `/imagenes/get/${nombresImagenes}`,
    //           {
    //             responseType: "arraybuffer",
    //           }
    //         );
    //         return { nombre: nombresImagenes, data: response.data };
    //       })
    //     );

    //     console.log(respuestas);
    //     // Crea un objeto para almacenar las imágenes con los nombres como clave
    //     const imagenes = {};

    //     // Convierte las respuestas en URLs y almacénalas en el objeto 'imagenes'
    //     respuestas.forEach((respuesta) => {
    //       const blob = new Blob([respuesta.data], { type: "image/jpg" }); // Ajusta el tipo de imagen según corresponda
    //       const imageUrl = URL.createObjectURL(blob);
    //       imagenes[respuesta.nombre] = imageUrl;
    //       this.$set(this.imagenesSolicitadas, respuesta.nombre, imageUrl);
    //       console.log(this.imagenesSolicitadas);
    //     });

    //     // 'imagenes' ahora contiene las URLs de las imágenes correspondientes a los nombres seleccionados
    //     // console.log(imagenes);
    //     return imagenes;
    //   } catch (error) {
    //     console.error("Error al obtener las imágenes:", error);
    //   }
    // },
  },
  computed: {},
  mounted() {
    // Cargar objetos desde el almacenamiento local al iniciar el componente
    // this.getNombreImagenes();
  },
  created() {},
};
</script>

<style>
.close-btn {
  float: right;
}
</style>
