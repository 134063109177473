<template>
  <v-container>
    <v-switch
      class="mr-2"
      color="success"
      v-model="switch1"
      @change="cambiarActivo"
    >
    </v-switch>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "ClienteActivo",
  props: ["item"],
  data() {
    return {
      switch1: "",
      usuario_id: this.item.id,
      errores: [],
    };
  },
  methods: {
    async cambiarActivo() {
      await axios
        .put(`/usuarios/cambiar_activo?usuario_id=${this.usuario_id}`)
        .catch((error) => {
          if (error) {
            this.errores.push(error);
            alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
          }
          this.serverError = true;
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
          }
        });
    },
  },
  created() {
    this.switch1 = this.item.activo;
  },
};
</script>
