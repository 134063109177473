<template>
  <v-card>
    <v-card-text>
      <v-container>
        <form ref="form" @submit.prevent="modificarFaltante()">
          <v-row>
            <v-col>
              <v-card-title class="justify-center">
                <div>Nombre del faltante: {{ props.nombre }}</div>
              </v-card-title>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="ID"
                outlined
                v-model="props.id"
                append-icon="mdi-identifier"
                readonly
                :value="props.id"
              ></v-text-field> </v-col
          ></v-row>
          <v-row v-if="props.observaciones">
            <v-col>
              <v-text-field
                label="Observaciones"
                outlined
                :value="props.observaciones"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <v-text-field
                label="Observaciones"
                outlined
                value="No hay observaciones"
                readonly
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                outlined
                label="Nuevo estado"
                v-model="props.nuevo_estado"
                :items="items"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                label="Detalles"
                outlined
                v-model="props.detalles"
                :value="props.detalles"
              ></v-textarea>
            </v-col>
            <v-card
              v-if="
                props.nuevo_estado === 'En Camino' ||
                props.nuevo_estado === 'En Calendario'
              "
            >
              <div v-if="!esFechaValida(this.props.detalles)">
                {{ actualizarDetalles() }}
              </div>

              <v-card-title class="justify-center"
                >Selecciona un día</v-card-title
              >
              <v-row justify="center">
                <v-date-picker
                  no-title
                  color="light-green"
                  v-model="props.detalles"
                  :min="nowDate"
                  locale="es-MX"
                >
                </v-date-picker>
              </v-row>
            </v-card>
          </v-row>
          <v-btn class="mb-5" type="submit" color="teal lighten-2" text
            >Modificar</v-btn
          >
          <!-- <v-row>
            <DesplegarComentarios
              :item="item"
              :usuarios="usuarios"
            ></DesplegarComentarios>
          </v-row> -->
        </form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
// import DesplegarComentarios from "./DesplegarComentarios.vue";
export default {
  name: "ModificarFaltante",
  props: ["item", "usuarios"],
  data() {
    return {
      props: {
        nombre: this.item.nombre,
        id: this.item.id,
        nuevo_estado: this.item.estado,
        detalles: this.item.detalle ? this.item.detalle : "",
        observaciones: this.item.observaciones,
      },
      nowDate: new Date().toISOString().slice(0, 10),
      currentPlantilla: "",
      items: [
        {
          text: "Cancelado",
          value: "Cancelado",
        },
        {
          text: "Facturado",
          value: "Facturado",
        },
        {
          text: "En Camino",
          value: "En Camino",
        },
        {
          text: "Requiere Aclaración",
          value: "Requiere Aclaración",
        },
        {
          text: "En Calendario",
          value: "En Calendario",
        },
        {
          text: "En Inventario",
          value: "En Inventario",
        },
      ],
    };
  },
  methods: {
    actualizarDetalles() {
      this.props.detalles = this.nowDate;
    },
    esFechaValida(fechaString) {
      const fecha = new Date(fechaString);
      // Comprueba si la fecha es válida y si el string tiene el formato ISO
      return !isNaN(fecha) && fechaString === fecha.toISOString().slice(0, 10);
    },
    async modificarFaltante() {
      try {
        if (this.props.nuevo_estado === "Requiere Aclaración") {
          this.props.nuevo_estado = "Requiere Aclaracion";
        }
        const response = await axios.post(
          `/faltantes/modificar_faltante?id_faltante=${this.props.id}&nuevo_estado=${this.props.nuevo_estado}&detalles=${this.props.detalles}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        console.log(JSON.stringify(response.data));
        // alert(JSON.stringify(response.data));
        alert("Faltante modificado");
        location.reload();
      } catch (error) {
        console.error(error);
        alert(JSON.stringify(error.response.data.detail)); // Handle any errors that might occur
      }
    },
  },
  components: {},
};
</script>
