<template>
  <v-dialog max-width="70%" v-model="dialog" persistent>
    <v-card>
      <v-card-title>
        Nuevo Faltante
        <v-spacer></v-spacer>
        <v-btn icon value="log in" color="blue darken-1" text @click="cerrar()">
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <form ref="form" @submit.prevent="crearPerfil()">
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="codigo_cliente"
                  :items="clientes"
                  :search-input.sync="searchClientes"
                  :loading="isLoadingCliente"
                  item-text="Description"
                  item-value="Cliente"
                  outlined
                  clearable
                  label="Código de cliente"
                  append-icon="mdi-barcode"
                  hide-no-data
                  hide-selected
                  placeholder="Busca el cliente"
                  @change="codigo_cliente = $event"
                  :disabled="!sucursal"
                >
                  <template #item="{ item }">
                    <div>
                      <v-list-item-title>{{ item.codigo }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.razon_social
                      }}</v-list-item-subtitle>
                    </div>
                  </template>
                </v-autocomplete>
                <v-row v-if="!sucursal">
                  <v-col>
                    <v-alert
                      color="warning"
                      outlined
                      prominent
                      :value="true"
                      icon="mdi-alert-outline"
                    >
                      Debes seleccionar una sucursal para poder editar el código
                      de cliente y seleccionar un vendedor.
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-row v-if="sucursal"
                    ><v-col
                      ><v-container
                        >¿No encuentras el Cliente?
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div :elevation="0" dark v-bind="attrs" v-on="on">
                              <AgregarCliente
                                :clientes="searchClientes"
                                @actualizarInformacion="actualizarInformacion"
                              />
                            </div>
                          </template>
                          <span>Nuevo cliente</span>
                        </v-tooltip></v-container
                      ></v-col
                    ></v-row
                  >
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-select
                  append-outer-icon="mdi-office-building"
                  label="Sucursal"
                  outlined
                  :items="sucursales"
                  v-model="sucursal"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="codigo_producto"
                  :items="productos"
                  :search-input.sync="search"
                  :loading="isLoading"
                  item-text="Description"
                  item-value="codigo"
                  outlined
                  clearable
                  label="Código de producto"
                  append-icon="mdi-barcode"
                  hide-no-data
                  hide-selected
                  placeholder="Busca el producto"
                  @change="codigo_producto = $event"
                >
                  <template #item="{ item }">
                    <div>
                      <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.codigo
                      }}</v-list-item-subtitle>
                    </div>
                  </template>
                </v-autocomplete>
                <v-row>
                  <v-container v-if="!isValidProduct"
                    >¿No encuentras el producto?
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div :elevation="0" dark v-bind="attrs" v-on="on">
                          <AgregarProducto
                            :item="codigo_producto"
                            @actualizarInformacion="actualizarInformacion"
                          />
                        </div>
                      </template>
                      <span>Nuevo Producto</span>
                    </v-tooltip></v-container
                  >
                </v-row>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  label="Cantidad"
                  append-icon="mdi-numeric"
                  v-model="cantidad"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  outlined
                  v-model="id_vendedor"
                  :disabled="!sucursal"
                  :items="getFilteredUsuarios"
                  :item-text="(user) => user.nombre"
                  :item-value="(user) => user.id"
                  :filterable="true"
                  :filter-method="filterUserBySucursal"
                  :clearable="true"
                  :label="'Selecciona el vendedor'"
                >
                </v-select>
                <!-- <v-text-field
                  outlined
                  label="ID de vendedor"
                  append-icon="mdi-card-account-details"
                  v-model="id_vendedor"
                  required
                ></v-text-field> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  label="Observaciones"
                  v-model="observaciones"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn type="submit" color="teal lighten-2" text> Enviar </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="teal lighten-2" text @click="cerrar()">
                Cerrar
              </v-btn>
            </v-card-actions>
          </form>
        </v-container>
      </v-card-text>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        color="deep-orange lighten-3"
        class="mr-2"
        v-on="on"
        v-bind="attrs"
      >
        Crear Faltante
      </v-btn>
    </template>
  </v-dialog>
</template>

<script>
import axios from "axios";
import AgregarProducto from "./AgregarProducto.vue";
import AgregarCliente from "./AgregarCliente.vue";
export default {
  props: [],
  data() {
    return {
      codigo_cliente: "",
      sucursal: "",
      codigo_producto: "",
      cantidad: "",
      id_vendedor: "",
      observaciones: "",
      dialog: false,
      isLoading: false,
      isLoadingCliente: false,
      search: null,
      searchClientes: null,
      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      errores: [],
      // productos: [],
      entries: [],
      entriesClientes: [],
      descriptionLimit: 60,
      usuarios: [],
    };
  },
  methods: {
    actualizarInformacion(nuevaInformacion) {
      this.entriesClientes = nuevaInformacion;
      this.entries = nuevaInformacion;
    },
    filterUserBySucursal(query, item) {
      if (item.nombre_adminpaq === null) {
        return false;
      }
      return item.sucursal.toLowerCase().indexOf(query.toLowerCase()) > -1;
    },
    async getUsuarios() {
      try {
        const response = await axios.get(`/usuarios/get_usuarios`);
        this.usuarios = response.data.usuarios;
      } catch (error) {
        console.error("Error al obtener los usuarios:", error);
      }
    },
    filterBySucursal(user) {
      // Check if the user's sucursal is equal to the selected sucursal.
      if (user.sucursal === this.sucursal) {
        // If the user's sucursal is equal to the selected sucursal, return the user.
        return user;
      }

      // If the user's sucursal is not equal to the selected sucursal, return undefined.
      return undefined;
    },
    toUpperCase() {
      this.codigo_producto = this.codigo_producto.toUpperCase();
    },
    async crearPerfil() {
      const payload = {
        codigo_cliente: this.codigo_cliente.split(" ")[0],
        sucursal: this.sucursal,
        codigo_producto: "" + this.codigo_producto,
        cantidad: this.cantidad,
        id_vendedor: this.id_vendedor,
        observaciones: this.observaciones,
      };
      const response = await axios
        .post(`/faltantes/crear`, payload)
        .catch((error) => {
          if (error) {
            this.errores.push(error);
            alert(`Error: ${JSON.stringify(error.response.data)}`);
          }
          this.serverError = true;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
      console.log(JSON.stringify(response.data));
      alert("Faltante creado");
      this.codigo_cliente = "";
      this.sucursal = "";
      this.codigo_producto = "";
      this.cantidad = "";
      this.id_vendedor = "";
      this.observaciones = "";
      //location.reload();
      //this.cerrar();
    },
    /*     async getProductos() {
          const response = await axios.get(`/productos/get`);
          this.productos = response.data;
          console.log(this.codigo_producto);
        }, */
    cerrar() {
      this.dialog = false;
      window.location.reload();
      //this.dialog = !this.dialog;
    },
  },
  computed: {
    getFilteredUsuarios() {
      return this.usuarios.filter((user) => {
        return user.sucursal === this.sucursal && user.nombre_adminpaq !== null;
      });
    },
    filteredClientes() {
      return this.clientes.filter(
        (cliente) => cliente.sucursal === this.sucursal
      );
    },
    fields() {
      if (!this.codigo_producto) return [];
      return Object.keys(this.codigo_producto).map((key) => {
        return {
          key,
          value: this.codigo_producto[key] || "n/a",
        };
      });
    },
    fieldsCliente() {
      if (!this.codigo_cliente) return [];
      return Object.keys(this.codigo_cliente).map((key) => {
        return {
          key,
          value: this.codigo_cliente[key] || "n/a",
        };
      });
    },
    productos() {
      return this.entries.map((entry) => {
        const codigoDescription =
          entry.codigo.length > this.descriptionLimit
            ? entry.codigo.slice(0, this.descriptionLimit) + "..."
            : entry.codigo;
        const nombreDescription =
          entry.nombre.length > this.descriptionLimit
            ? entry.nombre.slice(0, this.descriptionLimit) + "..."
            : entry.nombre;
        return Object.assign({}, entry, {
          Description: `${codigoDescription} - ${nombreDescription}`,
        });
      });
    },
    clientes: {
      get() {
        // Return the computed value of "clientes"
        return this.entriesClientes.map((entry) => {
          const codigoDescription =
            entry.codigo.length > this.descriptionLimit
              ? entry.codigo.slice(0, this.descriptionLimit) + "..."
              : entry.codigo;
          const nombreDescription =
            entry.razon_social.length > this.descriptionLimit
              ? entry.razon_social.slice(0, this.descriptionLimit) + "..."
              : entry.razon_social;
          return Object.assign({}, entry, {
            Description: `${codigoDescription} - ${nombreDescription}`,
          });
        });
      },
      set(value) {
        // Assign a new value to "entriesClientes"
        this.entriesClientes = value;
      },
    },
    isValidProduct() {
      return this.productos.some(
        (producto) => producto.codigo === this.codigo_producto
      );
    },
    selectedTabLocal: {
      get() {
        return this.selectedTab;
      },
    },
  },
  watch: {
    search() {
      // Items have already been loaded
      if (this.productos.length > 0) return;
      // Items have already been requested
      if (this.isLoading) return;
      this.isLoading = true;
      // Lazily load input items
      axios
        .get("/productos/get")
        .then((response) => {
          const entries = response.data;
          this.count = entries.length;
          this.entries = entries;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    searchClientes() {
      // Items have already been loaded
      if (this.clientes.length > 0) return;
      // Items have already been requested
      if (this.isLoading) return;
      this.isLoading = true;
      // Lazily load input items
      axios
        .get(`/clientes/get_nombres`) // Update the endpoint to include the selected "sucursal"
        .then((response) => {
          const entriesClientes = response.data.filter(
            (cliente) => cliente.sucursal === this.sucursal
          );
          // const entriesClientes = response.data;
          this.count = entriesClientes.length;
          this.entriesClientes = entriesClientes;
          console.log(this.entriesClientes);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    sucursal() {
      // Items have already been requested
      if (this.isLoading) return;
      this.isLoading = true;
      // Lazily load input items
      axios
        .get(`/clientes/get_nombres`) // Update the endpoint to include the selected "sucursal"
        .then((response) => {
          // const entriesClientes = response.data;
          const entriesClientes = response.data.filter(
            (cliente) => cliente.sucursal === this.sucursal
          );
          this.count = entriesClientes.length;
          this.entriesClientes = entriesClientes;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  created() {
    this.getUsuarios();
  },
  components: { AgregarProducto, AgregarCliente },
};
</script>
