<template>
  <v-card>
    <!-- <v-card-title>Ventas por mes</v-card-title> -->
    <v-card-text>
      <apexchart
        :options="chartOptions"
        :series="chartSeries"
        type="bar"
        height="600"
        width="100%"
      ></apexchart>
      <h3 class="text-center mb-3">Diferencia:</h3>
      <!-- se relaciona el item de los datosDiferencia por medio de un indice, siendo 12
       en total de acuerdo con los meses -->
      <v-row justify-center>
        <v-col
          v-for="(item, index) in datosDiferencia"
          :key="index"
          cols="3"
          md="1"
          sm="3"
        >
          <!-- posteriormente se muestra ese item con la funcion formatoPrecio(formateo de numero)  -->
          <div class="text-center">
            <span
              class="text-subtitle-2"
              :style="item >= 0 ? 'color: green' : 'color: red'"
            >
              {{ formatoPrecio(item) }}
            </span>
          </div>
          <!-- </v-col> -->

          <!-- <v-col
          v-for="(item, index) in meses"
          :key="index"
          cols="12"
          md="1"
          sm="2"
        > -->
          <!-- Finalizando con mostrar el array de meses y comporandolo con el indice de datos diferencia y mostrandolos juntos y orden correspondido-->
          <div class="text-center mt-2">
            <span class="text-subtitle-2">
              {{ meses[index] }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: ["chartSeries", "datosDiferencia"],
  data() {
    return {
      currentYear: new Date().getFullYear(),
      chartOptions: {
        title: {
          text: "Ventas por mes",
          align: "center",
          margin: 8,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "22px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263238",
          },
        },
        chart: {
          id: "mychart",
          type: "bar",
          locales: [
            {
              name: "es",
              options: {
                months: [
                  "Enero",
                  "Febrero",
                  "Marzo",
                  "Abril",
                  "Mayo",
                  "Junio",
                  "Julio",
                  "Agosto",
                  "Septiembre",
                  "Octubre",
                  "Noviembre",
                  "Diciembre",
                ],
                shortMonths: [
                  "Ene",
                  "Feb",
                  "Mar",
                  "Abr",
                  "May",
                  "Jun",
                  "Jul",
                  "Ago",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dic",
                ],
                days: [
                  "Domingo",
                  "Lunes",
                  "Martes",
                  "Miércoles",
                  "Jueves",
                  "Viernes",
                  "Sábado",
                ],
                shortDays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
                toolbar: {
                  exportToSVG: "Descargar SVG",
                  exportToPNG: "Descargar PNG",
                  exportToCSV: "Descargar CSV",
                  menu: "Menu",
                  selection: "Seleccionar",
                  selectionZoom: "Seleccionar Zoom",
                  zoomIn: "Aumentar",
                  zoomOut: "Disminuir",
                  pan: "Navegación",
                  reset: "Reiniciar Zoom",
                },
              },
            },
          ],
          defaultLocale: "es",
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#333"],
            fontSize: "14px",
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            borderRadius: 2,
            padding: 8,
            opacity: 0.7,
            borderWidth: 1,
            borderColor: "#fff",
          },
          formatter: function (val) {
            // Formatear el numero para solo mostrar la cantidad de millares
            const millares = Math.floor(val / 1000);
            return millares.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        colors: ["#FF1654", "#247BA0"],
        stroke: {
          width: [2, 2],
        },
        plotOptions: {
          bar: {
            columnWidth: "60%",
            borderRadius: 3,
            borderRadiusApplication: "end",
          },
        },

        xaxis: {
          categories: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
          ],
        },
        responsive: [
          {
            breakpoint: 800,
            yaxis: {
              categories: [
                "Ene",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Sep",
                "Oct",
                "Nov",
                "Dic",
              ],
            },
            xaxis: {
              labels: {},
            },
            options: {
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
            },
          },
        ],

        yaxis: {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            // color: "#FF1654",
          },
          labels: {
            //nueva funcion de formateo para el grafico vertical y horizontal
            formatter: function (value) {
              // if (isNaN(value)) return value;
              //verifica si el valor puesto de manera horizontal es vacio o indefinido si es asi retorna el valor de los meses
              if (isNaN(value) || value === null || value === undefined) {
                return value;
              }

              let numeroPrecio = Number(value.toFixed(2));

              //separar parte entera y parte decimal
              let partes = numeroPrecio.toString().split(".");
              let parteEntera = partes[0];
              let parteDecimal = partes.length > 1 ? "." + partes[1] : "";

              //agregar separadores de miles a la parte entera
              parteEntera = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              return "$" + parteEntera + parteDecimal;
            },
            /*formatter: function (value) {
              // Formatear el número utilizando una función personalizada
              const numeroPrecio = parseFloat(value);
              const precio = numeroPrecio.toFixed(2); // Limitar a 2 decimales
              const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
              const [parteEntera, parteDecimal] =
                precio.split(separadorDecimal);

              // Formatear la parte entera con separador de miles
              const parteEnteraFormateada = parteEntera.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              );

              return `$${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
            },*/
          },
          /*title: {
            text: "Ventas",
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: undefined,
              color: "#263238",
              // color: "#FF1654",
            },
          },*/
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        legend: {
          horizontalAlign: "center",
        },
      },
      meses: [
        "ENE",
        "FEB",
        "MAR",
        "ABR",
        "MAY",
        "JUN",
        "JUL",
        "AGO",
        "SEP",
        "OCT",
        "NOV",
        "DIC",
      ],
    };
  },
  //funcion para remplazar la parte entera a string
  methods: {
    formatoPrecio(precio) {
      /*if (typeof precio === "number" && !isNaN(precio)) {
        return precio
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }*/
      const numero = parseFloat(precio);
      const parteEntera = Math.floor(numero);
      return parteEntera.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  watch: {},
  computed: {},
  components: { apexchart: VueApexCharts },
};
</script>

<style scoped></style>
