<template>
  <v-container>
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Imágenes <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div :elevation="0" dark v-bind="attrs" v-on="on">
              <AgregarImagen @imagenesCambiadas="getImagenes"></AgregarImagen>
            </div>
          </template>
          <span>Nueva imágen</span>
        </v-tooltip>
      </v-card-title>
      <!-- <v-card-text class="mt-4"> Nombres de imágenes ya agregadas </v-card-text> -->
      <v-list>
        <v-subheader>Lista de imágenes agregadas</v-subheader>
        <v-list-item v-for="(imagen, index) in imagenes" :key="index">
          <v-list-item-content>
            <v-list-item-title v-text="imagen"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div :elevation="0" dark v-bind="attrs" v-on="on">
                  <v-btn icon @click="verImagen(imagen)">
                    <v-icon color="teal lighten-3">mdi-eye</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Ver imágen</span>
            </v-tooltip>

            <!-- Define el diálogo -->
            <v-dialog v-model="dialog" max-width="600">
              <v-img :src="imageSrc" contain></v-img>
            </v-dialog>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div :elevation="0" dark v-bind="attrs" v-on="on">
                  <v-btn icon @click="eliminarImagen(imagen)">
                    <v-icon color="red lighten-1">mdi-close</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Eliminar imágen</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <!-- <div v-if="imageSrc">
        <v-row>
          <h3 class="ml-4">Vista previa de imágen:</h3>
        </v-row>

        <v-row class="mb-3">
          <v-col>
            <img
              :src="imageSrc"
              alt="Vista previa de la imagen"
              style="max-width: 200px"
            />
          </v-col>
        </v-row>
      </div> -->
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import AgregarImagen from "@/components/AgregarImagen.vue";

export default {
  data() {
    return {
      imagenes: [],
      imageSrc: null,
      dialog: false,
    };
  },
  methods: {
    async getImagenes() {
      const response = await axios.get("/imagenes/get");
      this.imagenes = response.data.imagenes;
    },
    async eliminarImagen(nombreImagen) {
      try {
        const response = await axios.delete(
          `/imagenes/eliminar?nombre_imagen=${nombreImagen}`
        );
        console.log(response.data);
        alert("Imágen eliminada");
        this.getImagenes();
      } catch (error) {
        alert("Error al eliminar la imagen:", error);
        console.error("Error al eliminar la imagen:", error);
      }
    },
    async verImagen(nombreImagen) {
      try {
        const response = await axios.get(`/imagenes/get/${nombreImagen}`, {
          responseType: "arraybuffer", // Importante: Configura el tipo de respuesta como 'arraybuffer'
        });

        // Convierte la respuesta en un Blob y crea una URL para mostrar la imagen
        const blob = new Blob([response.data], { type: "image/jpg" }); // Ajusta el tipo de imagen según corresponda
        const imageUrl = URL.createObjectURL(blob);

        // Ahora puedes mostrar la imagen en tu componente
        this.imageSrc = imageUrl; // Donde 'imageSrc' es una propiedad de datos en tu componente
        this.dialog = true;
      } catch (error) {
        console.error("Error al obtener la imagen:", error);
      }
    },
    // async verImagen(nombreImagen) {
    //   try {
    //     const response = await axios.get(`/imagenes/get/${nombreImagen}`);
    //     console.log(response.data);
    //     this.getImagenes();
    //   } catch (error) {
    //     alert("Error al mostrar la imagen:", error);
    //     console.error("Error al mostrar la imagen:", error);
    //   }
    // },
  },
  components: { AgregarImagen },
  created() {
    this.getImagenes();
  },
};
</script>
