<template>
  <v-card>
    <v-card-text>
      <v-container>
        <form ref="form" @submit.prevent="modificarProducto()">
          <v-row>
            <v-col>
              <v-card-title class="justify-center">
                <div>Nombre del producto: {{ props.nombre }}</div>
              </v-card-title>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Nombre primario"
                append-icon="mdi-label-outline"
                v-model="nombre_primario"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Código"
                append-icon="mdi-numeric"
                v-model="codigo"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Etiquetas"
                append-icon="mdi-tag-outline"
                v-model="etiquetas"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Laboratorio"
                append-icon="mdi-flask-outline"
                v-model="laboratorio"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="SKU de Laboratorio"
                append-icon="mdi-barcode"
                v-model="sku_laboratorio"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Nombre"
                append-icon="mdi-file-document-outline"
                v-model="nombre"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Código Primario"
                append-icon="mdi-numeric"
                v-model="codigo_primario"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Códigos Alternos"
                append-icon="mdi-text-box-multiple-outline"
                v-model="codigos_alternos"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                outlined
                label="Marca"
                v-model="marca"
                :items="marcas.valores"
                :value="marca"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="itemsActivo"
                outlined
                label="Activo"
                v-model="activo"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-combobox
                v-model="guiaPromocion"
                outlined
                label="Guía de promoción"
                multiple
                chips
                deletable-chips
                clearable
              ></v-combobox>
            </v-col>
          </v-row>
          <v-btn type="submit" color="teal lighten-2" text>Modificar</v-btn>
        </form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "modificarProducto",
  props: ["item"],
  data() {
    return {
      props: {
        nombre: this.item.nombre,
        local: this.item.local,
        plantillas: [this.item.plantillas],
      },

      codigo: this.item.codigo,
      codigo_primario: this.item.codigo_primario,
      nombre_primario: this.item.nombre_primario,
      codigos_alternos: this.item.codigos_alternos,
      etiquetas: this.item.etiquetas,
      guiaPromocion: this.item.guia_promocion,
      ingredientesActivos: this.item.ingredientes_activos,
      marca: this.item.marca,
      laboratorio: this.item.laboratorio,
      sku_laboratorio: this.item.sku_laboratorio,
      activo: this.item.activo,

      nombre: this.item.nombre,
      itemsActivo: [
        {
          text: "Sí",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      currentPlantilla: "",
      marcas: [],
    };
  },
  methods: {
    async modificarProducto() {
      try {
        // Create an empty payload object
        const payload = {};

        // Check and add properties only if their corresponding values are not null

        payload.codigo = this.codigo;
        payload.codigo_primario = this.codigo_primario;
        payload.nombre_primario = this.nombre_primario;
        payload.codigos_alternos = this.codigos_alternos;
        payload.etiquetas = this.etiquetas;
        payload.laboratorio = this.laboratorio;
        payload.sku_laboratorio = this.sku_laboratorio;
        payload.marca = this.marca;
        payload.activo = this.activo;
        payload.guia_promocion = this.guiaPromocion;
        payload.ingredientes_activos = this.ingredientesActivos;

        // Check if the payload object is empty, if so, show an error message
        if (Object.keys(payload).length === 0) {
          alert("Error: All required fields are empty.");
          return;
        }

        console.log(payload);
        const response = await axios.post(
          `/productos/modificar_producto`,
          payload,
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        alert(JSON.stringify(response.data));
        location.reload();
      } catch (error) {
        console.error(error); // Handle any errors that might occur
      }
    },
    async getMarcas() {
      try {
        const response = await axios.get(
          "/varios/get_clasificaciones?tipo=marca"
        );
        this.marcas = response.data;
        this.setDefaultMarca();
        console.log(JSON.stringify(this.marcas));
      } catch (e) {
        alert(e);
        console.error(e);
      }
    },
    setDefaultMarca() {
      // Check if the "marca" prop is not null and is present in the "valores" array
      if (
        this.item &&
        this.item.marca &&
        this.marcas.valores.includes(this.item.marca)
      ) {
        this.marca = this.item.marca;
      }
    },
  },
  created() {
    this.getMarcas();
    console.log(this.item);
  },
  computed: {
    ...mapGetters(["perfil"]),
  },
};
</script>
