<template>
  <v-container>
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Editar Laboratorios <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div :elevation="0" dark v-bind="attrs" v-on="on">
              <AgregarLaboratorio
                @cambioLaboratorios="getLaboratorios"
                :items="items"
              />
            </div>
          </template>
          <span>Nuevo laboratorio</span>
        </v-tooltip>
      </v-card-title>

      <!-- v-cards con los laboratorios mostrando su logo e imagenes -->
      <v-row class="mt-2 mx-1">
        <v-col
          v-for="laboratorio in laboratorios"
          :key="laboratorio.id"
          cols="12"
          md="4"
          lg="3"
        >
          <v-card class="styled-card">
            <v-card-title>
              <div v-if="laboratorio.logo">
                <v-img
                  :src="imagenesSolicitadas[laboratorio.logo]"
                  alt="Vista previa de logo"
                  max-width="50"
                  class="mr-2"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-1"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              {{ laboratorio.nombre }}
            </v-card-title>

            <v-card-text class="ml-3">
              <!-- <v-row v-if="laboratorio.logo">
                <img
                  :src="imagenesSolicitadas[laboratorio.logo]"
                  alt="Vista previa de logo"
                  style="max-width: 50px"
                />
              </v-row> -->
              <v-row v-if="laboratorio.imagenes.length !== 0">
                <v-col
                  v-for="(imagen, index) in laboratorio.imagenes"
                  :key="index"
                >
                  <v-img
                    :src="imagenesSolicitadas[imagen]"
                    alt="Vista previa de imagen"
                    style="max-width: 90px"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-1"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <!-- <v-row>
                <div>
                  {{ laboratorio.descripcion }}
                </div>
              </v-row> -->
            </v-card-text>
            <v-card-actions>
              <ModificarLaboratorio
                @cambioLaboratorios="getLaboratorios"
                :laboratorio="laboratorio"
                :imagenesSolicitadas="imagenesSolicitadas"
                :items="items"
              />
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="
                  cardStates[laboratorio.id] = !cardStates[laboratorio.id]
                "
              >
                <v-icon>{{
                  cardStates[laboratorio.id]
                    ? "mdi-chevron-up"
                    : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="cardStates[laboratorio.id]">
                <v-divider></v-divider>
                <v-card-text>
                  <div v-if="laboratorio.descripcion">
                    {{ laboratorio.descripcion }}
                  </div>
                  <div v-else style="font-style: italic">
                    El laboratorio no cuenta con descripción.
                  </div>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import AgregarLaboratorio from "@/components/AgregarLaboratorio.vue";
import ModificarLaboratorio from "@/components/ModificarLaboratorio.vue";

export default {
  data() {
    return {
      laboratorios: [],
      imagenesSolicitadas: {},
      items: [], //Nombres de las imagenes disponibles
      show: false,
      cardStates: {},
    };
  },
  methods: {
    async getImagen(nombreImagen) {
      // if (this.imagenesSolicitadas[nombreImagen]) {
      //   return this.imagenesSolicitadas[nombreImagen];
      // }
      try {
        const response = await axios.get(`/imagenes/get/${nombreImagen}`, {
          responseType: "arraybuffer",
        });
        const respuestaImagen = { nombre: nombreImagen, data: response.data };

        const blob = new Blob([response.data], { type: "image/jpg" });
        const imageUrl = URL.createObjectURL(blob);
        this.$set(this.imagenesSolicitadas, respuestaImagen.nombre, imageUrl);

        // return this.imagenesSolicitadas[nombreImagen];
      } catch (error) {
        console.error("Error al obtener la imágen:");
      }
    },
    async getLaboratorios() {
      try {
        const response = await axios.get(`/laboratorios/get_laboratorios`);
        this.laboratorios = response.data;
      } catch (error) {
        console.error(error);
        alert("Error al mostrar laboratorios", error);
      }
    },
    async getNombreImagenes() {
      const response = await axios.get("/imagenes/get");
      this.items = response.data.imagenes;
    },
  },
  watch: {
    laboratorios(nuevoValor) {
      nuevoValor.forEach((laboratorio) => {
        if (
          !this.imagenesSolicitadas[laboratorio.logo] &&
          laboratorio.logo !== null
        ) {
          //Si no se encuentra el logo, se debe hacer una peticion al API
          this.getImagen(laboratorio.logo);
        }
        laboratorio.imagenes.forEach((imagen) => {
          if (!this.imagenesSolicitadas[imagen]) {
            //Si no se encuentra la imagen, se debe hacer una peticion al API
            this.getImagen(imagen);
          }
        });
      });
      // console.log(this.imagenesSolicitadas);
      // Inicializa el estado de cada tarjeta como oculta
      this.laboratorios.forEach((laboratorio) => {
        this.$set(this.cardStates, laboratorio.id, false);
      });
    },
  },
  mounted() {
    this.getLaboratorios();
    this.getNombreImagenes();
  },
  created() {},
  components: { AgregarLaboratorio, ModificarLaboratorio },
};
</script>

<style>
.styled-card {
  width: 100%;
  padding: 10px;
}
</style>
