<template>
  <v-card>
    <v-container>
      <v-card-title> Asociar factura del pedido: {{ item.id }}</v-card-title>
      <v-card-text>
        <v-container>
          <form ref="form" @submit.prevent="asociarFactura()">
            <!-- <v-row>
              <v-col>
                <v-card-title class="justify-center">
                  Asociar factura al pedido: {{ item.id }}
                </v-card-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Serie de la factura"
                  append-icon="mdi-card-text-outline"
                  v-model="serie_factura"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Folio de la factura"
                  append-icon="mdi mdi-file-document-outline"
                  v-model="folio_factura"
                  required
                ></v-text-field>
              </v-col>
            </v-row> -->

            <div v-for="(factura, index) in arrayFacturas" :key="index">
              <v-row v-if="index === 0">
                <v-col cols="6">
                  <v-text-field
                    label="Factura"
                    v-model="arrayFacturas[0]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="auto">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div :elevation="0" dark v-bind="attrs" v-on="on">
                        <v-btn icon class="mt-5" @click="agregarTextField">
                          <v-icon>mdi-plus-box-outline</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Agregar factura</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="6">
                  <v-text-field
                    label="Factura"
                    v-model="arrayFacturas[index]"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" md="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div :elevation="0" dark v-bind="attrs" v-on="on">
                        <v-btn
                          icon
                          class="mt-5"
                          @click="eliminarTextField(index)"
                        >
                          <v-icon>mdi-minus-box-outline</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Eliminar factura</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>

            <!-- <v-row>
              <v-col cols="6">
                <v-text-field label="Factura" v-model="factura" required>
                </v-text-field>
              </v-col>
              <v-col cols="auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div :elevation="0" dark v-bind="attrs" v-on="on">
                      <v-btn icon class="mt-5" @click="agregarTextField">
                        <v-icon>mdi-plus-box-outline</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Agregar factura</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-for="(texto, index) in textosFacturas" :key="index">
              <v-col cols="6">
                <v-text-field
                  v-model="textosFacturas[index]"
                  label="Factura"
                ></v-text-field>
              </v-col>
              <v-col cols="1" md="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div :elevation="0" dark v-bind="attrs" v-on="on">
                      <v-btn
                        icon
                        class="mt-5"
                        @click="eliminarTextField(index)"
                      >
                        <v-icon>mdi-minus-box-outline</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Eliminar factura</span>
                </v-tooltip>
              </v-col>
            </v-row> -->

            <v-row>
              <v-btn type="submit" color="teal lighten-2" text
                >Asociar factura
              </v-btn>
              <v-btn text color="teal lighten-2" @click="cerrarDialog">
                Cancelar
              </v-btn>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["item"],
  data() {
    return {
      serie_factura: "",
      folio_factura: "",
      factura: "",
      arrayFacturas:
        this.item.facturas.length !== 0 ? this.item.facturas : [""],
      textosFacturas: [],
      errores: [],
    };
  },
  methods: {
    async asociarFactura() {
      let textosFacturasString = "";
      if (this.arrayFacturas.length > 1) {
        //si se requiere asociar mas de una factura
        this.arrayFacturas.forEach((texto) => {
          if (texto !== "") {
            textosFacturasString =
              textosFacturasString + `&facturas=${texto.toUpperCase()}`;
          }
        });

        try {
          const response = await axios.post(
            `/pedidos/asociar_factura?id_pedido=${this.item.id}${textosFacturasString}`
          );
          console.log(JSON.stringify(response.data));
          this.$emit("pedidoModificado");
          alert("Factura asociada");
          // this.cerrar();
          // location.reload();
        } catch (error) {
          console.error(error);
          alert("Error al asociar la factura");
        }
      } else {
        //si se requiere asociar una sola factura
        try {
          const response = await axios.post(
            `/pedidos/asociar_factura?id_pedido=${
              this.item.id
            }&facturas=${this.arrayFacturas[0].toUpperCase()}`
          );
          console.log(JSON.stringify(response.data));
          this.$emit("pedidoModificado");
          alert("Factura asociada");
          // this.cerrar();
          // location.reload();
        } catch (error) {
          console.error(error);
          alert("Error al asociar la factura");
        }
      }
    },
    agregarTextField() {
      //Agrega un nuevo elemento al array de textosFacturas
      this.arrayFacturas.push("");
      console.log(this.arrayFacturas);
    },
    eliminarTextField(index) {
      // this.textosFacturas.pop(index);
      this.arrayFacturas.splice(index, 1);
      console.log(this.arrayFacturas);
    },
    cerrarDialog() {
      this.$emit("cerrarDialog");
    },
  },
  mounted() {},
};
</script>

<!-- <template>
  <v-dialog max-width="80%" v-model="dialog">
    <v-card>
      <v-container>
        <v-btn
          class="close-btn"
          icon
          value="log in"
          color="blue darken-1"
          text
          @click="cerrar()"
        >
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
        <v-card-title>Asociar factura al pedido: {{ item.id }}</v-card-title>
        <v-card-text>
          <v-container>
            <form ref="form" @submit.prevent="asociarFactura()">
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Serie de la factura"
                    append-icon="mdi-card-text-outline"
                    v-model="serie_factura"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Folio de la factura"
                    append-icon="mdi mdi-file-document-outline"
                    v-model="folio_factura"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn type="submit" color="teal lighten-2" text
                >Asociar factura</v-btn
              >
            </form>
          </v-container>
        </v-card-text>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["item"],
  data() {
    return {
      serie_factura: "",
      folio_factura: "",
      errores: [],
      dialog: false,
    };
  },
  methods: {
    async asociarFactura() {
      try {
        const response = await axios.post(
          `/pedidos/asociar_factura?id_pedido=${this.item.id}&serie_factura=${this.serie_factura}&folio_factura=${this.folio_factura}`
        );
        console.log(JSON.stringify(response.data));
        alert("Factura asociada");
        location.reload();
      } catch (error) {
        error.log(error);
        alert(JSON.stringify(error));
      }
    },

    cerrar() {
      this.dialog = !this.dialog;
    },
  },

  mounted() {
    // Cargar objetos desde el almacenamiento local al iniciar el componente
  },
  created() {
    console.log("salu2");
  },
};
</script>

<style>
.close-btn {
  float: right;
}
</style> -->
