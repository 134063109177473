<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Reporte ventas laboratorio
        <v-spacer></v-spacer>
        <!-- <v-btn @click="verData" class="mr-2" color="primary">data</v-btn> -->
      </v-card-title>
      <v-row class="mx-2 mt-1">
        <v-col cols="12" lg="4" md="5">
          <v-select
            v-model="laboratorioSelected"
            :items="laboratorios"
            item-value="value"
            label="Laboratorio"
            placeholder="Selecciona laboratorio"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="12" md="12">
          <v-checkbox-group
            v-model="sucursalesSeleccionadas"
            :item="sucursales"
            item-value="value"
            item-text="text"
            label="Sucursales"
            class="d-flex flex-wrap"
          >
            <v-checkbox
              v-for="sucursal in sucursales"
              :key="sucursal.value"
              :label="sucursal.text"
              :value="sucursal.value"
              v-model="sucursalesSeleccionadas"
              class="ml-5 mr-5"
            ></v-checkbox>
          </v-checkbox-group>
        </v-col>

        <v-col cols="2">
          <v-btn @click="getReporte" small class="mt-5" :loading="loadingBtn"
            >Buscar</v-btn
          >
        </v-col>
      </v-row>
      <!-- Grafico por mes y trimestre -->
      <v-row
        v-if="dataLaboratorio.length > 0"
        justify="center"
        class="mx-2 mt-1"
      >
        <v-col cols="12" xl="8">
          <VentasPorMes
            :chartSeries="datosChartMes"
            :datosDiferencia="datosDiferenciaMes"
          ></VentasPorMes>
        </v-col>
        <v-col cols="12" xl="8">
          <VentasPorTrimestre
            :chartSeries="datosChartTrimestre"
            :datosDiferencia="datosDiferenciaTrimestre"
          >
          </VentasPorTrimestre>
        </v-col>
      </v-row>
      <!-- Tabla de comparacion -->

      <v-row
        v-if="dataLaboratorio.length > 0"
        justify="center"
        class="mx-5 mt-5"
      >
        <v-col cols="12" md="10">
          <v-card class="pa-2">
            <v-card-title> Comparación por Sucursal </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="auto">
                  <v-select
                    v-model="selectedMonth"
                    :items="meses"
                    label="Mes"
                    @change="mesSeleccionado"
                  ></v-select>
                </v-col>
              </v-row>
              <v-progress-circular
                v-if="loadingTable"
                indeterminate
                color="primary"
                class="mx-auto"
              ></v-progress-circular>
              <v-data-table
                :headers="headers"
                :items="comparacionPorSucursal"
                :items-per-page="6"
                item-key="sucursal"
                hide-default-footer
                no-data-text="No hay resultados"
              >
                <template v-slot:[`item.añoAnterior`]="{ item }">
                  {{ "$" + formatoPrecio(item.añoAnterior) }}
                </template>
                <template v-slot:[`item.añoActual`]="{ item }">
                  {{ "$" + formatoPrecio(item.añoActual) }}
                </template>
                <template v-slot:[`item.diferencia`]="{ item }">
                  <strong
                    :class="
                      item.diferencia < 0
                        ? 'red--text text--lighten-1'
                        : 'green--text text--lighten-1'
                    "
                    >{{ "$" + formatoPrecio(item.diferencia) }}</strong
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import pako from "pako";
import Papa from "papaparse";
import VentasPorMes from "../components/VentasPorMes.vue";
import VentasPorTrimestre from "../components/VentasPorTrimestre.vue";
//import Swal from "sweetalert2";

export default {
  data() {
    return {
      items: [],
      dataLaboratorio: [],
      datosFiltrados: [],
      datosChartMes: [],
      datosChartTrimestre: [],
      datosCompLab: [],
      dataComp: [],
      laboratoriosSelected: null,

      datosDiferenciaMes: Array(12).fill(0),
      datosDiferenciaTrimestre: Array(4).fill(0),

      fechaFin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
      currentYear: new Date().getFullYear(),

      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      sucursalesSeleccionadas: [
        "Mexicali",
        "Tijuana",
        "Ensenada",
        "LaPaz",
        "Obregon",
        "Hermosillo",
      ],
      //sucursalSelected: "Mexicali",
      //laboratorioSelected: {},
      selectedMonth: new Date().getMonth() + 1,
      loadingTable: false,
      dialog: false,
      headers: [],
      meses: [
        {
          text: "Enero",
          value: 1,
        },
        {
          text: "Febrero",
          value: 2,
        },
        {
          text: "Marzo",
          value: 3,
        },
        {
          text: "Abril",
          value: 4,
        },
        {
          text: "Mayo",
          value: 5,
        },
        {
          text: "Junio",
          value: 6,
        },
        {
          text: "Julio",
          value: 7,
        },
        {
          text: "Agosto",
          value: 8,
        },
        {
          text: "Septiembre",
          value: 9,
        },
        {
          text: "Octubre",
          value: 10,
        },
        {
          text: "Noviembre",
          value: 11,
        },
        {
          text: "Diciembre",
          value: 12,
        },
      ],
      laboratorios: [], //lista de laboratorios importantes

      loadingBtn: false,
      //   itemsFiltrados: [],
    };
  },
  methods: {
    processData() {
      let totalCurrentYear = Array(12).fill(0);
      let totalLastYear = Array(12).fill(0);
      let totalCurrentYearTrimestre = Array(4).fill(0);
      let totalLastYearTrimestre = Array(4).fill(0);

      const datosParaGraficos = this.dataLaboratorio.filter(
        (item) =>
          (this.laboratorioSelected
            ? item.laboratorio === this.laboratorioSelected
            : true) &&
          (item.year === this.currentYear - 1 || item.year === this.currentYear)
      );

      if (this.dataLaboratorio.length > 0) {
        this.datosFiltrados = datosParaGraficos
          .filter(
            (item) =>
              this.selectedMonth === 0 || item.mes === this.selectedMonth
          )
          .map((item) => ({
            year: item.year,
            mes: item.mes,
            "neto-desc": item["neto-desc"],
            laboratorio: item.laboratorio,
            sucursal: item.sucursal,
          }));

        datosParaGraficos.forEach((item) => {
          if (item.year === this.currentYear) {
            totalCurrentYear[item.mes - 1] = (
              parseFloat(totalCurrentYear[item.mes - 1]) +
              parseFloat(item["neto-desc"])
            ).toFixed(2);
          } else if (item.year === this.currentYear - 1) {
            totalLastYear[item.mes - 1] = (
              parseFloat(totalLastYear[item.mes - 1]) +
              parseFloat(item["neto-desc"])
            ).toFixed(2);
          }
        });

        datosParaGraficos.forEach((item) => {
          const trimestre = Math.floor((item.mes - 1) / 3);
          if (item.year === this.currentYear) {
            totalCurrentYearTrimestre[trimestre] += item["neto-desc"];
          } else if (item.year === this.currentYear - 1) {
            totalLastYearTrimestre[trimestre] += item["neto-desc"];
          }
        });

        this.datosChartMes = [
          {
            name: "Año anterior",
            type: "column",
            data: totalLastYear,
          },
          {
            name: "Año actual",
            type: "column",
            data: totalCurrentYear,
          },
        ];
        this.datosChartTrimestre = [
          {
            name: "Año anterior",
            data: totalLastYearTrimestre,
          },
          {
            name: "Año actual",
            data: totalCurrentYearTrimestre,
          },
        ];

        this.datosDiferenciaMes = totalCurrentYear.map((item, index) => {
          return Math.round(item - totalLastYear[index]);
        });

        this.datosDiferenciaTrimestre = totalCurrentYearTrimestre.map(
          (item, index) => {
            return Math.round(item - totalLastYearTrimestre[index]);
          }
        );

        const grouped = this.datosFiltrados.reduce((acc, datoVenta) => {
          const { sucursal, year, "neto-desc": netoDesc } = datoVenta;
          if (!acc[sucursal]) {
            acc[sucursal] = {};
          }
          if (!acc[sucursal][year]) {
            acc[sucursal][year] = 0;
          }
          acc[sucursal][year] += netoDesc;
          return acc;
        }, {});

        const comparacion = Object.keys(grouped).map((sucursal) => {
          const años = Object.keys(grouped[sucursal])
            .map(Number)
            .sort((a, b) => a - b);
          const añoAnterior = grouped[sucursal][años[0]] || 0;
          const añoActual = grouped[sucursal][años[1]] || 0;
          return {
            sucursal,
            añoAnterior,
            añoActual,
            diferencia: añoActual - añoAnterior,
          };
        });

        this.comparacionPorSucursal = comparacion;

        this.loadingTable = false;
      } else {
        this.loadingTable = false;
        alert("No hay registros para mostrar");
      }
    },

    async fetchLaboratorios() {
      try {
        const response = await axios.get(
          `/varios/get_json?nombre=laboratorios_importantes`
        );
        this.laboratorios = response.data.objeto.lista.map((lab) => ({
          text: lab,
          value: lab,
        }));
      } catch (error) {
        console.error("Error al obtener los laboratorios importantes:", error);
      }
    },
    async getReporte() {
      this.loadingBtn = true;
      if (!this.laboratorioSelected) {
        alert("Selecciona un laboratorio.");
        this.loadingBtn = false;
        return;
      }

      let totalCurrentYear = Array(12).fill(0);
      let totalLastYear = Array(12).fill(0);
      let totalCurrentYearTrimestre = Array(4).fill(0);
      let totalLastYearTrimestre = Array(4).fill(0);
      this.datosChartMes = [];

      let labsImportantes = this.laboratorios.map((lab) => lab.value);

      try {
        let sucursalesString = "";
        if (this.sucursalesSeleccionadas.length !== 0) {
          this.sucursalesSeleccionadas.forEach((sucursal) => {
            sucursalesString = sucursalesString + `&sucursales=${sucursal}`;
          });
        }

        const response = await axios.get(
          `/reportes/pivot_table?fecha_inicial=${
            this.currentYear - 1
          }-01-01&fecha_final=${
            this.fechaFin
          }&sucursales=${sucursalesString}&indice=laboratorio&valor=neto-desc&filtros=laboratorio=${
            this.laboratorioSelected
          }`,

          {
            responseType: "arraybuffer",
          }
        );

        const decompressedData = pako.ungzip(response.data, { to: "string" });
        const csvData = Papa.parse(decompressedData, {
          header: true,
          dynamicTyping: true,
        });
        const data = csvData.data;
        data.pop();

        this.dataLaboratorio = data;
        this.processData();

        const datosParaGraficos = data.filter(
          (item) =>
            (this.laboratorioSelected
              ? item.laboratorio === this.laboratorioSelected
              : true) &&
            (item.year === this.currentYear - 1 ||
              item.year === this.currentYear)
        );

        if (data.length > 0) {
          this.datosFiltrados = datosParaGraficos
            .filter(
              (item) =>
                this.selectedMonth === 0 || item.mes === this.selectedMonth
            )
            .map((item) => ({
              year: item.year,
              mes: item.mes,
              "neto-desc": item["neto-desc"],
              laboratorio: item.laboratorio,
              //nueva linea agregada
              sucursal: item.sucursal,
            }));

          datosParaGraficos.forEach((item) => {
            if (item.year === this.currentYear) {
              totalCurrentYear[item.mes - 1] = (
                parseFloat(totalCurrentYear[item.mes - 1]) +
                parseFloat(item["neto-desc"])
              ).toFixed(2);
            } else if (item.year === this.currentYear - 1) {
              totalLastYear[item.mes - 1] = (
                parseFloat(totalLastYear[item.mes - 1]) +
                parseFloat(item["neto-desc"])
              ).toFixed(2);
            }
          });

          datosParaGraficos.forEach((item) => {
            const trimestre = Math.floor((item.mes - 1) / 3);
            if (item.year === this.currentYear) {
              totalCurrentYearTrimestre[trimestre] += item["neto-desc"];
            } else if (item.year === this.currentYear - 1) {
              totalLastYearTrimestre[trimestre] += item["neto-desc"];
            }
          });

          this.datosChartMes = [
            {
              name: "Año anterior",
              type: "column",
              data: totalLastYear,
            },
            {
              name: "Año actual",
              type: "column",
              data: totalCurrentYear,
            },
          ];
          this.datosChartTrimestre = [
            {
              name: "Año anterior",
              data: totalLastYearTrimestre,
            },
            {
              name: "Año actual",
              data: totalCurrentYearTrimestre,
            },
          ];

          this.datosDiferenciaMes = totalCurrentYear.map((item, index) => {
            return Math.round(item - totalLastYear[index]);
          });

          this.datosDiferenciaTrimestre = totalCurrentYearTrimestre.map(
            (item, index) => {
              return Math.round(item - totalLastYearTrimestre[index]);
            }
          );

          const grouped = this.datosFiltrados.reduce((acc, datoVenta) => {
            const { sucursal, year, "neto-desc": netoDesc } = datoVenta;
            if (!acc[sucursal]) {
              acc[sucursal] = {};
            }
            if (!acc[sucursal][year]) {
              acc[sucursal][year] = 0;
            }
            acc[sucursal][year] += netoDesc;
            return acc;
          }, {});

          const comparacion = Object.keys(grouped).map((sucursal) => {
            const años = Object.keys(grouped[sucursal])
              .map(Number)
              .sort((a, b) => a - b);
            const añoAnterior = grouped[sucursal][años[0]] || 0;
            const añoActual = grouped[sucursal][años[1]] || 0;
            return {
              sucursal,
              añoAnterior,
              añoActual,
              diferencia: añoActual - añoAnterior,
            };
          });

          this.comparacionPorSucursal = comparacion;

          labsImportantes.forEach((lab) => {
            if (!comparacion.some((comp) => comp.laboratorio === lab)) {
              comparacion.push({
                laboratorio: lab,
                añoAnterior: 0,
                añoActual: 0,
                diferencia: 0,
              });
            }
          });

          this.datosCompLab = comparacion;

          this.loadingBtn = false;
        } else {
          this.loadingBtn = false;

          alert("No hay registros para mostrar");
        }
        this.loadingBtn = false;
      } catch (error) {
        this.loadingBtn = false;
        alert(`Error al obtener los datos`);
        console.log(error.response.data);
      }
    },

    cerrarDialog() {
      this.dialog = false;
    },
    formatoPrecio(precioProducto) {
      // Formatear el número utilizando una función personalizada
      const numero = parseFloat(precioProducto);
      // console.log(numero);
      let precio = 0;

      precio = numero.toFixed(2); // Limitar a 2 decimales
      const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
      const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

      // Formatear la parte entera con separador de miles
      const parteEnteraFormateada = parteEntera.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
    },
  },
  watch: {
    sucursalesSeleccionadas: {
      handler() {
        this.getReporte();
      },
      deep: true,
    },
    selectedMonth() {
      this.processData();
    },
  },

  computed: {
    itemsFiltrados() {
      const itemsLaboratorio = this.items.filter(
        (cliente) => cliente.laboratorio === this.laboratorioSelected
      );
      return itemsLaboratorio;
    },
    ...mapGetters(["user"]),
  },
  created() {
    this.headers = [
      { text: "Sucursal", value: "sucursal" },
      {
        text: `${this.currentYear - 1}`,
        value: "añoAnterior",
        sortable: false,
      },
      { text: `${this.currentYear}`, value: "añoActual", sortable: false },
      { text: "Diferencia", value: "diferencia", sortable: false },
    ];
    this.fetchLaboratorios();
  },
  components: { VentasPorMes, VentasPorTrimestre },
};
</script>
