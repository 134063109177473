<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Productos
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn class="mr-2" @click="actualizarProductos" :loading="loading"
          >Actualizar</v-btn
        >
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div :elevation="0" dark v-bind="attrs" v-on="on">
              <AgregarProducto />
            </div>
          </template>
          <span>Nuevo Producto</span>
        </v-tooltip>
      </v-card-title>
      <v-data-table
        :search="search"
        :headers="headers"
        :items="items"
        :footer-props="{
          'items-per-page-text': 'Productos por página',
        }"
        :header-props="{
          'sort-by-text': 'Ordenar por',
        }"
      >
        <template v-slot:[`item.acciones`]="{ item }">
          <AccionesProductos :item="item"> </AccionesProductos>
        </template>
        <template v-slot:[`item.activo`]="{ item }">
          <ProductoActivo :item="item" />
        </template>
        <template v-slot:[`item.etiquetas`]="{ item }">
          <EtiquetasProductos
            :item="item"
            :etiquetasArray="etiquetasArray"
          ></EtiquetasProductos>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AgregarProducto from "@/components/AgregarProducto.vue";
import AccionesProductos from "@/components/AccionesProductos.vue";
import EtiquetasProductos from "@/components/EtiquetasProductos.vue";
import axios from "axios";
import ProductoActivo from "@/components/ProductoActivo.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search: "",
      loading: false,
      items: [],
      etiquetasArray: [],
      headers: [
        {
          text: "Código",
          value: "codigo",
        },
        {
          text: "Nombre",
          value: "nombre",
        },
        {
          text: "Código primario",
          value: "codigo_primario",
        },
        {
          text: "Nombre primario",
          value: "nombre_primario",
        },

        {
          text: "Etiquetas",
          value: "etiquetas",
        },
        {
          text: "Laboratorio",
          value: "laboratorio",
        },
        {
          text: "SKU de Laboratorio",
          value: "sku_laboratorio",
        },
        {
          text: "Códigos Alternos",
          value: "codigos_alternos",
        },
        {
          text: "Marca",
          value: "marca",
        },
        {
          text: "Activo",
          value: "activo",
        },
        {
          text: "Acciones",
          value: "acciones",
        },
      ],
    };
  },
  methods: {
    async actualizarProductos() {
      this.loading = true;
      console.log("Actualizando...");
      try {
        const response = await axios.post(`/productos/actualizar`);
        console.log(response.data);
        this.getProductos();
        this.loading = false;
        alert("Productos actualizados");
      } catch (error) {
        console.error(error);
        this.loading = false;
        alert("Error: " + error.response.data.detail);
      }
    },
    async getProductos() {
      const response = await axios.get("/productos/get");
      this.items = response.data;
    },
    async getEtiquetas() {
      const response = await axios.get(
        "/varios/get_clasificaciones?tipo=etiqueta_producto"
      );
      this.etiquetasArray = response.data.valores;
    },
  },
  created() {
    this.getProductos();
    this.getEtiquetas();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  components: {
    AgregarProducto,
    AccionesProductos,
    ProductoActivo,
    EtiquetasProductos,
  },
};
</script>

<style>
.hide-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
