<template>
  <v-dialog max-width="70%" v-model="dialog">
    <v-card>
      <v-container>
        <v-card-title>
          Modificar laboratorio
          <v-spacer />
          <v-btn
            class="close-btn"
            icon
            value="log in"
            color="blue darken-1"
            text
            @click="cerrar()"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-text-field
                  v-model="nombre"
                  outlined
                  label="Nombre"
                  required
                  :rules="nameRules"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="nombre_adminpaq"
                  outlined
                  label="Nombre Adminpaq"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-autocomplete
                  outlined
                  v-model="logo"
                  :items="items"
                  label="Logo"
                  small-chips
                  deletable-chips
                  @change="cambioLogo"
                ></v-autocomplete>
              </v-row>
              <v-row>
                <div v-if="logo">
                  <v-row>
                    <h3 class="ml-4">Vista previa de logo:</h3>
                  </v-row>

                  <v-row class="mb-3">
                    <v-col>
                      <img
                        :src="imagenesSolicitadas[logo]"
                        alt="Vista previa de logo"
                        style="max-width: 200px"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-row>
              <v-row>
                <v-autocomplete
                  outlined
                  v-model="imagenes"
                  :items="items"
                  label="Imágenes"
                  small-chips
                  deletable-chips
                  multiple
                  @change="cambioImagenes"
                ></v-autocomplete>
              </v-row>

              <v-row>
                <div v-if="imagenes.length > 0">
                  <v-row>
                    <h3 class="ml-4">Vista previa de imágenes:</h3>
                  </v-row>
                  <v-row class="mb-3">
                    <div v-for="nombre in imagenes" :key="nombre">
                      <v-col>
                        <img
                          :src="imagenesSolicitadas[nombre]"
                          alt="Vista previa de la imágen"
                          style="max-width: 150px"
                        />
                      </v-col>
                    </div>
                  </v-row>
                </div>
              </v-row>

              <v-row>
                <v-col>
                  <v-autocomplete
                    outlined
                    v-model="clasificacion"
                    :items="clasificaciones"
                    label="Clasificación"
                    small-chips
                    deletable-chips
                    multiple
                  ></v-autocomplete>
                  <v-select
                    outlined
                    v-model="activo"
                    :items="activoItems"
                    label="Activo"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-textarea
                    v-model="descripcion"
                    outlined
                    label="Descripción"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-btn
                :disabled="!valid"
                @click="modificarLaboratorio"
                color="teal lighten-2"
                text
                >Modificar laboratorio</v-btn
              >
            </v-form>
          </v-container>
        </v-card-text>
      </v-container>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-on="on" v-bind="attrs"> Editar</v-btn>
    </template>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["laboratorio", "imagenesSolicitadas", "items"],
  data() {
    return {
      valid: true,
      dialog: false,
      nombre: this.laboratorio.nombre,
      nombre_adminpaq: this.laboratorio.nombre_adminpaq,
      clasificaciones: ["Veterinaria", "Plagas"],
      clasificacion: this.laboratorio.clasificaciones,
      logo: this.laboratorio.logo,
      logoSrc: null,
      imagenes: this.laboratorio.imagenes,
      imagenesSeleccionadas: {},
      previewUrl: null,
      previewUrls: [],
      descripcion: this.laboratorio.descripcion,
      activoItems: [
        {
          text: "Sí",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      activo: this.laboratorio.activo,
      // rules: {
      //   required: (value) => !!value || "No puede estar vacio",
      // },
      nameRules: [(v) => !!v || "No puede estar vacio"],
    };
  },
  watch: {
    // logo(nuevoLogo) {
    //   // Aquí realizas la llamada a tu API para obtener la imagen según el nombre seleccionado
    //   // console.log(nuevoLogo);
    //   if (nuevoLogo) {
    //     this.getImagen(nuevoLogo);
    //   } else {
    //     this.logoSrc = null;
    //   }
    // },
  },
  methods: {
    cambioLogo() {
      if (this.logo) {
        if (!this.imagenesSolicitadas[this.logo]) {
          //Si no se encuentra la imagen, se debe hacer una peticion al API
          this.getImagen(this.logo);
        }
      } else {
        this.logoSrc = null;
      }
    },
    cambioImagenes() {
      // Realiza la llamada a tu API para obtener las imágenes correspondientes a los nombres seleccionados
      // Comprobar aqui se se ha solicitado la imagen antes
      this.imagenes.forEach((element) => {
        if (!this.imagenesSolicitadas[element]) {
          //Si no se encuentra la imagen, se debe hacer una peticion al API
          this.getImagen(element).then((imagen) => {
            this.imagenesSeleccionadas = imagen;
          });
        }
      });
    },
    async modificarLaboratorio() {
      if (this.$refs.form.validate()) {
        const datosLaboratorio = {
          id: this.laboratorio.id,
          nombre: this.nombre,
          nombre_adminpaq: this.nombre_adminpaq,
          logo: this.logo,
          imagenes: this.imagenes,
          clasificaciones: this.clasificacion,
          descripcion: this.descripcion,
          activo: this.activo,
        };
        console.log(datosLaboratorio);
        try {
          const response = await axios.put(
            `/laboratorios/modificar_laboratorio`,
            datosLaboratorio
          );
          alert("Laboratorio modificado");
          this.$emit("cambioLaboratorios");
          this.dialog = !this.dialog;
          console.log(response.data);
        } catch (error) {
          console.error("Error al modificar laboratorio:", error);
          alert("Error al modificar laboratorio:", error);
        }
      }
    },
    cerrar() {
      this.$refs.form.resetValidation();
      this.dialog = !this.dialog;
    },
    // async getNombreImagenes() {
    //   const response = await axios.get("/imagenes/get");
    //   this.items = response.data.imagenes;
    // },
    async getImagen(nombreImagen) {
      try {
        const response = await axios.get(`/imagenes/get/${nombreImagen}`, {
          responseType: "arraybuffer", // Importante: Configura el tipo de respuesta como 'arraybuffer'
        });
        const respuestaImagen = { nombre: nombreImagen, data: response.data };

        // Convierte la respuesta en un Blob y crea una URL para mostrar la imagen
        const blob = new Blob([response.data], { type: "image/jpg" }); // Ajusta el tipo de imagen según corresponda
        const imageUrl = URL.createObjectURL(blob);
        this.$set(this.imagenesSolicitadas, respuestaImagen.nombre, imageUrl);

        // Ahora puedes mostrar la imagen en tu componente
        // this.logoSrc = imageUrl;
      } catch (error) {
        console.error("Error al obtener la imagen:", error);
      }
    },
    // async getImagenesDesdeAPI(nombreImagen) {
    //   try {
    //     const response = await axios.get(`/imagenes/get/${nombreImagen}`, {
    //       responseType: "arraybuffer", // Importante: Configura el tipo de respuesta como 'arraybuffer'
    //     });
    //     const respuestaImagen = { nombre: nombreImagen, data: response.data };

    //     const blob = new Blob([response.data], { type: "image/jpg" }); // Ajusta el tipo de imagen según corresponda
    //     const imageUrl = URL.createObjectURL(blob);
    //     this.$set(this.imagenesSolicitadas, respuestaImagen.nombre, imageUrl);

    //     return respuestaImagen;
    //   } catch (error) {
    //     console.error("Error al obtener la imágen:", error);
    //   }
    // },
  },
  computed: {},
  mounted() {
    // Cargar objetos desde el almacenamiento local al iniciar el componente
    // this.getNombreImagenes();
    // this.logo = this.laboratorio.logo;
    // this.imagenes = this.laboratorio.imagenes;
  },
  created() {},
};
</script>

<style>
.close-btn {
  float: right;
}
</style>
