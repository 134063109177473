<template>
  <v-container v-if="user" fluid>
    <v-card>
      <v-col>
        <v-card-title
          >Envíos <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div :elevation="0" dark v-bind="attrs" v-on="on">
                <CrearEnvio
                  :allProducts="productos"
                  @envioCreado="envioCreado"
                />
              </div>
            </template>
            <span>Crear Envío</span>
          </v-tooltip>
        </v-card-title>
        <v-row>
          <v-col>
            <v-btn
              :class="[
                {
                  'disabled-button': selectedOption != 'EnTransito',
                  'enabled-button': selectedOption === 'EnTransito',
                },
                'mr-1',
                'ml-2',
                'mb-1',
              ]"
              color="primary"
              @click="selectedOption = 'EnTransito'"
            >
              En tránsito
            </v-btn>
            <v-btn
              :class="[
                {
                  'disabled-button': selectedOption != 'entregados',
                  'enabled-button': selectedOption === 'entregados',
                },
                'mr-1',
                'ml-2',
                'mb-1',
              ]"
              color="primary"
              @click="selectedOption = 'entregados'"
            >
              Entregados
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <NewEnviosTable
            v-if="selectedOption === 'EnTransito'"
            :productos="this.productos"
            :envios="this.envios"
            :usuarios="this.usuarios"
            @envioModificado="envioModificado"
          />
          <NewEnviosEntregadosTable
            v-if="selectedOption === 'entregados'"
            :enviosEntregados="this.enviosEntregados"
            :usuarios="this.usuarios"
            :productos="this.productos"
          />
        </v-row>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import CrearEnvio from "@/components/Crear/CrearEnvio.vue";
// import EnviosTable from "@/components/Otros/EnviosTable.vue";
// import EnviosTableEntregado from "@/components/Otros/EnviosTableEntregado.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import NewEnviosTable from "@/components/NewEnviosTable.vue";
import NewEnviosEntregadosTable from "@/components/NewEnviosEntregadosTable.vue";

export default {
  components: {
    CrearEnvio,
    NewEnviosTable,
    NewEnviosEntregadosTable,
  },
  data() {
    return {
      selectedOption: "",
      productos: [],
      usuarios: [],
      envios: [],
      enviosEntregados: [],
    };
  },
  methods: {
    async getProductos() {
      try {
        const response = await axios.get(`/productos/get`);
        this.productos = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getUsuarios() {
      const response = await axios.get(`/usuarios/get_usuarios`);
      this.usuarios = response.data.usuarios;
    },
    async getEnvios() {
      const response = await axios.get(`/envios/get`);
      this.envios = response.data;
    },
    async getEnviosEntregados() {
      const response = await axios.get(`/envios/recibidos_recientes`);
      this.enviosEntregados = response.data;
    },
    envioModificado(enviosActualizados) {
      this.getEnviosEntregados();
      //se solicitan los envios desde la tabla para poder actualizrla y se envian los envios actualizados a la vista
      this.envios = enviosActualizados;
    },
    envioCreado() {
      //   this.getEnviosEntregados();
      this.getEnvios();
      this.selectedOption = "";
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.getProductos();
    this.getEnvios();
    this.getUsuarios();
    this.getEnviosEntregados();
  },
};
</script>
<style>
.disabled-button {
  opacity: 0.3;
}
.enabled-button {
  pointer-events: none;
}
</style>
