<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Clientes
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn @click="dialogActualizar = true">Actualizar</v-btn>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div :elevation="0" dark v-bind="attrs" v-on="on">
              <AgregarCliente />
            </div>
          </template>
          <span>Nuevo Cliente</span>
        </v-tooltip>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        loading-text="Cargando..."
        no-data-text="No se encontraron clientes"
        no-results-text="No se encontraron clientes"
        :footer-props="{
          'items-per-page-text': 'Clientes por página',
        }"
        :header-props="{
          'sort-by-text': 'Ordenar por',
        }"
      >
        <!-- Columna zona -->
        <template v-slot:[`item.zona`]="{ item }">
          <div class="d-flex justify-center" v-if="item.zona">
            {{ item.zona }}
          </div>
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <AccionesCliente :item="item" />
        </template>
        <template v-slot:[`item.activo_adminpaq`]="{ item }">
          <v-icon
            class="d-flex justify-center"
            :color="getColor(item.activo_adminpaq)"
            >{{
              item.activo_adminpaq ? "mdi-check-circle" : "mdi-close-circle"
            }}</v-icon
          >
        </template>
        <template v-slot:[`item.activo_saux`]="{ item }">
          <v-icon
            class="d-flex justify-center"
            :color="getColor(item.activo_saux)"
            >{{
              item.activo_saux ? "mdi-check-circle" : "mdi-close-circle"
            }}</v-icon
          >
        </template>
        <template v-slot:[`item.visitas`]="{ item }">
          <v-icon
            class="d-flex justify-center"
            :color="getColor(item.visitas)"
            >{{
              item.visitas ? "mdi-check-circle" : "mdi-close-circle"
            }}</v-icon
          >
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogActualizar" max-width="600">
      <v-card>
        <v-card-title>
          Actualizar sucursal
          <v-spacer />
          <v-btn icon @click="dialogActualizar = false">
            <v-icon color="blue darken-1">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-select
            label="Sucursal"
            v-model="sucursal"
            :items="sucursales"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mb-5" color="primary" text @click="actualizarSucursal"
            >Actualizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import AccionesCliente from "@/components/AccionesCliente.vue";
import AgregarCliente from "@/components/AgregarCliente.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search: "",
      items: [],
      dialogActualizar: false,
      sucursal: "",
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Código",
          value: "codigo",
        },

        {
          text: "Sucursal",
          value: "sucursal",
        },
        {
          text: "RFC",
          value: "rfc",
        },
        {
          text: "Nombre",
          value: "razon_social",
        },
        {
          text: "Latitud",
          value: "latitud",
        },
        {
          text: "Longitud",
          value: "longitud",
        },
        {
          text: "Zona",
          value: "zona",
        },
        {
          text: "Activo",
          value: "activo_saux",
        },
        {
          text: "Visitas",
          value: "visitas",
        },
        {
          text: "Día",
          value: "dia",
        },

        {
          text: "Giros",
          value: "giros",
        },
        {
          text: "Activo en Adminpaq",
          value: "activo_adminpaq",
        },
        {
          text: "Vendedor",
          value: "vendedor",
        },
        {
          text: "Acciones",
          value: "acciones",
        },
      ],
      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      loading: true,
    };
  },
  methods: {
    async actualizarSucursal() {
      try {
        const response = await axios.post(
          `/clientes/actualizar_sucursal?sucursal=${this.sucursal}`
        );
        console.log(response.data);
        await this.getClientes();
        alert("Sucursal actualizada");
        this.dialogActualizar = false;
      } catch (error) {
        console.error(error);
        alert(error.response.data.detail);
      }
    },
    async getClientes() {
      const response = await axios.get("/clientes/get");
      this.items = response.data;
      console.log(this.items);
      this.loading = false;
    },
    getColor(estado) {
      return estado ? "green" : "red";
    },
  },
  created() {
    this.getClientes();
  },
  components: {
    AccionesCliente,
    AgregarCliente,
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
