<template>
  <v-container v-if="user" fluid>
    <v-card>
      <v-col>
        <v-card-title>
          Pedidos
          <v-spacer />
        </v-card-title>
        <v-row>
          <v-col>
            <v-btn
              :class="[
                {
                  'disabled-button': selectedOption != 'abiertos',
                  'enabled-button': selectedOption === 'abiertos',
                },
                'mr-1',
                'ml-2',
                'mb-1',
              ]"
              color="primary"
              @click="selectedOption = 'abiertos'"
            >
              Abiertos
            </v-btn>
            <v-btn
              :class="[
                {
                  'disabled-button': selectedOption != 'cerrados',
                  'enabled-button': selectedOption === 'cerrados',
                },
                'mr-1',
                'ml-2',
                'mb-1',
              ]"
              color="primary"
              @click="selectedOption = 'cerrados'"
            >
              Cerrados
            </v-btn>
            <v-btn
              :class="[
                {
                  'disabled-button': selectedOption != 'transfers',
                  'enabled-button': selectedOption === 'transfers',
                },
                'mr-1',
                'ml-2',
                'mb-1',
              ]"
              color="primary"
              @click="selectedOption = 'transfers'"
            >
              Transfers
            </v-btn>
            <v-btn
              v-if="permisoAreaTrabajo"
              :class="[
                {
                  'disabled-button': selectedOption != 'areaTrabajo',
                  'enabled-button': selectedOption === 'areaTrabajo',
                },
                'mr-1',
                'ml-2',
                'mb-1',
              ]"
              color="primary"
              @click="selectedOption = 'areaTrabajo'"
            >
              Área de trabajo
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="selectedOption === ''"
          class="text-center"
          justify="center"
        >
          <v-progress-circular
            class="my-10"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <v-row>
          <PedidosAbiertosTable
            v-if="selectedOption === 'abiertos'"
            :items="items"
            :usuarios="usuarios"
            :clientes="clientes"
            @pedidoModificado="pedidoModificado"
            @pedidoEntregado="pedidoEntregado"
            @pedidosActualizados="recibirPedidosActualizados"
          />
          <!-- <GraphComponent v-if="selectedOption === 'cerrados'" /> -->
          <PedidosCerradosTable
            v-if="selectedOption === 'cerrados'"
            :items="itemsCerrados"
            :usuarios="usuarios"
            :clientes="clientes"
          />
          <TransfersComponent
            v-if="selectedOption === 'transfers'"
            :items="items"
            :usuarios="usuarios"
            :clientes="clientes"
            :transfers="transfers"
            @setTransfers="setTransfers"
          />
          <AreaTrabajo
            v-if="selectedOption === 'areaTrabajo'"
            @solicitarPedidos="actualizarPedidos"
            :productos="productos"
            :usuarios="usuarios"
            :clientes="clientes"
          />
        </v-row>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import PedidosAbiertosTable from "@/components/PedidosAbiertosTable.vue";
import PedidosCerradosTable from "@/components/PedidosCerradosTable.vue";
import AreaTrabajo from "@/components/AreaTrabajo.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import TransfersComponent from "@/components/TransfersComponent.vue";
export default {
  data: () => ({
    selectedOption: "",
    items: [],
    clientes: [],
    usuarios: [],
    itemsCerrados: [],
    transfers: [],
    permisoAreaTrabajo: false,
  }),
  methods: {
    pedidoModificado() {
      this.actualizarPedidos();
    },
    setTransfers(transfersRecibidos) {
      this.transfers = transfersRecibidos;
    },
    pedidoEntregado() {
      this.getPedidosCerrados();
    },
    async getPedidos() {
      try {
        const response = await axios.get(`/pedidos/get_abiertos`);
        this.items = response.data;
        this.selectedOption = "abiertos";
      } catch (error) {
        console.error(error);
      }
    },
    async actualizarPedidos() {
      try {
        const response = await axios.get(`/pedidos/get_abiertos`);
        this.items = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getPedidosCerrados() {
      try {
        const response = await axios.get(`/pedidos/get_cerrados`);
        this.itemsCerrados = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getUsuarios() {
      try {
        const response = await axios.get(`/usuarios/get_usuarios`);
        this.usuarios = response.data.usuarios;
      } catch (error) {
        console.error(error);
      }
    },
    async getClientes() {
      try {
        const response = await axios.get(`/clientes/get_nombres`);
        this.clientes = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getProductos() {
      try {
        const response = await axios.get(`/productos/get_lista`);
        this.productos = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getPermisoAgregarPrepedido() {
      try {
        const response = await axios.get(`/usuarios/yo`);
        // Separar el string en partes usando espacio como delimitador
        const permisos = response.data.permisos.split(" ");

        // Definir los sufijos buscados
        const sufijosBuscados = [
          ":pedidos:crear",
          "pedidos:crear_ageno",
          ":ADMINISTRADOR",
        ];

        // Comprobar si alguna parte termina con alguno de los sufijos buscados
        const algunaParteTerminaConSufijo = permisos.some((parte) =>
          sufijosBuscados.some((sufijo) => parte.endsWith(sufijo))
        );

        // algunaParteTerminaConSufijo será true si alguna parte termina con "_existencia", de lo contrario, será false
        // console.log(algunaParteTerminaConSufijo);
        this.permisoAreaTrabajo = algunaParteTerminaConSufijo;
      } catch (error) {
        console.error(error);
      }
    },
    recibirPedidosActualizados(nuevosPedidos) {
      this.items = nuevosPedidos;
    },
  },
  async created() {
    this.getUsuarios();
    this.getPedidos();
    this.getPedidosCerrados();
    this.getClientes();
    this.getProductos();
    this.getPermisoAgregarPrepedido();

    // Establece un temporizador para llamar a getPedidos() cada cierto intervalo (por ejemplo, cada 5 segundos)
    // this.timer = setInterval(async () => {
    //   await this.getPedidos();
    // }, 10000); // 5000 milisegundos = 5 segundos
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {},
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["currentUser"]),
  },
  components: {
    PedidosAbiertosTable,
    AreaTrabajo,
    PedidosCerradosTable,
    TransfersComponent,
  },
};
</script>
<style>
.disabled-button {
  opacity: 0.3;
}
.enabled-button {
  pointer-events: none;
}
</style>
