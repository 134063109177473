<template>
  <NavBar></NavBar>
</template>

<script>
import NavBar from "./components/NavBar.vue";
export default {
  name: "App",

  components: { NavBar },

  data: () => ({
    //
  }),
  created() {
    document.title = "SISTEMA DE FALTANTES - CENTRAL EXPRESS";
  },
};
</script>
