<template>
  <v-card>
    <!-- <v-container>
      <v-btn
        class="close-btn"
        icon
        color="blue darken-1"
        text
        @click="cerrar()"
      >
        <v-icon dark> mdi-close </v-icon>
      </v-btn>
      <v-card-title class="justify-center">
        {{ item.razon_social }}
      </v-card-title>
      <v-card-text>
        <v-list v-if="items.length !== 0" subheader>
          <v-subheader class="justify-center text-h5"
            >Guía de ventas</v-subheader
          >

          <v-list-item v-for="item in items" :key="item.nombre">
            <v-card class="styled-card mt-3">
              <v-list-item-content>
                <v-list-item-title>
                  <strong>Nombre:</strong> {{ item.nombre }}
                </v-list-item-title>
                <v-list-item-title>
                  <strong>Cantidad:</strong>
                  {{ item.cantidad }}
                </v-list-item-title>
              </v-list-item-content>
            </v-card>
          </v-list-item>
        </v-list>
        <div v-else>Este cliente no cuenta con productos de guía</div>
      </v-card-text>
    </v-container> -->

    <v-card-title class="justify-center">
      {{ item.razon_social }}
    </v-card-title>
    <h4 class="text-center py-2">Guía de ventas</h4>
    <v-card-text>
      <div v-if="items.length !== 0">
        <div v-for="(item, index) in items" :key="item.nombre">
          <v-divider class="mb-3"></v-divider>
          <div class="ml-5 mb-3">
            <strong>{{ index + 1 + "-" }} Nombre:</strong> {{ item.nombre }}
          </div>
        </div>
      </div>
      <div v-else class="text-center py-2">
        Este cliente no cuenta con guía de ventas
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["item"],
  data() {
    return {
      items: [],
    };
  },
  methods: {
    async getGuia() {
      try {
        const response = await axios.get(
          `/clientes/get_guia_de_ventas?id_cliente=${this.item.id}`
        );
        const jsonData = JSON.parse(response.data);

        // Obtener la 'data' del objeto
        this.items = jsonData.data;
      } catch (error) {
        alert(error);
        error.log(error);
      }
    },
    cerrar() {
      this.$emit("cerrarDialog", (this.dialog = false));
    },
  },
  created() {
    this.getGuia();
  },
};
</script>
