<template>
  <v-container>
    <v-switch
      class="mr-2"
      color="success"
      v-model="switch1"
      @change="cambiarActivo"
    >
    </v-switch>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "ClienteActivo",
  props: ["item"],
  data() {
    return {
      switch1: "",
      codigo: this.item.codigo ? this.item.codigo : "",
      codigo_primario: this.item.codigo_primario
        ? this.item.codigo_primario
        : "",
      nombre_primario: this.item.nombre_primario
        ? this.item.nombre_primario
        : "",
      codigos_alternos: this.item.codigos_alternos
        ? this.item.codigos_alternos
        : "",
      etiquetas: this.item.etiquetas ? this.item.etiquetas : [],
      marca: this.item.marca ? this.item.marca : "",
      laboratorio: this.item.laboratorio ? this.item.laboratorio : "",
      sku_laboratorio: this.item.sku_laboratorio ? this.item.laboratorio : "",
      activo: this.item.activo ? this.item.activo : false,
      errores: [],
    };
  },
  methods: {
    async cambiarActivo() {
      try {
        const response = await axios.post(
          `/productos/modificar_producto`,
          {
            codigo: this.codigo,
            codigo_primario: this.codigo_primario,
            nombre_primario: this.nombre_primario,
            codigos_alternos: this.codigos_alternos,
            etiquetas: this.etiquetas,
            marca: this.marca,
            laboratorio: this.laboratorio,
            sku_laboratorio: this.sku_laboratorio,
            activo: !this.activo,
          },
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        alert(JSON.stringify(response.data));
        location.reload();
      } catch (error) {
        console.error(error); // Handle any errors that might occur
      }
    },
  },
  created() {
    this.switch1 = this.item.activo;
  },
};
</script>
