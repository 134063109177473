<template>
  <v-card>
    <v-container>
      <v-btn
        class="absolute top right"
        icon
        color="blue darken-1"
        text
        @click="cerrar()"
      >
        <v-icon dark> mdi-close </v-icon>
      </v-btn>
      <v-card-text>
        <v-container>
          <form ref="form" @submit.prevent="crearAsignacion()">
            <v-row>
              <v-col>
                <v-card-title class="justify-center">
                  Crear una asignación para: {{ item.nombre }}
                </v-card-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Id de usuario"
                  append-icon="mdi-text-box-multiple-outline"
                  v-model="usuario_id"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="Perfil"
                  outlined
                  :items="perfilOptions"
                  v-model="nombre_perfil"
                  required
                  @change="updateLocal"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-if="local"
                  label="Sucursal"
                  outlined
                  :items="sucursales"
                  v-model="sucursal"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-btn type="submit" color="teal lighten-2" text>Enviar</v-btn>
          </form>
        </v-container>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "CrearAsignacion",
  props: ["item"],
  data() {
    return {
      nombre_perfil: "",
      usuario_id: this.item.id,
      local: false,
      sucursal: "",
      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      items: [],
      errores: [],
    };
  },
  methods: {
    async getPerfiles() {
      const response = await axios.get("/usuarios/get_perfiles");
      this.items = response.data.perfiles;
    },
    async crearAsignacion() {
      try {
        let url;
        if (this.local) {
          url = `/usuarios/crear_asignacion?nombre_perfil=${this.nombre_perfil.nombre}&usuario_id=${this.usuario_id}&sucursal=${this.sucursal}`;
        } else {
          url = `/usuarios/crear_asignacion?nombre_perfil=${this.nombre_perfil.nombre}&usuario_id=${this.usuario_id}`;
        }
        // if (this.local) {
        //   url = `/usuarios/crear_asignacion?id_perfil=${this.nombre_perfil.id}&usuario_id=${this.usuario_id}&sucursal=${this.sucursal}`;
        // } else {
        //   url = `/usuarios/crear_asignacion?id_perfil=${this.nombre_perfil.id}&usuario_id=${this.usuario_id}`;
        // }

        const response = await axios.post(
          url,
          {},
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        // alert(JSON.stringify(response.data.resultado));
        console.log(JSON.stringify(response.data.resultado));
        alert("Asignación creada");
        location.reload();
        this.$emit("usuarioModificado");
      } catch (error) {
        if (error) {
          this.errores.push(error);
          alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
        this.serverError = true;
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    },
    updateLocal() {
      this.local = this.nombre_perfil.local;
    },
    cerrar() {
      this.$emit("cerrarDialog", (this.dialog = false));
    },
  },
  computed: {
    ...mapGetters(["perfil"]),
    perfilOptions() {
      return this.items.map((perfil) => {
        return { text: perfil.nombre, value: perfil };
      });
    },
  },
  async created() {
    await this.getPerfiles();
  },
};
</script>

<style>
.close-btn {
  float: right;
}

.absolute {
  position: absolute;
}

.top {
  top: 10;
}

.right {
  right: 10;
}
</style>
