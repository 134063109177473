var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-col',[_c('v-card-title',[_vm._v(" Faltantes "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({attrs:{"elevation":0,"dark":""}},'div',attrs,false),on),[_c('CrearFaltante')],1)]}}],null,false,4006964106)},[_c('span',[_vm._v("Crear Faltante")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{class:[
              {
                'disabled-button': _vm.selectedOption != 'abiertos',
                'enabled-button': _vm.selectedOption === 'abiertos',
              },
              'mr-2',
              'ml-2',
            ],attrs:{"color":"primary"},on:{"click":function($event){_vm.selectedOption = 'abiertos'}}},[_vm._v(" Abiertos ")]),_c('v-btn',{class:[
              {
                'disabled-button': _vm.selectedOption != 'cerrados',
                'enabled-button': _vm.selectedOption === 'cerrados',
              },
              'mr-2',
              'ml-2',
            ],attrs:{"color":"primary"},on:{"click":function($event){_vm.selectedOption = 'cerrados'}}},[_vm._v(" Cerrados ")])],1),(_vm.selectedTab != null)?_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.enviosClick(_vm.selectedTab)}}},[_vm._v("Revisar Envios")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.faltantesClick(_vm.selectedTab)}}},[_vm._v("Revisar Inventario")])],1):_vm._e()],1),_c('v-row',[(_vm.selectedOption === 'abiertos')?_c('FaltantesAbiertosTable',{attrs:{"items":_vm.items,"usuarios":_vm.usuarios,"clientes":_vm.clientes},on:{"enviarTab":_vm.getSelectedTab,"faltanteModificado":_vm.faltanteModificado}}):_vm._e(),(_vm.selectedOption === 'cerrados')?_c('FaltantesCerradosTable',{attrs:{"items":_vm.itemsCerrados,"usuarios":_vm.usuarios,"clientes":_vm.clientes},on:{"enviarTab":_vm.getSelectedTab}}):_vm._e()],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }