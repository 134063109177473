<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Estado de cuenta
      </v-card-title>
      <v-row class="mx-2 mt-1">
        <v-col cols="12" lg="3" md="3">
          <v-select
            v-model="sucursalSelected"
            :items="sucursales"
            item-value="value"
            label="Sucursal"
            placeholder="Selecciona sucursal"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="5" md="4">
          <v-autocomplete
            :items="itemsFiltrados"
            v-model="item"
            item-text="razon_social"
            :return-object="true"
            label="Cliente"
            no-data-text="No hay resultados"
            :filter="customFilter"
            @change="clienteSelected"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.razon_social"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.codigo"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <!-- Selector de año -->
        <!-- <v-col cols="12" lg="3" md="3">
          <v-menu
            ref="menuYear"
            v-model="menuYear"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selectedYear"
                label="Año"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :no-title="true"
              v-model="date"
              :active-picker.sync="activePicker"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substring(0, 10)
              "
              min="2000-01-01"
              @click:year="contextMenu"
              @change="save"
            ></v-date-picker>
          </v-menu>
        </v-col> -->
        <!-- Selector de año y mes -->
        <v-col cols="12" lg="3" md="3">
          <v-menu
            ref="menuMesAño"
            v-model="menuMesAño"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date2"
                label="Mes y año"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date2"
              type="month"
              no-title
              scrollable
              min="2000-01-01"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substring(0, 10)
              "
              :active-picker.sync="activePicker"
              @click:month="menuMesAño = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-btn @click="getEstadoCuenta" small class="mt-5">Buscar</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <!-- Dialog para la tabla de facturas -->
    <v-dialog fullscreen transition="dialog-bottom-transition" v-model="dialog">
      <v-card>
        <v-btn
          class="absolute top right"
          icon
          color="blue darken-1"
          text
          @click="cerrarDialog"
        >
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
        <v-card-title>Resumen</v-card-title>
        <v-card-text>
          <v-row class="mx-1 text-body-1" style="color: black">
            <v-col>
              {{ fechaResumen }}
            </v-col>
            <v-spacer />
            <v-col>
              Límite de crédito: {{ estadoCredito.limite_credito }}
            </v-col>
          </v-row>
          <v-row class="mx-1 text-body-1" style="color: black">
            <v-spacer />
            <v-col cols="4">
              Crédito activo:
              <v-icon
                class="mr-2"
                :color="estadoCredito.credito_activado ? 'green' : 'red'"
                size="25"
              >
                {{
                  estadoCredito.credito_activado
                    ? "mdi-check-circle"
                    : "mdi-close-circle"
                }}
              </v-icon>
            </v-col>
          </v-row>
          <v-list>
            <v-list-item>
              <v-list-item-title
                >Saldo inicial: ${{
                  formatoPrecio(saldo_inicial)
                }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                >Pendiente total: ${{
                  formatoPrecio(pendiente_total)
                }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Cargos (+): ${{ formatoPrecio(cargos) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Abonos (-): ${{ formatoPrecio(abonos) }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Saldo final: ${{
                    formatoPrecio(saldo_final)
                  }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row class="mx-1 text-body-1" style="color: black">
            <v-col cols="3">
              <v-switch
                v-model="switch1"
                inset
                :label="`Mostrar solo pendientes`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-data-table
            dense
            :headers="headers"
            :items="itemsFormat"
            :items-per-page="15"
            class="elevation-1"
            no-data-text="No hay elementos para mostrar"
            :footer-props="{
              'items-per-page-text': 'Elementos por página',
            }"
            :header-props="{
              'sort-by-text': 'Ordenar por',
            }"
          >
            <template v-slot:[`item.fecha`]="{ item }">
              {{ getFechaLegible(item.fecha) }}
            </template>
            <template v-slot:[`item.total`]="{ item }">
              {{ "$" + formatoPrecio(item.total) }}
            </template>
            <template v-slot:[`item.pendiente`]="{ item }">
              {{ "$" + formatoPrecio(item.pendiente) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      date2: new Date("2024-01-01").toISOString().substr(0, 7),
      modal2: false,
      item: {},
      sucursalSelected: "Mexicali",
      dialog: false,
      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      items: [],
      fechaResumen: "",
      estadoCredito: {},
      switch1: false,
      saldo_inicial: 0,
      saldo_final: 0,
      pendiente_total: 0,
      cargos: 0,
      abonos: 0,
      documentos: [],
      headers: [
        {
          text: "Concepto",
          value: "concepto",
        },
        {
          text: "Serie y Folio",
          value: "serie_folio",
        },
        {
          text: "Fecha",
          value: "fecha",
          // sortable: false,
        },
        {
          text: "Tipo",
          value: "naturaleza",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Pendiente",
          value: "pendiente",
          filter: (value) => {
            if (!this.switch1) {
              return true;
            } else {
              return value > 0;
            }
          },
        },
        {
          text: "Días para vencer",
          value: "dias_para_vencimiento",
        },
      ],
      menuYear: false,
      menuMesAño: false,
      selectedYear: 2024,
      activePicker: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  methods: {
    clienteSelected() {
      console.log(this.item);
    },
    async getEstadoCuenta() {
      const partes = this.date2.split("-");
      const year = partes[0];
      const month = partes[1];
      // Convertir el mes de formato numérico a nombre del mes
      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      const nombreMes = meses[parseInt(month) - 1]; // Restamos 1 porque los índices en el array comienzan en 0

      // Concatenar el resultado
      this.fechaResumen = `${nombreMes} ${year}`;
      try {
        const response = await axios.get(
          `/clientes/estado_de_cuenta?id_cliente=${this.item.id}&year=${year}&month=${month}`
        );
        console.log(response.data);
        this.estadoCredito = response.data.estado_credito;
        this.documentos = response.data.documentos;
        this.saldo_inicial = response.data.saldo_inicial;
        // let totalDeuda = this.facturas.reduce(
        //   (acumulador, item) => acumulador + item.pendiente,
        //   0
        // );
        this.cargos = this.documentos.reduce((acumulador, item) => {
          if (item.naturaleza === "cargo") {
            return acumulador + item.total;
          } else {
            return acumulador;
          }
        }, 0);
        this.abonos = this.documentos.reduce((acumulador, item) => {
          if (item.naturaleza === "abono") {
            return acumulador + item.total;
          } else {
            return acumulador;
          }
        }, 0);
        this.pendiente_total = this.documentos.reduce(
          (acumulador, item) => acumulador + item.pendiente,
          0
        );
        this.saldo_final = this.saldo_inicial + this.cargos - this.abonos;
        this.dialog = true;
      } catch (error) {
        alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        console.log(error.response.data);
      }
    },
    async getClientes() {
      try {
        const response = await axios.get(`/clientes/get_nombres`);
        this.items = response.data;
        console.log(this.items);
      } catch (error) {
        console.error(error);
      }
    },
    cerrarDialog() {
      this.dialog = false;
    },
    customFilter(item, queryText) {
      const textOne = item.razon_social.toLowerCase();
      const textTwo = item.codigo.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    getFechaLegible(fechaLarga) {
      const fecha = new Date(fechaLarga);

      const año = fecha.getFullYear();
      const mes = fecha.getMonth() + 1;
      const dia = fecha.getDate();
      // const hora = fecha.getHours();
      // const minutos = fecha.getMinutes().toString().padStart(2, "0");

      const fechaLegible = `${dia}/${mes}/${año}`;
      // const horaLegible = `${hora}:${minutos}`;

      return `${fechaLegible}`;
    },
    formatoPrecio(precioProducto) {
      // Formatear el número utilizando una función personalizada
      const numero = parseFloat(precioProducto);
      // console.log(numero);
      let precio = 0;

      precio = numero.toFixed(2); // Limitar a 2 decimales
      const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
      const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

      // Formatear la parte entera con separador de miles
      const parteEnteraFormateada = parteEntera.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
    },
    calcularDiasRestantes(fecha) {
      const fechaVencimiento = new Date(fecha);
      const fechaActual = new Date();

      // Calcula la diferencia en milisegundos
      const diferenciaMs = fechaVencimiento - fechaActual;

      // Convierte la diferencia en días
      const diasRestantes = Math.ceil(diferenciaMs / (1000 * 60 * 60 * 24));

      return diasRestantes;
    },
    //Solo se usan si se usa el menu de anio por separado y no junto al mes
    contextMenu(year, event) {
      this.selectedYear = year;
      event.preventDefault();
      this.menuYear = false;
    },
    save(date) {
      this.$refs.menuYear.save(date);
    },
  },
  created() {
    this.getClientes();
  },
  watch: {
    menuMesAño(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuYear(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    selectedYear(val) {
      this.fechaInicial = "";
      this.fechaFin = "";
      console.log(val);
      const fechaActual = new Date(); // Fecha actual
      const fechaInicio = new Date(val, 0, 1); // Primer día del año

      // Compara el año actual con el año seleccionado
      if (val === fechaActual.getFullYear()) {
        // Si es el año actual, utiliza la fecha actual como fecha final
        var fechaFin = fechaActual;
      } else {
        // Si no es el año actual, utiliza el último día del año
        fechaFin = new Date(val, 11, 31);
      }

      // Formatea las fechas en el formato 'YYYY-MM-DD'
      const fechaInicioFormateada = fechaInicio.toISOString().slice(0, 10);
      const fechaFinFormateada = fechaFin.toISOString().slice(0, 10);

      this.fechaInicial = fechaInicioFormateada;
      this.fechaFin = fechaFinFormateada;
    },
    // date2(val) {
    //   console.log(val);
    // },
  },
  components: {},
  computed: {
    ...mapGetters(["user"]),
    itemsFiltrados() {
      const itemsSucursal = this.items.filter(
        (cliente) => cliente.sucursal === this.sucursalSelected
      );
      return itemsSucursal;
    },
    itemsFormat() {
      return this.documentos.map((documento) => {
        const nuevoDocumento = { ...documento };
        // nuevoDocumento.fecha = this.getFechaLegible(documento.fecha);
        // nuevoDocumento.fecha_vencimiento = this.getFechaLegible(
        //   documento.fecha_vencimiento
        // );
        // nuevoDocumento.dias_restantes = this.calcularDiasRestantes(
        //   documento.fecha_vencimiento
        // );
        nuevoDocumento.serie_folio = `${documento.serie} ${documento.folio}`;
        return nuevoDocumento;
      });
    },
  },
};
</script>
