<template>
  <v-container>
    <v-menu>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
              mdi-dots-vertical
            </v-icon>
          </template>
          <span>Acciones</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="selectComponent(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog max-width="600px" v-model="dialog">
      <v-card v-if="selectedComponent === 'CancelarEnvio'">
        <CancelarEnvio :item="item" @envioModificado="envioModificado" />
      </v-card>
      <v-card v-if="selectedComponent === 'CambiarLlegadaEnvio'">
        <CambiarLlegadaEnvio :item="item" @envioModificado="envioModificado" />
      </v-card>
      <v-card v-if="selectedComponent === 'RecibirEnvio'">
        <RecibirEnvio :item="item" @envioModificado="envioModificado" />
      </v-card>
      <v-card v-if="selectedComponent === 'HistorialObjeto'">
        <HistorialObjeto
          :item="item"
          :usuarios="usuarios"
          @cerrarDialog="cerrarDialog"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CancelarEnvio from "../Cancelar/CancelarEnvio.vue";
import { mapActions } from "vuex";
import CambiarLlegadaEnvio from "../Otros/CambiarLlegadaEnvio.vue";
import RecibirEnvio from "../Otros/RecibirEnvio.vue";
import HistorialObjeto from "../Historial/HistorialObjeto.vue";
export default {
  props: ["item", "usuarios"],
  components: {
    CancelarEnvio,
    CambiarLlegadaEnvio,
    RecibirEnvio,
    HistorialObjeto,
  },
  data() {
    return {
      dialog: false,
      items: [
        {
          title: "Cambiar llegada",
          component: "CambiarLlegadaEnvio", // Corrected spelling
        },
        {
          title: "Recibir envío",
          component: "RecibirEnvio",
        },
        {
          title: "Cancelar envío",
          component: "CancelarEnvio",
        },
        {
          title: "Historial de envío",
          component: "HistorialObjeto",
        },
      ],
      selectedComponent: null,
    };
  },
  methods: {
    selectComponent(item) {
      if (item.component) {
        this.selectedComponent = item.component;
        this.dialog = true;
      }
    },
    ...mapActions(["setPerfil"]),
    openPerfil(perfil) {
      this.setPerfil(perfil);
      this.$emit("openPerfil"); // Emit an event to the parent component
    },
    cerrarDialog() {
      this.dialog = false;
    },
    envioModificado() {
      this.dialog = false;
      this.$emit("envioModificado");
    },
  },
};
</script>
