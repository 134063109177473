<template>
  <v-dialog max-width="50%" v-model="dialog">
    <v-card>
      <v-container>
        <v-card-title>
          Etiquetas
          <v-spacer />
          <v-btn
            class="close-btn"
            icon
            value="log in"
            color="blue darken-1"
            text
            @click="cerrar()"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <form ref="form" @submit.prevent="modificarEtiquetas()">
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Etiquetas"
                    append-icon="mdi-card-text-outline"
                    v-model="checkboxValues"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-btn @click="mostrarCheckboxes = !mostrarCheckboxes">
                  Mostrar etiquetas
                </v-btn>
              </v-row>

              <div v-if="mostrarCheckboxes">
                <div class="mt-6">
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Buscar etiquetas"
                        append-icon="mdi-magnify"
                        v-model="filtroEtiquetas"
                      ></v-text-field>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                </div>

                <v-row>
                  <div
                    v-for="(valor, index) in etiquetasFiltradas"
                    :key="index"
                    class="mt-4"
                  >
                    <v-col>
                      <label>
                        <input
                          type="checkbox"
                          v-model="checkboxValues"
                          :value="valor"
                        />
                        {{ valor }}
                      </label>
                    </v-col>
                  </div>
                </v-row>
              </div>
              <v-row>
                <div class="mt-4">
                  <v-btn type="submit" color="teal lighten-2" text
                    >Modificar</v-btn
                  >
                </div>
              </v-row>
            </form>
          </v-container>
        </v-card-text>
      </v-container>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-2" v-on="on" v-bind="attrs">Consultar</v-btn>
    </template>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: ["item", "etiquetasArray"],
  data() {
    return {
      dialog: false,
      errores: [],
      //etiquetasArray: this.etiquetasArray,
      checkboxValues: this.item.etiquetas ? this.item.etiquetas : [],
      mostrarCheckboxes: false,
      props: [],
      codigo: this.item.codigo ? this.item.codigo : "",
      codigo_primario: this.item.codigo_primario ? this.codigo_primario : "",
      nombre_primario: this.item.nombre_primario ? this.nombre_primario : "",
      codigos_alternos: this.item.codigos_alternos ? this.codigos_alternos : "",
      etiquetas: this.item.checkboxValues,
      marca: this.item.marca ? this.item.marca : "",
      laboratorio: this.item.laboratorio ? this.item.laboratorio : "",
      sku_laboratorio: this.item.sku_laboratorio
        ? this.item.sku_laboratorio
        : "",
      activo: this.item.activo ? this.item.activo : false,
      filtroEtiquetas: "",
    };
  },
  methods: {
    async modificarEtiquetas() {
      try {
        const response = await axios.post(
          `/productos/modificar_producto`,
          {
            codigo: this.codigo,
            codigo_primario: this.codigo_primario,
            nombre_primario: this.nombre_primario,
            codigos_alternos: this.codigos_alternos,
            etiquetas: this.checkboxValues,
            marca: this.marca,
            laboratorio: this.laboratorio,
            sku_laboratorio: this.sku_laboratorio,
            activo: this.activo,
          },
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        alert(JSON.stringify(response.data));
        location.reload();
      } catch (error) {
        console.error(error); // Handle any errors that might occur
      }
    },
    cerrar() {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    etiquetasFiltradas() {
      return this.etiquetasArray.filter((valor) => {
        return valor.toLowerCase().includes(this.filtroEtiquetas.toLowerCase());
      });
    },
  },
};
</script>

<style>
.close-btn {
  float: right;
}
</style>
