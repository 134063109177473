var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-col',[_c('v-card-title',[_vm._v(" Pedidos "),_c('v-spacer')],1),_c('v-row',[_c('v-col',[_c('v-btn',{class:[
              {
                'disabled-button': _vm.selectedOption != 'abiertos',
                'enabled-button': _vm.selectedOption === 'abiertos',
              },
              'mr-1',
              'ml-2',
              'mb-1',
            ],attrs:{"color":"primary"},on:{"click":function($event){_vm.selectedOption = 'abiertos'}}},[_vm._v(" Abiertos ")]),_c('v-btn',{class:[
              {
                'disabled-button': _vm.selectedOption != 'cerrados',
                'enabled-button': _vm.selectedOption === 'cerrados',
              },
              'mr-1',
              'ml-2',
              'mb-1',
            ],attrs:{"color":"primary"},on:{"click":function($event){_vm.selectedOption = 'cerrados'}}},[_vm._v(" Cerrados ")]),_c('v-btn',{class:[
              {
                'disabled-button': _vm.selectedOption != 'transfers',
                'enabled-button': _vm.selectedOption === 'transfers',
              },
              'mr-1',
              'ml-2',
              'mb-1',
            ],attrs:{"color":"primary"},on:{"click":function($event){_vm.selectedOption = 'transfers'}}},[_vm._v(" Transfers ")]),(_vm.permisoAreaTrabajo)?_c('v-btn',{class:[
              {
                'disabled-button': _vm.selectedOption != 'areaTrabajo',
                'enabled-button': _vm.selectedOption === 'areaTrabajo',
              },
              'mr-1',
              'ml-2',
              'mb-1',
            ],attrs:{"color":"primary"},on:{"click":function($event){_vm.selectedOption = 'areaTrabajo'}}},[_vm._v(" Área de trabajo ")]):_vm._e()],1)],1),(_vm.selectedOption === '')?_c('v-row',{staticClass:"text-center",attrs:{"justify":"center"}},[_c('v-progress-circular',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('v-row',[(_vm.selectedOption === 'abiertos')?_c('PedidosAbiertosTable',{attrs:{"items":_vm.items,"usuarios":_vm.usuarios,"clientes":_vm.clientes},on:{"pedidoModificado":_vm.pedidoModificado,"pedidoEntregado":_vm.pedidoEntregado,"pedidosActualizados":_vm.recibirPedidosActualizados}}):_vm._e(),(_vm.selectedOption === 'cerrados')?_c('PedidosCerradosTable',{attrs:{"items":_vm.itemsCerrados,"usuarios":_vm.usuarios,"clientes":_vm.clientes}}):_vm._e(),(_vm.selectedOption === 'transfers')?_c('TransfersComponent',{attrs:{"items":_vm.items,"usuarios":_vm.usuarios,"clientes":_vm.clientes,"transfers":_vm.transfers},on:{"setTransfers":_vm.setTransfers}}):_vm._e(),(_vm.selectedOption === 'areaTrabajo')?_c('AreaTrabajo',{attrs:{"productos":_vm.productos,"usuarios":_vm.usuarios,"clientes":_vm.clientes},on:{"solicitarPedidos":_vm.actualizarPedidos}}):_vm._e()],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }