import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex);

const state = {
    user: null,
    usuario: null,
};

const store =  new Vuex.Store({
    state,
    getters: {
        user: (state) => {
            return state.user;
        },
        currentUser: (state) => {
            return state.usuario;
        },
    },
    actions: {
        user(context, user) {
            context.commit('user', user);
        },
        async fetchUserData({ commit, state }) {
            if (!state.usuario) {
                try {
                    const response = await axios.get('/usuarios/yo');
                    const userData = response.data;
                    // Luego, llama a la mutación setUser para almacenar la información en el estado
                    commit('setUsuario', userData);
                } catch (error) {
                    console.error('Error al obtener la información del usuario:', error);
                }
            }
        },
        clearUserData({ commit }) {
            commit('clearUsuario');
        },
    },

    mutations: {
        user(state, user) {
            state.user = user;
        },
        setUsuario(state, user) {
            state.usuario = user;
        },
        clearUsuario(state) {
            state.usuario = null;
        },
    },
})

export default store;