<template>
  <v-container fluid>
    <v-card-title class="text-center teal lighten-3 accent-4 white--text">
      Área de trabajo
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div :elevation="0" dark v-bind="attrs" v-on="on">
            <AgregarPrepedido
              @solicitarPrepedidos="loadObjects"
              :entries="productos"
              :entriesClientes="clientes"
              :usuarios="usuarios"
            />
          </div>
        </template>
        <span>Nuevo prepedido</span>
      </v-tooltip>
    </v-card-title>
    <v-container fluid>
      <v-select
        v-model="id_vendedor"
        :items="userIsAdmin ? getFilteredUsuarios : usuarios"
        :item-text="(user) => user.nombre"
        :item-value="(user) => user.id"
        :filterable="true"
        :label="'Selecciona Vendedor'"
        :disabled="!userIsAdmin"
        @change="cambioDeVendedor"
      >
      </v-select>
      <v-btn @click="loadObjects" :disabled="!userIsAdmin">Cargar Datos</v-btn>
      <!-- Tu contenido aquí -->
    </v-container>
    <v-row class="mt-2">
      <v-col
        v-for="prepedido in prepedidosTemp"
        :key="prepedido.id"
        cols="12"
        md="4"
        lg="3"
      >
        <v-card class="styled-card">
          <v-card-title>
            {{ "Prepedido: " + prepedido.id }}
            <v-spacer></v-spacer>
            <v-btn
              class="styled-button"
              icon
              color="blue darken-1"
              text
              @click="eliminarPrepedido(prepedido.id)"
            >
              <v-icon dark>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="ml-3">
            <v-row>
              <strong>Cliente: </strong>
              <div class="ml-1">
                {{ getCliente(prepedido.cliente) || "Cliente no disponible" }}
              </div>
            </v-row>
            <!-- <v-row>
              <strong> Total: </strong>
              {{ calcularTotalTexto(prepedido.productos) }}
            </v-row> -->
            <v-row>
              <strong> Total: </strong>
              <div class="ml-1">
                {{ totalesPorPrepedido[prepedido.id] || "$0.00" }}
              </div>
            </v-row>
            <v-row>
              <strong>Creado hace: </strong>
              <div class="ml-1">
                {{ getAntiguedad(prepedido.fecha_creacion) }}
              </div>
            </v-row>
          </v-card-text>
          <v-row class="my-1">
            <v-col class="ml-3">
              <ModificarPrepedido
                @solicitarPrepedidos="loadObjects"
                :entries="productos"
                :entriesClientes="clientes"
                :usuarios="usuarios"
                :prepedido="prepedido"
                @actualizarTotal="actualizarTotal"
              />
            </v-col>
            <v-col class="mr-2">
              <v-btn small color="primary" @click="crearPedido(prepedido.id)"
                >Crear pedido
                <v-icon class="pl-1" size="small"> mdi-send</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-btn x-small @click="mostrarPrepedido(prepedido.id)">
              mostrar
            </v-btn>
          </v-row> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import AgregarPrepedido from "./AgregarPrepedido.vue";
import ModificarPrepedido from "./ModificarPrepedido.vue";

export default {
  props: ["productos", "usuarios", "clientes"],
  data() {
    return {
      //   productos: this.productos,
      //   clientes: this.clientes,
      //   usuarios: this.clientes,
      prepedidosTemp: [],
      clientesUsuario: [],
      //clientes: [],
      id_vendedor: null,
      userIsAdmin: false,
      //total: 0,
      totalesPorPrepedido: {},
      //dialog: false,
      //prepedido: null,
    };
  },
  methods: {
    async obtenerProductos(idPrepedido) {
      try {
        const response = await axios.get(`/pedidos/get_productos_pre_pedido`, {
          params: { id_pre_pedido: idPrepedido },
        });
        return response.data.productos; // Ajusta según la estructura de tu respuesta
      } catch (error) {
        console.error(
          "Error al obtener productos del prepedido:",
          error.response ? error.response.data : error.message
        );
        return [];
      }
    },

    async crearPedido(idPrepedido) {
      /*if (!this.idPrepedido) {
        console.error("ID de prepedido no disponible");
        return;
      }*/

      try {
        // Realiza la solicitud POST al backend para crear el pedido
        const response = await axios.post(
          `/pedidos/crear_pedido?id_pre_pedido=${idPrepedido}`
        );
        // Maneja la respuesta de éxito
        alert(`Pedido ${idPrepedido} creado con éxito.`);
        console.log("Pedido creado con éxito:", response.data);

        const prepedidoIndex = this.prepedidosTemp.findIndex(
          (prepedido) => prepedido.id === idPrepedido
        );

        if (prepedidoIndex !== -1) {
          this.prepedidosTemp.splice(prepedidoIndex, 1);
        }
        // Puedes agregar lógica adicional aquí, como limpiar el estado o redirigir al usuario
        this.$emit("solicitarPedidos");
        //location.reload();
      } catch (error) {
        // Maneja el error
        console.error("Error al crear el pedido:", error);
      }

      // Crear faltantes de los productos con existencia 0
      /*const productosSinExistencia = productosPedido.filter(
          (producto) => producto.existencia === 0
        );

        for (const producto of productosSinExistencia) {
          const datosFaltante = {
            codigo_cliente: prepedidoEncontrado.codigo_cliente,
            sucursal: producto.sucursal,
            codigo_producto: producto.producto,
            cantidad: parseInt(producto.cantidad),
            id_vendedor: prepedidoEncontrado.id_vendedor,
            observaciones: "Pedido: " + idPrepedido,
          };

          try {
            const response = await axios.post(
              `/faltantes/crear`,
              datosFaltante
            );
            console.log("Faltante creado:", response.data);
          } catch (error) {
            console.error(
              `Error al crear faltante para producto ${producto.producto}:`,
              error.response ? error.response.data : error.message
            );
          }
        }*/

      /*this.eliminarPrepedido(idPrepedido);
        this.$emit("solicitarPedidos");
      } catch (error) {
        console.error(
          "Error al crear pedido:",
          error.response ? error.response.data : error.message
        );
      }*/
    },

    actualizarTotal(idPrepedido, total) {
      if (idPrepedido) {
        console.log(
          `Actualizando total para prepedido ID: ${idPrepedido}, Total: ${total}`
        );
        this.$set(this.totalesPorPrepedido, idPrepedido, total);
      }
      /*this.total = nuevoTotal.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });*/
    },

    async eliminarPrepedido(idPrepedido) {
      // Confirmar la eliminación
      const isConfirmed = window.confirm(
        `¿Está seguro de que desea eliminar el prepedido: ${idPrepedido}?`
      );

      if (!isConfirmed) {
        return;
      }

      try {
        // Enviar la solicitud DELETE al endpoint
        await axios.delete(`/pedidos/eliminar_pre_pedido`, {
          params: { id_pre_pedido: idPrepedido },
        });

        // Eliminar el prepedido de la lista localmente
        const prepedidoIndex = this.prepedidosTemp.findIndex(
          (prepedido) => prepedido.id === idPrepedido
        );

        if (prepedidoIndex !== -1) {
          this.prepedidosTemp.splice(prepedidoIndex, 1);
        }

        // Eliminar el prepedido del almacenamiento local si es necesario
        localStorage.setItem(
          "prepedidosTemp",
          JSON.stringify(this.prepedidosTemp)
        );

        // Emitir un evento para solicitar la actualización de los pedidos si es necesario
        this.$emit("solicitarPedidos");

        alert(`Prepedido ${idPrepedido} eliminado exitosamente`);
        console.log(`Prepedido ${idPrepedido} eliminado exitosamente`);
      } catch (error) {
        console.error(`Error al eliminar el prepedido ${idPrepedido}:`, error);
      }
    },
    async loadObjects() {
      this.isLoading = true;
      try {
        // Cargar prepedidos
        const responsePrepedidos = await axios.get(
          `/pedidos/get_pre_pedidos?id_vendedor=${this.id_vendedor}`
        );
        this.prepedidosTemp = responsePrepedidos.data || [];

        // Cargar clientes si no es administrador
        if (!this.userIsAdmin && this.clientesUsuario.length === 0) {
          const responseClientes = await axios.get(
            `/clientes/get_mis_clientes?vendedor_id=${this.id_vendedor}`
          );
          this.clientesUsuario = responseClientes.data || [];
        }
      } catch (error) {
        console.error("Error al cargar los datos", error);
      } finally {
        this.isLoading = false;
      }
    },
    formatoFecha(fechaOriginal) {
      // Fecha en el formato original

      // Crear un objeto Date a partir de la fecha original
      const fecha = new Date(fechaOriginal);

      // Obtener los componentes de la fecha
      const year = fecha.getFullYear();
      const month = ("0" + (fecha.getMonth() + 1)).slice(-2); // Agrega un cero inicial si es necesario
      const day = ("0" + fecha.getDate()).slice(-2); // Agrega un cero inicial si es necesario

      // Construir la fecha en el formato deseado
      const fechaFormateada = `${day}-${month}-${year}`;

      return fechaFormateada; // Resultado: '2023-06-01'
    },
    getCliente(clienteID) {
      const clienteEncontrado = this.clientes.find(
        (cliente) => cliente.id === clienteID
      );
      if (clienteEncontrado) {
        return clienteEncontrado.razon_social + "";
      }
      return "";
    },
    getAntiguedad(fecha_creacion) {
      console.log("Fecha de creación", fecha_creacion);
      if (!fecha_creacion || typeof fecha_creacion !== "string") {
        return "Fecha inválida";
      }
      let fecha = new Date(fecha_creacion);

      if (isNaN(fecha.getTime())) {
        fecha = new Date(fecha_creacion.replace(/-/g, "/"));
        if (isNaN(fecha.getTime())) {
          return "Fecha inválida";
        }
      }

      const now = new Date();
      const diffMs = now - fecha;
      const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
      const diffHrs = Math.floor(
        (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

      if (diffDays > 0) {
        return `${diffDays} día${diffDays > 1 ? "s" : ""}`;
      } else if (diffHrs > 0) {
        return `${diffHrs} hora${diffHrs > 1 ? "s" : ""}`;
      } else if (diffMins > 0) {
        return `${diffMins} minuto${diffMins > 1 ? "s" : ""}`;
      } else {
        return `menos de un minuto`;
      }
    },
    async getUsuarioAdmin() {
      const permisos = this.currentUser.permisos.split(" ");

      // Definir los sufijos buscados
      const sufijosBuscados = [":ADMINISTRADOR"];

      // Comprobar si alguna parte termina con alguno de los sufijos buscados
      const algunaParteTerminaConSufijo = permisos.some((parte) =>
        sufijosBuscados.some((sufijo) => parte.endsWith(sufijo))
      );

      // algunaParteTerminaConSufijo será true si alguna parte termina con "_existencia", de lo contrario, será false
      this.userIsAdmin = algunaParteTerminaConSufijo;

      if (!this.userIsAdmin && this.currentUser.id) {
        this.id_vendedor = this.currentUser.id;
        this.getClientesDelUsuario(this.id_vendedor);
      }
    },
    cambioDeVendedor() {
      if (this.id_vendedor) {
        if (this.clientesUsuario.length == 0) {
          this.getClientesDelUsuario(this.id_vendedor);
        }
      }
    },
    async getClientesDelUsuario(idUsuario) {
      if (!idUsuario) return;

      if (this.clientesUsuario.length === 0) {
        try {
          const response = await axios.get(
            `/clientes/get_mis_clientes?vendedor_id=${idUsuario}`
          );
          this.clientesUsuario = response.data;
        } catch (error) {
          console.error("Error en la solicitud GET", error);
        }
      }
    },
  },
  mounted() {
    this.loadObjects();
  },
  created() {
    this.getUsuarioAdmin();
    //this.dialog = false;
    /*this.$root.$on("prepedidoCreado", (idPrepedido) => {
      console.log("Evento 'prepedidoCreado' recibido con ID:", idPrepedido);

      const prepedido = this.prepedidosTemp.find(
        (prepedido) => prepedido.id === idPrepedido
      );

      if (prepedido) {
        console.log("Prepedido encontrado:", prepedido);
        this.prepedido = prepedido;
        this.dialog = true;
      } else {
        console.log("Prepedido no encontrado en prepedidosTemp");
      }
      /*setTimeout(() => {
        const prepedido = this.prepedidosTemp.find(
          (prepedido) => prepedido.id === idPrepedido
        );

        if (prepedido) {
          console.log("Prepedido encontrado:", prepedido);
          this.prepedido = prepedido;
          this.dialog = true;
        } else {
          console.log("Prepedido no encontrado en prepedidosTemp");
        }
      }, 500);
    });*/
  },
  /*beforeDestroy() {
    this.$root.$off("prepedidoCreado");
  },*/
  components: { AgregarPrepedido, ModificarPrepedido },
  computed: {
    ...mapGetters(["user", "currentUser"]),
    getFilteredUsuarios() {
      return this.usuarios.filter((user) => {
        return user.nombre_adminpaq !== null;
      });
    },
  },
};
</script>

<style>
.styled-card {
  width: 100%;
  padding: 10px;
}

.styled-button {
  text-align: right;
}
</style>
