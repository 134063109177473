<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Perfiles <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div :elevation="0" dark v-bind="attrs" v-on="on">
              <CrearPerfil />
            </div>
          </template>
          <span>Nuevo Perfil</span>
        </v-tooltip>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :footer-props="{
          'items-per-page-text': 'Perfiles por página',
        }"
        :header-props="{
          'sort-by-text': 'Ordenar por',
        }"
      >
        <template v-slot:[`item.local`]="{ item }">
          {{ item.local ? "Cierto" : "Falso" }}
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <AccionesPerfil :item="item"
        /></template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import CrearPerfil from "@/components/CrearPerfil.vue";
import AccionesPerfil from "@/components/AccionesPerfil.vue";

export default {
  components: {
    CrearPerfil,
    AccionesPerfil,
  },
  data() {
    return {
      items: [],
      headers: [
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Local",
          value: "local",
        },
        {
          text: "Nombre",
          value: "nombre",
        },
        {
          text: "Plantillas",
          value: "plantillas",
        },
        {
          text: "Acciones",
          value: "acciones",
        },
      ],
    };
  },
  methods: {
    async getPerfiles() {
      try {
        const perfiles = await axios.get("/usuarios/get_perfiles");
        this.items = perfiles.data.perfiles;
      } catch (error) {
        console.error(error);
      }
    },
    getColor(estado) {
      return estado ? "green" : "red";
    },
  },
  created() {
    this.getPerfiles();
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
