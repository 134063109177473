<template>
  <v-dialog max-width="45%" v-model="dialog">
    <v-card>
      <v-card-title class="justify-center">Selecciona un día</v-card-title>
      <v-row justify="center">
        <v-date-picker
          no-title
          v-model="fecha"
          color="light-green"
          locale="es-MX"
          @input="enviarDatos"
        >
        </v-date-picker>
      </v-row>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mt-3" v-on="on" v-bind="attrs">
        <v-icon color="blue darken-1"> mdi-calendar-month-outline </v-icon>
      </v-btn>
    </template>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fecha: "",
      dialog: false,
    };
  },
  methods: {
    enviarDatos() {
      this.$emit("enviar-datos", this.fecha);
      this.dialog = !this.dialog;
    },
  },
  created() {},
};
</script>

<!-- ////////////////////////////////////// -->
