<template>
  <v-container class="mt-5" style="width: 250px; height: 250px">
    <Doughnut id="my-chart-id" :options="chartOptions" :data="chartData" />
  </v-container>
</template>

<script>
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from "chart.js";
import { Doughnut } from "vue-chartjs";
ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);
export default {
  name: "DoughnutChart",
  components: { Doughnut },
  props: ["datos"],
  data() {
    return {
      chartData: {
        labels: ["Visitados", "No visitados", "Sin localización"],
        datasets: [
          {
            backgroundColor: ["#43A047", "#FB8C00", "#EF5350"], // Colores para las secciones del diagrama
            data: [
              this.datos.clientesVisitados,
              this.datos.clientesNoVisitados,
              this.datos.clientesSinLocalizacion,
            ],
          },
        ],
      },
      chartOptions: {
        responsive: true,
      },
    };
  },
};
</script>
