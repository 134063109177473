<template>
  <v-container fluid>
    <v-card-title class="text-center teal lighten-3 accent-4 white--text">
      Faltantes Abiertos
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer />

      <v-select
        class="mt-6"
        label="Filtrar por estado"
        v-model="filtroEstado"
        :items="listaEstados"
      ></v-select>
    </v-card-title>
    <v-tabs v-model="selectedTab" color="teal lighten-3 accent-4" left>
      <v-tab
        v-for="(sucursal, index) in itemsSucursalesDisponibles"
        :key="index"
      >
        <div v-if="sucursal.sucursal === 'LaPaz'">La Paz</div>
        <div v-else>
          {{ sucursal.sucursal }}
        </div>
      </v-tab>
      <v-tab-item v-for="(sucursal, index) in itemsNombre" :key="index">
        <v-data-table
          :headers="headers"
          :items="selectedItems"
          :search="search"
          :footer-props="{ 'items-per-page-text': 'Faltantes por página' }"
          :header-props="{ 'sort-by-text': 'Ordenar por' }"
          no-data-text="No se encontraron faltantes"
          no-results-text="No se encontraron faltantes"
          :item-class="itemRowBackground"
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <AccionesFaltante
              :item="item"
              :usuarios="itemsUsuarios"
              @faltanteModificado="faltanteModificado"
            />
          </template>
          <template v-slot:[`item.seguimiento_automatico`]="{ item }">
            <SeguimientoAutomatico :item="item" />
          </template>
          <!-- Columna de antiguedad -->
          <template v-slot:[`item.fecha_creacion`]="{ item }">
            {{ getAntiguedad(item.fecha_creacion) }}
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import AccionesFaltante from "@/components/AccionesFaltante.vue";
import SeguimientoAutomatico from "@/components/SeguimientoAutomatico.vue";
export default {
  props: ["items", "usuarios", "clientes"],
  data() {
    return {
      selectedTab: 0,
      search: "",
      filtroEstado: "",
      listaEstados: [
        {
          text: "Sin filtro",
          value: "",
        },
        {
          text: "En Camino",
          value: "En Camino",
        },
        {
          text: "Requiere Aclaración",
          value: "Requiere Aclaracion",
        },
        {
          text: "En Calendario",
          value: "En Calendario",
        },
        {
          text: "En Inventario",
          value: "En Inventario",
        },
      ],
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Código de producto",
          value: "codigo_producto",
        },
        {
          text: "Nombre",
          value: "nombre",
        },
        {
          text: "Cantidad",
          value: "cantidad",
        },
        {
          text: "Cliente",
          value: "cliente",
        },
        {
          text: "Antigüedad",
          value: "fecha_creacion",
        },
        {
          text: "Estado",
          value: "estado",
          filter: (value) => {
            if (!this.filtroEstado) return true;

            return value === this.filtroEstado;
          },
        },
        {
          text: "Detalle",
          value: "detalle",
        },
        {
          text: "Laboratorio",
          value: "laboratorio",
        },

        {
          text: "Seguimiendo Automático",
          value: "seguimiento_automatico",
        },
        {
          text: "Vendedor",
          value: "vendedor",
        },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
        },
      ],
      ordenSucursales: [
        "Mexicali",
        "Tijuana",
        "Ensenada",
        "LaPaz",
        "Obregon",
        "Hermosillo",
      ],
      itemsSucursalesDisponibles: [],
      itemsFaltantes: this.items,
      itemsUsuarios: this.usuarios,
    };
  },
  methods: {
    obtenerArregloSucursalesDisponibles() {
      const nuevoArreglo = this.itemsFaltantes.sort((a, b) => {
        const indiceA = this.ordenSucursales.indexOf(a.sucursal);
        const indiceB = this.ordenSucursales.indexOf(b.sucursal);
        return indiceA - indiceB;
      });
      this.itemsSucursalesDisponibles = nuevoArreglo;
    },
    getNombreVendedor(vendedorId) {
      const vendedorEncontrado = this.usuarios.find(
        (usuario) => usuario.id === vendedorId
      );
      if (vendedorEncontrado) {
        return vendedorEncontrado.nombre + " ";
      }
      return "";
    },
    getNombreCliente(clienteId) {
      const clienteEncontrado = this.clientes.find(
        (cliente) => cliente.id === clienteId
      );
      if (clienteEncontrado) {
        return clienteEncontrado.razon_social + " ";
      }
      return "";
    },
    getAntiguedad(fecha_creacion) {
      const now = new Date();
      const fecha = new Date(fecha_creacion);
      const diffMs = now - fecha;
      const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
      const diffHrs = Math.floor(
        (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

      if (diffDays > 0) {
        return `${diffDays} día${diffDays > 1 ? "s" : ""}`;
      } else if (diffHrs > 0) {
        return `${diffHrs} hora${diffHrs > 1 ? "s" : ""}`;
      } else if (diffMins > 0) {
        return `${diffMins} minuto${diffMins > 1 ? "s" : ""}`;
      } else {
        return `menos de un minuto`;
      }
    },
    faltanteModificado() {
      this.$emit("faltanteModificado");
    },
  },
  computed: {
    itemsNombre() {
      return this.itemsSucursalesDisponibles.map((sucursal) => {
        const faltantes = sucursal.faltantes.map((faltante) => {
          const nuevoFaltante = { ...faltante };
          nuevoFaltante.vendedor = this.getNombreVendedor(faltante.vendedor);
          nuevoFaltante.cliente = this.getNombreCliente(faltante.cliente);
          // nuevoFaltante.antiguedad = this.getAntiguedad(faltante.fecha_creacion);
          return nuevoFaltante;
        });

        return { ...sucursal, faltantes };
      });
    },
    selectedItems() {
      //Se envia el nombre de la tab seleccionada a la view de faltantes para que pueda revisar envios e inventario
      this.$emit(
        "enviarTab",
        this.itemsSucursalesDisponibles[this.selectedTab].sucursal
      );
      if (this.selectedTab >= 1 && this.selectedTab <= 6) {
        return this.itemsNombre[this.selectedTab].faltantes;
      } else {
        return this.itemsNombre[this.selectedTab].faltantes || [];
      }
    },
    itemRowBackground() {
      return (item) => {
        const estado = item.estado;
        switch (estado) {
          case "Cancelado":
            return "red lighten-4";
          case "Facturado":
            return "teal lighten-4";
          case "En Camino":
            return "yellow lighten-4";
          case "Requiere Aclaracion":
            return "orange lighten-4";
          case "En Calendario":
            return "blue lighten-4";
          case "Nuevo Faltante":
            return "green lighten-4";
          case "En Inventario":
            return "indigo lighten-4";
          default:
            return "";
        }
      };
    },
  },
  mounted() {
    this.obtenerArregloSucursalesDisponibles();
  },
  components: { AccionesFaltante, SeguimientoAutomatico },
};
</script>
