<template>
  <v-container fluid>
    <v-card-title class="teal lighten-3 accent-4 white--text">
      Transfers
      <v-spacer />
      <!-- <v-btn small @click="mostrarProductos"> Pedidos </v-btn> -->
    </v-card-title>

    <v-row align="center" class="mx-2 mt-2">
      <v-col cols="12" sm="6" md="3">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fechaInicial"
              label="Fecha inicial"
              prepend-icon="mdi-calendar"
              readonly
              required
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaInicial"
            no-title
            color="teal lighten-3"
            locale="es-MX"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fechaFin"
              label="Fecha fin"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaFin"
            no-title
            color="teal lighten-3"
            locale="es-MX"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6" md="4" class="mt-1">
        <v-btn small @click="getPedidos"> Consultar </v-btn>
      </v-col>
    </v-row>

    <v-tabs
      v-if="itemsFiltrados.length > 0"
      class="mt-2"
      v-model="selectedTab"
      color="teal lighten-3 accent-4"
      left
    >
      <v-tab v-for="item in tabs" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab" v-if="itemsFiltrados.length > 0">
      <!-- Tab resumen -->
      <v-tab-item>
        <v-card flat>
          <v-card-title>Total por laboratorio</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headersLabGlobal"
              :items="itemsLabSucursal"
              :items-per-page="-1"
              hide-default-header
              hide-default-footer
              @click:row="getPedidosFilaLab($event, false)"
            >
              <!-- Columna de total -->
              <template v-slot:[`item.total`]="{ item }">
                {{ "$" + formatoPrecio(item.total) }}
              </template>
            </v-data-table>
            <v-card-title>Paquetes</v-card-title>
            <v-data-table
              :headers="headersPaqGlobal"
              :items="itemsPaqSucursal"
              :items-per-page="-1"
              hide-default-header
              hide-default-footer
              @click:row="getPedidosFilaPaq($event, false)"
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- Tab por sucursal -->
      <v-tab-item>
        <v-card flat>
          <v-card-title>Total por laboratorio</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headersLabSucursal"
              :items="itemsLabSucursal"
              :items-per-page="-1"
              hide-default-footer
              @click:row="getPedidosFilaLab($event, true)"
            >
              <!-- Columna de total Tijuana -->
              <template v-slot:[`item.totalTijuana`]="{ item }">
                {{ "$" + formatoPrecio(item.totalTijuana) }}
              </template>
              <!-- Columna de total Mexicali -->
              <template v-slot:[`item.totalMexicali`]="{ item }">
                {{ "$" + formatoPrecio(item.totalMexicali) }}
              </template>
              <!-- Columna de total Ensenada -->
              <template v-slot:[`item.totalEnsenada`]="{ item }">
                {{ "$" + formatoPrecio(item.totalEnsenada) }}
              </template>
            </v-data-table>
            <v-card-title>Paquetes</v-card-title>
            <v-data-table
              :headers="headersPaqSucursal"
              :items="itemsPaqSucursal"
              :items-per-page="-1"
              hide-default-footer
              @click:row="getPedidosFilaPaq($event, true)"
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- Tab pendiente -->
      <v-tab-item>
        <v-card flat>
          <v-card-title>Productos pendientes</v-card-title>
          <v-card-text>
            <v-row class="mt-1">
              <v-col cols="12" sm="auto">
                <v-switch
                  v-model="switchProductosPendientes"
                  label="Sucursales"
                ></v-switch>
              </v-col>
              <v-spacer />
              <v-col cols="12" sm="auto">
                <v-btn small @click="exportToCSV"> Descargar CSV </v-btn>
              </v-col>
            </v-row>
            <v-data-table
              v-if="!switchProductosPendientes"
              :headers="headersProductos"
              :items="productosPendientes"
              :items-per-page="-1"
              hide-default-footer
            >
            </v-data-table>
            <v-data-table
              v-if="switchProductosPendientes"
              :headers="headersProductos"
              :items="productosPendientesPorSucursal"
              :items-per-page="-1"
              hide-default-footer
              group-by="sucursal"
              no-data-text="No hay pedidos"
            >
              <!-- Fila de grupo/sucursal -->
              <template v-slot:[`group.header`]="{ group, isOpen, toggle }">
                <th colspan="5">
                  <v-icon @click="toggle"
                    >{{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
                  </v-icon>
                  {{ group }}
                </th>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <!-- Dialog para ver los pedidos de una fila de laboratorio seleccionada -->
    <v-dialog v-model="dialogPedidosFilaLab" scrollable max-width="800px">
      <v-card>
        <v-card-title>
          Pedidos
          <v-spacer />
          <v-switch v-model="switchSucursales" label="Sucursales"></v-switch>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersPedidos"
            item-key="id"
            :items="pedidosFila"
            :items-per-page="-1"
            hide-default-footer
            :group-by="switchSucursales ? 'sucursal' : null"
            no-data-text="No hay pedidos"
          >
            <!-- Fila de grupo/sucursal -->
            <template v-slot:[`group.header`]="{ group, isOpen, toggle }">
              <th colspan="4">
                <v-icon @click="toggle"
                  >{{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
                </v-icon>
                {{ group }}
              </th>
            </template>
            <!-- Columna de cliente -->
            <template v-slot:[`item.cliente`]="{ item }">
              {{ getNombreCliente(item.cliente) }}
            </template>
            <!-- Columna de facturado -->
            <template v-slot:[`item.facturas`]="{ item }">
              <v-icon
                class="d-flex justify-center"
                :color="item.facturas ? 'green' : 'red'"
                >{{
                  item.facturas ? "mdi-check-circle" : "mdi-close-circle"
                }}</v-icon
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: ["items", "usuarios", "clientes", "transfers"],
  data() {
    return {
      menu: false,
      menu2: false,
      dialogPedidosFilaLab: false,
      clickedRowLab: null,
      switchSucursales: true,
      switchProductosPendientes: false,

      itemsFiltrados: [],
      fechaInicial: "2024-06-01",
      fechaFin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      tabs: ["Resumen", "Por sucursal", "Pendientes"],
      selectedTab: null,
      pedidos: [],
      pedidosFila: [],
      arrayEtiquetas: [
        // Lista de etiquetas que se buscaran en los pedidos
        "transfer.pisa",
        "transfer.elanco",
        "transfer.dechra",
        "transfer.mederilab",
        "transfer.virbac",
        "transfer.pets",
        "transfer.vetoquinol",
        "paquete.freidora",
        "paquete.letrero",
        "paquete.ipad",
        "paquete.hielera",
        "paquete.secadora",
        "paquete.libros",
        "paquete.cotoneros",
        "paquete.termo",
        "paquete.mochila",
        "paquete.audio",
      ],
      headersLabGlobal: [
        {
          text: "Laboratorio",
          align: "start",
          value: "laboratorio",
          sortable: false,
        },
        {
          text: "Total",
          align: "center",
          value: "total",
          sortable: false,
        },
      ],
      headersPaqGlobal: [
        {
          text: "Paquete",
          align: "start",
          value: "paquete",
          sortable: false,
        },
        {
          text: "Total",
          align: "center",
          value: "total",
          sortable: false,
        },
      ],
      headersLabSucursal: [
        {
          text: "Laboratorio",
          align: "start",
          value: "laboratorio",
          sortable: false,
        },
        {
          text: "Tijuana",
          align: "center",
          value: "totalTijuana",
          sortable: false,
        },
        {
          text: "Mexicali",
          align: "center",
          value: "totalMexicali",
          sortable: false,
        },
        {
          text: "Ensenada",
          align: "center",
          value: "totalEnsenada",
          sortable: false,
        },
      ],
      headersPaqSucursal: [
        {
          text: "Paquete",
          align: "start",
          value: "paquete",
          sortable: false,
        },
        {
          text: "Tijuana",
          align: "center",
          value: "totalTijuana",
          sortable: false,
        },
        {
          text: "Mexicali",
          align: "center",
          value: "totalMexicali",
          sortable: false,
        },
        {
          text: "Ensenada",
          align: "center",
          value: "totalEnsenada",
          sortable: false,
        },
      ],
      headersPedidos: [
        {
          text: "ID",
          value: "id",
          sortable: false,
        },
        {
          text: "Cliente",
          value: "cliente",
          sortable: false,
        },
        {
          text: "Estado",
          value: "estado",
          sortable: false,
        },
        {
          text: "Facturado",
          align: "center",
          value: "facturas",
          sortable: false,
        },
      ],
      headersProductos: [
        {
          text: "Código",
          value: "codigo",
          sortable: false,
        },
        {
          text: "Nombre",
          value: "nombre",
          sortable: false,
        },
        {
          text: "Total",
          value: "cantidadTotal",
          sortable: false,
        },
        {
          text: "Facturados",
          align: "center",
          value: "facturados",
          sortable: false,
        },
        {
          text: "Pendientes",
          align: "center",
          value: "pendientes",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    getPedidosFilaLab(data, mostrarSucursales) {
      console.log(data);
      this.pedidosFila = this.itemsFiltrados.filter((pedido) => {
        return pedido.observaciones
          .toLowerCase()
          .includes(data.laboratorio.toLowerCase());
      });

      this.switchSucursales = mostrarSucursales;
      this.dialogPedidosFilaLab = true;

      console.log("Pedidos de fila:", this.pedidosFila);
    },
    getPedidosFilaPaq(data, mostrarSucursales) {
      console.log(data);
      this.pedidosFila = this.itemsFiltrados.filter((pedido) => {
        return pedido.observaciones
          .toLowerCase()
          .includes(data.paquete.toLowerCase());
      });

      this.switchSucursales = mostrarSucursales;
      this.dialogPedidosFilaLab = true;

      console.log("Pedidos de fila:", this.pedidosFila);
    },
    async getPedidos() {
      try {
        const response = await axios.get(
          `/pedidos/get_transfers?fecha_inicial=${this.fechaInicial}&fecha_final=${this.fechaFin}`
        );
        console.log(response.data);
        this.pedidos = response.data.pedidos;
        this.itemsFiltrados = this.pedidos.filter((pedido) => {
          return this.arrayEtiquetas.some((subcadena) =>
            pedido.observaciones.toLowerCase().includes(subcadena)
          );
          // JSON.stringify
          // JSON.parse
        });

        console.log("Pedidos con etiquetas:", this.itemsFiltrados);
        this.$emit("setTransfers", this.itemsFiltrados);
        // console.log(this.itemsFiltrados);
        if (!this.itemsFiltrados.length > 0) {
          alert("No se encontraron pedidos con etiquetas");
        }
      } catch (error) {
        console.error(error);
        alert("Error al obtener los datos");
      }
    },
    mostrarProductos() {
      // console.log(this.items);
      // console.log(this.itemsFiltrados);
      // console.log(this.itemsLabSucursal);
      // console.log(this.itemsPaqSucursal);
      console.log(
        "productosTotalSucursal",
        this.productosPendientesPorSucursal
      );
    },
    formatoPrecio(numero) {
      // Formatear el número utilizando una función personalizada
      const numeroPrecio = parseFloat(numero);
      const precio = numeroPrecio.toFixed(2); // Limitar a 2 decimales
      const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
      const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

      // Formatear la parte entera con separador de miles
      const parteEnteraFormateada = parteEntera.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
    },
    getNombreCliente(clienteId) {
      const clienteEncontrado = this.clientes.find(
        (cliente) => cliente.id === clienteId
      );
      if (clienteEncontrado) {
        return clienteEncontrado.razon_social + " ";
      }
      return "";
    },
    async exportToCSV() {
      if (this.productosPendientesPorSucursal.length === 0) {
        alert("No hay datos para exportar");
      } else {
        // Generar el contenido CSV
        let csvContent = null;

        csvContent = this.convertArrayOfObjectsToCSV(
          this.productosPendientesPorSucursal
        );

        // Crear un objeto Blob
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

        // Crear un enlace de descarga
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank";
        link.download = "productos.csv";

        // Agregar el enlace al documento y hacer clic en él
        document.body.appendChild(link);
        link.click();

        // Eliminar el enlace del documento
        document.body.removeChild(link);
      }
    },
    convertArrayOfObjectsToCSV(items) {
      const separator = ",";
      const keys = Object.keys(items[0]);

      // Encabezados CSV
      const headerRow = keys.join(separator);

      // Filas CSV
      const rows = items.map((item) => {
        const rowValues = keys.map((key) => {
          let value = item[key];

          if (value === null || value === undefined) {
            value = "";
          } else if (typeof value === "string") {
            // Escapar comillas dobles en el valor
            value = value.replace(/"/g, '""');
            // Encerrar el valor entre comillas si contiene comas, comillas dobles o saltos de línea
            if (
              value.includes(",") ||
              value.includes('"') ||
              value.includes("\n")
            ) {
              value = `"${value}"`;
            }
          }

          return value;
        });

        return rowValues.join(separator);
      });

      return headerRow + "\n" + rows.join("\n");
    },
  },
  created() {
    if (this.transfers) {
      this.itemsFiltrados = this.transfers;
    }
  },
  watch: {
    // pedidosFila(newValue) {
    //   console.log(newValue);
    //   this.dialogPedidosFila = true;
    // },
  },
  components: {},
  computed: {
    //ALLI003: 7
    //ARAN016: 3
    productosPendientesPorSucursal() {
      let arrayProductos = this.itemsFiltrados.map((item) => {
        return item.productos.lista_productos.map((producto) => {
          const newProducto = { ...producto };
          newProducto.facturado = item.facturas ? true : false;
          newProducto.sucursal = item.sucursal;
          return newProducto;
        });
      });

      arrayProductos = arrayProductos.flat();

      if (arrayProductos.length > 0) {
        const productosTotal = Object.values(
          arrayProductos.reduce((acc, producto) => {
            const key = `${producto.codigo}-${producto.sucursal}`;
            if (!acc[key]) {
              acc[key] = {
                codigo: producto.codigo,
                nombre: producto.nombre,
                cantidadTotal: 0,
                facturados: 0,
                pendientes: 0,
                sucursal: producto.sucursal,
              };
            }

            acc[key].cantidadTotal += producto.cantidad;
            if (producto.facturado) {
              acc[key].facturados += producto.cantidad;
            } else {
              acc[key].pendientes += producto.cantidad;
            }

            return acc;
          }, {})
        );
        return productosTotal;
      } else {
        return [];
      }
    },
    productosPendientes() {
      let arrayProductos = this.itemsFiltrados.map((item) => {
        return item.productos.lista_productos.map((producto) => {
          const newProducto = { ...producto };
          newProducto.facturado = item.facturas ? true : false;
          return newProducto;
        });
      });

      arrayProductos = arrayProductos.flat();
      console.log("arrayProductos", arrayProductos);

      if (arrayProductos.length > 0) {
        const productosTotal = Object.values(
          arrayProductos.reduce((acc, producto) => {
            if (!acc[producto.codigo]) {
              acc[producto.codigo] = {
                codigo: producto.codigo,
                nombre: producto.nombre,
                cantidadTotal: 0,
                facturados: 0,
                pendientes: 0,
              };
            }

            acc[producto.codigo].cantidadTotal += producto.cantidad;
            if (producto.facturado) {
              acc[producto.codigo].facturados += producto.cantidad;
            } else {
              acc[producto.codigo].pendientes += producto.cantidad;
            }

            return acc;
          }, {})
        );

        console.log("productosTotal", productosTotal);

        return productosTotal;
      } else {
        return [];
      }
    },
    // pedidosFila() {
    //   // almacena los pedidos que forman parte del total de una fila a la que se le hizo click
    //   if (this.clickedRowLab) {
    //     return this.itemsFiltrados.filter((pedido) => {
    //       return pedido.observaciones
    //         .toLowerCase()
    //         .includes(this.clickedRowLab.laboratorio.toLowerCase());
    //     });
    //   } else {
    //     return [];
    //   }
    // },
    itemsLabSucursal() {
      let pisaTijuana = 0;
      let elancoTijuana = 0;
      let dechraTijuana = 0;
      let mederilabTijuana = 0;
      let virbacTijuana = 0;
      let petsTijuana = 0;
      let vetoquinolTijuana = 0;
      let pisaMexicali = 0;
      let elancoMexicali = 0;
      let dechraMexicali = 0;
      let mederilabMexicali = 0;
      let virbacMexicali = 0;
      let petsMexicali = 0;
      let vetoquinolMexicali = 0;
      let pisaEnsenada = 0;
      let elancoEnsenada = 0;
      let dechraEnsenada = 0;
      let mederilabEnsenada = 0;
      let virbacEnsenada = 0;
      let petsEnsenada = 0;
      let vetoquinolEnsenada = 0;

      this.itemsFiltrados.map((pedido) => {
        this.arrayEtiquetas.forEach((subcadena) => {
          if (pedido.observaciones.toLowerCase().includes(subcadena)) {
            if (subcadena === "transfer.pisa") {
              if (pedido.sucursal === "Tijuana") {
                pisaTijuana += pedido.total;
              }
              if (pedido.sucursal === "Mexicali") {
                pisaMexicali += pedido.total;
              }
              if (pedido.sucursal === "Ensenada") {
                pisaEnsenada += pedido.total;
              }
            }
            if (subcadena === "transfer.elanco") {
              if (pedido.sucursal === "Tijuana") {
                elancoTijuana += pedido.total;
              }
              if (pedido.sucursal === "Mexicali") {
                elancoMexicali += pedido.total;
              }
              if (pedido.sucursal === "Ensenada") {
                elancoEnsenada += pedido.total;
              }
            }
            if (subcadena === "transfer.dechra") {
              if (pedido.sucursal === "Tijuana") {
                dechraTijuana += pedido.total;
              }
              if (pedido.sucursal === "Mexicali") {
                dechraMexicali += pedido.total;
              }
              if (pedido.sucursal === "Ensenada") {
                dechraEnsenada += pedido.total;
              }
            }
            if (subcadena === "transfer.mederilab") {
              if (pedido.sucursal === "Tijuana") {
                mederilabTijuana += pedido.total;
              }
              if (pedido.sucursal === "Mexicali") {
                mederilabMexicali += pedido.total;
              }
              if (pedido.sucursal === "Ensenada") {
                mederilabEnsenada += pedido.total;
              }
            }
            if (subcadena === "transfer.virbac") {
              if (pedido.sucursal === "Tijuana") {
                virbacTijuana += pedido.total;
              }
              if (pedido.sucursal === "Mexicali") {
                virbacMexicali += pedido.total;
              }
              if (pedido.sucursal === "Ensenada") {
                virbacEnsenada += pedido.total;
              }
            }
            if (subcadena === "transfer.pets") {
              if (pedido.sucursal === "Tijuana") {
                petsTijuana += pedido.total;
              }
              if (pedido.sucursal === "Mexicali") {
                petsMexicali += pedido.total;
              }
              if (pedido.sucursal === "Ensenada") {
                petsEnsenada += pedido.total;
              }
            }
            if (subcadena === "transfer.vetoquinol") {
              if (pedido.sucursal === "Tijuana") {
                vetoquinolTijuana += pedido.total;
              }
              if (pedido.sucursal === "Mexicali") {
                vetoquinolMexicali += pedido.total;
              }
              if (pedido.sucursal === "Ensenada") {
                vetoquinolEnsenada += pedido.total;
              }
            }
          }
        });
      });

      const totalesLabs = [
        {
          laboratorio: "PISA",
          totalTijuana: pisaTijuana,
          totalMexicali: pisaMexicali,
          totalEnsenada: pisaEnsenada,
          total: pisaTijuana + pisaMexicali + pisaEnsenada,
        },
        {
          laboratorio: "ELANCO",
          totalTijuana: elancoTijuana,
          totalMexicali: elancoMexicali,
          totalEnsenada: elancoEnsenada,
          total: elancoTijuana + elancoMexicali + elancoEnsenada,
        },
        {
          laboratorio: "DECHRA",
          totalTijuana: dechraTijuana,
          totalMexicali: dechraMexicali,
          totalEnsenada: dechraEnsenada,
          total: dechraTijuana + dechraMexicali + dechraEnsenada,
        },
        {
          laboratorio: "MEDERILAB",
          totalTijuana: mederilabTijuana,
          totalMexicali: mederilabMexicali,
          totalEnsenada: mederilabEnsenada,
          total: mederilabTijuana + mederilabMexicali + mederilabEnsenada,
        },
        {
          laboratorio: "VIRBAC",
          totalTijuana: virbacTijuana,
          totalMexicali: virbacMexicali,
          totalEnsenada: virbacEnsenada,
          total: virbacTijuana + virbacMexicali + virbacEnsenada,
        },
        {
          laboratorio: "PETS",
          totalTijuana: petsTijuana,
          totalMexicali: petsMexicali,
          totalEnsenada: petsEnsenada,
          total: petsTijuana + petsMexicali + petsEnsenada,
        },
        {
          laboratorio: "VETOQUINOL",
          totalTijuana: vetoquinolTijuana,
          totalMexicali: vetoquinolMexicali,
          totalEnsenada: vetoquinolEnsenada,
          total: vetoquinolTijuana + vetoquinolMexicali + vetoquinolEnsenada,
        },
      ];

      return totalesLabs;
    },
    itemsPaqSucursal() {
      let paqFreidoraTijuana = 0;
      let paqLetreroTijuana = 0;
      let paqIpadTijuana = 0;
      let paqHieleraTijuana = 0;
      let paqSecadoraTijuana = 0;
      let paqLibrosTijuana = 0;
      let paqCotonerosTijuana = 0;
      let paqTermoTijuana = 0;
      let paqMochilaTijuana = 0;
      let paqAudioTijuana = 0;
      let paqFreidoraMexicali = 0;
      let paqLetreroMexicali = 0;
      let paqIpadMexicali = 0;
      let paqHieleraMexicali = 0;
      let paqSecadoraMexicali = 0;
      let paqLibrosMexicali = 0;
      let paqCotonerosMexicali = 0;
      let paqTermoMexicali = 0;
      let paqMochilaMexicali = 0;
      let paqAudioMexicali = 0;
      let paqFreidoraEnsenada = 0;
      let paqLetreroEnsenada = 0;
      let paqIpadEnsenada = 0;
      let paqHieleraEnsenada = 0;
      let paqSecadoraEnsenada = 0;
      let paqLibrosEnsenada = 0;
      let paqCotonerosEnsenada = 0;
      let paqTermoEnsenada = 0;
      let paqMochilaEnsenada = 0;
      let paqAudioEnsenada = 0;

      this.itemsFiltrados.map((pedido) => {
        this.arrayEtiquetas.forEach((subcadena) => {
          if (pedido.observaciones.toLowerCase().includes(subcadena)) {
            if (subcadena === "paquete.freidora") {
              if (pedido.sucursal === "Tijuana") {
                paqFreidoraTijuana++;
              }
              if (pedido.sucursal === "Mexicali") {
                paqFreidoraMexicali++;
              }
              if (pedido.sucursal === "Ensenada") {
                paqFreidoraEnsenada++;
              }
            }
            if (subcadena === "paquete.letrero") {
              if (pedido.sucursal === "Tijuana") {
                paqLetreroTijuana++;
              }
              if (pedido.sucursal === "Mexicali") {
                paqLetreroMexicali++;
              }
              if (pedido.sucursal === "Ensenada") {
                paqLetreroEnsenada++;
              }
            }
            if (subcadena === "paquete.ipad") {
              if (pedido.sucursal === "Tijuana") {
                paqIpadTijuana++;
              }
              if (pedido.sucursal === "Mexicali") {
                paqIpadMexicali++;
              }
              if (pedido.sucursal === "Ensenada") {
                paqIpadEnsenada++;
              }
            }
            if (subcadena === "paquete.hielera") {
              if (pedido.sucursal === "Tijuana") {
                paqHieleraTijuana++;
              }
              if (pedido.sucursal === "Mexicali") {
                paqHieleraMexicali++;
              }
              if (pedido.sucursal === "Ensenada") {
                paqHieleraEnsenada++;
              }
            }
            if (subcadena === "paquete.secadora") {
              if (pedido.sucursal === "Tijuana") {
                paqSecadoraTijuana++;
              }
              if (pedido.sucursal === "Mexicali") {
                paqSecadoraMexicali++;
              }
              if (pedido.sucursal === "Ensenada") {
                paqSecadoraEnsenada++;
              }
            }
            if (subcadena === "paquete.libros") {
              if (pedido.sucursal === "Tijuana") {
                paqLibrosTijuana++;
              }
              if (pedido.sucursal === "Mexicali") {
                paqLibrosMexicali++;
              }
              if (pedido.sucursal === "Ensenada") {
                paqLibrosEnsenada++;
              }
            }
            if (subcadena === "paquete.cotoneros") {
              if (pedido.sucursal === "Tijuana") {
                paqCotonerosTijuana++;
              }
              if (pedido.sucursal === "Mexicali") {
                paqCotonerosMexicali++;
              }
              if (pedido.sucursal === "Ensenada") {
                paqCotonerosEnsenada++;
              }
            }
            if (subcadena === "paquete.termo") {
              if (pedido.sucursal === "Tijuana") {
                paqTermoTijuana++;
              }
              if (pedido.sucursal === "Mexicali") {
                paqTermoMexicali++;
              }
              if (pedido.sucursal === "Ensenada") {
                paqTermoEnsenada++;
              }
            }
            if (subcadena === "paquete.mochila") {
              if (pedido.sucursal === "Tijuana") {
                paqMochilaTijuana++;
              }
              if (pedido.sucursal === "Mexicali") {
                paqMochilaMexicali++;
              }
              if (pedido.sucursal === "Ensenada") {
                paqMochilaEnsenada++;
              }
            }
            if (subcadena === "paquete.audio") {
              if (pedido.sucursal === "Tijuana") {
                paqAudioTijuana++;
              }
              if (pedido.sucursal === "Mexicali") {
                paqAudioMexicali++;
              }
              if (pedido.sucursal === "Ensenada") {
                paqAudioEnsenada++;
              }
            }
          }
        });
      });

      const totalPaquetes = [
        {
          paquete: "Freidora",
          totalTijuana: paqFreidoraTijuana,
          totalMexicali: paqFreidoraMexicali,
          totalEnsenada: paqFreidoraEnsenada,
          total: paqFreidoraTijuana + paqFreidoraMexicali + paqFreidoraEnsenada,
        },
        {
          paquete: "Letrero",
          totalTijuana: paqLetreroTijuana,
          totalMexicali: paqLetreroMexicali,
          totalEnsenada: paqLetreroEnsenada,
          total: paqLetreroTijuana + paqLetreroMexicali + paqLetreroEnsenada,
        },
        {
          paquete: "iPad",
          totalTijuana: paqIpadTijuana,
          totalMexicali: paqIpadMexicali,
          totalEnsenada: paqIpadEnsenada,
          total: paqIpadTijuana + paqIpadMexicali + paqIpadEnsenada,
        },
        {
          paquete: "Hielera",
          totalTijuana: paqHieleraTijuana,
          totalMexicali: paqHieleraMexicali,
          totalEnsenada: paqHieleraEnsenada,
          total: paqHieleraTijuana + paqHieleraMexicali + paqHieleraEnsenada,
        },
        {
          paquete: "Secadora",
          totalTijuana: paqSecadoraTijuana,
          totalMexicali: paqSecadoraMexicali,
          totalEnsenada: paqSecadoraEnsenada,
          total: paqSecadoraTijuana + paqSecadoraMexicali + paqSecadoraEnsenada,
        },
        {
          paquete: "Libros",
          totalTijuana: paqLibrosTijuana,
          totalMexicali: paqLibrosMexicali,
          totalEnsenada: paqLibrosEnsenada,
          total: paqLibrosTijuana + paqLibrosMexicali + paqLibrosEnsenada,
        },
        {
          paquete: "Cotoneros",
          totalTijuana: paqCotonerosTijuana,
          totalMexicali: paqCotonerosMexicali,
          totalEnsenada: paqCotonerosEnsenada,
          total:
            paqCotonerosTijuana + paqCotonerosMexicali + paqCotonerosEnsenada,
        },
        {
          paquete: "Termo",
          totalTijuana: paqTermoTijuana,
          totalMexicali: paqTermoMexicali,
          totalEnsenada: paqTermoEnsenada,
          total: paqTermoTijuana + paqTermoMexicali + paqTermoEnsenada,
        },
        {
          paquete: "Mochila",
          totalTijuana: paqMochilaTijuana,
          totalMexicali: paqMochilaMexicali,
          totalEnsenada: paqMochilaEnsenada,
          total: paqMochilaTijuana + paqMochilaMexicali + paqMochilaEnsenada,
        },
        {
          paquete: "Audio",
          totalTijuana: paqAudioTijuana,
          totalMexicali: paqAudioMexicali,
          totalEnsenada: paqAudioEnsenada,
          total: paqAudioTijuana + paqAudioMexicali + paqAudioEnsenada,
        },
      ];

      return totalPaquetes;
    },
  },
};
</script>
