<template>
  <v-card>
    <v-btn
      class="absolute top right"
      icon
      color="blue darken-1"
      text
      @click="cerrar()"
    >
      <v-icon dark> mdi-close </v-icon>
    </v-btn>
    <v-card-title>Facturas pendientes</v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              >Pendiente total: ${{
                formatoPrecio(estadoCredito.pendiente_total)
              }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Crédito activo:
              <v-icon
                class="mr-2"
                :color="estadoCredito.credito_activado ? 'green' : 'red'"
                size="25"
              >
                {{
                  estadoCredito.credito_activado
                    ? "mdi-check-circle"
                    : "mdi-close-circle"
                }}
              </v-icon>
              <!-- {{
                estadoCredito.credito_activado ? "Sí" : "No"
              }} -->
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-data-table
        dense
        :headers="headers"
        :items="itemsFormat"
        :items-per-page="15"
        class="elevation-1"
        no-data-text="No hay elementos para mostrar"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
        }"
        :header-props="{
          'sort-by-text': 'Ordenar por',
        }"
      >
        <!-- Columna de Fecha -->
        <template v-slot:[`item.fecha`]="{ item }">
          {{ getFechaLegible(item.fecha) }}
        </template>
        <!-- Columna de Fecha vencimiento -->
        <template v-slot:[`item.fecha_vencimiento`]="{ item }">
          {{ getFechaLegible(item.fecha_vencimiento) }}
        </template>
        <!-- Columna de total -->
        <template v-slot:[`item.total`]="{ item }">
          {{ "$" + formatoPrecio(item.total) }}
        </template>
        <!-- Columna de Pendiente -->
        <template v-slot:[`item.pendiente`]="{ item }">
          {{ "$" + formatoPrecio(item.pendiente) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "FacturaPendiente",
  props: ["item"],
  data() {
    return {
      items: [],
      estadoCredito: {},
      saldo_pendiente: 0,
      headers: [
        {
          text: "Concepto",
          value: "concepto",
        },
        {
          text: "Serie y Folio",
          value: "serie_folio",
        },
        {
          text: "Fecha",
          value: "fecha",
          // sortable: false,
        },
        {
          text: "Fecha vencimiento",
          value: "fecha_vencimiento",
          // sortable: false,
        },
        {
          text: "Dias restantes",
          value: "dias_restantes",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Pendiente",
          value: "pendiente",
        },
      ],
    };
  },
  methods: {
    cerrar() {
      this.$emit("cerrarDialog", (this.dialog = false));
    },
    async getEstadoCrediticio() {
      try {
        const response = await axios.get(
          `/clientes/pendiente?id_cliente=${this.item.id}`
        );
        console.log(response.data);
        // this.estadoCredito = response.data.estado_credito;
        this.items = response.data.documentos;
        let totalDeuda = this.items.reduce(
          (acumulador, item) => acumulador + item.pendiente,
          0
        );
        this.estadoCredito = {
          credito_activado: response.data.estado_credito.credito_activado,
          pendiente_total: totalDeuda,
        };
      } catch (error) {
        // alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        console.log(error.response.data);
      }
    },
    getFechaLegible(fechaLarga) {
      const fecha = new Date(fechaLarga);

      const año = fecha.getFullYear();
      const mes = fecha.getMonth() + 1;
      const dia = fecha.getDate();
      // const hora = fecha.getHours();
      // const minutos = fecha.getMinutes().toString().padStart(2, "0");

      const fechaLegible = `${dia}/${mes}/${año}`;
      // const horaLegible = `${hora}:${minutos}`;

      return `${fechaLegible}`;
    },
    formatoPrecio(precioProducto) {
      // Formatear el número utilizando una función personalizada
      const numero = parseFloat(precioProducto);
      // console.log(numero);
      let precio = 0;

      precio = numero.toFixed(2); // Limitar a 2 decimales
      const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
      const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

      // Formatear la parte entera con separador de miles
      const parteEnteraFormateada = parteEntera.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
    },
    calcularDiasRestantes(fecha) {
      const fechaVencimiento = new Date(fecha);
      const fechaActual = new Date();

      // Calcula la diferencia en milisegundos
      const diferenciaMs = fechaVencimiento - fechaActual;

      // Convierte la diferencia en días
      const diasRestantes = Math.ceil(diferenciaMs / (1000 * 60 * 60 * 24));

      return diasRestantes;
    },
  },
  computed: {
    itemsFormat() {
      return this.items.map((documento) => {
        const nuevoDocumento = { ...documento };
        // nuevoDocumento.fecha = this.getFechaLegible(documento.fecha);
        // nuevoDocumento.fecha_vencimiento = this.getFechaLegible(
        //   documento.fecha_vencimiento
        // );
        nuevoDocumento.dias_restantes = this.calcularDiasRestantes(
          documento.fecha_vencimiento
        );
        nuevoDocumento.serie_folio = `${documento.serie} ${documento.folio}`;
        return nuevoDocumento;
      });
    },
  },
  async created() {
    this.getEstadoCrediticio();
  },
};
</script>

<style>
.close-btn {
  float: right;
}

.absolute {
  position: absolute;
}

.top {
  top: 10;
}

.right {
  right: 10;
}
</style>
