<template>
  <v-card>
    <v-container>
      <v-btn
        class="close-btn"
        icon
        color="blue darken-1"
        text
        @click="cerrarDialog"
      >
        <v-icon dark> mdi-close </v-icon>
      </v-btn>
      <v-card-title class="text-center py-6">
        Informacion del faltante:
        {{ item.nombre }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <DesplegarComentarios
            :item="item"
            :usuarios="usuarios"
            :comentarios="comentarios"
          ></DesplegarComentarios>
        </v-row>
        <v-row>
          <HistorialObjeto :item="item" :usuarios="usuarios" />
        </v-row>
        <v-row class="mx-2 mt-5">
          <div>
            <div class="mb-3"><strong>ID:</strong> {{ item.id }}</div>
            <div class="mb-3"><strong>Cliente:</strong> {{ item.cliente }}</div>
            <div class="mb-3">
              <strong>Vendedor:</strong> {{ item.vendedor }}
            </div>
            <div class="mb-3">
              <strong>Abierto:</strong> {{ item.abierto ? "Sí" : "No" }}
            </div>
            <div class="mb-3">
              <strong>Observaciones:</strong> {{ item.observaciones }}
            </div>
            <div class="mb-3">
              <strong>Fecha de Cerrado:</strong> {{ item.fecha_cerrado }}
            </div>
            <div class="mb-3">
              <strong>Cantidad:</strong> {{ item.cantidad }}
            </div>
            <div class="mb-3">
              <strong>Código de producto:</strong> {{ item.codigo_producto }}
            </div>
            <div class="mb-3">
              <strong>Sucursal:</strong> {{ item.sucursal }}
            </div>
            <div class="mb-3">
              <strong>Fecha de creación:</strong>
              {{ getFechaLegible(item.fecha_creacion) }}
            </div>
            <div class="mb-3"><strong>Estado:</strong> {{ item.estado }}</div>
            <div class="mb-3"><strong>Detalle:</strong> {{ item.detalle }}</div>
            <div class="mb-3">
              <strong>Laboratorio:</strong> {{ item.laboratorio }}
            </div>
            <div class="mb-3">
              <strong>Seguimiento automático:</strong>
              {{ item.seguimiento_automatico ? "Sí" : "No" }}
            </div>
          </div>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import DesplegarComentarios from "./DesplegarComentarios.vue";
import HistorialObjeto from "./Historial/HistorialObjeto.vue";

export default {
  props: ["item", "usuarios", "comentarios"],
  data() {
    return {
      items: [],
      id: this.item.id,
    };
  },
  methods: {
    cerrarDialog() {
      this.$emit("cerrarDialog");
    },
    getFechaLegible(fechaLarga) {
      const fecha = new Date(fechaLarga);

      const año = fecha.getFullYear();
      const mes = fecha.getMonth() + 1;
      const dia = fecha.getDate();
      const hora = fecha.getHours();
      const minutos = fecha.getMinutes().toString().padStart(2, "0");

      const fechaLegible = `${dia}/${mes}/${año}`;
      const horaLegible = `${hora}:${minutos}`;

      return `${fechaLegible} ${horaLegible}`;
    },
  },
  created() {},
  components: { DesplegarComentarios, HistorialObjeto },
};
</script>
<style scoped>
.close-btn {
  position: absolute;
  top: 10;
  right: 0;
}
</style>
