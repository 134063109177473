<template>
  <v-dialog max-width="50%" v-model="dialog">
    <v-card>
      <v-container>
        <v-btn
          class="close-btn"
          icon
          color="blue darken-1"
          text
          @click="cerrar()"
        >
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
        <v-card-text>
          <v-container>
            <form ref="form" @submit.prevent="agregarListaProductos()">
              <v-row>
                <v-col>
                  <v-card-title class="justify-center">
                    Agregar a lista de productos
                  </v-card-title>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    label="Sucursal"
                    outlined
                    :items="sucursales"
                    v-model="sucursal"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Serie"
                    append-icon="mdi-text-box-multiple-outline"
                    v-model="serie"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Folio"
                    append-icon="mdi-text-box-multiple-outline"
                    v-model="folio"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn type="submit" color="teal lighten-2" text
                >Agregar productos</v-btn
              >
            </form>
          </v-container>
        </v-card-text>
      </v-container>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small color="primary" class="mr-2" v-on="on" v-bind="attrs"
        >Agregar documento</v-btn
      >
    </template>
  </v-dialog>
</template>

<script>
//////////////////   revisar crearAsignacion para comprobar perfil del usuario
import axios from "axios";
//   import { mapGetters } from "vuex";
export default {
  name: "AgregarListaProductos",
  data() {
    return {
      dialog: false,
      sucursal: "Mexicali",
      serie: "",
      folio: "",
      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      items: [],
    };
  },
  methods: {
    async agregarListaProductos() {
      await new Promise((resolve, reject) => {
        this.getProductosDocumento()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
      this.$emit("enviar-datos", this.items);
      this.cerrar();
    },
    async getProductosDocumento() {
      try {
        const response = await axios.get(
          `/reportes/productos_de_documento?sucursal=${this.sucursal}&serie=${this.serie}&folio=${this.folio}`
        );
        this.items = response.data.productos_de_documento;
      } catch (error) {
        console.error(error);
      }
    },
    cerrar() {
      this.dialog = !this.dialog;
    },
  },
  computed: {},
  async created() {
    // this.getProductosDocumento();
  },
};
</script>

<style>
.close-btn {
  float: right;
}
</style>
