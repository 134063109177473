<template>
  <v-dialog max-width="70%" v-model="dialog">
    <v-card
      ><v-card-title
        >Perfil Nuevo <v-spacer></v-spacer>
        <v-btn icon value="log in" color="blue darken-1" text @click="cerrar()">
          <v-icon dark> mdi-close </v-icon>
        </v-btn></v-card-title
      >
      <v-card-text>
        <v-container>
          <form ref="form" @submit.prevent="crearPerfil()">
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Nombre"
                  append-icon="mdi-account-details"
                  v-model="nombre"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  label="Local"
                  outlined
                  :items="items"
                  v-model="local"
                  required
                ></v-select> </v-col
            ></v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Plantillas"
                  append-icon="mdi-text-box-multiple-outline"
                  v-model="plantillas"
                  required
                ></v-text-field> </v-col
            ></v-row>
            <v-btn type="submit" color="teal lighten-2" text>Enviar</v-btn>
          </form>
        </v-container>
      </v-card-text>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-2" v-on="on" v-bind="attrs"> Crear Perfil </v-btn>
    </template>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      nombre: "",
      local: "",
      plantillas: "",
      dialog: false,
      items: [
        {
          text: "Cierto",
          value: "true",
        },
        {
          text: "Falso",
          value: "false",
        },
      ],
      errores: [],
    };
  },
  methods: {
    async crearPerfil() {
      const response = await axios
        .post(
          "/usuarios/crear_perfil?nombre=" +
            this.nombre +
            "&local=" +
            this.local +
            "&plantillas=" +
            this.plantillas
        )
        .catch((error) => {
          if (error) {
            this.errores.push(error);
            alert(`Error: ${JSON.stringify(error.response.data)}`);
          }
          this.serverError = true;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
      alert(JSON.stringify(response.data.resultado));
      location.reload();
      this.cerrar();
    },
    cerrar() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
