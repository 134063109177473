<template>
  <v-container>
    <v-card-title class="text-center teal lighten-3 accent-4 white--text">
      <v-row>
        <v-col cols="2" class="mt-3 mr-10">Productos</v-col>
        <!-- <v-spacer></v-spacer> -->
        <v-col cols="3">
          <v-text-field
            @input="filtrarItems"
            v-model="search"
            append-icon="mdi-magnify"
            label="Búsqueda"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <!-- <v-spacer /> -->
        <v-col cols="2">
          <div>
            <v-btn class="mt-3" small @click="exportToCSV"
              >Exportar a CSV</v-btn
            >
          </div>
        </v-col>
        <v-col cols="4">
          <div v-if="totalUnidades != 0">
            <strong>Total unidades:</strong>
            {{ totalUnidades }}
          </div>
          <div v-if="totalPrecio != 0">
            <strong>Total neto:</strong>
            {{ getFormatoPrecio(totalPrecio) }}
          </div>
        </v-col>
      </v-row>
    </v-card-title>

    <v-container>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :footer-props="{ 'items-per-page-text': 'Productos por página' }"
        :header-props="{ 'sort-by-text': 'Ordenar por' }"
      >
        <template v-slot:[`item.fecha`]="{ item }">
          {{ formatoFecha(item.fecha) }}
        </template>
        <template v-slot:[`item.precio`]="{ item }">
          {{ getFormatoPrecio(item.precio) }}
        </template>
        <template v-slot:[`item.neto`]="{ item }">
          {{ getFormatoPrecio(item.neto) }}
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  props: ["datos"],
  data() {
    return {
      items: [],
      itemsFiltrados: [],
      search: "",
      headers: [
        {
          text: "Serie factura",
          value: "serie_factura",
        },
        {
          text: "Folio factura",
          value: "folio_factura",
        },
        {
          text: "Razón social",
          value: "razon_social",
        },
        {
          text: "RFC",
          value: "rfc",
        },
        {
          text: "Fecha",
          value: "fecha",
        },
        {
          text: "Código producto",
          value: "codigo_producto",
        },
        {
          text: "Nombre producto",
          value: "nombre_producto",
        },
        {
          text: "Unidades",
          value: "unidades",
        },
        {
          text: "Precio",
          value: "precio",
        },
        {
          text: "Neto",
          value: "neto",
        },
        {
          text: "Sucursal",
          value: "sucursal",
        },
      ],
    };
  },
  methods: {
    async getProductos() {
      try {
        if (this.datos.sucursales.includes("Mexicali")) {
          const response = await axios.get(
            `/reportes/compras_productos?sucursal=Mexicali&inicio=${this.datos.fechaInicio}&fin=${this.datos.fechaFin}`
          );
          this.items.push(...response.data.compras_productos);
        }
        if (this.datos.sucursales.includes("Tijuana")) {
          const response = await axios.get(
            `/reportes/compras_productos?sucursal=Tijuana&inicio=${this.datos.fechaInicio}&fin=${this.datos.fechaFin}`
          );
          this.items.push(...response.data.compras_productos);
        }
        if (this.datos.sucursales.includes("Ensenada")) {
          const response = await axios.get(
            `/reportes/compras_productos?sucursal=Ensenada&inicio=${this.datos.fechaInicio}&fin=${this.datos.fechaFin}`
          );
          this.items.push(...response.data.compras_productos);
        }
        if (this.datos.sucursales.includes("LaPaz")) {
          const response = await axios.get(
            `/reportes/compras_productos?sucursal=LaPaz&inicio=${this.datos.fechaInicio}&fin=${this.datos.fechaFin}`
          );
          this.items.push(...response.data.compras_productos);
        }
        if (this.datos.sucursales.includes("Obregon")) {
          const response = await axios.get(
            `/reportes/compras_productos?sucursal=Obregon&inicio=${this.datos.fechaInicio}&fin=${this.datos.fechaFin}`
          );
          this.items.push(...response.data.compras_productos);
        }
        if (this.datos.sucursales.includes("Hermosillo")) {
          const response = await axios.get(
            `/reportes/compras_productos?sucursal=Hermosillo&inicio=${this.datos.fechaInicio}&fin=${this.datos.fechaFin}`
          );
          this.items.push(...response.data.compras_productos);
        }
      } catch (error) {
        console.error(error);
      }
    },
    formatoFecha(fechaOriginal) {
      // Fecha en el formato original

      // Crear un objeto Date a partir de la fecha original
      const fecha = new Date(fechaOriginal);

      // Obtener los componentes de la fecha
      const year = fecha.getFullYear();
      const month = ("0" + (fecha.getMonth() + 1)).slice(-2); // Agrega un cero inicial si es necesario
      const day = ("0" + fecha.getDate()).slice(-2); // Agrega un cero inicial si es necesario

      // Construir la fecha en el formato deseado
      const fechaFormateada = `${year}-${month}-${day}`;

      return fechaFormateada; // Resultado: '2023-06-01'
    },
    getFormatoPrecio(precioNumero) {
      // Formatear el número utilizando una función personalizada
      const precio = precioNumero.toFixed(2); // Limitar a 2 decimales
      const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
      const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

      // Formatear la parte entera con separador de miles
      const parteEnteraFormateada = parteEntera.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return `$${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
    },
    filtrarItems() {
      const criterioBusqueda = this.search.toLowerCase();

      this.itemsFiltrados = this.items.filter((item) => {
        // Aplica aquí tu lógica de filtrado según tus necesidades
        for (let prop in item) {
          const valor = item[prop];

          if (
            typeof valor === "string" &&
            valor.toLowerCase().includes(criterioBusqueda)
          ) {
            return true; // Si alguna propiedad coincide, se incluye el item en los resultados
          } else if (
            typeof valor === "number" &&
            valor.toString().toLowerCase().includes(criterioBusqueda)
          ) {
            return true; // Si el valor numérico coincide, se incluye el item en los resultados
          }
        }
        return false;
      });
    },
    // exportToCSV() {
    //   // Generar el contenido CSV
    //   let csvContent = null;

    //   if (this.itemsFiltrados.length === 0) {
    //     csvContent = this.convertArrayOfObjectsToCSV(this.items);
    //   } else {
    //     csvContent = this.convertArrayOfObjectsToCSV(this.itemsFiltrados);
    //   }

    //   //Parece que hay un error al convertir los datos a csv en este metodo

    //   // Crear un elemento de enlace temporal
    //   const link = document.createElement("a");
    //   link.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
    //   link.target = "_blank";
    //   link.download = "datos.csv";
    //   link.click();
    // },
    exportToCSV() {
      // Generar el contenido CSV
      let csvContent = null;

      if (this.itemsFiltrados.length === 0) {
        csvContent = this.convertArrayOfObjectsToCSV(this.items);
      } else {
        csvContent = this.convertArrayOfObjectsToCSV(this.itemsFiltrados);
      }

      // Crear un objeto Blob
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

      // Crear un enlace de descarga
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = "datos.csv";

      // Agregar el enlace al documento y hacer clic en él
      document.body.appendChild(link);
      link.click();

      // Eliminar el enlace del documento
      document.body.removeChild(link);
    },
    convertArrayOfObjectsToCSV(items) {
      const separator = ",";
      const keys = Object.keys(items[0]);

      // Encabezados CSV
      const headerRow = keys.join(separator);

      // Filas CSV
      const rows = items.map((item) => {
        const rowValues = keys.map((key) => {
          let value = item[key];

          // Comprobar si el valor contiene comas
          if (typeof value === "string" && value.includes(",")) {
            value = `"${value}"`; // Encerrar el valor entre comillas
          }

          return value;
        });

        return rowValues.join(separator);
      });

      return headerRow + "\n" + rows.join("\n");
    },
  },
  computed: {
    totalPrecio() {
      return this.itemsFiltrados.reduce((total, item) => total + item.neto, 0);
    },
    totalUnidades() {
      return this.itemsFiltrados.reduce(
        (total, item) => total + item.unidades,
        0
      );
    },
  },
  mounted() {
    this.getProductos();
    //   setTimeout(() => {
    //     this.obtenerArregloSucursales();
    //   }, 100);
  },
};
</script>
