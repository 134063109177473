<template>
  <v-card>
    <v-card-text>
      <v-container>
        <form ref="form" @submit.prevent="cambiarLlegada()">
          <v-row>
            <v-col>
              <v-card-title class="justify-center">
                <div>Modificar fecha de llegada del envío: {{ item.id }}</div>
              </v-card-title>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="ID"
                append-icon="mdi-identifier"
                v-model="id"
                readonly
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Nueva Fecha"
                append-icon="mdi-calendar"
                v-model="nueva_fecha"
                @focus="showDatePicker = true"
                required
              ></v-text-field>
              <v-expand-transition>
                <v-card v-if="showDatePicker">
                  <v-card-title class="justify-center"
                    >Selecciona un día</v-card-title
                  >
                  <v-row justify="center">
                    <v-date-picker
                      no-title
                      color="light-green"
                      v-model="nueva_fecha"
                      :min="nowDate"
                      locale="es-MX"
                      @input="showDatePicker = false"
                    >
                    </v-date-picker>
                  </v-row>
                </v-card>
              </v-expand-transition>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn type="submit" color="teal lighten-2" text>Modificar</v-btn>
          </v-row>
        </form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "CambiarLlegada",
  props: ["item"],
  data() {
    return {
      id: this.item.id,
      nueva_fecha: this.item.fecha_llegada,
      itemsActivo: [
        {
          text: "Cierto",
          value: true,
        },
        {
          text: "Falso",
          value: false,
        },
      ],
      showDatePicker: false,
      currentPlantilla: "",
      date: undefined,
      nowDate: new Date().toISOString().slice(0, 10),
    };
  },
  methods: {
    async cambiarLlegada() {
      try {
        const response = await axios.put(
          `/envios/cambiar_llegada?id_envio=${this.id}&nueva_fecha=${this.nueva_fecha}`,
          {},
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        alert("Fecha de llegada modificada");
        console.log(JSON.stringify(response.data));
        // location.reload();
        this.$emit("envioModificado");
      } catch (error) {
        alert(error);
        console.error(error); // Handle any errors that might occur
      }
    },
  },
  computed: {
    ...mapGetters(["perfil"]),
    tenYearsAgo() {
      let date = new Date();
      date.setFullYear(date.getFullYear() - 10);
      return date.toISOString().substr(0, 10);
    },
  },
};
</script>
