<template>
  <v-dialog max-width="50%" v-model="dialog">
    <v-card>
      <v-container>
        <v-card-title>
          Producto Nuevo
          <v-spacer />
          <v-btn
            class="close-btn"
            icon
            value="log in"
            color="blue darken-1"
            text
            @click="cerrar()"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <form ref="form" @submit.prevent="agregarProducto()">
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Código"
                    append-icon="mdi-account-details"
                    v-model="codigo"
                    required
                  />
                </v-col>
              </v-row>
              <v-btn type="submit" color="teal lighten-2" text>Enviar</v-btn>
            </form>
          </v-container>
        </v-card-text>
      </v-container>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-2" v-on="on" v-bind="attrs">Agregar Producto</v-btn>
    </template>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: ["item"],
  data() {
    return {
      codigo: "",
      sucursal: "",
      dialog: false,
      errores: [],
      items: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      props: [],
    };
  },
  methods: {
    async agregarProducto() {
      await axios
        .post(`/productos/agregar?codigo=${this.codigo}`)
        .then((response) => {
          this.$emit("actualizarInformacion", []);
          alert(JSON.stringify(response.data));
          this.cerrar();
          // location.reload();
        })
        .catch((error) => {
          if (error) {
            this.errores.push(error);
            alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
          }
          this.serverError = true;
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    },
    cerrar() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style>
.close-btn {
  float: right;
}
</style>
