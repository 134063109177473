<template>
  <v-card>
    <v-card-text>
      <v-container>
        <form ref="form" @submit.prevent="modificarCliente()">
          <v-row>
            <v-col>
              <v-card-title class="justify-center">
                <div>ID del cliente: {{ item.id }}</div>
              </v-card-title>
            </v-col>
          </v-row>
          <v-row>
            <v-text-field
              outlined
              label="Nombre"
              append-icon="mdi-card-text-outline"
              v-model="razon_social"
              :disabled="true"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="ID"
                append-icon="mdi-identifier"
                v-model="id"
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                label="Código"
                append-icon="mdi-barcode"
                v-model="codigo"
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="RFC"
                append-icon="mdi-card-text-outline"
                v-model="rfc"
                :disabled="true"
              ></v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                outlined
                label="Sucursal"
                append-icon="mdi-map-marker"
                v-model="sucursal"
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-row>
              <v-divider></v-divider>
            </v-row>
          </v-row>
          <v-row>
            <v-col>
              <div class="text-center">
                Activo en Adminpaq
                <div class="mt-2">
                  <v-icon class="d-flex justify-center" :color="colorIcon">
                    {{
                      activo_adminpaq ? "mdi-check-circle" : "mdi-close-circle"
                    }}
                  </v-icon>
                </div>
              </div>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                label="Día"
                append-icon="mdi-calendar-today"
                v-model="dia"
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Vendedor"
                append-icon="mdiaccount-tie-woman"
                v-model="vendedor"
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="metodoPago"
                :items="metodosPagos"
                label="Método de pago"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="cfdi"
                :items="arrayCfdis"
                label="CFDI"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Latitud"
                append-icon="mdi-latitude"
                v-model="latitud"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                label="Longitud"
                append-icon="mdi-longitude"
                v-model="longitud"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-text-field
              outlined
              label="Giros"
              append-icon="mdi-rotate-orbit"
              v-model="checkboxValues"
              readonly
            ></v-text-field>
          </v-row>
          <v-row>
            <v-btn @click="mostrarCheckboxes = !mostrarCheckboxes">
              Mostrar giros
            </v-btn>
          </v-row>

          <div v-if="mostrarCheckboxes">
            <v-row>
              <div
                v-for="(valor, index) in girosArray"
                :key="index"
                class="mt-4"
              >
                <v-col>
                  <label>
                    <input
                      type="checkbox"
                      v-model="checkboxValues"
                      :value="valor"
                    />
                    {{ valor }}
                  </label>
                </v-col>
              </div>
            </v-row>
          </div>
          <v-row>
            <v-col>
              <template>
                Activo
                <v-switch
                  class="mr-2"
                  color="success"
                  v-model="activo_saux"
                ></v-switch>
              </template>
            </v-col>

            <v-col>
              <template>
                Visitas
                <v-switch
                  class="mr-2"
                  color="success"
                  v-model="visitas"
                ></v-switch>
              </template>
            </v-col>
          </v-row>
          <v-btn type="submit" color="teal lighten-2" text>Modificar</v-btn>
        </form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "modificarCliente",
  props: ["item"],
  data() {
    return {
      props: {
        nombre: this.item.nombre,
        local: this.item.local,
        plantillas: [this.item.plantillas],
      },
      girosArray: [],
      checkboxValues: this.item.giros ? this.item.giros : [],
      mostrarCheckboxes: false,
      razon_social: this.item.razon_social,
      id: this.item.id,
      sucursal: this.item.sucursal,
      latitud: this.item.latitud,
      longitud: this.item.longitud,
      visitas: this.item.visitas,
      dia: this.item.dia,
      rfc: this.item.rfc,
      codigo: this.item.codigo,
      //giros: this.item.giros,
      activo_adminpaq: this.item.activo_adminpaq,
      vendedor: this.item.vendedor,
      activo_saux: this.item.activo_saux,
      cfdi: this.item.cfdi_defecto,
      metodoPago: this.item.metodo_de_pago_defecto,
      colorIcon: this.item.activo_adminpaq ? "green" : "red",
      metodosPagos: [
        "01 Efectivo",
        "02 Cheque nominativo",
        "03 Transferencia electrónica de fondos",
        "04 Tarjeta de crédito",
        "05 Monedero electrónico",
        "06 Dinero electrónico",
        "07 Vales de despensa",
        "08 Pago por subrogación",
        "12 Dación en pago",
        "13 Pago por consignación",
        "14 Pago con subrogación",
        "15 Condonación",
        "17 Compensación",
        "23 Novación",
        "24 Confusión",
        "25 Remisión de deuda",
        "26 Prescripción o caducidad",
        "27 A satisfacción del acreedor",
        "28 Tarjeta de débito",
        "29 Tarjeta de servicios",
        "30 Aplicación de anticipos",
        "31 Intermediario de pagos",
        "99 Por definir",
      ],
      arrayCfdis: [
        "01 Efectivo",
        "02 Cheque nominativo",
        "03 Transferencia electronica de fondos",
        "04 Tarjeta de credito",
        "05 Monedero electronico",
        "06 Dinero electronico",
        "07 Vales de despensa",
        "08 Pago por subrogacion",
        "12 Dacion en pago",
        "13 Pago por consignacion",
        "14 Pago con subrogacion",
        "15 Condonacion",
        "17 Compensacion",
        "23 Novacion",
        "24 Confusión",
        "25 Remision de deuda",
        "26 Prescripcion o caducidad",
        "27 A satisfaccion del acreedor",
        "28 Tarjeta de debito",
        "29 Tarjeta de servicios",
        "30 Aplicacion de anticipos",
        "31 Intermediario pagos",
        "99 Por definir",
      ],
    };
  },
  methods: {
    async modificarCliente() {
      try {
        const response = await axios.post(
          `/clientes/modificar_cliente`,
          {
            codigo: this.codigo,
            sucursal: this.sucursal,
            latitud: this.latitud,
            longitud: this.longitud,
            giros: this.checkboxValues,
            activo_saux: this.activo_saux,
            visitas: this.visitas,
            cfdi_defecto: this.cfdi,
            metodo_de_pago_defecto: this.metodoPago,
          },
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        alert(JSON.stringify(response.data));
        location.reload();
      } catch (error) {
        console.error(error); // Handle any errors that might occur
      }
    },
    async getGiros() {
      const response = await axios.get("/varios/get_clasificaciones?tipo=giro");
      this.girosArray = response.data.valores;
    },
  },
  computed: {
    ...mapGetters(["perfil"]),
  },
  created() {
    this.getGiros();
  },
};
</script>
