<template>
  <v-container>
    <v-menu>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
              mdi-dots-vertical
            </v-icon>
          </template>
          <span>Acciones</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="selectComponent(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog max-width="50%" v-model="dialog">
      <v-card v-if="selectedComponent === 'ModificarProducto'">
        <ModificarProducto :item="item" @cerrarDialog="cerrarDialog" />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ModificarProducto from "./ModificarProducto.vue";
import { mapActions } from "vuex";
export default {
  props: ["item"],
  components: {
    ModificarProducto,
  },
  data() {
    return {
      dialog: false,
      items: [
        {
          title: "Modificar Producto",
          component: "ModificarProducto",
        },
      ],
      selectedComponent: null,
    };
  },
  methods: {
    selectComponent(item) {
      if (item.component) {
        this.selectedComponent = item.component;
        this.dialog = true;
      }
    },
    ...mapActions(["setPerfil"]),
    openPerfil(perfil) {
      this.setPerfil(perfil);
      this.$emit("openPerfil"); // Emit an event to the parent component
    },
    cerrarDialog(dialog) {
      this.dialog = dialog;
    },
  },
};
</script>
