<template>
  <div v-on:keyup.esc="limpiarTabla">
    <v-container v-if="user">
      <v-card>
        <v-card-title class="text-center teal lighten-3 accent-4 white--text">
          Precios y Existencias
          <v-spacer></v-spacer>
        </v-card-title>
        <v-row class="mx-2">
          <v-col cols="12" md="3" sm="3">
            <v-text-field
              label="Código producto"
              v-model="codigo_producto"
              @keydown="onKeyDown($event)"
            />
          </v-col>
          <v-col class="mt-4" cols="12" md="2" sm="2">
            <v-btn @click="simularTeclaEnter">Enviar </v-btn>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              v-model="sucursalSelected"
              :items="sucursales"
              item-value="value"
              label="Sucursal"
              placeholder="Selecciona sucursal"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="almacenSelected"
              label="Almacen"
              :items="almacenes"
              item-value="value"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="mx-2">
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="etiquetasSelected"
              :items="etiquetas"
              chips
              small-chips
              clearable
              deletable-chips
              label="Etiquetas"
              multiple
              auto-select-first
              no-data-text="Sin coincidencias"
              :search-input.sync="searchInputEtiquetas"
              @change="clearSearchInput"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="ingredientesSelected"
              :items="ingredientes"
              chips
              small-chips
              clearable
              deletable-chips
              label="Ingrediente activo"
              multiple
              auto-select-first
              no-data-text="Sin coincidencias"
              :search-input.sync="searchInputIngrediente"
              @change="clearSearchInput"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="600">
          <v-list>
            <v-subheader>Guía de promoción</v-subheader>
            <v-list-item v-for="item in arrayGuiaPromocion" :key="item">
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-dialog>

        <v-data-table
          :headers="headers"
          :items="items"
          :footer-props="{
            'items-per-page-text': 'Productos por página',
          }"
          :header-props="{
            'sort-by-text': 'Ordenar por',
          }"
          no-data-text="No hay productos para mostrar"
          loading-text="Cargando..."
          :loading="loadingTable"
          @click:row="onRowClick"
        >
          <template v-slot:[`item.precio_publico`]="{ item }">
            {{ getFormatoPrecio(item.precio_publico) }}
          </template>
          <template v-slot:[`item.precio_especial`]="{ item }">
            {{ getFormatoPrecio(item.precio_especial) }}
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      items: [],
      ingredientes: [],
      etiquetas: [],

      codigo_producto: "",
      almacenSelected: "mexicali_cedis",
      sucursalSelected: "Mexicali",
      ingredientesSelected: [],
      etiquetasSelected: [],

      searchInputEtiquetas: "",
      searchInputIngrediente: "",

      loadingTable: false,

      productoSelected: {},
      dialog: false,
      arrayGuiaPromocion: [],

      headers: [
        {
          text: "Código",
          value: "codigo_producto",
        },

        {
          text: "Nombre",
          value: "nombre_producto",
        },
        {
          text: "Precio público",
          value: "precio_publico",
        },
        {
          text: "Precio especial",
          value: "precio_especial",
        },
        {
          text: "Existencia",
          value: "existencia",
        },
      ],
      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      almacenes: [
        {
          text: "Mexicali cedis",
          value: "mexicali_cedis",
        },
        {
          text: "Mexicali mostrador",
          value: "mexicali_mostrador",
        },
        {
          text: "Tijuana",
          value: "tijuana",
        },
        {
          text: "Ensenada",
          value: "ensenada",
        },
        {
          text: "La Paz",
          value: "lapaz",
        },
        {
          text: "Obregón",
          value: "obregon",
        },
        {
          text: "Hermosillo",
          value: "hermosillo",
        },
      ],
    };
  },
  methods: {
    async onRowClick(item) {
      this.productoSelected = item;
      // console.log(this.productoSelected);
      try {
        const response = await axios.get(
          `/productos/get_guia_de_promocion?codigo=${this.productoSelected.codigo_producto}`
        );
        console.log(response.data);
        this.arrayGuiaPromocion = response.data;
        this.dialog = true;
      } catch (error) {
        console.log(error);
        alert(error.response.data.detail);
      }
    },
    clearSearchInput() {
      // Establece el search-input en una cadena vacía cuando se selecciona un elemento
      this.searchInputEtiquetas = "";
      this.searchInputIngrediente = "";
    },
    mostrarEtiquetas() {
      console.log(this.etiquetasSelected);
      console.log(this.busqueda);
    },
    simularTeclaEnter() {
      const evento = new KeyboardEvent("keydown", { key: "Enter" });
      this.$el.dispatchEvent(evento);
      this.onKeyDown(evento);
    },
    async onKeyDown(event) {
      // Si la tecla presionada es Enter, se hace la busqueda
      if (event.key === "Enter") {
        //Quitar la comprobacion de codigo del producto
        this.loadingTable = true;
        this.limpiarTabla();

        //Se cream los strings para pasarlos a la solicitud si no estan vacios
        let codigoString = "";
        let etiquetasString = "";
        let ingredientesString = "";

        if (this.codigo_producto !== "") {
          codigoString = `&str_busqueda=${this.codigo_producto}`;
        }

        if (this.etiquetasSelected.length !== 0) {
          this.etiquetasSelected.forEach((etiqueta) => {
            etiquetasString = etiquetasString + `&etiquetas=${etiqueta}`;
          });
        }

        if (this.ingredientesSelected.length !== 0) {
          this.ingredientesSelected.forEach((ingrediente) => {
            ingredientesString =
              ingredientesString + `&ingredientes_activos=${ingrediente}`;
          });
        }

        axios
          .get(
            `/productos/get_precios_existencia?almacen=${this.almacenSelected}&sucursal=${this.sucursalSelected}${codigoString}${etiquetasString}${ingredientesString}`
          )
          .then((response) => {
            // Aquí puedes manejar la respuesta de la API
            console.log(response.data.productos);
            this.items.push(...response.data.productos);
            this.loadingTable = false;
          })
          .catch((error) => {
            // Aquí puedes manejar los errores
            this.loadingTable = false;
            if (error.response.status === 404) {
              console.error(error);
              alert(`No se encotraron productos`);
            } else {
              console.error(error);
              alert(`Error: ${error}`);
            }
          });
        this.$nextTick(() => {
          this.codigo_producto = "";
        });
      }
    },
    limpiarTabla() {
      this.items = []; // Asignar un array vacío para limpiar la tabla
    },
    async getIngredientes() {
      try {
        const response = await axios.get(
          "/varios/get_clasificaciones?tipo=ingrediente_activo"
        );
        const ingredientesTransformados = response.data.valores.map(
          (ingrediente) => {
            const text = ingrediente
              .replace(/_/g, " ")
              .replace(/\b\w/g, (c) => c.toUpperCase());
            return { text, value: ingrediente };
          }
        );
        this.ingredientes = ingredientesTransformados;
        // console.log(this.ingredientes);
      } catch (error) {
        console.error(error);
      }
    },
    async getEtiquetas() {
      try {
        const response = await axios.get(
          "/varios/get_clasificaciones?tipo=etiqueta_producto"
        );
        // Crear un nuevo array transformado
        const etiquetasTransformadas = response.data.valores.map((etiqueta) => {
          const text = etiqueta
            .replace(/_/g, " ")
            .replace(/\b\w/g, (c) => c.toUpperCase());
          return { text, value: etiqueta };
        });
        this.etiquetas = etiquetasTransformadas;
        // console.log(this.etiquetas);
      } catch (error) {
        console.error(error);
      }
    },
    getFormatoPrecio(precioNumero) {
      // Formatear el número utilizando una función personalizada
      const precio = precioNumero.toFixed(2); // Limitar a 2 decimales
      const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
      const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

      // Formatear la parte entera con separador de miles
      const parteEnteraFormateada = parteEntera.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return `$${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
    },
  },
  created() {
    this.getIngredientes();
    this.getEtiquetas();
  },
  components: {},
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
