var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-col',[_c('v-card-title',[_vm._v("Envíos "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({attrs:{"elevation":0,"dark":""}},'div',attrs,false),on),[_c('CrearEnvio',{attrs:{"allProducts":_vm.productos},on:{"envioCreado":_vm.envioCreado}})],1)]}}],null,false,414500185)},[_c('span',[_vm._v("Crear Envío")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{class:[
              {
                'disabled-button': _vm.selectedOption != 'EnTransito',
                'enabled-button': _vm.selectedOption === 'EnTransito',
              },
              'mr-1',
              'ml-2',
              'mb-1',
            ],attrs:{"color":"primary"},on:{"click":function($event){_vm.selectedOption = 'EnTransito'}}},[_vm._v(" En tránsito ")]),_c('v-btn',{class:[
              {
                'disabled-button': _vm.selectedOption != 'entregados',
                'enabled-button': _vm.selectedOption === 'entregados',
              },
              'mr-1',
              'ml-2',
              'mb-1',
            ],attrs:{"color":"primary"},on:{"click":function($event){_vm.selectedOption = 'entregados'}}},[_vm._v(" Entregados ")])],1)],1),_c('v-row',[(_vm.selectedOption === 'EnTransito')?_c('NewEnviosTable',{attrs:{"productos":this.productos,"envios":this.envios,"usuarios":this.usuarios},on:{"envioModificado":_vm.envioModificado}}):_vm._e(),(_vm.selectedOption === 'entregados')?_c('NewEnviosEntregadosTable',{attrs:{"enviosEntregados":this.enviosEntregados,"usuarios":this.usuarios,"productos":this.productos}}):_vm._e()],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }