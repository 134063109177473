<template>
  <v-container v-if="user" fluid>
    <v-card>
      <v-col>
        <v-card-title>
          Faltantes
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div :elevation="0" dark v-bind="attrs" v-on="on">
                <CrearFaltante />
              </div>
            </template>
            <span>Crear Faltante</span>
          </v-tooltip>
        </v-card-title>
        <v-row>
          <v-col>
            <v-btn
              :class="[
                {
                  'disabled-button': selectedOption != 'abiertos',
                  'enabled-button': selectedOption === 'abiertos',
                },
                'mr-2',
                'ml-2',
              ]"
              color="primary"
              @click="selectedOption = 'abiertos'"
            >
              Abiertos
            </v-btn>
            <v-btn
              :class="[
                {
                  'disabled-button': selectedOption != 'cerrados',
                  'enabled-button': selectedOption === 'cerrados',
                },
                'mr-2',
                'ml-2',
              ]"
              color="primary"
              @click="selectedOption = 'cerrados'"
            >
              Cerrados
            </v-btn>
          </v-col>
          <v-col v-if="selectedTab != null" class="text-right">
            <v-btn color="primary" text @click="enviosClick(selectedTab)"
              >Revisar Envios</v-btn
            >
            <v-btn color="primary" text @click="faltantesClick(selectedTab)"
              >Revisar Inventario</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <FaltantesAbiertosTable
            v-if="selectedOption === 'abiertos'"
            @enviarTab="getSelectedTab"
            :items="items"
            :usuarios="usuarios"
            :clientes="clientes"
            @faltanteModificado="faltanteModificado"
          />
          <FaltantesCerradosTable
            v-if="selectedOption === 'cerrados'"
            @enviarTab="getSelectedTab"
            :items="itemsCerrados"
            :usuarios="usuarios"
            :clientes="clientes"
          />
        </v-row>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import CrearFaltante from "@/components/CrearFaltante.vue";
import FaltantesAbiertosTable from "@/components/FaltantesAbiertosTable.vue";
import FaltantesCerradosTable from "@/components/FaltantesCerradosTable.vue";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    selectedOption: "",

    selectedTab: null,
    items: [],
    itemsCerrados: [],
    ordenSucursales: [
      "Mexicali",
      "Tijuana",
      "Ensenada",
      "LaPaz",
      "Obregon",
      "Hermosillo",
    ],
  }),
  methods: {
    getSelectedTab(data) {
      this.selectedTab = data;
    },
    async enviosClick() {
      try {
        const response = await axios.post(
          `/faltantes/revisar_envios?sucursal=${this.selectedTab}`
        );
        const alerta = response.data;
        alert(
          "Faltantes en camino completo: " +
            alerta.faltantes_en_camino_completo +
            "\nFaltantes en camino parcial: " +
            alerta.faltantes_en_camino_parcial
        );
      } catch (error) {
        console.error(error);
        alert("Error: " + error);
      }
    },
    async faltantesClick() {
      try {
        const response = await axios.post(
          `/faltantes/revisar_inventario?sucursal=${this.selectedTab}`
        );
        let alerta = response.data.resultado;
        alerta = alerta.slice(0, 1);
        alert("Faltantes encontrados en inventario: " + alerta);
      } catch (error) {
        console.error(error);
        alert("Error: " + error);
      }
    },
    async getFaltantes() {
      try {
        const response = await axios.get(`/faltantes/get_abiertos`);
        this.items = response.data;
        // this.obtenerSucursalesDisponibles(this.items);
      } catch (error) {
        console.error(error);
      }
    },
    async getFaltantesCerrados() {
      try {
        const response = await axios.get(`/faltantes/get_cerrados_recientes`);
        this.itemsCerrados = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getUsuarios() {
      try {
        const response = await axios.get(`/usuarios/get_usuarios`);
        this.usuarios = response.data.usuarios;
      } catch (error) {
        console.error(error);
      }
    },
    async getClientes() {
      try {
        const response = await axios.get(`/clientes/get_nombres`);
        this.clientes = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    faltanteModificado() {
      this.getFaltantes();
      this.getFaltantesCerrados();
    },
  },
  created() {
    this.getUsuarios();
    this.getFaltantes();
    this.getClientes();
    this.getFaltantesCerrados();
  },
  mounted() {},
  computed: {
    ...mapGetters(["user"]),
  },
  components: { CrearFaltante, FaltantesAbiertosTable, FaltantesCerradosTable },
};
</script>
<style>
.disabled-button {
  opacity: 0.3;
}
.enabled-button {
  pointer-events: none;
}
</style>
