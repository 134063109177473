<template>
  <v-app v-if="!user">
    <!-- modal de alerta cuando las credenciales son incorrectas -->
    <v-dialog v-model="showModal" max-width="400">
      <v-card class="mx-auto text-center">
        <v-icon size="50" class="mx-auto" color="red"
          >mdi-alert-circle-outline</v-icon
        >
        <v-card-title class="headline text-center"
          >Error: "Credenciales incorrectas"
        </v-card-title>

        <v-card-actions>
          <v-btn color="#80CBC4" dark @click="showModal = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fin del modal -->
    <v-main>
      <v-container center sma-4>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6>
            <v-card class="elevation-12">
              <v-toolbar dark color="#80CBC4">
                <v-toolbar-title>Inicio de sesión</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <form ref="form" @submit.prevent="handleSubmit()">
                  <v-text-field
                    v-model="username"
                    name="username"
                    label="Usuario"
                    type="text"
                    placeholder="usuario"
                    required
                    @keyup="toLowerCase"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    name="password"
                    label="Contraseña"
                    type="password"
                    placeholder="contraseña"
                    required
                  ></v-text-field>
                  <v-btn
                    type="submit"
                    class="mt-4"
                    color="#80CBC4"
                    dark
                    value="log in"
                    >Iniciar Sesión</v-btn
                  >
                </form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "LoginView",
  data() {
    return {
      username: "",
      password: "",
      errores: [],
      showModal: false,
      errorMessage: "",
    };
  },
  methods: {
    toLowerCase() {
      this.username = this.username.toLowerCase();
    },
    async handleSubmit() {
      var details = {
        username: this.username,
        password: this.password,
      };
      var formBody = [];
      for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      const response = await axios
        .post("login", formBody, {
          "Content-Type": "application/x-www-form-urlencoded",
          accept: "application/json",
        })
        .catch((error) => {
          if (error) {
            this.errores.push(error);
            /*alert(`Error: ${JSON.stringify(error.response.data.detail)}`);*/
            this.showModal = true;
          }
          this.serverError = true;
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
      localStorage.setItem("access_token", response.data.access_token);
      sessionStorage.setItem("prepedidosTemp", JSON.stringify(null));
      this.$store.dispatch("user", response.data.user);
      this.$router.push({ name: "Home" });
      location.reload();
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style scoped></style>
