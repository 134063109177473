<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Lotes
      </v-card-title>
      <v-row class="mx-2 mt-1">
        <v-col cols="12" lg="3" md="3">
          <v-select
            v-model="sucursalSelected"
            :items="sucursales"
            item-value="value"
            label="Sucursal"
            placeholder="Selecciona sucursal"
          ></v-select>
        </v-col>

        <v-col cols="12" lg="3" md="3">
          <v-text-field
            label="Código almacén"
            v-model="codigo_almacen"
            clearable
          />
        </v-col>
        <v-col cols="2">
          <v-btn @click="getLote" small class="mt-5">Buscar</v-btn>
        </v-col>
        <v-col v-if="itemsNombre.length !== 0" cols="12" sm="2">
          <v-btn
            @click="exportToCSV"
            small
            :loading="loading"
            color="primary"
            class="mt-5"
            >Exportar csv</v-btn
          >
        </v-col>
      </v-row>

      <v-row class="mx-2 mt-1">
        <v-col cols="12" lg="3" md="3">
          <v-text-field
            label="Búsqueda"
            v-model="search"
            append-icon="mdi-magnify"
            clearable
          />
        </v-col>
        <v-col cols="12" lg="3" md="3">
          <v-text-field
            label="Filtrar por caducidad"
            v-model="filtrarCaducidad"
            type="number"
            clearable
            hide-spin-buttons
          />
        </v-col>
        <v-col cols="12" lg="4" md="4">
          <v-text-field
            label="Filtrar por caducidad aceptada"
            v-model="filtrarCaducidadAceptada"
            type="number"
            clearable
            hide-spin-buttons
          />
        </v-col>
      </v-row>
      <v-row class="mx-2">
        <v-col lg="3" md="3">
          <v-checkbox
            v-model="caducadosCheckbox"
            label="Mostrar caducados"
          ></v-checkbox>
        </v-col>
        <v-col lg="3" md="3">
          <v-checkbox
            v-model="activosCheckbox"
            label="Mostrar inactivos"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="itemsNombre"
        :search="search"
        :footer-props="{
          'items-per-page-text': 'Productos por página',
        }"
        :header-props="{
          'sort-by-text': 'Ordenar por',
        }"
      >
        <template v-slot:[`item.fecha_recibido`]="{ item }">
          {{ getFormatoFecha(item.fecha_recibido) }}
        </template>
        <template v-slot:[`item.fecha_caducidad`]="{ item }">
          {{ getFormatoFecha(item.fecha_caducidad) }}
        </template>
        <template v-slot:[`item.activo`]="{ item }">
          <v-icon
            class="d-flex justify-center"
            :color="getColor(item.activo)"
            >{{ item.activo ? "mdi-check-circle" : "mdi-close-circle" }}</v-icon
          >
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      codigo_almacen: "",
      sucursalSelected: "Mexicali",
      search: "",
      filtrarCaducidad: "",
      filtrarCaducidadAceptada: "",
      caducadosCheckbox: true,
      activosCheckbox: true,
      loading: false,
      headers: [
        {
          text: "Código producto",
          value: "codigo_producto",
        },
        {
          text: "Nombre producto",
          value: "nombre_producto",
        },
        {
          text: "Fecha recibido",
          value: "fecha_recibido",
        },
        {
          text: "Activo",
          value: "activo",
        },
        {
          text: "Lote",
          value: "lote",
        },
        {
          text: "Caducidad",
          value: "fecha_caducidad",
        },
        {
          text: "Existencia",
          value: "existencia",
        },
        {
          text: "Días para caducidad",
          value: "dias_para_caducidad",
          filter: (value) => {
            if (!this.filtrarCaducidad) return true;
            return value <= parseInt(this.filtrarCaducidad);
          },
        },
        {
          text: "Días de caducidad aceptada",
          value: "caducidad_aceptada",
          filter: (value) => {
            if (!this.filtrarCaducidadAceptada) return true;
            return value <= parseInt(this.filtrarCaducidadAceptada);
          },
        },
      ],
      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      lotes: [],
      itemsFiltrados: [],
    };
  },
  methods: {
    async getLote() {
      try {
        const response = await axios.get(
          `/reportes/lotes?sucursal=${this.sucursalSelected}&codigo_almacen=${this.codigo_almacen}`
        );
        const responseData = response.data.lotes; // Datos de respuesta
        const dataObjetos = JSON.parse(responseData);
        this.lotes = Object.values(dataObjetos.data);
      } catch (error) {
        console.error(error);
        alert("Error al obtener lotes");
      }
    },
    getFormatoFecha(fechaOriginal) {
      const fecha = new Date(fechaOriginal);

      const año = fecha.getFullYear();
      const mes = fecha.getMonth() + 1;
      const dia = fecha.getDate();
      //   const hora = fecha.getHours();
      //   const minutos = fecha.getMinutes().toString().padStart(2, "0");

      const fechaLegible = `${dia}/${mes}/${año}`;
      //   const horaLegible = `${hora}:${minutos}`;

      return `${fechaLegible}`;
    },

    // exportToCSV() {
    //   // Exportar todos los datos en lotes
    //   this.loading = true;
    //   let filteredItems = this.filtrarLotesCSV();

    //   //   filteredItems.forEach((lote) => {
    //   //     lote.fecha_caducidad = this.getFormatoFecha(lote.fecha_caducidad);
    //   //     lote.fecha_recibido = this.getFormatoFecha(lote.fecha_recibido);
    //   //   });

    //   //Se opto por hacer un .map porque el forEach de arriba actualizaba los datos de la tabla haciendo que las fechas dieron NaN

    //   const lotesActualizados = filteredItems.map((lote) => {
    //     const nuevoLote = { ...lote };
    //     nuevoLote.fecha_caducidad = this.getFormatoFecha(lote.fecha_caducidad);
    //     nuevoLote.fecha_recibido = this.getFormatoFecha(lote.fecha_recibido);
    //     return nuevoLote;
    //   });

    //   // Filtra el array resultante para eliminar elementos 'undefined' o 'null'
    //   const lotesFiltrados = lotesActualizados.filter(
    //     (lote) => lote !== undefined
    //   );

    //   const batchSize = 1000; // Número de elementos por lote
    //   let currentIndex = 0;

    //   //   console.log(lotesFiltrados);

    //   const exportBatch = () => {
    //     const batch = lotesFiltrados.slice(
    //       currentIndex,
    //       currentIndex + batchSize
    //     );
    //     const csvContent = this.convertArrayOfObjectsToCSV(batch);

    //     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    //     const url = URL.createObjectURL(blob);

    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.target = "_blank";
    //     link.download = `datos_batch_${currentIndex + 1}_to_${
    //       currentIndex + batch.length
    //     }.csv`;
    //     link.click();

    //     URL.revokeObjectURL(url);

    //     currentIndex += batchSize;

    //     if (currentIndex < lotesFiltrados.length) {
    //       // Programar la exportación del próximo lote después de un pequeño retraso
    //       setTimeout(exportBatch, 100);
    //     }
    //   };
    //   this.loading = false;
    //   exportBatch();
    // },
    exportToCSV() {
      // Exportar todos los datos en lotes
      this.loading = true;
      let filteredItems = this.filtrarLotesCSV();

      const lotesActualizados = filteredItems.map((lote) => {
        const nuevoLote = { ...lote };
        nuevoLote.fecha_caducidad = this.getFormatoFecha(lote.fecha_caducidad);
        nuevoLote.fecha_recibido = this.getFormatoFecha(lote.fecha_recibido);
        return nuevoLote;
      });

      // Filtra el array resultante para eliminar elementos 'undefined' o 'null'
      const lotesFiltrados = lotesActualizados.filter(
        (lote) => lote !== undefined
      );

      const csvContent = this.convertArrayOfObjectsToCSV(lotesFiltrados);
      // Crear un objeto Blob
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

      // Crear un enlace de descarga
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = "lotes.csv";
      // Agregar el enlace al documento y hacer clic en él
      link.click();
      URL.revokeObjectURL(url);

      this.loading = false;
    },
    convertArrayOfObjectsToCSV(items) {
      const separator = ",";
      const keys = Object.keys(items[0]);

      // Encabezados CSV
      const headerRow = keys.join(separator);

      // Filas CSV
      const rows = items.map((item) => {
        const rowValues = keys.map((key) => {
          let value = item[key];

          // Comprobar si el valor contiene comas
          if (typeof value === "string" && value.includes(",")) {
            value = `"${value}"`; // Encerrar el valor entre comillas
          }

          return value;
        });

        return rowValues.join(separator);
      });

      return headerRow + "\n" + rows.join("\n");
    },
    filtrarLotesCSV() {
      // Aplica el filtro general
      //   const filteredByName = this.itemsNombre.filter(item => {
      //     return item.nombre.toLowerCase().includes(this.search.toLowerCase());
      //   });

      let criterioBusqueda = "";
      if (this.search) {
        criterioBusqueda = this.search.toLowerCase();
      }

      const filteredByName = this.itemsNombre.filter((item) => {
        // Aplica aquí tu lógica de filtrado según tus necesidades
        for (let prop in item) {
          const valor = item[prop];

          if (
            typeof valor === "string" &&
            valor.toLowerCase().includes(criterioBusqueda)
          ) {
            return true; // Si alguna propiedad coincide, se incluye el item en los resultados
          } else if (
            typeof valor === "number" &&
            valor.toString().toLowerCase().includes(criterioBusqueda)
          ) {
            return true; // Si el valor numérico coincide, se incluye el item en los resultados
          }
        }
        return false;
      });

      // Aplica el filtro personalizado por caducidad
      const filteredByCaducidad = this.itemsNombre.filter((item) => {
        if (!this.filtrarCaducidad) return true;
        return item.dias_para_caducidad <= parseInt(this.filtrarCaducidad);
      });

      // Aplica el nuevo filtro personalizado por caducidad aceptada
      const filteredByCaducidadAceptada = this.itemsNombre.filter((item) => {
        if (!this.filtrarCaducidadAceptada) return true;
        return (
          item.caducidad_aceptada <= parseInt(this.filtrarCaducidadAceptada)
        );
      });

      // Combina todos los filtros
      return filteredByName
        .filter((item) => filteredByCaducidad.includes(item))
        .filter((item) => filteredByCaducidadAceptada.includes(item));
    },
    getColor(estado) {
      return estado ? "green" : "red";
    },
  },
  created() {},
  components: {},
  computed: {
    ...mapGetters(["user"]),
    itemsNombre() {
      const lotesActualizados = this.lotes.map((lote) => {
        const nuevoLote = { ...lote };
        // nuevoLote.fecha_caducidad = this.getFormatoFecha(lote.fecha_caducidad);
        // nuevoLote.fecha_recibido = this.getFormatoFecha(lote.fecha_recibido);

        // if (this.caducadosCheckbox) {
        //   return nuevoLote;
        // } else {
        //   if (nuevoLote.dias_para_caducidad > 0) {
        //     return nuevoLote;
        //   }
        // }

        if (this.activosCheckbox) {
          if (this.caducadosCheckbox) {
            return nuevoLote;
          } else {
            if (nuevoLote.dias_para_caducidad > 0) {
              return nuevoLote;
            }
          }
        } else {
          if (this.caducadosCheckbox) {
            if (nuevoLote.activo) {
              return nuevoLote;
            }
          } else {
            if (nuevoLote.dias_para_caducidad > 0 && nuevoLote.activo) {
              return nuevoLote;
            }
          }
        }

        // Devuelve null o simplemente omite el 'else' para los casos que no deseas incluir
        // return null;
      });

      // Filtra el array resultante para eliminar elementos 'undefined' o 'null'
      const lotesFiltrados = lotesActualizados.filter(
        (lote) => lote !== undefined
      );

      console.log(lotesFiltrados);
      return lotesFiltrados;
    },
  },
};
</script>
