<template>
  <v-container v-if="user">{{ items }}</v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      items: [],
    };
  },
  methods: {
    async getSecciones() {
      const response = await axios.get(`/usuarios/get_secciones`);
      this.items = response.data.secciones;
    },
  },
  created() {
    this.getSecciones();
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
