var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('v-container',[_c('v-card',[_c('v-card-title',{staticClass:"text-center teal lighten-3 accent-4 white--text"},[_vm._v(" Usuarios "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({attrs:{"elevation":0,"dark":""}},'div',attrs,false),on),[_c('CrearUsuario')],1)]}}],null,false,770522093)},[_c('span',[_vm._v("Nuevo Usuario")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsNombre,"search":_vm.search,"items-per-page":-1,"footer-props":{
        'items-per-page-text': 'Usuarios por página',
      },"header-props":{
        'sort-by-text': 'Ordenar por',
      }},scopedSlots:_vm._u([{key:`item.fecha_creacion`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.fecha_creacion.slice(0, 10))+" ")]}},{key:`item.activo`,fn:function({ item }){return [_c('UsuarioActivo',{attrs:{"item":item}})]}},{key:`item.acciones`,fn:function({ item }){return [_c('AccionesComponente',{attrs:{"item":item}})]}},{key:`item.asignaciones`,fn:function({ item }){return [(item.asignaciones)?_c('div',[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Asignaciones ")]),_c('v-expansion-panel-content',[(
                    Array.isArray(item.asignaciones) &&
                    item.asignaciones.length > 1
                  )?[_c('v-list',_vm._l((item.asignaciones),function(asignacion,index){return _c('v-list-item',{key:index},[_vm._v(" "+_vm._s(asignacion)+" ")])}),1)]:[_vm._v(" "+_vm._s(item.asignaciones)+" ")]],2)],1)],1)],1):_vm._e()]}},{key:`item.permisos`,fn:function({ item }){return [(item.permisos)?_c('div',[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Permisos ")]),_c('v-expansion-panel-content',[(
                    Array.isArray(item.permisos.permisos_disponibles) &&
                    item.permisos.permisos_disponibles.length > 1
                  )?[_c('v-list',_vm._l((item.permisos.split(' ')),function(permiso,index){return _c('v-list-item',{key:index},[_vm._v(" "+_vm._s(permiso)+" ")])}),1)]:[_vm._v(" "+_vm._s(item.permisos)+" ")]],2)],1)],1)],1):_vm._e()]}}],null,true)})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }