<template>
  <v-container fluid>
    <v-card-title class="teal lighten-3 accent-4 white--text">
      <v-row>
        <v-col cols="12" md="3" class="mt-4"> Pedidos Cerrados </v-col>

        <!-- <v-spacer></v-spacer> -->
        <v-col cols="12" md="4">
          <v-text-field
            @input="filtrarItems"
            v-model="search"
            append-icon="mdi-magnify"
            label="Búsqueda"
            single-line
            hide-details
          ></v-text-field>
        </v-col>

        <!-- <v-spacer /> -->
        <v-col cols="12" md="3">
          <v-select
            @input="filtrarItems"
            label="Filtrar por estado"
            v-model="filtroEstado"
            :items="listaEstados"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2" class="mt-4">
          <v-btn small @click="abrirVistaImpresion"> Imprimir </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row>
            <v-btn small @click="abrirVistaImpresion"> Imprimir </v-btn>
          </v-row> -->
    </v-card-title>
    <v-tabs v-model="selectedTab" color="teal lighten-3 accent-4" left>
      <v-tab
        v-for="(sucursal, index) in itemsSucursalesDisponibles"
        :key="index"
      >
        <div v-if="sucursal.sucursal === 'LaPaz'">La Paz</div>
        <div v-else>
          {{ sucursal.sucursal }}
        </div>
      </v-tab>
      <v-tab-item
        :disabled="true"
        v-for="(sucursal, index) in itemsNombre"
        :key="index"
      >
        <v-data-table
          :headers="headers"
          :items="selectedItems"
          :search="search"
          :footer-props="{ 'items-per-page-text': 'Pedidos por página' }"
          :header-props="{ 'sort-by-text': 'Ordenar por' }"
          no-data-text="No se encontraron pedidos"
          no-results-text="No se encontraron pedidos"
          :item-class="itemRowBackgroundPrioridad"
          @current-items="currentItems"
        >
          <!-- Columna de Lista plegable de productos -->
          <template v-slot:[`item.lista_productos`]="{ item }">
            <v-list-group v-bind:class="itemRowBackground(item)">
              <template v-slot:activator> </template>
              <v-list-item
                v-for="producto in item.productos.lista_productos"
                :key="producto.codigo"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      producto.codigo +
                      " " +
                      producto.nombre +
                      ": " +
                      producto.cantidad
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
          <!-- Columna de total -->
          <template v-slot:[`item.total`]="{ item }">
            {{ "$" + formatoPrecio(item.total) }}
          </template>
          <!-- Columna de duración -->
          <template v-slot:[`item.fecha_cerrado`]="{ item }">
            {{ getDuracion(item.fecha_creacion, item.fecha_cerrado) }}
          </template>
          <!-- Columna de discrepancias -->
          <template v-slot:[`item.discrepancias`]="{ item }">
            <div v-if="item.discrepancias === 'Sin discrepancias'">
              Sin discrepancias
            </div>
            <v-list-group v-else v-bind:class="itemRowBackground(item)">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-btn icon color="warning" text>
                      <v-icon dark> mdi-alert-outline </v-icon>
                    </v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="discrepancia in crearListaDiscrepancias(
                  item.discrepancias
                )"
                :key="discrepancia"
                no-action
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ discrepancia }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
          <!-- Columna de Lista plegable de facturas -->
          <template v-slot:[`item.facturas`]="{ item }">
            <div v-if="item.facturas.length != 0">
              <div v-if="item.facturas.length === 1">
                {{ item.facturas[0] }}
              </div>
              <v-list-group
                v-if="item.facturas.length > 1"
                v-bind:class="itemRowBackground(item)"
              >
                <template v-slot:activator> </template>
                <v-list-item v-for="factura in item.facturas" :key="factura">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ factura }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </div>
            <div v-else>Sin facturas</div>
          </template>
          <!-- Columna de estado -->
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip color="primary" small>
              {{ item.estado }}
            </v-chip>
          </template>
          <!-- Columna de acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <AccionesPedido
              :item="item"
              :usuarios="usuarios"
              @pedidoModificado="pedidoModificado"
            />
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
    <v-dialog v-model="dialogUsuariosGPS" max-width="500px">
      <v-card>
        <v-card-title> Agregar mensajero </v-card-title>
        <v-card-text>
          <v-select
            label="Mensajero"
            v-model="mensajero"
            :items="mensajeros"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="avanzarRutaEntrega">
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AccionesPedido from "@/components/AccionesPedido.vue";
import axios from "axios";
export default {
  props: ["items", "usuarios", "clientes"],
  data() {
    return {
      selectedTab: 0,
      search: "",
      filtroEstado: "",
      itemsActuales: [],
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Cliente",
          value: "cliente",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Lista de productos",
          value: "lista_productos",
          sortable: false,
        },
        {
          text: "Pago",
          value: "pago",
        },
        {
          text: "Vendedor",
          value: "vendedor",
        },
        {
          text: "Zona",
          value: "zona",
        },
        {
          text: "Duración",
          value: "fecha_cerrado",
        },
        {
          text: "Discrepancias",
          value: "discrepancias",
        },
        {
          text: "Facturas",
          value: "facturas",
        },
        {
          text: "Estado",
          value: "estado",
          filter: (value) => {
            if (!this.filtroEstado) return true;

            return value === this.filtroEstado;
          },
        },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
        },
      ],
      ordenSucursales: [
        "Mexicali",
        "Tijuana",
        "Ensenada",
        "LaPaz",
        "Obregon",
        "Hermosillo",
      ],
      ordenEstados: [
        {
          text: "Pedido creado",
          value: "pedido_creado",
        },
        {
          text: "Confirmación",
          value: "confirmacion",
        },
        {
          text: "Facturación",
          value: "facturacion",
        },
        {
          text: "Preparación en almacén",
          value: "preparacion_en_almacen",
        },
        {
          text: "Preparado",
          value: "preparado",
        },
        {
          text: "En ruta de entrega",
          value: "en_ruta_de_entrega",
        },
        {
          text: "Entregado",
          value: "entregado",
        },
      ],
      listaEstados: [
        {
          text: "Sin filtro",
          value: "",
        },
        {
          text: "Entregado",
        },
        {
          text: "Cancelado",
        },
      ],
      itemsSucursalesDisponibles: [],
      itemsFiltrados: [],
      itemsPedidos: this.items,

      dialogUsuariosGPS: false,
      pedidoEnRuta: null,
      mensajero: "",
      mensajeros: [],
    };
  },
  methods: {
    currentItems: function (value) {
      this.itemsActuales = value;
      // console.log(this.itemsActuales);
    },
    filtrarItems() {
      const criterioBusqueda = this.search.toLowerCase();

      this.itemsFiltrados = this.itemsActuales.filter((item) => {
        // Aplica aquí tu lógica de filtrado según tus necesidades
        for (let prop in item) {
          const valor = item[prop];

          if (
            typeof valor === "string" &&
            valor.toLowerCase().includes(criterioBusqueda)
          ) {
            return true; // Si alguna propiedad coincide, se incluye el item en los resultados
          } else if (
            typeof valor === "number" &&
            valor.toString().toLowerCase().includes(criterioBusqueda)
          ) {
            return true; // Si el valor numérico coincide, se incluye el item en los resultados
          }
        }
        return false;
      });
    },
    async getComentarios() {
      let arrayFiltrado = [];

      if (this.itemsFiltrados.length === 0) {
        arrayFiltrado = this.itemsNombre[this.selectedTab].pedidos;
      } else {
        arrayFiltrado = this.itemsFiltrados;
      }

      try {
        // Creamos un array de promesas utilizando map para hacer una solicitud por cada pedido
        const promesasSolicitudes = arrayFiltrado.map(async (pedido) => {
          const response = await axios.get(
            `/varios/get_comentarios?id_objeto=${pedido.id}&tipo_objeto=pedido`
          );
          return response.data; // Aquí puedes retornar la data específica que necesitas del response
        });

        // Resolvemos todas las promesas utilizando Promise.all
        const resultados = await Promise.all(promesasSolicitudes);

        // con resultados.flat() haces que el array sea de nivel 1 para no tener todos los comentarios anidados
        // console.log(resultados.flat());
        return resultados.flat();
      } catch (error) {
        console.error("Error al obtener los comentarios:", error);
      }
    },
    filtrarComentariosPorIdObjeto(idObjeto, arrayComentarios) {
      // Utilizamos el método 'filter' para obtener los comentarios que coinciden con 'id_objeto'
      const comentariosFiltrados = arrayComentarios.filter(
        (comentario) => comentario.id_objeto === idObjeto
      );

      return comentariosFiltrados;
    },
    async abrirVistaImpresion() {
      let arrayFiltrado = [];

      if (this.itemsFiltrados.length === 0) {
        arrayFiltrado = this.itemsNombre[this.selectedTab].pedidos;
      } else {
        arrayFiltrado = this.itemsFiltrados;
      }

      // Se obtienen los comentarios de todos los pedidos
      const arrayComentarios = await this.getComentarios();

      const fechaActual = new Date();
      // Crear una ventana emergente
      const ventana = window.open("", "_blank");

      // Construir el contenido HTML de la vista previa de impresión
      const contenidoHTML = `
      <html>
        <head>
          <style>
            /* Estilos adicionales para la vista previa de impresión */
            @media print {
              /* Estilos personalizados para la vista previa de impresión */
              body {
                font-family: Arial, sans-serif;
              }
              .pedido {
                position: relative; /* Agregar posición relativa para posicionar el texto */
                margin-bottom: 20px;
                padding: 10px;
                border: 2px solid #ccc;
                /* Evitar que el pedido se corte entre páginas */
                page-break-inside: avoid;
              }
              .texto-prioridad {
                position: absolute;
                top: 15px; /* Ajustar la posición vertical del texto */
                right: 20px; /* Ajustar la posición horizontal del texto */
                font-size: 20px;
                font-weight: bold;
              }
              .texto-pago {
                position: absolute;
                top: 40px; /* Ajustar la posición vertical del texto */
                right: 20px; /* Ajustar la posición horizontal del texto */
                font-size: 20px;
                font-weight: bold;
              }
              .texto-zona {
                position: absolute;
                top: 65px; /* Ajustar la posición vertical del texto */
                right: 20px; /* Ajustar la posición horizontal del texto */
                font-size: 20px;
                font-weight: bold;
              }
              .titulo {
                font-size: 19px;
                font-weight: bold;
              }
              .subtitulo {
                font-weight: bold;
                margin-bottom: 5px;
                font-size: 15px;
              }
              .propiedad {
                margin-bottom: 3px;
              }
              .valor {
                margin-left: 3px;
                font-size: 14px;
              }
              .productos {
                margin-top: 5px;
                margin-bottom: 3px;
              }
              .producto {
                margin-bottom: 3px;
              }
            }
          </style>
        </head>
        <body>
          ${arrayFiltrado
            .map(
              (pedido) => `
              <div class="pedido">
                <div class="titulo">Pedido ID: ${pedido.id}  ${
                pedido.estado
              }</div>
                <div class="propiedad">
                  <span class="subtitulo">Creado por:</span>
                  <span class="valor">${pedido.creado_por}</span>
                </div>
                <div class="texto-prioridad">${
                  pedido.prioridad ? "Prioridad" : ""
                }</div>
                <div class="propiedad">
                  <span class="subtitulo">Fecha de creación:</span>
                  <span class="valor">${this.getFechaLegible(
                    pedido.fecha_creacion
                  )}</span>
                </div>
                <div class="texto-pago">${pedido.pago ? pedido.pago : ""}</div>
                <div class="texto-zona">${
                  pedido.zona ? pedido.zona : "Sin zona"
                }</div>
                <div class="propiedad">
                  <span class="subtitulo">Fecha de impresión:</span>
                  <span class="valor">${this.getFechaLegible(
                    fechaActual
                  )}</span>
                </div>
                <div class="productos">
                  <div class="subtitulo">Productos:</div>
                  ${pedido.productos.lista_productos
                    .map(
                      (producto) => `
                      <div class="producto">
                        <span>${producto.codigo} - ${producto.nombre}</span>
                        <span class="valor">Cantidad: ${producto.cantidad}</span>
                      </div>
                    `
                    )
                    .join("")}
                </div>
                <div class="propiedad">
                  <span class="subtitulo">Cliente:</span>
                  <span class="valor">${pedido.cliente}</span>
                </div>

                <div class="propiedad">
                  <span class="subtitulo">Facturas:</span>
                  <span class="valor">
                    ${pedido.facturas}
                  </span>
                </div>

                <div class="propiedad">
                  <span class="subtitulo">Observaciones:</span>
                  <span class="valor">${pedido.observaciones}</span>
                </div>
                <div class="productos">
                  <span class="subtitulo">Comentarios:</span>
                  ${this.filtrarComentariosPorIdObjeto(
                    pedido.id,
                    arrayComentarios
                  )
                    .map(
                      (comentario) => `
                      <div class="producto">
                        <span>${this.getNombreVendedor(
                          comentario.usuario
                        )} - ${this.getFechaLegible(
                        comentario.fecha_creacion
                      )} - ${comentario.texto_comentario}</span>
                      </div>
                      `
                    )
                    .join("")}
                </div>
                <div class="propiedad">
                  <span class="subtitulo">Total:</span>
                  <span class="valor">$${pedido.total}</span>
                </div>
                <!-- Otras propiedades y detalles del pedido... -->
              </div>
            `
            )
            .join("")}
        </body>
      </html>
    `;

      // Escribir el contenido HTML en la ventana emergente
      ventana.document.open();
      ventana.document.write(contenidoHTML);
      ventana.document.close();

      // Imprimir la ventana emergente
      ventana.print();
    },
    formatoPrecio(numero) {
      // Formatear el número utilizando una función personalizada
      const numeroPrecio = parseFloat(numero);
      const precio = numeroPrecio.toFixed(2); // Limitar a 2 decimales
      const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
      const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

      // Formatear la parte entera con separador de miles
      const parteEnteraFormateada = parteEntera.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
    },
    async pedidoModificado() {
      await this.getPedidos(); //Se solicitan los pedidos para actualizar la tabla sin tener que recargar la view completa
      await this.obtenerArregloSucursalesDisponibles();
      this.$emit("pedidoModificado");
    },
    getSiguienteEstado(pedido) {
      const estadoEncontrado = this.ordenEstados.find(
        (estado) => estado.text === pedido.estado
      );

      if (estadoEncontrado !== undefined) {
        const indice = this.ordenEstados.indexOf(estadoEncontrado);
        return this.ordenEstados[indice + 1];
      }
    },
    async avanzarRutaEntrega() {
      try {
        const response = await axios.post(
          `/pedidos/avanzar_pedido?id_pedido=${this.pedidoEnRuta.id}&nuevo_estado=en_ruta_de_entrega&mensajero_id=${this.mensajero}` //this.mensajero contiene solo el id del mensajero
        );
        console.log(response.data);
        alert("Pedido avanzado");
        // location.reload();
        await this.getPedidos(); //Se solicitan los pedidos para actualizar la tabla sin tener que recargar toda la view completa
        await this.obtenerArregloSucursalesDisponibles();
        this.$emit("pedidoModificado");
        this.dialogUsuariosGPS = false;
      } catch (error) {
        console.error(error.response);
        alert(error.response.data.detail);
        this.dialogUsuariosGPS = false;
      }
    },
    async avanzarPedido(pedido) {
      const nuevoEstado = this.getSiguienteEstado(pedido);
      //Si el siguiente estado del pedido es en_ruta_de_entrega, se abre un dialogo para seleccionar el mensajero
      if (nuevoEstado.value == "en_ruta_de_entrega") {
        this.pedidoEnRuta = pedido;

        // Utilizar el método filter para obtener un nuevo array con usuarios cuya sucursal sea la del pedido
        const mensajerosArray = this.usuarios.filter(
          (usuario) =>
            usuario.sucursal === pedido.sucursal && usuario.gps !== null
        );
        console.log(mensajerosArray);

        this.mensajeros = mensajerosArray.map((mensajero) => ({
          text: mensajero.nombre,
          value: mensajero.id,
        }));

        this.dialogUsuariosGPS = true;
      } else {
        try {
          const response = await axios.post(
            `/pedidos/avanzar_pedido?id_pedido=${pedido.id}&nuevo_estado=${nuevoEstado.value}`
          );
          console.log(response.data);
          alert("Pedido avanzado");
          // location.reload();
          await this.getPedidos(); //Se solicitan los pedidos para actualizar la tabla sin tener que recargar toda la view completa
          await this.obtenerArregloSucursalesDisponibles();
          this.$emit("pedidoModificado");
        } catch (error) {
          console.error(error.response);
          alert(error.response.data.detail);
        }
      }
    },
    crearListaDiscrepancias(stringDiscrepancias) {
      const discrepanciasList = stringDiscrepancias.split(",");
      return discrepanciasList;
    },
    obtenerArregloSucursalesDisponibles() {
      const nuevoArreglo = this.itemsPedidos.sort((a, b) => {
        const indiceA = this.ordenSucursales.indexOf(a.sucursal);
        const indiceB = this.ordenSucursales.indexOf(b.sucursal);
        return indiceA - indiceB;
      });
      this.itemsSucursalesDisponibles = nuevoArreglo;
    },
    getNombreVendedor(vendedorId) {
      const vendedorEncontrado = this.usuarios.find(
        (usuario) => usuario.id === vendedorId
      );
      if (vendedorEncontrado) {
        return vendedorEncontrado.nombre + " ";
      }
      return "";
    },
    getNombreCliente(clienteId) {
      const clienteEncontrado = this.clientes.find(
        (cliente) => cliente.id === clienteId
      );
      if (clienteEncontrado) {
        return clienteEncontrado.razon_social + " ";
      }
      return "";
    },
    getZonaCliente(clienteId) {
      const clienteEncontrado = this.clientes.find(
        (cliente) => cliente.id === clienteId
      );
      if (clienteEncontrado) {
        if (clienteEncontrado.zona) {
          return clienteEncontrado.zona + " ";
        }
      }
      return "";
    },
    getAntiguedad(fecha_creacion) {
      const now = new Date();
      const fecha = new Date(fecha_creacion);
      const diffMs = now - fecha;
      const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
      const diffHrs = Math.floor(
        (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

      if (diffDays > 0) {
        return `${diffDays} día${diffDays > 1 ? "s" : ""}`;
      } else if (diffHrs > 0) {
        return `${diffHrs} hora${diffHrs > 1 ? "s" : ""}`;
      } else if (diffMins > 0) {
        return `${diffMins} minuto${diffMins > 1 ? "s" : ""}`;
      } else {
        return `menos de un minuto`;
      }
    },
    async getPedidos() {
      try {
        const response = await axios.get(`/pedidos/get_abiertos`);
        this.itemsPedidos = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    formatoFecha(fechaOriginal) {
      // Fecha en el formato original

      // Crear un objeto Date a partir de la fecha original
      const fecha = new Date(fechaOriginal);

      // Obtener los componentes de la fecha
      const year = fecha.getFullYear();
      const month = ("0" + (fecha.getMonth() + 1)).slice(-2); // Agrega un cero inicial si es necesario
      const day = ("0" + fecha.getDate()).slice(-2); // Agrega un cero inicial si es necesario

      // Construir la fecha en el formato deseado
      const fechaFormateada = `${day}-${month}-${year}`;

      return fechaFormateada; // Resultado: '01-06-2023'
    },
    getFechaLegible(fechaOriginal) {
      const fecha = new Date(fechaOriginal);

      const año = fecha.getFullYear();
      const mes = fecha.getMonth() + 1;
      const dia = fecha.getDate();
      const hora = fecha.getHours();
      const minutos = fecha.getMinutes().toString().padStart(2, "0");

      const fechaLegible = `${dia}/${mes}/${año}`;
      const horaLegible = `${hora}:${minutos}`;

      return `${fechaLegible} ${horaLegible}`;
    },
    getDuracion(fecha_creacion, fecha_cerrado) {
      const fechaCreacion = new Date(fecha_creacion);
      const fechaCerrado = new Date(fecha_cerrado);
      const diffMs = fechaCerrado - fechaCreacion;
      const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
      const diffHrs = Math.floor(
        (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

      if (diffDays > 0) {
        return `${diffDays} día${diffDays > 1 ? "s" : ""}`;
      } else if (diffHrs > 0) {
        return `${diffHrs} hora${diffHrs > 1 ? "s" : ""}`;
      } else if (diffMins > 0) {
        return `${diffMins} minuto${diffMins > 1 ? "s" : ""}`;
      } else {
        return `menos de un minuto`;
      }
    },
    /**Busca el cliente por id en el arreglo de clientes y retorna su informacion */
    getInfoCliente(clienteId) {
      const clienteEncontrado = this.clientes.find(
        (cliente) => cliente.id === clienteId
      );
      if (clienteEncontrado) {
        return clienteEncontrado;
      }
      return null;
    },
  },
  computed: {
    itemsNombre() {
      return this.itemsSucursalesDisponibles.map((sucursal) => {
        const pedidos = sucursal.pedidos.map((pedido) => {
          const nuevoPedido = { ...pedido };
          const infoCliente = this.getInfoCliente(pedido.cliente);
          nuevoPedido.vendedor = this.getNombreVendedor(pedido.vendedor);
          nuevoPedido.creado_por = this.getNombreVendedor(pedido.creado_por);
          // nuevoPedido.cliente = this.getNombreCliente(pedido.cliente);
          // nuevoPedido.zona = this.getZonaCliente(pedido.cliente);

          if (infoCliente) {
            nuevoPedido.cliente = infoCliente.razon_social + " ";
            if (infoCliente.zona) {
              nuevoPedido.zona = infoCliente.zona + " ";
            } else {
              nuevoPedido.zona = "";
            }
          } else {
            nuevoPedido.cliente = "";
            nuevoPedido.zona = "";
          }

          // nuevoPedido.antiguedad = this.getAntiguedad(pedido.fecha_creacion);

          // if (pedido.serie_factura && pedido.folio_factura) {
          //   nuevoPedido.serie_folio =
          //     pedido.serie_factura + " " + pedido.folio_factura;
          // }

          if (pedido.facturas) {
            if (pedido.facturas[0] === null) {
              nuevoPedido.facturas = [];
            }
          } else {
            nuevoPedido.facturas = [];
          }

          // nuevoPedido.total = "$" + pedido.total;
          if (pedido.discrepancias === null) {
            nuevoPedido.discrepancias = "Sin discrepancias";
          }
          if (pedido.contado) {
            nuevoPedido.pago = "Contado";
          } else {
            nuevoPedido.pago = "Crédito";
          }
          let estado = pedido.estado;
          switch (estado) {
            case "pedido_creado":
              estado = "Pedido creado";
              break;
            case "confirmacion":
              estado = "Confirmación";
              break;
            case "facturacion":
              estado = "Facturación";
              break;
            case "preparacion_en_almacen":
              estado = "Preparación en almacén";
              break;
            case "preparado":
              estado = "Preparado";
              break;
            case "en_ruta_de_entrega":
              estado = "En ruta de entrega";
              break;
            case "entregado":
              estado = "Entregado";
              break;
            case "cancelado":
              estado = "Cancelado";
              break;
            default:
          }
          nuevoPedido.estado = estado;
          return nuevoPedido;
        });

        //Para colocar los pedidos con prioridad primero
        // Usamos sort() junto con un comparador personalizado
        pedidos.sort((a, b) => {
          // Primero, verificamos si 'a' tiene prioridad y 'b' no
          if (a.prioridad && !b.prioridad) {
            return -1; // 'a' va antes que 'b'
          }

          // Segundo, verificamos si 'b' tiene prioridad y 'a' no
          if (!a.prioridad && b.prioridad) {
            return 1; // 'b' va antes que 'a'
          }

          // En caso de que ambos tengan la misma prioridad o ambos no tengan prioridad,
          // se mantiene el orden actual.
          return 0;
        });

        // console.log(pedidos);
        return { ...sucursal, pedidos };
      });
    },
    selectedItems() {
      this.filtrarItems();
      if (this.selectedTab >= 1 && this.selectedTab <= 6) {
        return this.itemsNombre[this.selectedTab].pedidos;
      } else {
        return this.itemsNombre[this.selectedTab].pedidos || [];
      }
    },
    itemRowBackgroundPrioridad() {
      return (item) => {
        const estado = item.estado;
        const prioridad = item.prioridad;
        let classes = "";

        switch (estado) {
          case "Cancelado":
            classes = "red lighten-4";
            break;
          case "Facturación":
            classes = "teal lighten-4";
            break;
          case "En ruta de entrega":
            classes = "yellow lighten-4";
            break;
          case "Preparación en almacén":
            classes = "orange lighten-4";
            break;
          case "Preparado":
            classes = "blue lighten-4";
            break;
          case "Pedido creado":
            classes = "green lighten-4";
            break;
          case "Confirmación":
            return "light-green lighten-4";
          case "Entregado":
            classes = "light-blue lighten-4";
            break;
          default:
            classes = "";
            break;
        }

        if (prioridad) {
          classes += " highlighted-row";
        }

        return classes;
      };
    },
    itemRowBackground() {
      return (item) => {
        const estado = item.estado;
        switch (estado) {
          case "Cancelado":
            return "red lighten-4";
          case "Facturación":
            return "teal lighten-4";
          case "En ruta de entrega":
            return "yellow lighten-4";
          case "Preparación en almacén":
            return "orange lighten-4";
          case "Preparado":
            return "blue lighten-4";
          case "Pedido creado":
            return "green lighten-4";
          case "Confirmación":
            return "light-green lighten-4";
          case "Entregado":
            return "light-blue lighten-4";
          default:
            return "";
        }
      };
    },
  },
  watch: {},
  mounted() {
    this.obtenerArregloSucursalesDisponibles();
  },
  components: { AccionesPedido },
};
</script>

<style>
.highlighted-row {
  outline: 2px solid rgb(237, 97, 97); /* Cambie el color y el estilo del borde según sus necesidades */
  box-shadow: inset 2px 0 0 rgb(237, 97, 97), inset -2px 0 0 rgb(237, 97, 97); /* Ajuste el color y tamaño según sus necesidades */
}
</style>
