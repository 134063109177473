<template>
  <v-card>
    <v-container>
      <v-btn
        class="absolute top right"
        icon
        color="blue darken-1"
        text
        @click="cerrar()"
      >
        <v-icon dark> mdi-close </v-icon>
      </v-btn>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <!-- <form ref="form" @submit.prevent="AgregarLatitudLongitud"> -->
            <v-row class="justify-center">
              <v-card-title>
                {{ item.razon_social }}
              </v-card-title>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Latitud y longitud"
                  append-icon="mdi-map-search"
                  v-model="latitudLongitud"
                  hint="Agrega latitud y longitud separadas por una coma (,)"
                  persistent-hint
                  required
                  :rules="[rules.required, rules.coordenadas]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              @click="AgregarLatitudLongitud"
              :disabled="!valid"
              color="teal lighten-2"
              text
              >Agregar</v-btn
            >
          </v-form>
        </v-container>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "AgregarLatitudLongitud",
  props: ["item"],
  data() {
    return {
      latitudLongitud: null,
      valid: true,
      rules: {
        required: (value) => !!value || "Completa este campo",
        coordenadas: (value) => {
          const pattern = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/; // Expresión regular para validar el formato
          return (
            pattern.test(value) || "El formato de las coordenadas es invalido"
          );
        },
      },
    };
  },
  methods: {
    async AgregarLatitudLongitud() {
      if (this.$refs.form.validate()) {
        const latitud = this.latitudLongitud.split(",")[0].trim();
        const longitud = this.latitudLongitud.split(",")[1].trim();
        console.log("Latitud: " + latitud + "\nLongitud: " + longitud);
        try {
          const response = await axios.put(
            `/clientes/modificar_localizacion?id_cliente=${this.item.id}&latitud=${latitud}&longitud=${longitud}`
          );

          console.log(JSON.stringify(response.data));
          alert("Latitud y longitud agregadas");

          this.$emit("clienteModificado");
          this.cerrar();
          this.$refs.form.reset();
          // location.reload();
        } catch (error) {
          alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
          console.log(error.response.data);
          8;
        }
      }
    },
    cerrar() {
      this.$refs.form.resetValidation();
      this.$emit("cerrarDialog", (this.dialog = false));
    },
    formatoLatitudLongitud() {
      if (this.item.latitud && this.item.longitud) {
        this.latitudLongitud = `${this.item.latitud},${this.item.longitud}`;
      } else {
        this.latitudLongitud = null;
      }
    },
  },
  computed: {},
  async created() {
    console.log(this.item);
    this.formatoLatitudLongitud();
  },
};
</script>

<style>
.absolute {
  position: absolute;
}

.top {
  top: 10px;
}

.right {
  right: 10px;
}
</style>
