<template>
  <v-container>
    <h2>Comentarios</h2>
    <v-divider class="mt-2 mb-2"></v-divider>
    <!--boton para agregar comentario en ver  seccion de ver perdidos -->
    <v-row justify="center" align="center" class="mt-2 mb-0">
      <v-col cols="12" md="4" sm="6">
        <v-btn @click="mostrarModal = true" rounded="lg" elevation="5" dark>
          <div class="pr-2"><v-icon> mdi-comment-edit-outline </v-icon></div>
          Agregar Comentario
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="mostrarModal" max-width="600">
      <v-card>
        <v-container>
          <v-card-text>
            <v-container>
              <form ref="form" @submit.prevent="crearComentario()">
                <v-row>
                  <v-col>
                    <v-card-title class="justify-center">
                      Crear comentario nuevo para el {{ objeto }}: {{ item.id }}
                    </v-card-title>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      label="Comentario"
                      v-model="comentario"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn type="submit" color="teal lighten-2" text
                    >Crear comentario
                  </v-btn>
                </v-row>
              </form>
            </v-container>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mostrarModalConfirmacion" max-width="400" persistent>
      <v-card>
        <div class="text-center">
          <v-icon size="70" color="green">mdi-check-circle-outline</v-icon>
          <v-card-text class="font-weight-bold">
            <div>¡El comentario fue creado con exito!</div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="teal lighten-2"
              @click="mostrarModalConfirmacion = false"
              text
              >Aceptar</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <div v-if="comentariosInverso.length === 0">
      Este {{ objeto }} no cuenta con comentarios
    </div>
    <div v-else-if="comentariosInverso.length <= 3">
      <v-list>
        <v-list-item
          v-for="comentario in comentariosInverso"
          :key="comentario.id"
        >
          <v-card class="styled-card mb-2">
            <v-list-item-content>
              <v-list-item-title>
                <strong>Comentario: </strong>
                <span
                  v-html="comentario.texto_comentario"
                  style="white-space: pre-line"
                ></span>
                <!-- {{ comentario.texto_comentario }} -->
              </v-list-item-title>
              <v-list-item-title>
                <strong>Realizado por: </strong>
                <span
                  v-html="getVendedor(comentario.usuario)"
                  style="white-space: pre-line"
                ></span>
                <!-- {{ getVendedor(comentario.usuario) }} -->
              </v-list-item-title>
              <v-list-item-title>
                <strong>Fecha: </strong>
                <span
                  v-html="getFechaLegible(comentario.fecha_creacion)"
                  style="white-space: pre-line"
                ></span>
                <!-- {{ getFechaLegible(comentario.fecha_creacion) }} -->
              </v-list-item-title>
            </v-list-item-content>
          </v-card>
        </v-list-item>
      </v-list>
    </div>
    <div v-else>
      <v-list>
        <v-list-item
          v-for="comentario in comentariosFiltrados"
          :key="comentario.id"
        >
          <v-card class="styled-card mb-2">
            <v-list-item-content>
              <v-list-item-title>
                <strong>Comentario: </strong>
                {{ comentario.texto_comentario }}
              </v-list-item-title>
              <v-list-item-title>
                <strong>Realizado por:</strong>
                {{ getVendedor(comentario.usuario) }}
              </v-list-item-title>
              <v-list-item-title>
                <strong>Fecha: </strong>
                {{ getFechaLegible(comentario.fecha_creacion) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-card>
        </v-list-item>
      </v-list>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header
            >Comentarios más antiguos</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-list>
              <v-list-item
                v-for="comentario in comentariosInverso.slice(3)"
                :key="comentario.id"
              >
                <v-card class="styled-card mt-3">
                  <v-list-item-content>
                    <v-list-item-title>
                      <strong>Comentario:</strong>
                      {{ comentario.texto_comentario }}
                    </v-list-item-title>
                    <v-list-item-title>
                      <strong>Realizado por:</strong>
                      {{ getVendedor(comentario.usuario) }}
                    </v-list-item-title>
                    <v-list-item-title>
                      <strong>Fecha:</strong>
                      {{ getFechaLegible(comentario.fecha_creacion) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-card>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: ["item", "usuarios", "comentarios"],
  data() {
    return {
      //comentarios: [],
      objeto: "",
      comentario: "",
      mostrarModal: false,
      mostrarModalConfirmacion: false,
    };
  },
  methods: {
    //funcion para agregar comentarios
    async crearComentario() {
      try {
        const response = await axios.post(
          `/varios/crear_comentario?id_objeto=${this.item.id}&tipo_objeto=${this.objeto}&comentario=${this.comentario}`
        );
        console.log(JSON.stringify(response.data));
        this.$emit("pedidoModificado"); // Emitir el evento 'pedidoModificado' que se encarga de actualizar los pedidos
        this.$emit("cerrarDialog");
        this.$emit("comentarioCreado");
        //alert("Comentario creado");
        this.comentario = "";
        this.mostrarModal = false;
        this.mostrarModalConfirmacion = true;
        //this.cerrar();
        //location.reload();
      } catch (error) {
        error.log(error);
        alert(JSON.stringify(error));
      }
    },

    /*async recargarComentarios() {
      try {
        const response = axios.get(
          `/varios/get_comentario?id_objeto=${this.item.id}&tipo_objeto=${this.objeto}&comentario=${this.comentario}`
        );
        this.comentario = response.data;
        location.reload();
      } catch (error) {
        console.error("Error al actulizar los datos:", error);
        alert("Error al actulizar los datos:");
      }
    },*/
    getVendedor(vendedorId) {
      const vendedorEncontrado = this.usuarios.find(
        (usuario) => usuario.id === vendedorId
      );
      if (vendedorEncontrado) {
        return vendedorEncontrado.nombre + " ";
      }
      return "";
    },
    getFechaLegible(fechaLarga) {
      const fecha = new Date(fechaLarga);

      const año = fecha.getFullYear();
      const mes = fecha.getMonth() + 1;
      const dia = fecha.getDate();
      const hora = fecha.getHours();
      const minutos = fecha.getMinutes().toString().padStart(2, "0");

      const fechaLegible = `${dia}/${mes}/${año}`;
      const horaLegible = `${hora}:${minutos}`;

      return `${fechaLegible} ${horaLegible}`;
    },
    //arreglar la funcion getComentarios para que no de error unexpected mutation of comentarios
    /* async getComentarios() {
      try {
        const response = await axios.get(
          `/varios/get_comentarios?id_objeto=${this.item.id}&tipo_objeto=${this.objeto}`
        );
        this.comentarios = response.data;
        console.log(this.comentarios);
      } catch (error) {
        console.error(error);
      }
    },*/
    checkObjectType() {
      if (
        typeof this.item.lista_productos !== "undefined" &&
        typeof this.item.transportista !== "undefined"
      ) {
        // Check if the item object has the properties "sucursal" and "envios"
        this.objeto = "envio";
      } else if (
        typeof this.item.id !== "undefined" &&
        typeof this.item.nombre !== "undefined" &&
        typeof this.item.cliente !== "undefined"
      ) {
        // Check if the item object has the properties "id", "nombre", and "cliente"
        this.objeto = "faltante";
      } else if (
        typeof this.item.id !== "undefined" &&
        typeof this.item.productos !== "undefined" &&
        typeof this.item.cliente !== "undefined"
      ) {
        // Check if the item object has the properties "id", "productos", and "cliente"
        this.objeto = "pedido";
      } else {
        // Default case if the item object doesn't match any expected format
        this.objeto = "unknown";
      }
    },
  },
  //   mounted() {
  //     this.getComentarios();
  //   },
  cerrar() {
    this.mostrarModal = false;
  },
  created() {
    this.checkObjectType();
    //this.getComentarios();
  },
  computed: {
    comentariosInverso() {
      return this.comentarios.slice().reverse();
    },
    comentariosFiltrados() {
      return this.comentariosInverso.slice(0, 3);
    },
  },
};
</script>

<style>
.styled-card {
  width: 100%;
  padding: 10px;
}
</style>
