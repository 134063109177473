<template>
  <v-container>
    Sistema de faltantes de Central Express S.A. de C.V.
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
