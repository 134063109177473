<template>
  <v-container>
    <v-menu>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
              mdi-dots-vertical
            </v-icon>
          </template>
          <span>Acciones</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="selectComponent(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="actualizarCliente()">
          Actualizar cliente
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog max-width="50%" v-model="dialog">
      <v-card v-if="selectedComponent === 'ModificarCliente'">
        <ModificarCliente :item="item" @cerrarDialog="cerrarDialog" />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ModificarCliente from "./ModificarCliente.vue";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  props: ["item"],
  components: {
    ModificarCliente,
  },
  data() {
    return {
      dialog: false,
      items: [
        {
          title: "Modificar Cliente",
          component: "ModificarCliente",
        },
      ],
      selectedComponent: null,
    };
  },
  methods: {
    async actualizarCliente() {
      try {
        const response = await axios.post(
          `/clientes/actualizar_cliente?cliente_id=${this.item.id}`,
          {},
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        alert(JSON.stringify(response.data));
        console.log(response);
        // alert("Usuario suspendido.");
        location.reload();
      } catch (error) {
        if (error) {
          this.errores.push(error);
          alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
        this.serverError = true;
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    },
    selectComponent(item) {
      if (item.component) {
        this.selectedComponent = item.component;
        this.dialog = true;
      }
    },
    ...mapActions(["setPerfil"]),
    openPerfil(perfil) {
      this.setPerfil(perfil);
      this.$emit("openPerfil"); // Emit an event to the parent component
    },
    cerrarDialog(dialog) {
      this.dialog = dialog;
    },
  },
};
</script>
