<template>
  <v-container>
    <v-switch
      class="mb-2 switch-small"
      color="success"
      v-model="switch1"
      @change="cambiarActivo"
    >
    </v-switch>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "SeguimientoAutomatico",
  props: ["item"],
  data() {
    return {
      switch1: "",
      id_faltante: this.item.id,
      errores: [],
    };
  },
  methods: {
    async cambiarActivo() {
      await axios
        .put(
          `/faltantes/seguimiento_automatico?id_faltante=${this.id_faltante}`
        )
        .catch((error) => {
          if (error) {
            this.errores.push(error);
            alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
          }
          this.serverError = true;
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
          }
        });
    },
  },
  created() {
    this.switch1 = this.item.seguimiento_automatico;
  },
};
</script>
<style>
.switch-small {
  width: 40px; /* Ancho personalizado */
  height: 20px; /* Altura personalizada */
}
</style>
