<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Reporte de pedidos
      </v-card-title>
      <form ref="form" @submit.prevent="getReportes()">
        <!-- Fecha inicial y fecha fin  -->
        <v-row class="mx-2 mt-1">
          <v-col cols="6" md="3">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaInicial"
                  label="Fecha inicial"
                  prepend-icon="mdi-calendar"
                  readonly
                  required
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaInicial"
                no-title
                color="teal lighten-3"
                locale="es-MX"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" md="3">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaFin"
                  label="Fecha fin"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaFin"
                no-title
                color="teal lighten-3"
                locale="es-MX"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- <v-col cols="6" md="4">
            <v-select
              label="Sucursal"
              :items="sucursales"
              v-model="sucursal"
              required
            ></v-select>
          </v-col> -->
          <v-col cols="6" md="4">
            <v-select
              v-model="sucursalesSelected"
              :items="sucursales"
              label="Sucursal"
              multiple
              chips
              deletable-chips
              @change="mostrarSucursal"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mx-2 mt-1">
          <v-col cols="6" md="2">
            <v-btn type="submit" :loading="loadingBtn" class="mt-3"
              >Enviar
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </v-card>
    <!-- <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="dialogReportes"
    >
      <v-card>
        <v-container>
          <v-btn
            class="close-btn"
            icon
            color="blue darken-1"
            text
            @click="dialogReportes = !dialogReportes"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
          <v-card-title
            >Promedio de duración de pedidos en minutos</v-card-title
          >
          <v-card-text>
            <div class="subtitle-1 mb-3">
              De {{ fechaInicial }} a {{ fechaFin }}
            </div>
            <div class="text-h6">
              Pedidos entregados : {{ datos.pedidos_entregados }}
            </div>
            <apexchart
              :options="chartOptions"
              :series="chartSeries"
              type="bar"
              height="300"
            ></apexchart>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog> -->
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="dialogAllReportes"
    >
      <v-card>
        <v-container>
          <v-btn
            class="close-btn"
            icon
            color="blue darken-1"
            text
            @click="dialogAllReportes = !dialogAllReportes"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
          <v-card-title>Reporte de pedidos</v-card-title>
          <v-card-text v-if="pedidosSucursales.length > 0">
            <div class="subtitle-1 mb-3">
              De {{ fechaInicial }} a {{ fechaFin }}
            </div>

            <div v-for="(sucursal, index) in pedidosSucursales" :key="index">
              <div
                class="text-h5 mt-4"
                v-if="sucursalesSelected[index] === 'LaPaz'"
              >
                La Paz
              </div>
              <div
                class="text-h5 mt-4"
                v-else-if="sucursalesSelected[index] === 'Obregon'"
              >
                Obregón
              </div>
              <div class="text-h5 mt-4" v-else>
                {{ sucursalesSelected[index] }}
              </div>
              <div class="text-h6">
                Pedidos entregados : {{ sucursal.pedidos_entregados }}
              </div>
              <div class="text-h6">
                Duracion promedio de pedidos :
                {{ formatMinutes(sucursal.totalMinutos) }}
              </div>
              <apexchart
                :options="chartOptions"
                :series="sucursal.series"
                type="bar"
                height="300"
              ></apexchart>
            </div>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
import { intervalToDuration } from "date-fns";
export default {
  data() {
    return {
      fechaInicial: "",
      fechaFin: "",
      sucursalesSelected: [],
      dialogReportes: false,
      dialogAllReportes: false,

      datos: [],

      menu: false,
      menu2: false,

      loadingBtn: false,

      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#333"],
            fontSize: "14px",
          },
          formatter: function (val) {
            const days = Math.floor(val / (24 * 60));
            const hours = Math.floor((val % (24 * 60)) / 60);
            const mins = val % 60;

            if (days === 0) {
              if (hours === 0) {
                return `${mins}m`;
              } else {
                return `${hours}h, ${mins}m`;
              }
            } else {
              if (mins === 0) {
                return `${days}d, ${hours}h`;
              } else {
                return `${days}d, ${hours}h, ${mins}m`;
              }
            }
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            borderRadius: 2,
            padding: 4,
            opacity: 0.5,
            borderWidth: 1,
            borderColor: "#fff",
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            // dataLabels: {
            //   position: "top",
            // },
          },
        },
        xaxis: {
          categories: [
            "Pedido creado",
            "Revisión de crédito",
            "Confirmación",
            "Facturación",
            "Prep. en almacén",
            "Preparado",
            "En ruta entrega",
          ],
          labels: {},
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-yaxis-label",
            },
          },
          title: {
            text: "Duración promedio (minutos)",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "16px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 525,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        colors: ["#42A5F5"],
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      },

      chartSeries: [
        {
          name: "Tiempo (min)",
          data: [0, 0, 0, 0, 0, 0, 0],
        },
      ],

      pedidosSucursales: [],

      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      nombresSucursales: [
        "Mexicali",
        "Tijuana",
        "Ensenada",
        "LaPaz",
        "Obregon",
        "Hermosillo",
      ],
    };
  },
  methods: {
    // formatMinutesBars(minutes) {
    //   const duration = intervalToDuration({
    //     start: 0,
    //     end: minutes * 60 * 1000,
    //   });
    //   const { days, hours, minutes: mins } = duration;

    //   return `${days}d, ${hours}h, ${mins}m`;
    // },
    formatMinutes(minutes) {
      const duration = intervalToDuration({
        start: 0,
        end: minutes * 60 * 1000,
      });
      const { days, hours, minutes: mins } = duration;

      if (days === 0) {
        if (hours === 0) {
          return `${mins} minutos`;
        } else {
          return `${hours} horas, ${mins} minutos`;
        }
      } else {
        if (mins === 0) {
          return `${days} días, ${hours} horas`;
        } else {
          return `${days} días, ${hours} horas, ${mins} minutos`;
        }
      }
    },
    mostrarSucursal() {
      const ordenOriginal = this.sucursales.map((s) => s.value);
      this.sucursalesSelected.sort((a, b) => {
        return ordenOriginal.indexOf(a) - ordenOriginal.indexOf(b);
      });
      // console.log(this.sucursal);
    },
    async getReportes() {
      if (
        this.fechaInicial === "" ||
        this.fechaFin === "" ||
        this.sucursalesSelected.length === 0
      ) {
        alert("No puede haber campos vacios");
      } else {
        this.loadingBtn = true;
        console.log(this.fechaInicial);
        console.log(this.fechaFin);

        try {
          // Creamos un array de promesas utilizando map para hacer una solicitud por cada sucursal
          const promesasSolicitudes = this.sucursalesSelected.map(
            (sucursal) => {
              return axios
                .get(
                  `/reportes/pedidos?fecha_inicial=${this.fechaInicial}&fecha_final=${this.fechaFin}&sucursal=${sucursal}`
                )
                .then((response) => response.data) // Asegurarse de devolver los datos de la respuesta
                .catch((error) => {
                  console.error("Error al obtener datos:", error);
                  if (error.response.request.status === 404) {
                    return {
                      pedidos_entregados: 0,
                      pedido_creado: 0,
                      revision_de_credito: 0,
                      confirmacion: 0,
                      facturacion: 0,
                      preparacion_en_almacen: 0,
                      preparado: 0,
                      en_ruta_de_entrega: 0,
                    };
                  } else {
                    return null; // Devolver null o algún valor para manejar el error
                  }
                });
            }
          );

          // Usamos Promise.allSettled para esperar a que todas las promesas se resuelvan o rechacen
          const resultados = await Promise.allSettled(promesasSolicitudes);

          this.pedidosSucursales = resultados.map((result) => {
            const datosPedido = {
              pedidos_entregados: result.value.pedidos_entregados,
              totalMinutos:
                Math.round(result.value.pedido_creado / 60) +
                Math.round(result.value.revision_de_credito / 60) +
                Math.round(result.value.confirmacion / 60) +
                Math.round(result.value.facturacion / 60) +
                Math.round(result.value.preparacion_en_almacen / 60) +
                Math.round(result.value.preparado / 60) +
                Math.round(result.value.en_ruta_de_entrega / 60),
              series: [
                {
                  name: "Tiempo",
                  data: [
                    Math.round(result.value.pedido_creado / 60),
                    Math.round(result.value.revision_de_credito / 60),
                    Math.round(result.value.confirmacion / 60),
                    Math.round(result.value.facturacion / 60),
                    Math.round(result.value.preparacion_en_almacen / 60),
                    Math.round(result.value.preparado / 60),
                    Math.round(result.value.en_ruta_de_entrega / 60),
                  ],
                },
              ],
            };

            return datosPedido;
          });

          console.log(resultados);
          console.log(this.pedidosSucursales);
          this.loadingBtn = false;
          this.dialogAllReportes = true;
        } catch (error) {
          console.error("Error al obtener datos:", error);
        }

        // if (this.sucursal === "all") {
        //   console.log("Todas las sucursales");

        //   try {
        //     // Creamos un array de promesas utilizando map para hacer una solicitud por cada sucursal
        //     const promesasSolicitudes = this.nombresSucursales.map(
        //       (sucursal) => {
        //         return axios
        //           .get(
        //             `/reportes/pedidos?fecha_inicial=${this.fechaInicial}&fecha_final=${this.fechaFin}&sucursal=${sucursal}`
        //           )
        //           .then((response) => response.data) // Asegurarse de devolver los datos de la respuesta
        //           .catch((error) => {
        //             console.error("Error al obtener datos:", error);
        //             if (error.response.request.status === 404) {
        //               return {
        //                 pedidos_entregados: 0,
        //                 pedido_creado: 0,
        //                 revision_de_credito: 0,
        //                 confirmacion: 0,
        //                 facturacion: 0,
        //                 preparacion_en_almacen: 0,
        //                 preparado: 0,
        //                 en_ruta_de_entrega: 0,
        //               };
        //             } else {
        //               return null; // Devolver null o algún valor para manejar el error
        //             }
        //           });
        //       }
        //     );

        //     // Usamos Promise.allSettled para esperar a que todas las promesas se resuelvan o rechacen
        //     const resultados = await Promise.allSettled(promesasSolicitudes);

        //     console.log(resultados);

        //     this.pedidosSucursales = [
        //       {
        //         pedidos_entregados: resultados[0].value.pedidos_entregados,
        //         series: [
        //           {
        //             name: "Tiempo",
        //             data: [
        //               Math.floor(resultados[0].value.pedido_creado),
        //               Math.floor(resultados[0].value.revision_de_credito),
        //               Math.floor(resultados[0].value.confirmacion),
        //               Math.floor(resultados[0].value.facturacion),
        //               Math.floor(resultados[0].value.preparacion_en_almacen),
        //               Math.floor(resultados[0].value.preparado),
        //               Math.floor(resultados[0].value.en_ruta_de_entrega),
        //             ],
        //           },
        //         ],
        //       },
        //       {
        //         pedidos_entregados: resultados[1].value.pedidos_entregados,
        //         series: [
        //           {
        //             name: "Tiempo",
        //             data: [
        //               Math.floor(resultados[1].value.pedido_creado),
        //               Math.floor(resultados[1].value.revision_de_credito),
        //               Math.floor(resultados[1].value.confirmacion),
        //               Math.floor(resultados[1].value.facturacion),
        //               Math.floor(resultados[1].value.preparacion_en_almacen),
        //               Math.floor(resultados[1].value.preparado),
        //               Math.floor(resultados[1].value.en_ruta_de_entrega),
        //             ],
        //           },
        //         ],
        //       },
        //       {
        //         pedidos_entregados: resultados[2].value.pedidos_entregados,
        //         series: [
        //           {
        //             name: "Tiempo",
        //             data: [
        //               Math.floor(resultados[2].value.pedido_creado),
        //               Math.floor(resultados[2].value.revision_de_credito),
        //               Math.floor(resultados[2].value.confirmacion),
        //               Math.floor(resultados[2].value.facturacion),
        //               Math.floor(resultados[2].value.preparacion_en_almacen),
        //               Math.floor(resultados[2].value.preparado),
        //               Math.floor(resultados[2].value.en_ruta_de_entrega),
        //             ],
        //           },
        //         ],
        //       },
        //       {
        //         pedidos_entregados: resultados[3].value.pedidos_entregados,
        //         series: [
        //           {
        //             name: "Tiempo",
        //             data: [
        //               Math.floor(resultados[3].value.pedido_creado),
        //               Math.floor(resultados[3].value.revision_de_credito),
        //               Math.floor(resultados[3].value.confirmacion),
        //               Math.floor(resultados[3].value.facturacion),
        //               Math.floor(resultados[3].value.preparacion_en_almacen),
        //               Math.floor(resultados[3].value.preparado),
        //               Math.floor(resultados[3].value.en_ruta_de_entrega),
        //             ],
        //           },
        //         ],
        //       },
        //       {
        //         pedidos_entregados: resultados[4].value.pedidos_entregados,
        //         series: [
        //           {
        //             name: "Tiempo",
        //             data: [
        //               Math.floor(resultados[4].value.pedido_creado),
        //               Math.floor(resultados[4].value.revision_de_credito),
        //               Math.floor(resultados[4].value.confirmacion),
        //               Math.floor(resultados[4].value.facturacion),
        //               Math.floor(resultados[4].value.preparacion_en_almacen),
        //               Math.floor(resultados[4].value.preparado),
        //               Math.floor(resultados[4].value.en_ruta_de_entrega),
        //             ],
        //           },
        //         ],
        //       },
        //       {
        //         pedidos_entregados: resultados[5].value.pedidos_entregados,
        //         series: [
        //           {
        //             name: "Tiempo",
        //             data: [
        //               Math.floor(resultados[5].value.pedido_creado),
        //               Math.floor(resultados[5].value.revision_de_credito),
        //               Math.floor(resultados[5].value.confirmacion),
        //               Math.floor(resultados[5].value.facturacion),
        //               Math.floor(resultados[5].value.preparacion_en_almacen),
        //               Math.floor(resultados[5].value.preparado),
        //               Math.floor(resultados[5].value.en_ruta_de_entrega),
        //             ],
        //           },
        //         ],
        //       },
        //     ];

        //     this.loadingBtn = false;
        //     this.dialogAllReportes = true;
        //   } catch (error) {
        //     console.error("Error al avanzar pedidos:", error);
        //     this.loadingBtn = false;
        //   }

        //   this.loadingBtn = false;
        // } else {
        //   try {
        //     const response = await axios.get(
        //       `/reportes/pedidos?fecha_inicial=${this.fechaInicial}&fecha_final=${this.fechaFin}&sucursal=${this.sucursal}`
        //     );
        //     this.datos = response.data;
        //     console.log(this.datos);

        //     this.chartSeries = [
        //       {
        //         name: "Tiempo",
        //         data: [
        //           Math.floor(this.datos.pedido_creado),
        //           Math.floor(this.datos.revision_de_credito),
        //           Math.floor(this.datos.confirmacion),
        //           Math.floor(this.datos.facturacion),
        //           Math.floor(this.datos.preparacion_en_almacen),
        //           Math.floor(this.datos.preparado),
        //           Math.floor(this.datos.en_ruta_de_entrega),
        //         ],
        //       },
        //     ];
        //     this.loadingBtn = false;
        //     this.dialogReportes = true;
        //   } catch (error) {
        //     console.error(error);
        //     alert("Error al obtener los datos");
        //     this.loadingBtn = false;
        //   }
        // }
      }
    },
  },
  watch: {},
  created() {
    // this.getDatos();
    // this.prepararDatos();
  },
  components: { apexchart: VueApexCharts },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
