<template>
  <v-card>
    <v-container>
      <v-card-title class="text-center py-6">
        Información del pedido:
        {{ item.id }}
        <v-spacer />
        <v-btn
          class="close-btn"
          icon
          color="blue darken-1"
          text
          @click="cerrarDialog"
        >
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <DesplegarComentarios
            :item="item"
            :usuarios="usuarios"
            :comentarios="comentarios"
          ></DesplegarComentarios>
        </v-row>
        <v-row>
          <HistorialObjeto :item="item" :usuarios="usuarios" />
        </v-row>
        <v-row class="mx-2 mt-5">
          <div>
            <div class="mb-1">
              <strong>Creado por:</strong> {{ item.creado_por }}
            </div>
            <div class="mb-1">
              <strong>Fecha de creación:</strong>
              {{ getFechaLegible(item.fecha_creacion) }}
            </div>
            <div class="mb-1">
              <strong>Productos:</strong>
              <ul>
                <li
                  v-for="(producto, index) in item.productos.lista_productos"
                  :key="index"
                >
                  <strong>Código:</strong> {{ producto.codigo }}<br />
                  <strong>Nombre:</strong> {{ producto.nombre }}<br />
                  <strong>Cantidad:</strong> {{ producto.cantidad }}
                </li>
              </ul>
            </div>
            <div class="mb-1"><strong>Cliente:</strong> {{ item.cliente }}</div>
            <div class="mb-1">
              <strong>Abierto:</strong> {{ item.abierto ? "Sí" : "No" }}
            </div>
            <div class="mb-1">
              <strong>Observaciones:</strong> {{ item.observaciones }}
            </div>
            <div v-if="item.facturas.length !== 0" class="mb-1">
              <div>
                <strong>Facturas:</strong>
                <ul>
                  <li v-for="(factura, index) in item.facturas" :key="index">
                    {{ factura }}<br />
                  </li>
                </ul>
              </div>
            </div>
            <div v-else class="mb-1">
              <strong>Facturas:</strong> Sin facturas
            </div>
            <!-- <div class="mb-1">
              <strong>Folio factura:</strong> {{ item.folio_factura }}
            </div> -->
            <div class="mb-1">
              <strong>Contado:</strong> {{ item.contado ? "Sí" : "No" }}
            </div>
            <div class="mb-1">
              <strong>Discrepancias:</strong> {{ item.discrepancias }}
            </div>
            <div class="mb-1">
              <strong>Vendedor:</strong> {{ item.vendedor }}
            </div>
            <div class="mb-1"><strong>Estado:</strong> {{ item.estado }}</div>
            <div class="mb-1">
              <strong>Detalles:</strong> {{ item.detalles }}
            </div>
            <!-- <div class="mb-1">
              <strong>Serie factura:</strong> {{ item.serie_factura }}
            </div> -->
            <div class="mb-1">
              <strong>Prioridad:</strong> {{ item.prioridad ? "Sí" : "No" }}
            </div>
            <div class="mb-1">
              <strong>Total:</strong>
              {{
                "$" +
                item.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class="mb-1">
              <strong>Sucursal:</strong> {{ item.sucursal }}
            </div>
            <div class="mb-5">
              <strong>Mensajero:</strong> {{ item.mensajero }}
            </div>
          </div>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import DesplegarComentarios from "./DesplegarComentarios.vue";
import HistorialObjeto from "./Historial/HistorialObjeto.vue";

export default {
  props: ["item", "usuarios", "comentarios"],
  data() {
    return {};
  },
  methods: {
    cerrarDialog() {
      this.$emit("cerrarDialog");
    },
    getFechaLegible(fechaLarga) {
      const fecha = new Date(fechaLarga);

      const año = fecha.getFullYear();
      const mes = fecha.getMonth() + 1;
      const dia = fecha.getDate();
      const hora = fecha.getHours();
      const minutos = fecha.getMinutes().toString().padStart(2, "0");

      const fechaLegible = `${dia}/${mes}/${año}`;
      const horaLegible = `${hora}:${minutos}`;

      return `${fechaLegible} ${horaLegible}`;
    },
  },
  components: { DesplegarComentarios, HistorialObjeto },
  created() {
    //PENDIENTE POR TERMINAR
    /*this.$parent.$on("comentarioCreado", (comentario) => {
      this.comentarios.push(comentario);
    });
    //this.recargarComentarios();*/
  },
  mounted() {},
};
</script>
<style>
.close-btn {
  float: right;
}
</style>
