<template>
  <v-card>
    <v-card-text>
      <v-container>
        <form ref="form" @submit.prevent="modificarCliente()">
          <v-row>
            <v-col>
              <v-card-title class="justify-center">
                <div>Recibir el envío: {{ item.id }}</div>
              </v-card-title>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="ID"
                append-icon="mdi-identifier"
                v-model="id"
                readonly
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Discrepancias"
                append-icon="mdi-alert"
                v-model="discrepancias"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn type="submit" color="teal lighten-2" text>Recibir envío</v-btn>
        </form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "modificarCliente",
  props: ["item"],
  data() {
    return {
      id: this.item.id,
      discrepancias: this.item.discrepancias,
      itemsActivo: [
        {
          text: "Cierto",
          value: true,
        },
        {
          text: "Falso",
          value: false,
        },
      ],
      currentPlantilla: "",
    };
  },
  methods: {
    async modificarCliente() {
      try {
        const response = await axios.put(
          `/envios/recibir?id_envio=${this.id}&discrepancias=${this.discrepancias}`,
          {},
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        alert("Envio recibido");
        console.log(JSON.stringify(response.data));
        // location.reload();
        this.$emit("envioModificado");
      } catch (error) {
        console.error(error); // Handle any errors that might occur
        alert(error);
      }
    },
  },
  computed: {
    ...mapGetters(["perfil"]),
  },
};
</script>
