<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="teal lighten-3 accent-4 white--text">
        Reportes
      </v-card-title>
      <v-card-text>
        <v-subheader class="mt-2"> Ventas </v-subheader>
        <!-- Cliente, agente, laboratorio, sucursal, Avance, tabla pivote -->
        <v-row class="ml-2">
          <v-col cols="12" md="auto">
            <v-btn link to="/precios_existencias" class="my-2"
              >Precios y existencias</v-btn
            >
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn link to="/tabla_pivote" class="my-2">Tabla pivote</v-btn>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn link to="/reportes_pedidos" class="my-2">Pedidos</v-btn>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn link to="/reporte_cliente" class="my-2">Cliente</v-btn>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn link to="/reporte_sucursal" class="my-2">Sucursal</v-btn>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn link to="/avance" class="my-2">Avance</v-btn>
          </v-col>
          <!-- NUEVA IMPLEMENTACION -->
          <v-col cols="12" md="auto">
            <v-btn link to="/reporte_laboratorio" class="my-2"
              >Laboratorio</v-btn
            >
          </v-col>
        </v-row>
        <v-subheader class="mt-2"> Inventario </v-subheader>
        <v-row class="ml-2">
          <v-col cols="12" md="auto">
            <v-btn link to="/lotes" class="my-2">Lotes</v-btn>
          </v-col>
          <!-- <v-col cols="12" md="auto">
            <v-btn class="my-2">Movimientos</v-btn>
          </v-col> -->
          <v-col cols="12" md="auto">
            <v-btn link to="/stock" class="my-2">Stock</v-btn>
          </v-col>
        </v-row>
        <v-subheader class="mt-2"> Compras </v-subheader>
        <!-- Compras, productos -->
        <v-row class="ml-2">
          <v-col cols="12" md="auto">
            <v-btn link to="/compras" class="my-2">Compras</v-btn>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn link to="/productos" class="my-2">Productos</v-btn>
          </v-col>
        </v-row>
        <v-subheader class="mt-2"> Crédito y cobranza </v-subheader>
        <v-row class="ml-2">
          <v-col cols="12" md="auto">
            <v-btn link to="/estado_de_cuenta" class="my-2"
              >Estado de cuenta</v-btn
            >
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn link to="/facturas_pendientes" class="my-2"
              >Facturas pendientes</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
  components: {},
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
