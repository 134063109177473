<template>
  <v-card>
    <v-card-text>
      <v-container>
        <form ref="form" @submit.prevent="modificarPerfil()">
          <v-row>
            <v-col>
              <v-card-title class="justify-center">
                <div>Nombre del perfil: {{ props.nombre }}</div>
              </v-card-title>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                label="Local"
                outlined
                :items="items"
                v-model="props.local"
                required
              ></v-select>
            </v-col>
          </v-row>
          <!--           <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Plantillas"
                append-icon="mdi-text-box-multiple-outline"
                v-model="currentPlantilla"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn icon color="primary" @click="addPlantilla()"
                ><v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col>
              <div class="caption">Plantillas</div>
              <v-textarea solo v-model="props.plantillas" required></v-textarea>
            </v-col>
          </v-row>
          <v-btn type="submit" color="teal lighten-2" text>Modificar</v-btn>
        </form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "ModificarPerfil",
  props: ["item"],
  data() {
    return {
      props: {
        nombre: this.item.nombre,
        local: this.item.local,
        plantillas: [this.item.plantillas],
      },
      items: [
        {
          text: "Cierto",
          value: true,
        },
        {
          text: "Falso",
          value: false,
        },
      ],
      currentPlantilla: "",
    };
  },
  methods: {
    async modificarPerfil() {
      try {
        const response = await axios.post(
          `/usuarios/modificar_perfil?perfil_nombre=${this.props.nombre}&nuevas_plantillas=${this.props.plantillas}&nuevo_local=${this.props.local}`,
          {},
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        console.log(this.props.plantillas);
        alert(JSON.stringify(response.data.resultado));
        //location.reload();
      } catch (error) {
        console.error(error); // Handle any errors that might occur
      }
    },
    addPlantilla() {
      if (this.currentPlantilla !== "") {
        this.props.plantillas += " " + this.currentPlantilla;
        this.currentPlantilla = "";
      }
    },
  },
};
</script>
