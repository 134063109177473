import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import AboutView from '../views/AboutView.vue'
// import UsuariosTable from '../views/UsuariosTable.vue'
import InfoCuenta from '../views/InfoCuenta.vue'
// import FaltantesView from '../views/FaltantesView.vue'
import ProductosView from '../views/ProductosView.vue'
import PerfilesView from '../views/PerfilesView.vue'
import ClientesView from '../views/ClientesView.vue'
// import EnviosView from '../views/EnviosView.vue'
import StockView from '../views/StockView.vue'
import PreciosExistenciasView from "../views/PreciosExistenciasView.vue"
// import AreaDeTrabajoView from "../views/AreaDeTrabajoView.vue"
import ComprasView from "../views/ComprasView.vue"
import NewFaltantesView from '../views/NewFaltantesView.vue'
import MisClientesView from '../views/MisClientesView.vue'
import LotesView from '../views/LotesView.vue'
import PedidosView from '../views/PedidosView.vue'
import EditarLaboratorios from '../views/EditarLaboratoriosView.vue'
import ImagenesView from '../views/ImagenesView.vue'
import VisitasView from '../views/VisitasView.vue'
import TablaPivoteView from '../views/TablaPivoteView.vue'
// import VentasView from '../views/VentasView.vue'
import ReportePedidosView from '../views/ReportePedidosView.vue'
import UsuariosView from '../views/UsuariosView.vue'
import JSONView from '../views/JSONView.vue'
import NewEnviosView from '../views/NewEnviosView.vue'
import ReportesView from '@/views/ReportesView.vue'
import FacturasPendientes from '@/views/FacturasPendientesView.vue'
import EstadoDeCuenta from '@/views/EstadoDeCuenta.vue'
import ReporteCliente from '@/views/ReporteCliente.vue'
import ReporteSucursal from '@/views/ReporteSucursal.vue'
import AvanceView from '@/views/AvanceView.vue'
import ReporteLaboratorio from '@/views/ReporteLaboratorio.vue'

import axios from "axios";
Vue.use(VueRouter)

const NotFound = { template: "<div>not found</div>" };
const routes = [
    {
        path: '/home',
        name: 'Home',
        component: HomeView, LoginView,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/',
        name: 'Login',
        component: LoginView,
        meta: {
            requiresGuest: true,
        },
    },
        
    {
        path: '/about',
        name: 'About',
        component: AboutView,
    },
    // {
    //     path: '/usuarios',
    //     name: 'Usuarios',
    //     component: UsuariosTable,
    //     meta: {
    //         requiresAuth: true
    //     },
    //     beforeEnter: async (to, from, next) => {
    //         try {
    //             // Realizar llamada a la API
    //             const response = await axios.get('/usuarios/get_secciones');
    //             const secciones = response.data.secciones;
    //             let condicion = false;
    //             secciones.forEach(element => {
    //                 if(element === 'ADMINISTRADOR' || element === 'usuarios')
    //                 condicion = true;
    //             });
    //             // Verificar la condición basada en el resultado de la API
    //             if (condicion) {
    //                 next(); // Permite la navegación a la ruta original
    //             } else {
    //                 next('/home'); // Redirige a una nueva ruta
    //             }
    //         } catch (error) {
    //             console.error(error);
    //             next('/home'); // Redirige a una página de error en caso de error de la API
    //         }
    //     },
    // },
    {
        path: '/usuarios',
        name: 'Usuarios',
        component: UsuariosView,
        meta: {
            requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
            try {
                // Realizar llamada a la API
                const response = await axios.get('/usuarios/get_secciones');
                const secciones = response.data.secciones;
                let condicion = false;
                secciones.forEach(element => {
                    if(element === 'ADMINISTRADOR' || element === 'usuarios')
                    condicion = true;
                });
                // Verificar la condición basada en el resultado de la API
                if (condicion) {
                    next(); // Permite la navegación a la ruta original
                } else {
                    next('/home'); // Redirige a una nueva ruta
                }
            } catch (error) {
                console.error(error);
                next('/home'); // Redirige a una página de error en caso de error de la API
            }
        },
    },
    {
        path: '/cuenta',
        name: 'Cuenta',
        component: InfoCuenta,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/editar_json',
        name: 'Editar_JSON',
        component: JSONView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reportes',
        name: 'Reportes',
        component: ReportesView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/faltantes',
        name: 'Faltantes',
        component: NewFaltantesView,
        meta: {
            requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
            try {
                // Realizar llamada a la API
                const response = await axios.get('/usuarios/get_secciones');
                const secciones = response.data.secciones;
                let condicion = false;
                secciones.forEach(element => {
                    if(element === 'ADMINISTRADOR' || element === 'faltantes')
                    condicion = true;
                });
                // Verificar la condición basada en el resultado de la API
                if (condicion) {
                    next(); // Permite la navegación a la ruta original
                } else {
                    next('/home'); // Redirige a una nueva ruta
                }
            } catch (error) {
                console.error(error);
                next('/home'); // Redirige a una página de error en caso de error de la API
            }
        },
    },
    {
        path: '/precios_existencias',
        name: 'Precios_Existencias',
        component: PreciosExistenciasView,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/reporte_cliente',
        name: 'Reporte_Cliente',
        component: ReporteCliente,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/reporte_sucursal',
        name: 'Reporte_Sucursal',
        component: ReporteSucursal,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/avance',
        name: 'Avance',
        component: AvanceView,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/reporte_laboratorio',
        name: 'Reporte_Laboratorio',
        component: ReporteLaboratorio,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/compras',
        name: 'Compras',
        component: ComprasView,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/lotes',
        name: 'Lotes',
        component: LotesView,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/pedidos',
        name: 'Pedidos',
        component: PedidosView,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/tabla_pivote',
        name: 'Tabla_Pivote',
        component: TablaPivoteView,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/reportes_pedidos',
        name: 'Reportes_Pedidos',
        component: ReportePedidosView,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/facturas_pendientes',
        name: 'Facturas_Pendientes',
        component: FacturasPendientes,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/estado_de_cuenta',
        name: 'Estado_de_Cuenta',
        component: EstadoDeCuenta,
        meta: {
            requiresAuth: true
        },
    },
    // {
    //     path: '/ventas',
    //     name: 'Ventas',
    //     component: VentasView,
    //     meta: {
    //         requiresAuth: true
    //     },
    // },
    {
        path: '/visitas',
        name: 'Visitas',
        component: VisitasView,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/imagenes',
        name: 'Imagenes',
        component: ImagenesView,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/editar_laboratorios',
        name: 'Editar_Laboratorios',
        component: EditarLaboratorios,
        meta: {
            requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
            try {
                // Realizar llamada a la API
                const response = await axios.get('/usuarios/get_secciones');
                const secciones = response.data.secciones;
                let condicion = false;
                secciones.forEach(element => {
                    if(element === 'ADMINISTRADOR')
                    condicion = true;
                });
                // Verificar la condición basada en el resultado de la API
                if (condicion) {
                    next(); // Permite la navegación a la ruta original
                } else {
                    next('/home'); // Redirige a una nueva ruta
                }
            } catch (error) {
                console.error(error);
                next('/home'); // Redirige a una página de error en caso de error de la API
            }
        },
    },
    {
        path: '/mis_clientes',
        name: 'Mis_Clientes',
        component: MisClientesView,
        meta: {
            requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
            try {
                // Realizar llamada a la API
                const response = await axios.get('/usuarios/get_secciones');
                const secciones = response.data.secciones;
                let condicion = false;
                secciones.forEach(element => {
                    if(element === 'ADMINISTRADOR' || element === 'mis_clientes')
                    condicion = true;
                });
                // Verificar la condición basada en el resultado de la API
                if (condicion) {
                    next(); // Permite la navegación a la ruta original
                } else {
                    next('/home'); // Redirige a una nueva ruta
                }
            } catch (error) {
                console.error(error);
                next('/home'); // Redirige a una página de error en caso de error de la API
            }
        },
    },
    {
        path: '/clientes',
        name: 'Clientes',
        component: ClientesView,
        meta: {
            requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
            try {
                // Realizar llamada a la API
                const response = await axios.get('/usuarios/get_secciones');
                const secciones = response.data.secciones;
                let condicion = false;
                secciones.forEach(element => {
                    if(element === 'ADMINISTRADOR' || element === 'clientes')
                    condicion = true;
                });
                // Verificar la condición basada en el resultado de la API
                if (condicion) {
                    next(); // Permite la navegación a la ruta original
                } else {
                    next('/home'); // Redirige a una nueva ruta
                }
            } catch (error) {
                console.error(error);
                next('/home'); // Redirige a una página de error en caso de error de la API
            }
        },
    },
    {
        path: '/productos',
        name: 'Productos',
        component: ProductosView,
        meta: {
            requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
            try {
                // Realizar llamada a la API
                const response = await axios.get('/usuarios/get_secciones');
                const secciones = response.data.secciones;
                let condicion = false;
                secciones.forEach(element => {
                    if(element === 'ADMINISTRADOR' || element === 'productos')
                    condicion = true;
                });
                // Verificar la condición basada en el resultado de la API
                if (condicion) {
                    next(); // Permite la navegación a la ruta original
                } else {
                    next('/home'); // Redirige a una nueva ruta
                }
            } catch (error) {
                console.error(error);
                next('/home'); // Redirige a una página de error en caso de error de la API
            }
        },
    },
    {
        path: '/perfiles',
        name: 'Perfiles',
        component: PerfilesView,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/envios',
        name: 'Envíos',
        component: NewEnviosView,
        meta: {
            requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
            try {
                // Realizar llamada a la API
                const response = await axios.get('/usuarios/get_secciones');
                const secciones = response.data.secciones;
                let condicion = false;
                secciones.forEach(element => {
                    if(element === 'ADMINISTRADOR' || element === 'envios')
                    condicion = true;
                });
                // Verificar la condición basada en el resultado de la API
                if (condicion) {
                    next(); // Permite la navegación a la ruta original
                } else {
                    next('/home'); // Redirige a una nueva ruta
                }
            } catch (error) {
                console.error(error);
                next('/home'); // Redirige a una página de error en caso de error de la API
            }
        },
    },
    {
        path: '/stock',
        name: 'Stock',
        component: StockView,
        meta: {
            requiresAuth: true
        },
        beforeEnter: async (to, from, next) => {
            try {
                // Realizar llamada a la API
                const response = await axios.get('/usuarios/get_secciones');
                const secciones = response.data.secciones;
                let condicion = false;
                secciones.forEach(element => {
                    if(element === 'ADMINISTRADOR' || element === 'reportes')
                    condicion = true;
                });
                // Verificar la condición basada en el resultado de la API
                if (condicion) {
                    next(); // Permite la navegación a la ruta original
                } else {
                    next('/home'); // Redirige a una nueva ruta
                }
            } catch (error) {
                console.error(error);
                next('/home'); // Redirige a una página de error en caso de error de la API
            }
        },
    },
    { path: "*", component: NotFound },
    
]

export default new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
}) 

