<template>
  <v-container v-if="user">
    <form ref="form" @submit.prevent="getStock()">
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        <v-row class="ml-1">Stock</v-row>
        <v-btn type="submit" :loading="isLoading" text> Enviar </v-btn>
      </v-card-title>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="4"
          ><v-text-field
            @keyup="toUpperCase"
            v-model="codigo_producto"
            label="Código de producto"
            required
          >
          </v-text-field
        ></v-col>
        <v-col cols="4"
          ><v-text-field v-model="dias_envio" label="Días de envío" required>
          </v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col
              v-for="(dataTable, index) in dataTables"
              :key="index"
              cols="12"
              md="6"
              lg="4"
            >
              <v-card :title="codigo_producto">
                <v-btn
                  class="styled-button"
                  icon
                  value="log in"
                  color="blue darken-1"
                  text
                  @click="eliminarTabla(dataTable.id)"
                >
                  <v-icon dark>mdi-close</v-icon>
                </v-btn>
                <v-card-title>{{
                  dataTable.title + "-" + dataTable.name
                }}</v-card-title>
                <v-card-text>
                  <div id="chart">
                    <apexchart
                      type="bar"
                      height="350"
                      :options="dataTable.chartOptions"
                      :series="dataTable.series"
                    ></apexchart>
                  </div>
                  <v-row class="texto-centrado">
                    <div style="margin: 0 auto; text-align: center">
                      <strong>Stock:</strong>
                      <span
                        :class="{
                          positive: getStockTotal(dataTable.id) > 0,
                          negative: getStockTotal(dataTable.id) < 0,
                        }"
                      >
                        {{ getStockTotal(dataTable.id) }}
                      </span>
                    </div>
                  </v-row>

                  <v-row>
                    <v-col style="margin: 0 auto; text-align: center">
                      <strong>Existencia:</strong>
                      <span>
                        {{ getExistenciaTotal(dataTable.id) }}
                      </span>
                    </v-col>
                    <v-col style="margin: 0 auto; text-align: center">
                      <strong>Pronóstico:</strong>
                      <span>
                        {{ getPronosticoTotal(dataTable.id) }}
                      </span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col style="margin: 0 auto; text-align: center">
                      <strong>En camino:</strong>
                      <span>
                        {{ getEnCaminoTotal(dataTable.id) }}
                      </span>
                    </v-col>
                    <v-col style="margin: 0 auto; text-align: center">
                      <strong>Faltantes:</strong>
                      <span>
                        {{ getFaltantesTotal(dataTable.id) }}
                      </span>
                    </v-col>
                  </v-row>

                  <!-- <div v-if="dataTable.showTable">
                    <v-data-table
                      hide-default-footer
                      :items="dataTable.items"
                      :headers="dataTable.headers"
                    >
                      <template v-slot:[`item.pronostico`]="{ item }">
                        {{ item.pronostico.toFixed(2) }}
                      </template>
                      <template v-slot:[`item.stock_piezas`]="{ item }">
                        {{ item.stock_piezas.toFixed(2) }}
                      </template>
                    </v-data-table>
                  </div> -->
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    class="mb-3 ml-2"
                    @click="dataTable.showTable = !dataTable.showTable"
                    >{{
                      dataTable.showTable ? "Ocultar tabla" : "Mostrar Tabla"
                    }}</v-btn
                  >
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="dataTable.showTable">
                    <v-divider></v-divider>

                    <v-data-table
                      hide-default-footer
                      :items="dataTable.items"
                      :headers="dataTable.headers"
                    >
                      <template v-slot:[`item.pronostico`]="{ item }">
                        {{ item.pronostico.toFixed(2) }}
                      </template>
                      <template v-slot:[`item.stock_piezas`]="{ item }">
                        {{ item.stock_piezas.toFixed(2) }}
                      </template>
                    </v-data-table>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </form>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    items: [],
    itemsByLocation: [],
    stockData: [],
    codigo_producto: "",
    dias_envio: "10",
    dataTables: [],
    barcharts: [],
    series: [],
    seriesChart: [],
    locationsChart: [],
    showTable: false,
    idTable: 0,
    isLoading: false,
  }),
  methods: {
    getFaltantesTotal(idTabla) {
      const tablaEncontrada = this.dataTables.find(
        (tabla) => tabla.id === idTabla
      );

      if (tablaEncontrada !== undefined) {
        const indice = this.dataTables.indexOf(tablaEncontrada);
        let totales = 0;
        this.dataTables[indice].items.forEach((sucursal) => {
          totales = sucursal.faltantes + totales;
        });

        return totales.toFixed(2);
      }

      return "No hay datos";
    },
    getPronosticoTotal(idTabla) {
      const tablaEncontrada = this.dataTables.find(
        (tabla) => tabla.id === idTabla
      );

      if (tablaEncontrada !== undefined) {
        const indice = this.dataTables.indexOf(tablaEncontrada);
        let totales = 0;
        this.dataTables[indice].items.forEach((sucursal) => {
          totales = sucursal.pronostico + totales;
        });

        return totales.toFixed(2);
      }

      return "No hay datos";
    },
    getEnCaminoTotal(idTabla) {
      const tablaEncontrada = this.dataTables.find(
        (tabla) => tabla.id === idTabla
      );

      if (tablaEncontrada !== undefined) {
        const indice = this.dataTables.indexOf(tablaEncontrada);
        let totales = 0;
        this.dataTables[indice].items.forEach((sucursal) => {
          totales = sucursal.en_camino + totales;
        });

        return totales.toFixed(2);
      }

      return "No hay datos";
    },
    getExistenciaTotal(idTabla) {
      const tablaEncontrada = this.dataTables.find(
        (tabla) => tabla.id === idTabla
      );

      if (tablaEncontrada !== undefined) {
        const indice = this.dataTables.indexOf(tablaEncontrada);
        let totales = 0;
        this.dataTables[indice].items.forEach((sucursal) => {
          totales = sucursal.existencia + totales;
        });

        return totales.toFixed(2);
      }

      return "No hay datos";
    },
    getStockTotal(idTabla) {
      const tablaEncontrada = this.dataTables.find(
        (tabla) => tabla.id === idTabla
      );

      if (tablaEncontrada !== undefined) {
        const indice = this.dataTables.indexOf(tablaEncontrada);
        let totales = 0;
        this.dataTables[indice].items.forEach((sucursal) => {
          totales = sucursal.stock_piezas + totales;
        });
        if (totales > 0) {
          return "+" + totales.toFixed(2);
        } else {
          return totales.toFixed(2);
        }
      }

      return "No hay datos";
    },
    eliminarTabla(idTabla) {
      const tablaEncontrada = this.dataTables.find(
        (tabla) => tabla.id === idTabla
      );

      if (tablaEncontrada !== undefined) {
        const indice = this.dataTables.indexOf(tablaEncontrada);
        this.dataTables.splice(indice, 1);
      }
    },
    toUpperCase() {
      this.codigo_producto = this.codigo_producto.toUpperCase();
    },
    async getStock() {
      this.isLoading = true;
      try {
        const response = await axios.get(
          `/reportes/get_stock?codigo_producto=${this.codigo_producto}&dias_envios=${this.dias_envio}`
        );
        this.items = Array(response.data);
        this.itemsByLocation = [];
        this.idTable = this.idTable + 1;

        const locations = [
          {
            text: "MXL",
            value: "Mexicali",
            total: "",
          },
          {
            text: "TIJ",
            value: "Tijuana",
            total: "",
          },
          {
            text: "ENS",
            value: "Ensenada",
            total: "",
          },
          {
            text: "LPZ",
            value: "LaPaz",
            total: "",
          },
          {
            text: "OBR",
            value: "Obregon",
            total: "",
          },
          {
            text: "HMO",
            value: "Hermosillo",
            total: "",
          },
        ];
        locations.forEach((location) => {
          this.itemsByLocation.push(this.items[0][location.value]);
        });

        const existenciaData = [];
        const enCaminoData = [];
        const pronosticoData = [];
        const faltantesData = [];

        locations.forEach((location) => {
          const stockDataByLocation = response.data[location.value];
          const totalSucursal =
            stockDataByLocation.existencia +
            stockDataByLocation.en_camino -
            (stockDataByLocation.pronostico + stockDataByLocation.faltantes);
          existenciaData.push(stockDataByLocation.existencia.toFixed(2));
          enCaminoData.push(stockDataByLocation.en_camino.toFixed(2));
          pronosticoData.push(stockDataByLocation.pronostico.toFixed(2));
          faltantesData.push(stockDataByLocation.faltantes.toFixed(2));
          location.total = totalSucursal.toFixed(2); // set the total value for the location
        });

        this.locationsChart = [...locations];

        this.seriesChart = [
          {
            name: "Existencia",
            group: "budget",
            data: existenciaData,
          },
          {
            name: "En camino",
            group: "budget",
            data: enCaminoData,
          },
          {
            name: "Pronóstico",
            group: "actual",
            data: pronosticoData,
          },
          {
            name: "Faltantes",
            group: "actual",
            data: faltantesData,
          },
        ];

        this.addDataTable();
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.isLoading = false;
        alert("Producto no encontrado.");
      }
    },
    addDataTable() {
      const title = this.codigo_producto;
      // Generate a new data table and add it to the dataTables array
      const newDataTable = {
        headers: [
          {
            text: "Sucursal",
            value: "sucursal",
          },
          {
            text: "Existencia",
            value: "existencia",
          },
          {
            text: "Pronóstico",
            value: "pronostico",
          },
          {
            text: "En Camino",
            value: "en_camino",
          },
          {
            text: "Faltantes",
            value: "faltantes",
          },
          {
            text: "Piezas en Stock",
            value: "stock_piezas",
          },
        ],
        title,
        items: this.itemsByLocation,
        name: this.items[0].nombre,
        id: this.idTable,
        chartOptions: {
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          dataLabels: {},
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          xaxis: {
            categories: this.locationsChart.map((data) => {
              if (data.total > 0) {
                return `${data.text} : +${data.total}`;
              } else {
                return `${data.text} : ${data.total}`;
              }
            }),
            labels: {},
          },
          yaxis: {
            labels: {
              style: {
                colors: this.locationsChart.map((data) => {
                  return data.total >= 0 ? "blue" : "red"; // set color based on value of total
                }),
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 400,
                cssClass: "apexcharts-yaxis-label",
              },
            },
          },
          fill: {
            opacity: 1,
          },
          colors: ["#8CEE95", "#66B5E3", "#F0B65A", "#E13B30"],
          legend: {
            position: "top",
            horizontalAlign: "left",
          },
          title: {
            text: "Gráfico",
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: undefined,
              color: "#263238",
            },
          },
        },
        series: this.seriesChart,
        showTable: false,
      };
      this.dataTables.push(newDataTable);
      this.itemsByLocation = [];
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    codigo_producto_uppercase() {
      return this.codigo_producto.toUpperCase();
    },
  },
};
</script>

<style scoped>
/* Style for v-card */
.texto-centrado {
  font-size: 16px;
  text-align: center;
}
.v-card {
  width: 500px;
  margin: 10px;
}
.styled-button {
  padding: 20px;
}

.positive {
  color: blue;
}

.negative {
  color: red;
}
</style>
