<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>Historial</h2>
        <!-- <strong>Items:</strong> {{ JSON.stringify(items) }} -->
        <v-divider class="mt-2"></v-divider>
        <div v-if="items.length === 0">
          Este {{ objeto }} no cuenta con historial
        </div>
        <div
          v-else-if="
            objeto === 'faltante' || objeto === 'envio' || objeto === 'pedido'
          "
        >
          <v-list>
            <v-list-item v-for="(data, index) in itemsInverso" :key="data.id">
              <v-card class="styled-card mt-3">
                <v-list-item-content>
                  <v-list-item-title>
                    <strong>Tipo:</strong>
                    <span
                      v-html="data.funcion"
                      style="white-space: pre-line"
                    ></span>
                    <!-- {{ data.funcion }} -->
                  </v-list-item-title>
                  <v-list-item-title>
                    <strong>Realizado por:</strong>
                    <span
                      v-html="getVendedor(data.usuario)"
                      style="white-space: pre-line"
                    ></span>
                    <!-- {{ getVendedor(data.usuario) }} -->
                  </v-list-item-title>
                  <v-list-item-title>
                    <strong>Fecha:</strong>
                    <span
                      v-html="getFechaLegible(data.fecha_creacion)"
                      style="white-space: pre-line"
                    ></span>
                    <!-- {{ getFechaLegible(data.fecha_creacion) }} -->
                  </v-list-item-title>
                  <div v-if="index < itemsInverso.length - 1">
                    <v-divider class="my-2"></v-divider>
                    <strong>Cambios</strong>
                    <span
                      class="formatted-text"
                      style="white-space: pre-line"
                      v-html="
                        getFormattedCambios(
                          itemsInverso[index + 1].objeto,
                          data.objeto
                        )
                      "
                    ></span>
                  </div>
                </v-list-item-content>
              </v-card>
            </v-list-item>
          </v-list>
        </div>

        <!-- <div v-else-if="objeto === 'envio'">
          <v-list>
            <v-list-item v-for="(data, index) in itemsInverso" :key="data.id">
              <v-card class="styled-card mt-3">
                <v-list-item-content>
                  <v-list-item-title>
                    <strong>Tipo:</strong> {{ data.funcion }}
                  </v-list-item-title>
                  <v-list-item-title>
                    <strong>Realizado por:</strong>
                    {{ getVendedor(data.usuario) }}
                  </v-list-item-title>
                  <v-list-item-title>
                    <strong>Fecha:</strong>
                    {{ getFechaLegible(data.fecha_creacion) }}
                  </v-list-item-title>
                  <div v-if="index < itemsInverso.length - 1">
                    <v-divider class="my-2"></v-divider>
                    <strong>Cambios</strong>
                    <span
                      class="formatted-text"
                      style="white-space: pre-line"
                      v-html="
                        getFormattedCambios(
                          itemsInverso[index + 1].objeto,
                          data.objeto
                        )
                      "
                    ></span>
                  </div>
                </v-list-item-content>
              </v-card>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <div v-if="items.length != 0">
            <div
              class="mt-2"
              v-for="(valor, propiedad) in JSON.parse(items[0].objeto)"
              :key="propiedad"
            >
              <strong>{{ propiedad }}:</strong> {{ valor }}
            </div>
          </div>
        </div> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "HistorialObjeto",
  props: ["item", "usuarios"],
  data() {
    return {
      items: [],
      objeto: "",
      id_objeto: this.item.id,
    };
  },
  methods: {
    getFormattedCambios(objeto1, objeto2) {
      const cambios = this.getCambiosRealizados(objeto1, objeto2);
      const formattedCambios = cambios.replace(
        /(.*?):/g,
        '<span class="highlight">$1:</span>'
      );
      return formattedCambios;
    },
    getCambiosRealizados(string1, string2) {
      const obj1 = JSON.parse(string1);
      const obj2 = JSON.parse(string2);
      // console.log(obj1);
      // console.log(obj2);

      const difference = {};

      // Compara las propiedades de los objetos
      for (let key in obj1) {
        if (obj1[key] !== obj2[key] && key !== "productos") {
          let value = obj2[key];
          if (typeof value === "boolean") {
            value = value ? "Sí" : "No";
          }
          let originalValue = obj1[key];
          if (typeof originalValue === "boolean") {
            originalValue = originalValue ? "Sí" : "No";
          }

          difference[key] = `se cambió de ${originalValue} a ${value}`;
        }
      }

      const differenceString = JSON.stringify(difference, null, 2).replace(
        /"([^"]+)": "(.*?)"/g,
        "$1: $2"
      );

      if (differenceString === "{}") {
        return "\nSin cambios";
      }

      return differenceString.slice(1, -1);
    },
    getVendedor(vendedorId) {
      const vendedorEncontrado = this.usuarios.find(
        (usuario) => usuario.id === vendedorId
      );
      if (vendedorEncontrado) {
        return vendedorEncontrado.nombre + " ";
      }
      return "";
    },
    async getHistorialObjeto() {
      const response = await axios.get(
        `/varios/get_historial_objeto?tipo_objeto=${this.objeto}&id_objeto=${this.id_objeto}`
      );
      this.items = response.data.historial;
    },
    checkObjectType() {
      if (
        typeof this.item.lista_productos !== "undefined" &&
        typeof this.item.transportista !== "undefined"
      ) {
        // Check if the item object has the properties "sucursal" and "envios"
        this.objeto = "envio";
      } else if (
        typeof this.item.id !== "undefined" &&
        typeof this.item.nombre !== "undefined" &&
        typeof this.item.cliente !== "undefined"
      ) {
        // Check if the item object has the properties "id", "nombre", and "cliente"
        this.objeto = "faltante";
      } else if (
        typeof this.item.id !== "undefined" &&
        typeof this.item.productos !== "undefined" &&
        typeof this.item.cliente !== "undefined"
      ) {
        // Check if the item object has the properties "id", "productos", and "cliente"
        this.objeto = "pedido";
      } else {
        // Default case if the item object doesn't match any expected format
        this.objeto = "unknown";
      }
    },
    getFechaLegible(fechaLarga) {
      const fecha = new Date(fechaLarga);

      const año = fecha.getFullYear();
      const mes = fecha.getMonth() + 1;
      const dia = fecha.getDate();
      const hora = fecha.getHours();
      const minutos = fecha.getMinutes().toString().padStart(2, "0");

      const fechaLegible = `${dia}/${mes}/${año}`;
      const horaLegible = `${hora}:${minutos}`;

      return `${fechaLegible} ${horaLegible}`;
    },
  },
  computed: {
    itemsInverso() {
      return this.items.slice().reverse();
    },
  },
  mounted() {
    this.checkObjectType();
    this.getHistorialObjeto();
  },
};
</script>

<style>
.styled-card {
  width: 100%;
  padding: 10px;
}

.formatted-text {
  font-size: 16px;
}

.highlight {
  color: teal;
  font-size: 17px;
}
</style>
