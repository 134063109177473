<template>
  <v-dialog max-width="70%" v-model="dialog">
    <v-card>
      <v-container>
        <v-card-title>
          Agregar imágen
          <v-spacer />
          <v-btn
            class="close-btn"
            icon
            value="log in"
            color="blue darken-1"
            text
            @click="cerrar()"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-file-input
                  v-model="imagen"
                  counter
                  outlined
                  label="Imágen"
                  :rules="nameRules"
                  required
                  show-size
                  small-chips
                  accept="image/png, image/jpeg, image/bmp"
                  truncate-length="13"
                  append-icon="mdi-camera"
                  prepend-icon=""
                ></v-file-input>
              </v-row>
              <v-row>
                <div v-if="previewUrl">
                  <v-row>
                    <h3 class="ml-4">Vista previa de imágen:</h3>
                  </v-row>

                  <v-row class="mb-3">
                    <v-col>
                      <img
                        :src="previewUrl"
                        alt="Vista previa de la imagen"
                        style="max-width: 200px"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-row>
              <v-btn
                class="mt-3"
                :disabled="!valid"
                @click="agregarImagen"
                color="teal lighten-2"
                text
                >Agregar imágen</v-btn
              >
            </v-form>
          </v-container>
        </v-card-text>
      </v-container>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-2" v-on="on" v-bind="attrs">Agregar imágen</v-btn>
    </template>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: [],
  data() {
    return {
      valid: true,
      dialog: false,
      imagen: null,
      previewUrl: null,
      rules: {
        required: (value) => !!value || "Debes agregar una imágen",
      },
      nameRules: [(v) => !!v || "Debes agregar una imágen"],
    };
  },
  watch: {
    imagen(newFile) {
      this.generatePreviewUrl(newFile);
    },
  },
  methods: {
    async agregarImagen() {
      if (this.$refs.form.validate()) {
        try {
          // Crea un objeto FormData para enviar la imagen al backend
          const formData = new FormData();
          formData.append("file", this.imagen);

          // Realiza la solicitud POST a tu endpoint de backend utilizando Axios
          const response = await axios.post("/imagenes/agregar", formData, {
            headers: {
              "Content-Type": "multipart/form-data", // Importante: Indica que estás enviando un archivo
            },
          });

          // Maneja la respuesta del servidor si es necesario
          console.log(response.data);
          alert("Imágen agregada");
          this.$refs.form.reset();
          this.cerrar();
          this.$emit("imagenesCambiadas");
        } catch (error) {
          // Maneja los errores de la subida de imagen
          alert("Error al subir la imagen:", error);
          console.error("Error al subir la imagen:", error);
        }
      }
    },
    generatePreviewUrl(file) {
      if (!file) {
        this.previewUrl = null;
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        this.previewUrl = reader.result;
      };
      reader.readAsDataURL(file);
    },
    cerrar() {
      this.$refs.form.resetValidation();
      this.dialog = !this.dialog;
    },
  },
  computed: {},
  mounted() {
    // Cargar objetos desde el almacenamiento local al iniciar el componente
  },
  created() {},
};
</script>

<style>
.close-btn {
  float: right;
}
</style>
