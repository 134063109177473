<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Reporte ventas cliente
        <v-spacer></v-spacer>
        <!-- <v-btn @click="verData" class="mr-2" color="primary">data</v-btn> -->
      </v-card-title>
      <v-row class="mx-2 mt-1">
        <v-col cols="12" lg="3" md="3">
          <v-select
            v-model="sucursalSelected"
            :items="sucursales"
            item-value="value"
            label="Sucursal"
            placeholder="Selecciona sucursal"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="5" md="4">
          <v-autocomplete
            :items="itemsFiltrados"
            v-model="clienteSelected"
            item-text="razon_social"
            :return-object="true"
            label="Cliente"
            no-data-text="No hay resultados"
            :filter="customFilter"
            @change="clienteSeleccionado"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.razon_social"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.codigo"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-btn @click="getReporte" small class="mt-5" :loading="loadingBtn"
            >Buscar</v-btn
          >
        </v-col>
      </v-row>
      <!-- Grafico por mes y trimestre -->
      <v-row v-if="dataCliente.length > 0" justify="center" class="mx-2 mt-1">
        <v-col cols="12" xl="8">
          <VentasPorMes
            :chartSeries="datosChartMes"
            :datosDiferencia="datosDiferenciaMes"
          ></VentasPorMes>
        </v-col>
        <v-col cols="12" xl="8">
          <VentasPorTrimestre
            :chartSeries="datosChartTrimestre"
            :datosDiferencia="datosDiferenciaTrimestre"
          >
          </VentasPorTrimestre>
        </v-col>
      </v-row>
      <!-- Tabla de comparacion -->
      <v-row v-if="dataCliente.length > 0" justify="center" class="mx-5 mt-5">
        <v-col cols="12" md="8">
          <v-card class="pa-2">
            <v-card-title> Comparación de laboratorios </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="auto">
                  <v-select
                    v-model="selectedMonth"
                    :items="meses"
                    label="Mes"
                  ></v-select>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="dataComparacion"
                :items-per-page="-1"
                hide-default-footer
                no-data-text="No hay resultados"
              >
                <template v-slot:[`item.lastYear`]="{ item }">
                  {{ "$" + formatoPrecio(item.lastYear) }}
                </template>
                <template v-slot:[`item.actualYear`]="{ item }">
                  {{ "$" + formatoPrecio(item.actualYear) }}
                </template>
                <template v-slot:[`item.diferencia`]="{ item }">
                  <strong
                    :class="
                      item.diferencia < 0
                        ? 'red--text text--lighten-1'
                        : 'green--text text--lighten-1'
                    "
                    >{{ "$" + formatoPrecio(item.diferencia) }}</strong
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import pako from "pako";
import Papa from "papaparse";
import VentasPorMes from "../components/VentasPorMes.vue";
import VentasPorTrimestre from "../components/VentasPorTrimestre.vue";
export default {
  data() {
    return {
      items: [],
      dataCliente: [],
      datosFiltrados: [],
      datosChartMes: [],
      datosChartTrimestre: [],
      datosCompLab: [],
      dataComp: [],

      datosDiferenciaMes: Array(12).fill(0),
      datosDiferenciaTrimestre: Array(4).fill(0),

      fechaFin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
      currentYear: new Date().getFullYear(),
      clienteSelected: {},
      sucursalSelected: "Mexicali",
      selectedMonth: new Date().getMonth() + 1,
      dialog: false,
      headers: [],
      meses: [
        {
          text: "Enero",
          value: 1,
        },
        {
          text: "Febrero",
          value: 2,
        },
        {
          text: "Marzo",
          value: 3,
        },
        {
          text: "Abril",
          value: 4,
        },
        {
          text: "Mayo",
          value: 5,
        },
        {
          text: "Junio",
          value: 6,
        },
        {
          text: "Julio",
          value: 7,
        },
        {
          text: "Agosto",
          value: 8,
        },
        {
          text: "Septiembre",
          value: 9,
        },
        {
          text: "Octubre",
          value: 10,
        },
        {
          text: "Noviembre",
          value: 11,
        },
        {
          text: "Diciembre",
          value: 12,
        },
      ],
      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      loadingBtn: false,
      //   itemsFiltrados: [],
    };
  },
  methods: {
    mesSeleccionado() {
      console.log(this.selectedMonth);
    },
    async getReporte() {
      this.loadingBtn = true;
      let totalCurrentYear = Array(12).fill(0);
      let totalLastYear = Array(12).fill(0);
      let totalCurrentYearTrimestre = Array(4).fill(0);
      let totalLastYearTrimestre = Array(4).fill(0);
      this.datosChartMes = [];
      let labsImportantes = [];

      try {
        const response = await axios.get(
          `/varios/get_json?nombre=laboratorios_importantes`
        );

        // console.log(response.data);
        if (response.data.objeto.lista.length > 0) {
          labsImportantes = response.data.objeto.lista;
        }
      } catch (error) {
        console.error("Error al obtener los laboratorios importantes:", error);
      }

      try {
        const response = await axios.get(
          `/reportes/pivot_table?fecha_inicial=${
            this.currentYear - 1
          }-01-01&fecha_final=${this.fechaFin}&sucursales=${
            this.sucursalSelected
          }&indice=codigo&valor=neto-desc&filtros=codigo_cliente=${
            this.clienteSelected.codigo
          }`,
          {
            responseType: "arraybuffer", // se especifica el tipo de respuesta
          }
        );

        // Descomprimir el archivo gzip
        const decompressedData = pako.ungzip(response.data, { to: "string" });

        // Leer el archivo CSV
        const csvData = Papa.parse(decompressedData, {
          header: true, // si tu archivo CSV tiene encabezados
          dynamicTyping: true, // para convertir automáticamente los campos numéricos a números
        });
        const data = csvData.data;
        data.pop();
        // console.log("DATA", data);
        this.dataCliente = data;

        if (data.length > 0) {
          //Primero se filtra el array de data para obtener solo los datos de este año y el anterior y si es laboratorio importante, y obtenemos solo los valores que nos interesan
          this.datosFiltrados = data
            .filter(
              (item) =>
                labsImportantes.includes(item.laboratorio) &&
                (item.year === this.currentYear - 1 ||
                  item.year === this.currentYear)
            )
            .map((item) => ({
              year: item.year,
              mes: item.mes,
              "neto-desc": item["neto-desc"],
              laboratorio: item.laboratorio,
            }));

          // console.log("DATOS FILTRADOS", this.datosFiltrados);

          //Dependiendo el año y el mes del dato, se suma su valor al total y se agrega al array correspondiente
          //Dependiendo el año y el trimestre del dato, se suma su valor al total y se agrega al array correspondiente
          this.datosFiltrados.forEach((item) => {
            const trimestre = Math.floor((item.mes - 1) / 3); // Calcula el trimestre

            if (item.year === this.currentYear) {
              totalCurrentYear[item.mes - 1] = (
                parseFloat(totalCurrentYear[item.mes - 1]) +
                parseFloat(item["neto-desc"])
              ).toFixed(2);
              totalCurrentYearTrimestre[trimestre] += item["neto-desc"];
            } else if (item.year === this.currentYear - 1) {
              totalLastYear[item.mes - 1] = (
                parseFloat(totalLastYear[item.mes - 1]) +
                parseFloat(item["neto-desc"])
              ).toFixed(2);
              totalLastYearTrimestre[trimestre] += item["neto-desc"];
            }
          });

          // totalLastYear = [
          //   1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6, 5.1, 3.8, 4.5, 5.4,
          // ];
          // totalCurrentYear = [20, 29, 37, 36, 44, 45, 50, 58, 64, 56, 64, 71];

          this.datosChartMes = [
            {
              name: "Año anterior",
              type: "column",
              data: totalLastYear,
            },
            {
              name: "Año actual",
              type: "column",
              data: totalCurrentYear,
            },
          ];

          this.datosChartTrimestre = [
            {
              name: "Año anterior",
              data: totalLastYearTrimestre,
            },
            {
              name: "Año actual",
              data: totalCurrentYearTrimestre,
            },
          ];

          //Se calculan las diferencias entre el año actual y el año anterior de cada mes
          this.datosDiferenciaMes = totalCurrentYear.map((item, index) => {
            return Math.round(item - totalLastYear[index]);
          });

          //Se calculan las diferencias entre el trimestre actual y el trimestre del año anterior
          this.datosDiferenciaTrimestre = totalCurrentYearTrimestre.map(
            (item, index) => {
              return Math.round(item - totalLastYearTrimestre[index]);
            }
          );

          //Se agrupan los datos por laboratorio y año
          // const grouped = this.datosFiltrados.reduce((acc, datoVenta) => {
          //   const { laboratorio, year, "neto-desc": netoDesc } = datoVenta;
          //   if (!acc[laboratorio]) {
          //     acc[laboratorio] = {};
          //   }
          //   if (!acc[laboratorio][year]) {
          //     acc[laboratorio][year] = 0;
          //   }
          //   acc[laboratorio][year] += netoDesc;
          //   return acc;
          // }, {});

          // console.log("grouped", grouped);

          // const comparacion = Object.keys(grouped).map((laboratorio) => {
          //   const años = Object.keys(grouped[laboratorio])
          //     .map(Number)
          //     .sort((a, b) => a - b);
          //   const añoAnterior = grouped[laboratorio][años[0]] || 0;
          //   const añoActual = grouped[laboratorio][años[1]] || 0;
          //   return {
          //     laboratorio,
          //     añoAnterior,
          //     añoActual,
          //     diferencia: añoActual - añoAnterior,
          //   };
          // });

          // Asegurar que todos los laboratorios importantes estén en comparacion
          // labsImportantes.forEach((lab) => {
          //   if (!comparacion.some((comp) => comp.laboratorio === lab)) {
          //     comparacion.push({
          //       laboratorio: lab,
          //       añoAnterior: 0,
          //       añoActual: 0,
          //       diferencia: 0,
          //     });
          //   }
          // });

          // console.log("comp", comparacion);
          // this.datosCompLab = comparacion;

          let totalesArray = this.datosFiltrados.reduce((acc, venta) => {
            let lab = acc.find(
              (item) => item.laboratorio === venta.laboratorio
            );
            if (!lab) {
              lab = {
                laboratorio: venta.laboratorio,
                actualYear: new Array(12).fill(0),
                lastYear: new Array(12).fill(0),
              };
              acc.push(lab);
            }

            if (venta.year === this.currentYear) {
              lab.actualYear[venta.mes - 1] += venta["neto-desc"];
            } else if (venta.year === this.currentYear - 1) {
              lab.lastYear[venta.mes - 1] += venta["neto-desc"];
            }

            return acc;
          }, []);

          // Asegurar que todos los laboratorios importantes estén en comparacion
          labsImportantes.forEach((lab) => {
            if (
              !totalesArray.some((totalesLab) => totalesLab.laboratorio === lab)
            ) {
              totalesArray.push({
                laboratorio: lab,
                actualYear: new Array(12).fill(0),
                lastYear: new Array(12).fill(0),
              });
            }
          });

          this.dataComp = totalesArray;
          // console.log("totalesArray", totalesArray);

          this.loadingBtn = false;
        } else {
          this.loadingBtn = false;
          alert("No hay registros para mostrar");
        }
      } catch (error) {
        this.loadingBtn = false;
        alert(`Error al obtener los datos`);
        console.log(error.response.data);
      }
    },
    async getClientes() {
      try {
        const response = await axios.get(`/clientes/get_nombres`);
        this.items = response.data;
        // console.log(this.items);
      } catch (error) {
        console.error(error);
      }
    },
    cerrarDialog() {
      this.dialog = false;
    },
    clienteSeleccionado() {
      console.log(this.clienteSelected);
    },
    customFilter(item, queryText) {
      //Filtro para poder buscar cliente por razon social o codigo
      const textOne = item.razon_social.toLowerCase();
      const textTwo = item.codigo.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    formatoPrecio(precioProducto) {
      // Formatear el número utilizando una función personalizada
      const numero = parseFloat(precioProducto);
      // console.log(numero);
      let precio = 0;

      precio = numero.toFixed(2); // Limitar a 2 decimales
      const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
      const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

      // Formatear la parte entera con separador de miles
      const parteEnteraFormateada = parteEntera.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
    },
  },
  computed: {
    itemsFiltrados() {
      const itemsSucursal = this.items.filter(
        (cliente) => cliente.sucursal === this.sucursalSelected
      );
      return itemsSucursal;
    },
    dataComparacion() {
      const data = this.dataComp.map((item) => {
        const lab = {
          laboratorio: item.laboratorio,
          actualYear: item.actualYear[this.selectedMonth - 1],
          lastYear: item.lastYear[this.selectedMonth - 1],
          diferencia:
            item.actualYear[this.selectedMonth - 1] -
            item.lastYear[this.selectedMonth - 1],
        };

        return lab;
      });

      // console.log("Dataza", data);
      return data;
    },
    ...mapGetters(["user"]),
  },
  created() {
    this.getClientes();
    this.headers = [
      {
        text: "Laboratorio",
        value: "laboratorio",
      },
      {
        text: `${this.currentYear - 1}`,
        value: "lastYear",
        sortable: false,
      },
      {
        text: `${this.currentYear}`,
        value: "actualYear",
        sortable: false,
      },
      {
        text: "Diferencia",
        value: "diferencia",
        sortable: false,
      },
    ];
  },
  components: { VentasPorMes, VentasPorTrimestre },
};
</script>
