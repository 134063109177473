import axios from 'axios';


axios.defaults.baseURL = 'https://saux-api.centralexpress.info';
//axios.defaults.baseURL = 'http://192.168.8.200:8000';
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;

//codigo nuevo para evitar que se muestre un mensaje de error despues de cierto tiempo de inactividad
//+++++++Con este codigo marca error en el login, revisar el error y arreglar
// axios.interceptors.response.use(
//     function (response) {
//       // Código para manejar una respuesta exitosa
//       return response;
//     },
//     function (error) {
//       // Código para manejar un error en la respuesta
//       if (error.response && error.response.status === 401) {
//         // Redireccionar a la página de inicio de sesión
//         window.location.href = "/";
//       }
//       return Promise.reject(error);
//     }
//   );
  

//Codigo que se tenia antes (al parecer arroja error despues de cierto tiempo de inactividad) 
// // Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
    }, 
    (function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error && error.response && error.response.status === 401) {
            // redirect to login page
            window.location.href = "/";
        }
        return Promise.reject(error);
      }, 1000 * 60 * 60 ))

      //return Promise.reject(error);}, 1000 * 60 * 60 ))
      //se queda ciclado cuando solo retorn el error