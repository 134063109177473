<template>
  <v-container v-if="user">
    <v-card class="text-center accent-4">
      <form @submit.prevent="">
        <v-col>
          <v-card-title>Compras </v-card-title>
          <v-row>
            <v-col cols="3" class="ml-4">
              <v-text-field
                label="Fecha inicio"
                v-model="fecha_inicio"
                placeholder="AAAA-MM-DD"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div :elevation="0" dark v-bind="attrs" v-on="on">
                    <SeleccionarFecha @enviar-datos="selectFechaInicio" />
                  </div>
                </template>
                <span>Abrir calendario</span>
              </v-tooltip>
            </v-col>
            <v-col cols="3" class="ml-5">
              <v-text-field
                label="Fecha fin"
                v-model="fecha_fin"
                placeholder="AAAA-MM-DD"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div :elevation="0" dark v-bind="attrs" v-on="on">
                    <SeleccionarFecha @enviar-datos="selectFechaFin" />
                  </div>
                </template>
                <span>Abrir calendario</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row class="mb-1 ml-1">
            <div v-for="(sucursal, index) in sucursalesCheckbox" :key="index">
              <v-col>
                <label>
                  <input
                    type="checkbox"
                    v-model="checkboxValues"
                    :value="sucursal.value"
                  />
                  {{ sucursal.text }}
                </label>
              </v-col>
            </div>
          </v-row>
        </v-col>
        <v-row>
          <v-col>
            <v-btn
              class="mr-2"
              type="submit"
              @click="selectFacturas"
              @focus="selectedOption = ''"
            >
              Compras
            </v-btn>
            <v-btn
              @click="selectProductos"
              @focus="selectedOption = ''"
              type="submit"
            >
              Productos
            </v-btn>
            <FacturasTable
              v-if="selectedOption === 'facturas'"
              :datos="datos"
            ></FacturasTable>
            <ProductosTable
              v-if="selectedOption === 'productos'"
              :datos="datos"
            >
            </ProductosTable>
          </v-col>
        </v-row>
      </form>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import FacturasTable from "@/components/FacturasTable.vue";
import ProductosTable from "@/components/ProductosTable.vue";
import SeleccionarFecha from "@/components/SeleccionarFecha.vue";
export default {
  data() {
    return {
      items: [],
      fecha_inicio: "",
      fecha_fin: "",
      selectedOption: "",
      datos: {},
      sucursalesCheckbox: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      checkboxValues: [
        "Mexicali",
        "Tijuana",
        "Ensenada",
        "LaPaz",
        "Obregon",
        "Hermosillo",
      ],
    };
  },
  methods: {
    selectProductos() {
      this.datos = {
        fechaInicio: this.fecha_inicio,
        fechaFin: this.fecha_fin,
        sucursales: this.checkboxValues,
      };
      this.selectedOption = "productos";
    },
    selectFacturas() {
      this.datos = {
        fechaInicio: this.fecha_inicio,
        fechaFin: this.fecha_fin,
        sucursales: this.checkboxValues,
      };
      this.selectedOption = "facturas";
    },
    selectFechaInicio(fechaInicio) {
      this.fecha_inicio = fechaInicio;
      this.mostrarHijo = false;
    },
    selectFechaFin(fechaFin) {
      this.fecha_fin = fechaFin;
      this.mostrarHijo = false;
    },
    async getProductos() {
      try {
        const response = await axios.get(`/productos/get`);
        this.items = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  components: { FacturasTable, ProductosTable, SeleccionarFecha },
  created() {},
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
