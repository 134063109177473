<template>
  <v-card>
    <v-container>
      <v-btn
        class="absolute top right"
        icon
        color="blue darken-1"
        text
        @click="cerrar()"
      >
        <v-icon dark> mdi-close </v-icon>
      </v-btn>
      <v-card-text>
        <v-container>
          <v-row class="justify-center">
            <v-card-title>
              {{ item.razon_social }}
            </v-card-title>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="giros"
                :items="items"
                small-chips
                label="Giros"
                multiple
                clearable
                deletable-chips
              ></v-select>
            </v-col>
          </v-row>
          <v-btn @click="modificarGiros" color="teal lighten-2" text
            >Modificar</v-btn
          >
        </v-container>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "AgregarLatitudLongitud",
  props: ["item"],
  data() {
    return {
      giros: this.item.giros,
      items: [],
    };
  },
  methods: {
    async modificarGiros() {
      console.log(this.giros);
      const arrayGiros = this.giros;
      try {
        await axios.put(
          `/clientes/modificar_giros?id_cliente=${this.item.id}`,
          arrayGiros
        );
        // console.log(response.data);
        alert("Giros modificados");
        this.$emit("clienteModificado");
        this.cerrar();
      } catch (error) {
        console.log(error);
      }
    },
    async AgregarLatitudLongitud() {
      if (this.$refs.form.validate()) {
        const latitud = this.latitudLongitud.split(",")[0].trim();
        const longitud = this.latitudLongitud.split(",")[1].trim();
        console.log("Latitud: " + latitud + "\nLongitud: " + longitud);
        try {
          const response = await axios.put(
            `/clientes/modificar_localizacion?id_cliente=${this.item.id}&latitud=${latitud}&longitud=${longitud}`
          );

          console.log(JSON.stringify(response.data));
          alert("Latitud y longitud agregadas");

          this.$emit("clienteModificado");
          this.cerrar();
          this.$refs.form.reset();
          // location.reload();
        } catch (error) {
          alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
          console.log(error.response.data);
          8;
        }
      }
    },
    async getGiros() {
      try {
        const response = await axios.get(
          "/varios/get_clasificaciones?tipo=giro"
        );
        this.items = response.data.valores;
        // console.log(this.items);
      } catch (error) {
        console.log(error);
      }
    },
    cerrar() {
      this.$emit("cerrarDialog", (this.dialog = false));
    },
  },
  computed: {},
  created() {
    this.getGiros();
  },
};
</script>

<style>
.close-btn {
  float: right;
}

.absolute {
  position: absolute;
}

.top {
  top: 10;
}

.right {
  right: 10;
}
</style>
