<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Editar JSON
      </v-card-title>
      <v-row>
        <v-col class="ml-7 mt-3" cols="4">
          <!-- El combobox solo puede entregar el valor de todo el objeto forzosamente  -->
          <v-combobox
            v-model="nombre"
            item-text="nombre"
            item-value="nombre"
            :items="items"
            label="Nombre JSON"
            @change="cargarJSON"
          ></v-combobox>
        </v-col>
        <v-col class="ml-4 mt-7">
          <v-btn small @click="clearEditor">Limpiar</v-btn>
        </v-col>
      </v-row>
      <v-card-text class="ml-7">
        <div class="my-editor" ref="jsoneditor"></div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-7 mb-3"
          color="primary"
          :disabled="!jsonValido"
          text
          @click="guardarJSON"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<!-- {
  "valor1": 123,
  "valor2": "abc",
  "valor3": [
    "Un array",
    "de strings"
  ],
  "valor4": {
    "valor1": "Un objeto",
    "valor2": "con strings"
  }
} -->

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { JSONEditor } from "vanilla-jsoneditor";
export default {
  data() {
    return {
      editorInstance: null, // Esta es la referencia al editor
      content: { text: "{}" }, // Este es el contenido inicial del editor
      nombre: "",
      items: [],
      jsonValido: true,
    };
  },
  methods: {
    handleChange(updatedContent) {
      this.content = updatedContent;
      this.jsonValido = this.validarJSON();
      // console.log(updatedContent);
    },
    updateEditorContent(newContent) {
      if (this.editorInstance) {
        this.editorInstance.update(newContent).then(() => {
          console.log("Editor content updated");
        });
      }
    },
    cargarJSON() {
      console.log(this.nombre);
      console.log(this.content);
      if (typeof this.nombre === "object" && this.nombre !== null) {
        const nuevoContent = { text: JSON.stringify(this.nombre.objeto) };
        this.updateEditorContent(nuevoContent);
      } else if (typeof this.nombre === "string") {
        console.log("No hay JSON asignado");
      } else {
        console.log("Vacio");
      }
    },
    async guardarJSON() {
      console.log(this.content);
      const datos = this.content;
      if (datos.json !== undefined) {
        console.log("Contiene json");
        const datosFormato = JSON.stringify(datos.json);
        if (this.validarJSON()) {
          const datosJSON = JSON.parse(datosFormato);
          let nombreJSON = "";
          if (typeof this.nombre === "object" && this.nombre !== null) {
            nombreJSON = this.nombre.nombre;
          } else if (typeof this.nombre === "string") {
            nombreJSON = this.nombre;
          }
          const body = {
            nombre: nombreJSON,
            objeto: JSON.stringify(datosJSON),
          };
          console.log(body);
          try {
            await axios.post("/varios/crear_json", body);
            alert("JSON creado");
            console.log(body);
          } catch (error) {
            console.error(error);
            alert("Error: " + error.response.statusText);
          }
        } else {
          console.log("JSON no valido");
          alert("Formato JSON no valido");
        }
      } else if (datos.text !== undefined) {
        console.log("Contiene text");
        if (this.validarJSON()) {
          let nombreJSON = "";
          if (typeof this.nombre === "object" && this.nombre !== null) {
            nombreJSON = this.nombre.nombre;
          } else if (typeof this.nombre === "string") {
            nombreJSON = this.nombre;
          }
          const body = {
            nombre: nombreJSON,
            objeto: datos.text,
          };
          console.log(body);
          try {
            await axios.post("/varios/crear_json", body);
            alert("JSON creado");
            console.log(body);
          } catch (error) {
            console.error(error);
            alert("Error: " + error.response.statusText);
          }
        } else {
          console.log("JSON no valido");
          alert("Formato JSON no valido");
        }
      } else {
        console.log("JSON no valido");
      }
    },
    async getJSONs() {
      try {
        const response = await axios.get("/varios/get_jsons");
        this.items = response.data;
        console.log(this.items);
      } catch (error) {
        console.error(error);
      }
    },
    validarJSON() {
      const errores = this.editorInstance.validate();
      if (errores) {
        // console.log("Errores en el JSON", errores);
        return false;
      } else {
        // console.log("JSON validado correctamente", errores);
        return true;
      }
    },
    async refreshEditor() {
      await this.crearEditor();
      await this.editorInstance.refresh();
    },
    clearEditor() {
      if (this.editorInstance) {
        const newContent = { text: "{}" };
        this.nombre = "";
        this.editorInstance.update(newContent).then(() => {
          console.log("Editor content updated");
        });
      }
    },
    crearEditor() {
      //Se monta el editor JSON y se añaden las propiedades que va a tener
      this.editorInstance = new JSONEditor({
        target: this.$refs["jsoneditor"], // Asegúrate de tener un elemento con ref="jsoneditor" en tu template
        props: {
          content: this.content,
          onChange: this.handleChange, // Manejador de eventos para actualizar el contenido en tu componente Vue
          navigationBar: false,
          mode: "text",
        },
      });
    },
  },
  mounted() {
    this.crearEditor();
  },
  created() {
    this.getJSONs();
  },
  components: {},
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style>
.my-editor {
  padding: 10px;
  width: 700px;
  height: 500px;
  display: flex;
}
</style>
