<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Tabla pivote
      </v-card-title>
      <!-- Botones para seleccionar un periodo de tiempo -->
      <v-row class="mx-2 mt-2">
        <v-menu
          v-model="menuMes"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-3 mb-3"
              color="primary"
              small
              v-bind="attrs"
              v-on="on"
            >
              Mes
            </v-btn>
          </template>
          <v-date-picker
            v-model="selectedMes"
            type="month"
            no-title
            scrollable
            locale="es"
          >
          </v-date-picker>
        </v-menu>

        <v-menu
          ref="menuYear"
          v-model="menuYear"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-3 mb-3"
              color="primary"
              small
              v-bind="attrs"
              v-on="on"
            >
              Año
            </v-btn>
          </template>
          <v-date-picker
            :no-title="true"
            v-model="date"
            :active-picker.sync="activePicker"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substring(0, 10)
            "
            min="2000-01-01"
            @click:year="contextMenu"
            @change="save"
          ></v-date-picker>
        </v-menu>

        <v-btn class="ml-3 mb-3" color="primary" small @click="actualYear">
          Año actual
        </v-btn>

        <v-btn
          class="ml-3 mb-3"
          color="primary"
          small
          @click="dialogTrimestre = !dialogTrimestre"
        >
          Trimestre
        </v-btn>

        <!-- dialog para seleccionar trimestre -->
        <v-dialog v-model="dialogTrimestre" max-width="500px">
          <v-card>
            <v-card-title> Trimestre </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-btn
                    @click="getFechasTrimestre(1)"
                    color="primary"
                    x-small
                    dark
                  >
                    Trimestre 1
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    @click="getFechasTrimestre(2)"
                    color="primary"
                    x-small
                    dark
                  >
                    Trimestre 2
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    @click="getFechasTrimestre(3)"
                    color="primary"
                    x-small
                    dark
                  >
                    Trimestre 3
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    @click="getFechasTrimestre(4)"
                    color="primary"
                    x-small
                    dark
                  >
                    Trimestre 4
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- <v-date-picker v-model="date" @click:year="contextMenu"></v-date-picker> -->
      </v-row>
      <!-- Form para solicitar todos los datos de la tabla pivote -->
      <form ref="form" @submit.prevent="getTablaPivote()">
        <!-- Fecha inicial y fecha fin  -->
        <v-row class="mx-2 mt-1">
          <v-col cols="6" md="3">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaInicial"
                  label="Fecha inicial"
                  prepend-icon="mdi-calendar"
                  readonly
                  required
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaInicial"
                no-title
                color="teal lighten-3"
                locale="es-MX"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" md="3">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaFin"
                  label="Fecha fin"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaFin"
                no-title
                color="teal lighten-3"
                locale="es-MX"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" md="4" class="mt-1">
            <v-autocomplete
              v-model="sucursalesSelected"
              :items="sucursales"
              chips
              small-chips
              clearable
              deletable-chips
              label="Sucursales"
              multiple
              auto-select-first
              no-data-text="Sin coincidencias"
              :search-input.sync="searchInputSucursal"
              hint="Agrega las sucursales"
              :persistent-hint="hintSucursales"
              @change="clearSearchInput"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4">
            <!-- <v-text-field required label="Índice" v-model="indice">
            </v-text-field> -->
            <v-select
              required
              v-model="indice"
              label="Índice"
              :items="opcionesString"
              multiple
              @input="actualizarTabla"
            ></v-select>
          </v-col>
          <v-col cols="6" md="4">
            <!-- <v-text-field label="Columnas" v-model="columnas"> </v-text-field> -->
            <v-select
              v-model="columnas"
              label="Columnas"
              :items="opcionesString"
              multiple
              @input="actualizarTabla"
            ></v-select>
          </v-col>
          <v-col cols="6" md="4">
            <!-- <v-text-field label="Valor" v-model="valor"> </v-text-field> -->
            <v-select
              v-model="valor"
              label="Valor"
              :items="opcionesValor"
              clearable
              @input="actualizarTabla"
            ></v-select>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field label="Filtro" v-model="filtros"> </v-text-field>
            <v-row v-for="(texto, index) in textosFiltros" :key="index">
              <v-col>
                <v-text-field
                  v-model="textosFiltros[index]"
                  label="Filtro"
                ></v-text-field>
              </v-col>
              <v-col v-if="index === textosFiltros.length - 1" cols="1" md="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div :elevation="0" dark v-bind="attrs" v-on="on">
                      <v-btn icon class="mt-5" @click="eliminarTextField">
                        <v-icon>mdi-minus-box-outline</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Eliminar filtro</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1" md="1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div :elevation="0" dark v-bind="attrs" v-on="on">
                  <v-btn icon class="mt-5" @click="agregarTextField">
                    <v-icon>mdi-plus-box-outline</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Agregar filtro</span>
            </v-tooltip>
          </v-col>
          <v-col cols="4" md="4">
            <v-select
              v-model="comparacion"
              label="Comparación"
              :items="comparacionOptions"
              clearable
              @input="actualizarTablaComparacion"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mx-2 mt-1">
          <v-col cols="6" md="2">
            <v-btn type="submit" :loading="loadingBtn" class="mt-3"
              >Enviar
            </v-btn>
          </v-col>

          <v-col
            cols="6"
            md="3"
            v-if="datos.length !== 0 || datosAlmacenados.length !== 0"
          >
            <v-btn class="mb-2 mt-3" @click="descargarCSV">Descargar CSV</v-btn>
          </v-col>

          <v-col
            cols="6"
            md="3"
            v-if="datos.length !== 0 || datosAlmacenados.length !== 0"
          >
            <v-text-field
              @input="filtrarItems"
              v-model="search"
              append-icon="mdi-magnify"
              label="Búsqueda"
              single-line
              hide-details
            ></v-text-field>
          </v-col>

          <!-- <v-col cols="6" md="3">
            <v-btn @click="preset" class="mt-3">Comparación cliente</v-btn>
          </v-col> -->

          <!-- <v-dialog v-model="comparacionDialog" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Open Dialog
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">User Profile</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        label="Legal first name*"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        label="Legal middle name"
                        hint="example of helper text only on focus"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        label="Legal last name*"
                        hint="example of persistent helper text"
                        persistent-hint
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field label="Email*" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Password*"
                        type="password"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        :items="['0-17', '18-29', '30-54', '54+']"
                        label="Age*"
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        :items="[
                          'Skiing',
                          'Ice hockey',
                          'Soccer',
                          'Basketball',
                          'Hockey',
                          'Reading',
                          'Writing',
                          'Coding',
                          'Basejump',
                        ]"
                        label="Interests"
                        multiple
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
        </v-row>
      </form>
    </v-card>

    <!-- <ag-grid-vue
      style="width: 900px; height: 600px"
      class="ag-theme-alpine mt-10"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :defaultColDef="defaultColDef"
    >
    </ag-grid-vue> -->

    <v-row>
      <v-col cols="4">
        <v-switch
          v-if="datos.length !== 0 || datosAlmacenados.length !== 0"
          class="mt-10"
          v-model="mostrarTotal"
          label="Mostrar total"
        ></v-switch>
      </v-col>
    </v-row>

    <vue-pivottable
      v-if="datos.length !== 0 || datosAlmacenados.length !== 0"
      :data="datos"
      :aggregator-name="aggregatorName"
      :renderer-name="rendererName"
      :rows="rows"
      :cols="cols"
      :vals="vals"
      :row-total="mostrarTotal"
      :col-total="mostrarTotal"
      :locales="locales"
      :locale="locale"
      :colOrder="colOrder"
    >
    </vue-pivottable>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { VuePivottable, PivotUtilities } from "vue-pivottable";
import "vue-pivottable/dist/vue-pivottable.css";
import pako from "pako";
import Papa from "papaparse";

export default {
  data() {
    return {
      textosFiltros: [],
      sucursalesSelected: [],
      searchInputSucursal: "",
      search: "",
      fechaInicial: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substring(0, 10),
      fechaFin: "",
      indice: [],
      columnas: [],
      valor: null,
      filtros: "",
      comparacion: "",

      // 5548
      // -4622

      menu: false,
      menu2: false,
      menuMes: false,
      menuYear: false,
      dialogTrimestre: false,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
      selectedYear: null,
      activePicker: null,

      selectedMes: null,

      comparacionDialog: false,

      loadingBtn: false,

      datos: [], // Almacena los datos que se estaran filtrando
      datosAlmacenados: [], // Almacena los datos originales

      comparacionOptions: [
        {
          text: "Periodo anterior",
          value: "periodo_anterior",
        },
        {
          text: "Año anterior",
          value: "año_anterior",
        },
      ],
      opcionesString: [
        {
          text: "Código",
          value: "codigo",
        },
        {
          text: "Nombre",
          value: "nombre",
        },
        {
          text: "Fecha",
          value: "fecha",
        },
        {
          text: "Periodo",
          value: "periodo",
        },
        {
          text: "Folio",
          value: "folio",
        },
        {
          text: "Razón social",
          value: "razon_social",
        },
        {
          text: "Agente",
          value: "agente",
        },
        {
          text: "Estado",
          value: "estado",
        },
        {
          text: "Código cliente",
          value: "codigo_cliente",
        },
        {
          text: "Sucursal",
          value: "sucursal",
        },
        {
          text: "Neto-desc",
          value: "neto-desc",
        },
        {
          text: "Laboratorio",
          value: "laboratorio",
        },
        {
          text: "Cliente esp",
          value: "cliente_esp",
        },
        {
          text: "Año",
          value: "year",
        },
        {
          text: "Mes",
          value: "mes",
        },
        {
          text: "Semana",
          value: "semana",
        },
      ],
      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      opcionesValor: [
        {
          text: "Neto-desc",
          value: "neto-desc",
        },
        {
          text: "Cantidad",
          value: "cantidad",
        },
        {
          text: "Comparación",
          value: "comparacion",
        },
      ],

      datosTemp: [
        {
          agente: "MOSTRADOR MEXICALI",
          "neto-desc": 488,
          year: 2022,
        },
        {
          agente: "MOSTRADOR MEXICALI",
          "neto-desc": 60,
          year: 2022,
        },
        {
          agente: "VICTOR MARTINEZ RANGEL",
          "neto-desc": 144,
          year: 2022,
        },
        {
          agente: "MOSTRADOR MEXICALI",
          "neto-desc": 927,
          year: 2022,
        },
        {
          agente: "MOSTRADOR MEXICALI",
          "neto-desc": 541,
          year: 2022,
        },
        {
          agente: "MOSTRADOR MEXICALI",
          "neto-desc": 1042,
          year: 2022,
        },
        {
          agente: "VICTOR MARTINEZ RANGEL",
          "neto-desc": 2402,
          year: 2022,
        },
        {
          agente: "VICTOR MARTINEZ RANGEL",
          "neto-desc": 320,
          year: 2022,
        },
        {
          agente: "VICTOR MARTINEZ RANGEL",
          "neto-desc": 965,
          year: 2022,
        },

        ////////////////////////////////
        {
          agente: "MOSTRADOR MEXICALI",
          "neto-desc": 123,
          year: 2023,
        },
        {
          agente: "MOSTRADOR MEXICALI",
          "neto-desc": 2050,
          year: 2023,
        },
        {
          agente: "VICTOR MARTINEZ RANGEL",
          "neto-desc": 400,
          year: 2023,
        },
        {
          agente: "MOSTRADOR MEXICALI",
          "neto-desc": 321,
          year: 2023,
        },
        {
          agente: "MOSTRADOR MEXICALI",
          "neto-desc": 789,
          year: 2023,
        },
        {
          agente: "MOSTRADOR MEXICALI",
          "neto-desc": 540,
          year: 2023,
        },
        {
          agente: "VICTOR MARTINEZ RANGEL",
          "neto-desc": 632,
          year: 2023,
        },
        {
          agente: "VICTOR MARTINEZ RANGEL",
          "neto-desc": 544,
          year: 2023,
        },
        {
          agente: "VICTOR MARTINEZ RANGEL",
          "neto-desc": 899,
          year: 2023,
        },
      ],

      mostrarTotal: false,
      aggregatorName: "Sum",
      rendererName: "Table",
      rows: ["agente"],
      cols: ["year"],
      vals: ["neto-desc"],
      disabledFromDragDrop: [],
      hiddenFromDragDrop: [],

      locale: "en",
      locales: {
        en: PivotUtilities.locales.en,
        total: {
          aggregators: PivotUtilities.aggregators,
          localeStrings: {
            totals: "Total",
          },
        },
        diferencia: {
          aggregators: PivotUtilities.aggregators,
          localeStrings: {
            totals: "Diferencia",
          },
        },
      },

      hintSucursales: false,

      arrayBufferArchivoCSV: null,
      colOrder: "key_a_to_z", //el orden de las columnas sera de la a a la z en el nombre de su propiedad
    };
  },
  methods: {
    filtrarItems() {
      // console.log(this.datos);

      const criterioBusqueda = this.search.toLowerCase();

      const datosFiltrados = this.datosAlmacenados.filter((item) => {
        // Aplica aquí tu lógica de filtrado según tus necesidades
        for (let prop in item) {
          const valor = item[prop];

          if (
            typeof valor === "string" &&
            valor.toLowerCase().includes(criterioBusqueda)
          ) {
            return true; // Si alguna propiedad coincide, se incluye el item en los resultados
          } else if (
            typeof valor === "number" &&
            valor.toString().toLowerCase().includes(criterioBusqueda)
          ) {
            return true; // Si el valor numérico coincide, se incluye el item en los resultados
          }
        }
        return false;
      });
      this.datos = datosFiltrados;
    },
    eliminarTextField() {
      this.textosFiltros.pop();
      console.log(this.textosFiltros);
    },
    agregarTextField() {
      //Agrega un nuevo elemento al array de textosFiltros
      this.textosFiltros.push("");
      console.log(this.textosFiltros);
    },
    getFechasTrimestre(trimestre) {
      this.fechaInicial = "";
      this.fechaFin = "";
      const year = new Date().getFullYear();
      let fechaInicio, fechaFinal;

      switch (trimestre) {
        case 1:
          fechaInicio = `${year}-01-01`;
          fechaFinal = `${year}-03-31`;
          break;
        case 2:
          fechaInicio = `${year}-04-01`;
          fechaFinal = `${year}-06-30`;
          break;
        case 3:
          fechaInicio = `${year}-07-01`;
          fechaFinal = `${year}-09-30`;
          break;
        case 4:
          fechaInicio = `${year}-10-01`;
          fechaFinal = `${year}-12-31`;
          break;
        default:
          break;
      }

      this.fechaInicial = fechaInicio;
      this.fechaFin = fechaFinal;
      this.dialogTrimestre = false;
    },
    actualYear() {
      const añoActual = new Date().getFullYear();
      const primerDiaDelAño = new Date(añoActual, 0, 1);
      const fechaFormato = (fecha) => {
        const year = fecha.getFullYear();
        const month = String(fecha.getMonth() + 1).padStart(2, "0");
        const day = String(fecha.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      this.fechaInicial = fechaFormato(primerDiaDelAño);
      this.fechaFin = fechaFormato(new Date());
    },
    save(date) {
      this.$refs.menuYear.save(date);
    },
    contextMenu(year, event) {
      this.selectedYear = year;
      event.preventDefault();
      this.menuYear = false;

      // alert(`You have activated context menu for year ${year}`);
    },
    preset() {
      const fechaActual = new Date();
      const primerDiaDelMes = new Date(
        fechaActual.getFullYear(),
        fechaActual.getMonth(),
        1
      );

      // Formatear la fecha en 'AAAA-MM-DD'
      const año = primerDiaDelMes.getFullYear();
      const mes = (primerDiaDelMes.getMonth() + 1).toString().padStart(2, "0");
      const dia = primerDiaDelMes.getDate().toString().padStart(2, "0");

      const fechaPrimerDia = `${año}-${mes}-${dia}`;

      this.fechaFin = this.fechaInicial;
      this.fechaInicial = fechaPrimerDia;

      this.indice = "agente";

      this.hintSucursales = true;
    },
    actualizarTablaComparacion() {
      if (this.comparacion) {
        this.locale = "diferencia";
        this.valor = "comparacion";
        this.vals = ["comparacion"];
      } else {
        this.locale = "total";
        this.valor = "neto-desc";
        this.vals = ["neto-desc"];
        this.comparacion = "";
      }
    },
    actualizarTabla() {
      this.rows = this.indice;
      this.cols = this.columnas;
      if (this.valor !== null) {
        this.vals = [this.valor];
      } else {
        this.valor = "neto-desc";
        this.vals = ["neto-desc"];
      }
    },
    async getTablaPivote() {
      this.loadingBtn = true;
      this.columnDefs = [];
      this.rowData = [];

      //Se cream los strings para pasarlos a la solicitud si no estan vacios
      let fechaFinString = "";
      let sucursalesString = "";
      let indiceString = "";
      let columnasString = "";
      let valorString = "";
      let filtrosString = "";
      let textosFiltrosString = "";
      let comparacionString = "";

      if (this.fechaFin !== "") {
        fechaFinString = `&fecha_final=${this.fechaFin}`;
      }
      if (this.sucursalesSelected.length !== 0) {
        this.sucursalesSelected.forEach((sucursal) => {
          sucursalesString = sucursalesString + `&sucursales=${sucursal}`;
        });
      }
      if (this.indice !== "") {
        this.indice.forEach((indice) => {
          indiceString = indiceString + `&indice=${indice}`;
        });
      }
      if (this.columnas.length !== 0) {
        this.columnas.forEach((columna) => {
          columnasString = columnasString + `&columnas=${columna}`;
        });
        // columnasString = `&columnas=${this.columnas}`;
      }
      if (this.valor !== null) {
        if (this.valor === "comparacion") {
          valorString = "";
        } else {
          valorString = `&valor=${this.valor}`;
        }
      }
      if (this.filtros !== "") {
        filtrosString = `&filtros=${this.filtros}`;
      }

      if (this.textosFiltros.length !== 0) {
        // console.log(this.textosFiltros);
        this.textosFiltros.forEach((texto) => {
          if (texto !== "") {
            textosFiltrosString = filtrosString + `&filtros=${texto}`;
          }
        });
      }
      if (this.comparacion && this.comparacion !== null) {
        comparacionString = `&comparacion=${this.comparacion}`;
        this.colOrder = "value_a_to_z"; //el orden de las columnas sera de la a a la z en los valores (en este caso de menor a mayor)
      } else {
        this.colOrder = "key_a_to_z"; //el orden de las columnas sera de la a a la z en el nombre de su propiedad
      }

      try {
        const response = await axios.get(
          `/reportes/pivot_table?fecha_inicial=${this.fechaInicial}${fechaFinString}${sucursalesString}${indiceString}${columnasString}${valorString}${filtrosString}${textosFiltrosString}${comparacionString}`,
          {
            responseType: "arraybuffer", // se especifica el tipo de respuesta
          }
        );

        // Convierte el array de bytes en un blob
        this.arrayBufferArchivoCSV = response.data;

        // Descomprimir el archivo gzip
        const decompressedData = pako.ungzip(response.data, { to: "string" });
        // console.log(decompressedData);

        // Leer el archivo CSV
        const csvData = Papa.parse(decompressedData, {
          header: true, // si tu archivo CSV tiene encabezados
          dynamicTyping: true, // para convertir automáticamente los campos numéricos a números
        });
        // console.log(csvData);

        this.datos = csvData.data;
        this.datos.pop();
        this.datosAlmacenados = this.datos;
        // console.log(this.datos);
        if (this.datos.length === 0) {
          this.loadingBtn = false;
          alert("No hay registros para mostrar");
        } else {
          this.rows = this.indice;
          this.cols = this.columnas;
          if (this.valor !== null) {
            this.vals = [this.valor];
          } else {
            this.valor = "neto-desc";
            this.vals = ["neto-desc"];
          }

          if (this.comparacion) {
            this.cols = ["periodo"];
            this.columnas = ["periodo"];
            this.mostrarTotal = true;

            let years = this.datos.map((obj) => obj.year);
            let uniqueYears = [...new Set(years)];
            uniqueYears.sort((a, b) => a - b);

            this.datos.forEach((obj) => {
              //se ordena los datos para que al año mas reciente se le reste el valor del año anterior
              if (obj.year === uniqueYears[0]) {
                obj.comparacion = -obj["neto-desc"];
              } else {
                obj.comparacion = obj["neto-desc"];
              }
            });

            this.locale = "diferencia";
            this.vals = ["comparacion"];
            this.valor = "comparacion";
          } else {
            this.mostrarTotal = false;
            this.locale = "total";
          }

          this.loadingBtn = false;
        }
      } catch (error) {
        this.loadingBtn = false;
        console.error(error);

        // Convertir el ArrayBuffer en un array de bytes
        const byteArray = new Uint8Array(error.response.data);

        // Crear un objeto de texto a partir del array de bytes
        const text = new TextDecoder("latin1").decode(byteArray); //latin1

        // Analizar la cadena de texto como JSON

        const responseObject = JSON.parse(text);

        // Ahora responseObject contiene el objeto devuelto por el servidor
        // Puedes verificar si es un objeto 'detail' y mostrar la descripción del error

        if (error.response.status === 422) {
          console.error(responseObject.detail);
          alert(
            responseObject.detail[0].loc[1] + " " + responseObject.detail[0].msg
          );
        }
        if (error.response.status === 400) {
          console.error(responseObject.detail);
          alert(responseObject.detail);
        }

        // alert("Error al obtener los datos");
      }
    },
    // async getDatos() {
    //   try {
    //     const response = await axios.get(
    //       "/reportes/pivot_table?fecha_inicial=2023-01-01&fecha_final=2023-01-03&sucursales=Mexicali&indice=agente&indice=razon_social&columnas=fecha&columnas=laboratorio&valor=neto-desc&comparacion=false",
    //       {
    //         responseType: "arraybuffer", // se especifica el tipo de respuesta
    //       }
    //     );

    //     // Descomprimir el archivo gzip
    //     const decompressedData = pako.ungzip(response.data, { to: "string" });

    //     // Leer el archivo CSV
    //     const csvData = Papa.parse(decompressedData, {
    //       header: true, // si tu archivo CSV tiene encabezados
    //       dynamicTyping: true, // para convertir automáticamente los campos numéricos a números
    //     });

    //     console.log(csvData.data);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    clearSearchInput() {
      // Establece el search-input en una cadena vacía cuando se selecciona un elemento
      this.searchInputSucursal = "";
      this.hintSucursales = false;
    },
    prepararDatos() {
      let years = this.datosTemp.map((obj) => obj.year);
      let uniqueYears = [...new Set(years)];
      uniqueYears.sort((a, b) => a - b);

      this.datosTemp.forEach((obj) => {
        if (obj.year === uniqueYears[0]) {
          obj.resultado = -obj["neto-desc"];
        } else {
          obj.resultado = obj["neto-desc"];
        }
      });
    },
    async descargarCSV() {
      // Descomprime el blob utilizando pako
      const decompressedUint8Array = pako.ungzip(
        new Uint8Array(this.arrayBufferArchivoCSV)
      );

      // Crea un blob descomprimido
      const decompressedBlob = new Blob([decompressedUint8Array], {
        type: "text/csv",
      });

      // Crea un enlace temporal para descargar el archivo descomprimido
      const url = window.URL.createObjectURL(decompressedBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "archivo.csv";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
  },
  watch: {
    selectedYear(val) {
      this.fechaInicial = "";
      this.fechaFin = "";
      console.log(val);
      const fechaActual = new Date(); // Fecha actual
      const fechaInicio = new Date(val, 0, 1); // Primer día del año

      // Compara el año actual con el año seleccionado
      if (val === fechaActual.getFullYear()) {
        // Si es el año actual, utiliza la fecha actual como fecha final
        var fechaFin = fechaActual;
      } else {
        // Si no es el año actual, utiliza el último día del año
        fechaFin = new Date(val, 11, 31);
      }

      // Formatea las fechas en el formato 'YYYY-MM-DD'
      const fechaInicioFormateada = fechaInicio.toISOString().slice(0, 10);
      const fechaFinFormateada = fechaFin.toISOString().slice(0, 10);

      this.fechaInicial = fechaInicioFormateada;
      this.fechaFin = fechaFinFormateada;
    },
    menuYear(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    selectedMes(nuevoValor) {
      console.log(nuevoValor);

      // Dividimos la fecha en año y mes
      const [anio, mes] = nuevoValor.split("-");

      // Creamos una nueva fecha con el primer día del mes
      const primerDia = new Date(anio, mes - 1, 1);
      // Creamos una nueva fecha con el primer día del siguiente mes y restamos 1 día para obtener el último día del mes actual
      const ultimoDia = new Date(anio, mes, 0);

      // Formateamos la fecha como 'AAAA-MM-DD'
      this.fechaInicial = primerDia.toISOString().slice(0, 10);
      this.fechaFin = ultimoDia.toISOString().slice(0, 10);
    },
  },
  created() {
    // this.getDatos();
    // this.prepararDatos();
  },
  components: { VuePivottable },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
