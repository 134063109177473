<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Facturas pendientes
      </v-card-title>
      <v-row class="mx-2 mt-1">
        <v-col cols="12" lg="3" md="3">
          <v-select
            v-model="sucursalSelected"
            :items="sucursales"
            item-value="value"
            label="Sucursal"
            placeholder="Selecciona sucursal"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="5" md="4">
          <v-autocomplete
            :items="itemsFiltrados"
            v-model="item"
            item-text="razon_social"
            :return-object="true"
            label="Cliente"
            no-data-text="No hay resultados"
            :filter="customFilter"
            @change="clienteSelected"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.razon_social"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.codigo"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-btn @click="getPendiente" small class="mt-5">Buscar</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <!-- Dialog para la tabla de facturas -->
    <v-dialog fullscreen transition="dialog-bottom-transition" v-model="dialog">
      <v-card>
        <v-btn
          class="absolute top right"
          icon
          color="blue darken-1"
          text
          @click="cerrarDialog"
        >
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
        <v-card-title>Facturas pendientes</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Pendiente total: ${{
                    formatoPrecio(estadoCredito.pendiente_total)
                  }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Crédito activo:
                  <v-icon
                    class="mr-2"
                    :color="estadoCredito.credito_activado ? 'green' : 'red'"
                    size="25"
                  >
                    {{
                      estadoCredito.credito_activado
                        ? "mdi-check-circle"
                        : "mdi-close-circle"
                    }}
                  </v-icon>
                  <!-- {{
                estadoCredito.credito_activado ? "Sí" : "No"
              }} -->
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-data-table
            dense
            :headers="headers"
            :items="itemsFormat"
            :items-per-page="15"
            class="elevation-1"
            no-data-text="No hay elementos para mostrar"
            :footer-props="{
              'items-per-page-text': 'Elementos por página',
            }"
            :header-props="{
              'sort-by-text': 'Ordenar por',
            }"
          >
            <!-- Columna de Fecha -->
            <template v-slot:[`item.fecha`]="{ item }">
              {{ getFechaLegible(item.fecha) }}
            </template>
            <!-- Columna de Fecha vencimiento -->
            <template v-slot:[`item.fecha_vencimiento`]="{ item }">
              {{ getFechaLegible(item.fecha_vencimiento) }}
            </template>
            <!-- Columna de total -->
            <template v-slot:[`item.total`]="{ item }">
              {{ "$" + formatoPrecio(item.total) }}
            </template>
            <!-- Columna de Pendiente -->
            <template v-slot:[`item.pendiente`]="{ item }">
              {{ "$" + formatoPrecio(item.pendiente) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      item: {},
      sucursalSelected: "Mexicali",
      dialog: false,
      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      //   itemsFiltrados: [],
      items: [],
      facturas: [],
      estadoCredito: {},
      saldo_pendiente: 0,
      headers: [
        {
          text: "Concepto",
          value: "concepto",
        },
        {
          text: "Serie y Folio",
          value: "serie_folio",
        },
        {
          text: "Fecha",
          value: "fecha",
          // sortable: false,
        },
        {
          text: "Fecha vencimiento",
          value: "fecha_vencimiento",
          // sortable: false,
        },
        {
          text: "Dias restantes",
          value: "dias_restantes",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Pendiente",
          value: "pendiente",
        },
      ],
    };
  },
  methods: {
    async getPendiente() {
      try {
        const response = await axios.get(
          `/clientes/pendiente?id_cliente=${this.item.id}`
        );
        console.log(response.data);
        // this.estadoCredito = response.data.estado_credito;
        this.facturas = response.data.documentos;
        let totalDeuda = this.facturas.reduce(
          (acumulador, item) => acumulador + item.pendiente,
          0
        );
        this.estadoCredito = {
          credito_activado: response.data.estado_credito.credito_activado,
          pendiente_total: totalDeuda,
        };
        this.dialog = true;
      } catch (error) {
        // alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        console.log(error.response.data);
      }
    },
    async getClientes() {
      try {
        const response = await axios.get(`/clientes/get_nombres`);
        this.items = response.data;
        console.log(this.items);
      } catch (error) {
        console.error(error);
      }
    },
    cerrarDialog() {
      this.dialog = false;
    },
    clienteSelected() {
      console.log(this.item);
    },
    customFilter(item, queryText) {
      const textOne = item.razon_social.toLowerCase();
      const textTwo = item.codigo.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    getFechaLegible(fechaLarga) {
      const fecha = new Date(fechaLarga);

      const año = fecha.getFullYear();
      const mes = fecha.getMonth() + 1;
      const dia = fecha.getDate();
      // const hora = fecha.getHours();
      // const minutos = fecha.getMinutes().toString().padStart(2, "0");

      const fechaLegible = `${dia}/${mes}/${año}`;
      // const horaLegible = `${hora}:${minutos}`;

      return `${fechaLegible}`;
    },
    formatoPrecio(precioProducto) {
      // Formatear el número utilizando una función personalizada
      const numero = parseFloat(precioProducto);
      // console.log(numero);
      let precio = 0;

      precio = numero.toFixed(2); // Limitar a 2 decimales
      const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
      const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

      // Formatear la parte entera con separador de miles
      const parteEnteraFormateada = parteEntera.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
    },
    calcularDiasRestantes(fecha) {
      const fechaVencimiento = new Date(fecha);
      const fechaActual = new Date();

      // Calcula la diferencia en milisegundos
      const diferenciaMs = fechaVencimiento - fechaActual;

      // Convierte la diferencia en días
      const diasRestantes = Math.ceil(diferenciaMs / (1000 * 60 * 60 * 24));

      return diasRestantes;
    },
  },
  created() {
    this.getClientes();
  },
  components: {},
  computed: {
    ...mapGetters(["user"]),
    itemsFiltrados() {
      const itemsSucursal = this.items.filter(
        (cliente) => cliente.sucursal === this.sucursalSelected
      );
      return itemsSucursal;
    },
    itemsFormat() {
      return this.facturas.map((documento) => {
        const nuevoDocumento = { ...documento };
        // nuevoDocumento.fecha = this.getFechaLegible(documento.fecha);
        // nuevoDocumento.fecha_vencimiento = this.getFechaLegible(
        //   documento.fecha_vencimiento
        // );
        nuevoDocumento.dias_restantes = this.calcularDiasRestantes(
          documento.fecha_vencimiento
        );
        nuevoDocumento.serie_folio = `${documento.serie} ${documento.folio}`;
        return nuevoDocumento;
      });
    },
  },
};
</script>
