<template>
  <v-container>
    <v-menu>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
              mdi-dots-vertical
            </v-icon>
          </template>
          <span>Acciones</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="seleccionarComponent(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="desplegarPedido"> Ver pedido </v-list-item>
        <v-list-item v-if="item.abierto" @click="cambiarPrioridad">
          Cambiar prioridad
        </v-list-item>
        <v-list-item
          v-if="item.abierto"
          @click="dialogRegresarPedido = !dialogRegresarPedido"
        >
          Regresar pedido
        </v-list-item>

        <!-- <v-list-item> Enviar por Whatsapp </v-list-item>
        <v-list-item> Enviar por correo </v-list-item> -->

        <!-- <v-list-item @click="crearCotizacion"> Cotización </v-list-item> -->
        <v-list-item v-if="item.abierto" @click="cancelarPedido">
          Cancelar pedido
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog scrollable max-width="60%" v-model="dialog">
      <v-card v-if="selectedComponent === 'AsociarFactura'">
        <AsociarFactura
          :item="item"
          @pedidoModificado="pedidoModificado"
          @cerrarDialog="cerrarDialog"
        />
      </v-card>
      <v-card v-if="selectedComponent === 'CrearComentario'">
        <CrearComentario :item="item" @pedidoModificado="pedidoModificado" />
      </v-card>
    </v-dialog>
    <!-- Dialog para ver el pedido -->
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="dialogVerPedido"
    >
      <VerPedido
        :item="item"
        :usuarios="usuarios"
        :comentarios="comentariosPedido"
        @cerrarDialog="cerrarDialog"
      />
    </v-dialog>
    <v-dialog v-model="dialogRegresarPedido" max-width="500px">
      <v-card>
        <v-card-title> Regresar pedido a: </v-card-title>
        <v-card-text>
          <v-select label="Estado" v-model="estado" :items="estados"></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="regresarPedido">
            Regresar pedido
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import AsociarFactura from "./AsociarFactura.vue";
import VerPedido from "./VerPedido.vue";
import CrearComentario from "./CrearComentario.vue";
import moment from "moment";
export default {
  props: ["item", "usuarios"],
  components: {
    AsociarFactura,
    VerPedido,
    CrearComentario,
  },
  data() {
    return {
      dialog: false,
      dialogRegresarPedido: false,
      dialogVerPedido: false,
      items: [],
      selectedComponent: null,
      formatDate: "",
      estado: "",
      logoURL: require("@/assets/log.png"),
      visamastercard: require("@/assets/visa-mastercard.png"),
      estados: [
        {
          text: "Preparación en almacén",
          value: "preparacion_en_almacen",
        },
        {
          text: "Preparado",
          value: "preparado",
        },
      ],
      comentariosPedido: [],
    };
  },
  methods: {
    async desplegarPedido() {
      try {
        const response = await axios.get(
          `/varios/get_comentarios?id_objeto=${this.item.id}&tipo_objeto=pedido`
        );
        this.comentariosPedido = response.data;
        // console.log(this.comentarios);
      } catch (error) {
        console.error(error);
      }

      this.dialogVerPedido = true;
    },
    cerrarDialog() {
      this.dialog = false;
      this.dialogVerPedido = false;
    },
    getIvaIeps(precioProducto, impuesto) {
      // Formatear el número utilizando una función personalizada
      const numero = parseFloat(precioProducto);
      let precio = 0;

      if (impuesto > 0) {
        const impuestoDecimal = impuesto / 100;
        precio = numero * impuestoDecimal; // Limitar a 2 decimales

        return precio;
      } else {
        return 0;
      }
    },
    async crearCotizacion() {
      const pedido = this.item;
      const productos = this.item.productos.lista_productos;
      const currentDate = moment()
        .locale("es")
        .format("dddd, DD [de] MMMM [de] YYYY");
      this.formatDate =
        currentDate.charAt(0).toUpperCase() + currentDate.slice(1); // Capitalize first letter
      // console.log(this.formatDate);
      const subtotalPedido = productos
        .reduce(
          (total, producto) =>
            total + parseFloat(producto.subtotal.replace(",", "")),
          0
        )
        .toFixed(2);
      const iepsTotal = productos
        .reduce(
          (total, producto) =>
            total +
            this.getIvaIeps(producto.subtotal.replace(",", ""), producto.ieps),
          0
        )
        .toFixed(2);
      const ivaTotal = productos
        .reduce(
          (total, producto) =>
            total +
            this.getIvaIeps(producto.subtotal.replace(",", ""), producto.iva),
          0
        )
        .toFixed(2);
      const totalPedido = (
        parseFloat(subtotalPedido) +
        parseFloat(iepsTotal) +
        parseFloat(ivaTotal)
      ).toFixed(2);
      console.log(pedido);

      // Crear una ventana emergente
      const ventana = window.open("", "_blank");

      // Construir el contenido HTML de la vista previa de impresión
      const contenidoHTML = `
      <!doctype html>
<html lang="es">

<head>

    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Cotizacion</title>
    <!-- Enlace al archivo CSS de Bootstrap -->
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet"
        integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css">
    <style>
        @import url('https://fonts.googleapis.com/css2?family=Arvo:wght@700&display=swap');

        body {
            font-family: Arial, sans-serif;
            display: flex;
            flex-direction: column;
            height: 100vh;
            margin: 0;
        }

        .content {
            flex: 1;
        }

        .table-table {
            width: 100%;
            border-collapse: collapse;
            font-size: 11px;
        }

        .tabla-th {
            background-color: #f2f2f2;
            padding: 8px;
            text-align: left;
        }

        .tabla-td {
            padding: 8px;
            text-align: left;
        }

        .datos-table {
            width: 100%;
            border-collapse: collapse;
        }

        .datos-th {
            padding: 8px;
            font-size: 10px;
            text-align: left;
        }

        .datos-td {
            padding: 8px;
            font-size: 14px;
            text-align: left;
        }

        .invoice-title {
            font-family: 'Arvo', sans-serif;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #28B2BB;
            color: white;
            padding: 10px 0;
            font-size: 30px;
            margin-top: 20px;
            height: 80px;
        }

        p.right-align {
            text-align: right;
            margin: 15px;
            /* Ajusta el valor de margin según tus necesidades */
        }

        p {
            margin: 15px;
            /* Ajusta el valor de margin según tus necesidades */
        }

        .data-total {
            background-color: #28B2BB;
            color: white;
            padding: 8px;
            text-align: right;
            font-weight: bold;
        }

        .footer-rectangle {
            background-color: #28B2BB;
            width: 80%;
            /* 2/3 of the page width */
            padding: 20px 20px;
            color: white;
            position: absolute;
            bottom: 20px;
            /* margin from the bottom */
        }

        .footer-content {
            margin: 0;
            padding: 0 20px;
        }

        /* .footer-info {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            position: absolute;
            bottom: 120px;
            width: 100%;
        } */

        .socialfb-text {
            color: #1668c7;
            font-size: 12px;
        }

        .telephone-text {
            font-size: 24px;
            font-family: 'Arvo', sans-serif;
            margin-right: 15px;
            text-align: right;
        }

        .invoice-date {
            background-color: grey;
            display: inline-block;
            padding: 1px 15px;
            font-size: 18px;
            color: white;
        }

        .text-right {
            text-align: right;
            margin-right: 15px;
        }

        .socialmail-text {
            color: #1668c7;
            font-size: 14px;
        }

        .social-link {
            color: #1668c7;
            text-decoration: none;
            /* Esto elimina el subrayado del enlace */
        }

        .totals {
            font-size: 14px;
            position: absolute;
            bottom: 170px;
            /* Adjusted to be above the adjusted salesman line */
            right: 0;
        }

        .img-logo {
            width: 140px;
            height: 140px;
        }

        .custom-position {
            position: absolute;
            top: 50%;
            left: 15%;
            transform: translate(-50%, -30%);
        }

        i {
            padding-left: 14px;
            padding-right: 8px;
        }

        @media print {

            .invoice-title,
            .footer-rectangle,
            .tabla-th,
            .rectangulo-footer {
                background-color: #28B2BB !important;
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
            }

            .invoice-date {
                background-color: grey;
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
            }

            .tabla-th {
                background-color: #f2f2f2 !important;
            }
        }
    </style>
</head>

<body class="d-flex">
    <div class="row">
        <div class="invoice-title">COTIZACIÓN</div>
    </div>
    <div class="position-relative">
        <img src="${
          this.logoURL
        }" alt="Logo" class="img-logo img-fluid z-1 custom-position" />
    </div>

    <div class="d-flex flex-column mt-2">
        <div class="p-2 telephone-text"><i class="bi bi-telephone-fill"></i><strong> (686) 563-2834</strong></div>
        <div class="p-2 text-right"><span class="invoice-date">${
          this.formatDate
        }</span></div>
    </div>

    <div class="d-flex flex-column">
        <div class="p-1"><strong>${pedido.cliente}</strong></div>
        <div class="p-1">PRESENTE.-</div>
        <div class="p-1 mb-2">En atención a usted(es), ponemos a su consideración y disposición la
            presente cotización de los siguientes productos y/o servicios
        </div>
        <table class="table-table table table-borderless table-sm">
            <thead>
                <tr>
                    <th class="tabla-th">Cantidad</th>
                    <th class="tabla-th">Descripción</th>
                    <th class="tabla-th">Precio unitario</th>
                    <th class="tabla-th">Neto descuento</th>
                    <th class="tabla-th">IEPS</th>
                    <th class="tabla-th">IVA</th>
                    <th class="tabla-th">Importe</th>
                </tr>
            </thead>
            <tbody>
                ${productos
                  .map(
                    (producto) => `
                <tr>
                    <td class="tabla-td">${producto.cantidad}</td>
                    <td class="tabla-td">${producto.nombre}</td>
                    <td class="tabla-td">$${this.formatoPrecio(
                      producto.precio
                    )}</td>
                    <td class="tabla-td">$${this.formatoPrecioDescuento(
                      producto.precio,
                      producto.descuento
                    )}</td>
                    <td class="tabla-td">${producto.ieps}%</td>
                    <td class="tabla-td">${producto.iva}%</td>
                    <td class="tabla-td">$${producto.subtotal}</td>
                </tr>
                `
                  )
                  .join("")}
            </tbody>
        </table>
    </div>

    <strong class="mt-3" style="font-size: 14;">Datos y correo para pagos:</strong>
    <div class="d-flex justify-content-between">
        <div class="p-1">
            <table class="datos-table table table-borderless table-sm">
                <thead>
                    <tr>
                        <th class="datos-th">Banco:</th>
                        <th class="datos-th">Moneda:</th>
                        <th class="datos-th">Cuenta CLABE:</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="datos-td">Banamex</td>
                        <td class="datos-td">MXN</td>
                        <td class="datos-td">002 020 48880052279 9</td>
                    </tr>
            </table>
        </div>
        <div class="p-1">
            <strong style="font-size: 14px;">Otras opciones<br>de pago:</strong>
            <img src="${
              this.visamastercard
            }" alt="VisaMastercard" class="img-fluid" style="width: 70px;" />
        </div>
        <div class="d-flex flex-column">
            <div class="p1" style="font-size: small;"><strong>Subtotal:</strong>$${subtotalPedido}</div>
            <div class="p1" style="font-size: small;"><strong>IEPS:</strong>$${iepsTotal}</div>
            <div class="p1" style="font-size: small;"><strong>IVA:</strong>$${ivaTotal}</div>
            <div class="p1" style="font-size: medium;"><strong>Total:</strong>$${totalPedido}</div>
        </div>
    </div>
    <strong style="font-size: 14;">Términos y condiciones:</strong>
    <div class="d-flex flex-column mt-3">
        <div class="p1"> 1) Precios sujetos a cambios sin previo aviso.</div>
        <div class="p1"> 2) Aplican restricciones y/o cargos por fletes y otros impuestos.</div>
        <div class="p1"> 3) No se aceptan devoluciones.</div>
    </div>

    <div class="footer-info mt-3">
        <div class="d-flex mb-3">
            <div class="me-auto"><span>Atentamente: <br>${
              pedido.vendedor
            }</span></div>
            <div class="align-self-end"><span class="socialfb-text"><i class="bi bi-facebook"
                        style="font-size: 20px;"></i><strong><a
                            href="https://www.facebook.com/centralexpressdecontroldeplagas"
                            class="social-link">facebook.com/centralexpressdecontroldeplagas</a></strong></span></div>
            <div class="align-self-end"><i class="bi bi-envelope" style="font-size: 20px; color: #63dee7;"></i><span
                    class="socialmail-text"><strong>ventas@centralexpress.mx</strong></span></div>
        </div>
    </div>

    <div class="mt-auto footer-rectangle">
        <strong>Central Express de Control de Plagas, SA de CV</strong>
        <br>
        <span style="font-size: 12px;">Blvd. Lázaro Cárdenas 526, Ex Ejido Coahuila, C.P. 21360, Mexicali, Baja
            California, México</span>
    </div>
</body>

</html>`;

      // Escribir el contenido HTML en la ventana emergente
      ventana.document.open();
      ventana.document.write(contenidoHTML);
      ventana.document.close();

      // Esperar un poco para asegurarnos de que la ventana se haya abierto correctamente
      setTimeout(() => {
        // Imprimir la ventana emergente
        ventana.print();
      }, 500);
    },
    formatoPrecio(precioProducto) {
      // Formatear el número utilizando una función personalizada
      const numero = parseFloat(precioProducto);
      // console.log(numero);
      let precio = 0;

      precio = numero.toFixed(2); // Limitar a 2 decimales
      const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
      const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

      // Formatear la parte entera con separador de miles
      const parteEnteraFormateada = parteEntera.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
    },
    async regresarPedido() {
      try {
        const response = await axios.put(
          `/pedidos/regresar?nuevo_estado=${this.estado}&id_pedido=${this.item.id}`
        );
        console.log(response.data);
        alert("Se cambio el estado del pedido");
        this.$emit("pedidoModificado");
      } catch (error) {
        console.error(error);
        alert(error.response.data.detail);
      }
    },
    pedidoModificado() {
      this.$emit("pedidoModificado");
      this.dialog = false;
    },
    async cambiarPrioridad() {
      try {
        const response = await axios.post(
          `/pedidos/cambiar_prioridad?id_pedido=${this.item.id}`
        );
        console.log(response.data);
        alert("Se cambio la prioridad");
        this.$emit("pedidoModificado");
      } catch (error) {
        console.error(error);
        alert(error.response.data.detail);
      }
    },
    async cancelarPedido() {
      try {
        const response = await axios.post(
          `/pedidos/avanzar_pedido?id_pedido=${this.item.id}&nuevo_estado=cancelado`
        );
        console.log(response.data);
        alert("Pedido cancelado");
        this.$emit("pedidoModificado");
      } catch (error) {
        console.error(error);
        alert(error.response.data.detail);
      }
    },
    getOpciones() {
      if (this.item.abierto) {
        //Si el pedido esta cerrado no se muestra la opcion de asociar factura
        this.items = [
          {
            title: "Asociar factura",
            component: "AsociarFactura",
          },
          // {
          //   title: "Ver pedido",
          //   component: "VerPedido",
          // },
          {
            title: "Agregar comentario",
            component: "CrearComentario",
          },
        ];
      } else {
        this.items = [
          // {
          //   title: "Ver pedido",
          //   component: "VerPedido",
          // },
          {
            title: "Agregar comentario",
            component: "CrearComentario",
          },
        ];
      }
    },
    seleccionarComponent(item) {
      if (item.component) {
        this.selectedComponent = item.component;
        this.dialog = true;
      }
    },
    ...mapActions(["setPerfil"]),
    openPerfil(perfil) {
      this.setPerfil(perfil);
      this.$emit("openPerfil"); // Emit an event to the parent component
    },
    precioDescuento(precioProducto, descuento) {
      // Formatear el número utilizando una función personalizada
      const numero = parseFloat(precioProducto);
      // console.log(numero);
      let precio = 0;

      if (descuento > 0) {
        const descuentoDecimal = descuento / 100;
        precio = (numero * (1 - descuentoDecimal)).toFixed(2); // Limitar a 2 decimales
        const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
        const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

        // Formatear la parte entera con separador de miles
        const parteEnteraFormateada = parteEntera.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
      } else {
        precio = numero.toFixed(2); // Limitar a 2 decimales
        const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
        const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

        // Formatear la parte entera con separador de miles
        const parteEnteraFormateada = parteEntera.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
      }
    },
    formatoPrecioDescuento(precioProducto, descuento) {
      // Formatear el número utilizando una función personalizada
      const numero = parseFloat(precioProducto);
      // console.log(numero);
      let precio = 0;

      if (descuento > 0) {
        const descuentoDecimal = descuento / 100;
        precio = (numero * (1 - descuentoDecimal)).toFixed(2); // Limitar a 2 decimales
        const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
        const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

        // Formatear la parte entera con separador de miles
        const parteEnteraFormateada = parteEntera.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}(-${descuento}%)`;
      } else {
        precio = numero.toFixed(2); // Limitar a 2 decimales
        const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
        const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

        // Formatear la parte entera con separador de miles
        const parteEnteraFormateada = parteEntera.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
      }
    },
  },
  mounted() {
    this.getOpciones();
  },
};
</script>
