<template>
  <v-card>
    <v-container>
      <v-card-text>
        <v-container>
          <form ref="form" @submit.prevent="crearComentario()">
            <v-row>
              <v-col>
                <v-card-title class="justify-center">
                  Crear comentario para el {{ objeto }}: {{ item.id }}
                </v-card-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Comentario"
                  v-model="comentario"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-btn type="submit" color="teal lighten-2" text
                >Crear comentario
              </v-btn>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["item"],
  data() {
    return {
      objeto: "",
      comentario: "",
    };
  },
  methods: {
    async crearComentario() {
      try {
        const response = await axios.post(
          `/varios/crear_comentario?id_objeto=${this.item.id}&tipo_objeto=${this.objeto}&comentario=${this.comentario}`
        );
        console.log(JSON.stringify(response.data));
        this.$emit("pedidoModificado"); // Emitir el evento 'pedidoModificado' que se encarga de actualizar los pedidos
        this.$emit("cerrarDialog");
        this.$emit("comentarioCreado");
        alert("Comentario creado");
        this.comentario = "";
        // this.cerrar();
        // location.reload();
      } catch (error) {
        error.log(error);
        alert(JSON.stringify(error));
      }
    },
    checkObjectType() {
      if (
        typeof this.item.lista_productos !== "undefined" &&
        typeof this.item.transportista !== "undefined"
      ) {
        // Check if the item object has the properties "sucursal" and "envios"
        this.objeto = "envio";
      } else if (
        typeof this.item.id !== "undefined" &&
        typeof this.item.nombre !== "undefined" &&
        typeof this.item.cliente !== "undefined"
      ) {
        // Check if the item object has the properties "id", "nombre", and "cliente"
        this.objeto = "faltante";
      } else if (
        typeof this.item.id !== "undefined" &&
        typeof this.item.productos !== "undefined" &&
        typeof this.item.cliente !== "undefined"
      ) {
        // Check if the item object has the properties "id", "productos", and "cliente"
        this.objeto = "pedido";
      } else {
        // Default case if the item object doesn't match any expected format
        this.objeto = "unknown";
      }
      console.log(this.item);
    },
    cerrar() {
      this.dialog = !this.dialog;
    },
  },
  created() {
    this.checkObjectType();
  },
  mounted() {},
};
</script>
