<template>
  <v-dialog max-width="50%" v-model="dialog">
    <v-card
      ><v-container>
        <v-card-title>
          Usuario Nuevo
          <v-spacer />
          <v-btn
            class="close-btn"
            icon
            value="log in"
            color="blue darken-1"
            text
            @click="cerrar()"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <form ref="form" @submit.prevent="crearUsuario()">
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Nombre"
                    append-icon="mdi-account-details"
                    v-model="nombre"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Correo"
                    append-icon="mdi-email-outline"
                    v-model="correo"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    label="Sucursal"
                    outlined
                    :items="items"
                    v-model="sucursal"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Contraseña"
                    append-icon="mdi-lock"
                    v-model="password"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    label="Confirmar contraseña"
                    append-icon="mdi-lock"
                    v-model="passwordConfirm"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn type="submit" color="teal lighten-2" text>Enviar</v-btn>
            </form>
          </v-container>
        </v-card-text>
      </v-container>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-2" v-on="on" v-bind="attrs"> Crear Usuario</v-btn>
    </template>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      nombre: "",
      correo: "",
      password: "",
      passwordConfirm: "",
      sucursal: "",
      dialog: false,
      errores: [],
      items: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      props: [],
    };
  },
  methods: {
    async crearUsuario() {
      if (this.password != this.passwordConfirm) {
        alert("Las contraseñas deben ser iguales");
        (this.password = ""), (this.passwordConfirm = "");
      } else {
        const newString = {
          nombre: this.nombre,
          correo: "" + this.correo,
          password: this.password,
          sucursal: "" + this.sucursal,
        };
        await axios
          .post("/usuarios/crear_usuario", newString)
          .then((response) => {
            // alert(JSON.stringify(response.data));
            alert("Usuario creado correctamente.");
            console.log(response);
            console.log(this.props);
            location.reload();
            // this.cerrar();
          })
          .catch((error) => {
            if (error) {
              this.errores.push(error);
              alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
            }
            this.serverError = true;
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      }
    },
    cerrar() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style>
.close-btn {
  float: right;
}
</style>
