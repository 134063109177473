<template>
  <v-container>
    <v-menu>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
              mdi-dots-vertical
            </v-icon>
          </template>
          <span>Acciones</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item @click="desplegarFaltante"> Ver faltante </v-list-item>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="selectComponent(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="item.abierto" @click="dialogCancelarFaltante = true">
          Cancelar faltante
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog max-width="80%" v-model="dialog">
      <!-- <v-card v-if="selectedComponent === 'VerFaltante'">
        <VerFaltante 
        :item="item" 
        :usuarios="usuarios" 
        @cerrarDialog="cerrarDialog" />
      </v-card> -->
      <v-card v-if="selectedComponent === 'CrearComentario'">
        <CrearComentario :item="item" @comentarioCreado="comentarioCreado" />
      </v-card>
      <v-card v-if="selectedComponent === 'ModificarFaltante'">
        <ModificarFaltante
          :item="item"
          :usuarios="usuarios"
          @cerrarDialog="cerrarDialog"
        />
      </v-card>
      <v-card v-if="selectedComponent === 'HistorialObjeto'">
        <HistorialObjeto
          :item="item"
          :usuarios="usuarios"
          @cerrarDialog="cerrarDialog"
        />
      </v-card>
    </v-dialog>
    <!-- Dialog ver faltante -->
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="dialogVerFaltante"
    >
      <VerFaltante
        :item="item"
        :usuarios="usuarios"
        :comentarios="comentariosFaltante"
        @cerrarDialog="cerrarDialogFaltante"
      />
    </v-dialog>
    <!-- Dialog cancelar faltante -->
    <v-dialog max-width="80%" v-model="dialogCancelarFaltante">
      <v-card>
        <v-btn
          class="close-btn"
          icon
          color="blue darken-1"
          text
          @click="dialogCancelarFaltante = false"
        >
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
        <v-card-title>Cancelar faltante: {{ item.nombre }}</v-card-title>
        <v-card-text>
          <v-textarea
            outlined
            label="Detalles"
            rows="3"
            v-model="detalles"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="teal lighten-2" @click="cancelarFaltante">
            Cancelar Faltante
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import VerFaltante from "./VerFaltante.vue";
import ModificarFaltante from "./ModificarFaltante.vue";
import HistorialObjeto from "./Historial/HistorialObjeto.vue";
import CrearComentario from "./CrearComentario.vue";
export default {
  props: ["item", "usuarios"],
  components: {
    VerFaltante,
    ModificarFaltante,
    HistorialObjeto,
    CrearComentario,
  },
  data() {
    return {
      dialog: false,
      dialogVerFaltante: false,
      dialogCancelarFaltante: false,
      detalles: "",
      items: [
        // {
        //   title: "Ver Faltante",
        //   component: "VerFaltante",
        // },
        {
          title: "Crear comentario",
          component: "CrearComentario",
        },
        {
          title: "Modificar Faltante",
          component: "ModificarFaltante",
        },
        {
          title: "Historial de faltante",
          component: "HistorialObjeto",
        },
      ],
      selectedComponent: null,
      comentariosFaltante: [],
    };
  },
  methods: {
    async desplegarFaltante() {
      try {
        const response = await axios.get(
          `/varios/get_comentarios?id_objeto=${this.item.id}&tipo_objeto=faltante`
        );
        this.comentariosFaltante = response.data;
        // console.log(this.comentarios);
      } catch (error) {
        console.error(error);
      }

      this.dialogVerFaltante = true;
    },
    async cancelarFaltante() {
      try {
        const response = await axios.post(
          `/faltantes/modificar_faltante?id_faltante=${this.item.id}&nuevo_estado=Cancelado&detalles=${this.detalles}`
        );
        console.log(response.data);
        this.$emit("faltanteModificado"); // Emitir el evento 'pedidoModificado' que se encarga de actualizar los pedidos
        alert("Faltante cancelado");
        // this.cerrar();
        // location.reload();
      } catch (error) {
        console.error(error);
        alert(JSON.stringify(error.response.data.detail));
      }
      this.dialogCancelarFaltante = false;
    },
    selectComponent(item) {
      if (item.component) {
        this.selectedComponent = item.component;
        this.dialog = true;
      }
    },
    ...mapActions(["setPerfil"]),
    openPerfil(perfil) {
      this.setPerfil(perfil);
      this.$emit("openPerfil"); // Emit an event to the parent component
    },
    cerrarDialog(dialog) {
      this.dialog = dialog;
    },
    cerrarDialogFaltante() {
      this.dialogVerFaltante = false;
    },
    comentarioCreado() {
      this.$emit("faltanteModificado");
      this.dialog = false;
    },
  },
};
</script>
<style>
.close-btn {
  float: right;
  margin: 5px;
}
</style>
